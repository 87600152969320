import axios from 'axios';
import { Store } from './store';
import { Data } from './data';

export class Translations {
	objectName = 'Translations';
	allLanguages = ['nl', 'en', 'de', 'fr', 'pl'];
	translations = [];
	translationsLoaded = false;

	static get CURRENT() {
		if (Translations._CURRENT === null || typeof Translations._CURRENT === 'undefined') {
			Translations._CURRENT = new Translations();
		}
		return Translations._CURRENT;
	}

	constructor() {
		if (this.translationsLoaded === false) {
			this.loadAllTranslations(this.allLanguages, () => {
				this.translationsLoaded = true;
			});
		}
	}

	translate(text, language = null) {
		// Wanneer geen specifiek language opgegeven is de ingestelde van de store gebruiken.
		if (language === null) {
			language = Store.CURRENT.languages.currentLanguage.toLowerCase();
		}

		// lijst nog niet opgehaald dan ophalen
		if (typeof this.translations === 'undefined' || this.translations === null || language === null) {
			return text;
		}

		// Goeie lijst met vertalingen ophalen.
		const listOfTranslations = this.translations.find((translation) => translation.language === language);

		// Kan zijn dat er een taal opgegeven word die niet bestaat, dan gewoon string returnen.
		if (listOfTranslations === null || typeof listOfTranslations === 'undefined') {
			return text;
		}
		// zoek de tekst op
		let found = listOfTranslations.translations.filter((l) => l.name === text);

		// niet gevonden of geen vertaling dan lege string
		if (found.length === 0) {
			return text;
		}
		if (found[0].value === null) {
			return text;
		}
		// gevonden waarde teruggeven
		return found[0].value;
	}
	loadAllTranslations(languages = ['en'], thenCallBack, catchCallBack) {
		// Wanneer niet een array is dan puur string opgegeven, dan array maken met 1 waarde die in de string stond.
		if (!Array.isArray(languages)) {
			languages = [languages];
		}
		let self = this;
		let loadedLaguages = 0;
		let server = Data.baseUrl;

		languages.forEach((language, index) => {
			axios
				.get(server + 'translations?language=' + language)
				.then(function (response) {
					self.translations.push({ language: language, translations: response.data });
					loadedLaguages++;
					if (typeof thenCallBack === 'function' && loadedLaguages === languages.length) {
						thenCallBack();
					}
				})
				.catch((error) => {
					console.log(error);
				});
		});
	}
}
