import { DrawObject } from './drawObject';
import { DrawValue } from './drawValue';

export class Rectangle extends DrawObject {
	objectName = 'Rectangle';
	width = new DrawValue();
	height = new DrawValue();

	constructor(x, y, width, height, color = null, fillColor = null, lineColor = null, mouseDetect = null, object = null, objectParams = null, dashedLine = false, border = false, wallOffset = false) {
		super(x, y, color, fillColor, lineColor, mouseDetect, object, objectParams, dashedLine, border, wallOffset);
		this.width = width;
		this.height = height;
	}

	draw(canvas) {
		if (this.visible === false) {
			return {
				x: -1,
				y: -1,
				width: -1,
				height: -1,
			};
		}

		canvas.ctx.globalAlpha = this.opacity;
		canvas.ctx.fillStyle = this.fillColor;
		canvas.ctx.strokeStyle = this.lineColor;

		canvas.ctx.beginPath();
		const calculatedX = this.x.scaledValue(canvas.scaleFactor) + (this.wallOffset ? canvas.offsetWallLeft : canvas.offsetLeft);
		const calculatedY = this.y.scaledValue(canvas.scaleFactor) + (this.wallOffset ? canvas.offsetWallTop : canvas.offsetTop);

		const calculatedWidth = this.width.scaledValue(canvas.scaleFactor);
		const calculatedHeight = this.height.scaledValue(canvas.scaleFactor);
		this.minScaled = {
			x: calculatedX,
			y: calculatedY,
		};
		this.maxScaled = {
			x: calculatedX + calculatedWidth,
			y: calculatedY + calculatedHeight,
		};
		if (this.dashedLine) {
			canvas.ctx.setLineDash([6]);
		} else {
			canvas.ctx.setLineDash([]);
		}

		canvas.ctx.lineWidth = 1;
		canvas.ctx.rect(calculatedX, calculatedY, calculatedWidth, calculatedHeight);

		canvas.ctx.closePath();
		if (this.fillColor !== 'transparent') {
			canvas.ctx.fill();
		}
		if (this.border === true) {
			canvas.ctx.stroke();
		}

		let offset = { x: 0, y: 0 };
		if (typeof this.objectParams !== 'undefined' && this.objectParams !== null && typeof this.objectParams.mouseAreaOffset !== 'undefined') {
			offset.x = this.objectParams.mouseAreaOffset.x;
			offset.y = this.objectParams.mouseAreaOffset.y;
		} else if (typeof this.object !== 'undefined' && this.object !== null && typeof this.object.mouseAreaOffset !== 'undefined') {
			offset.x = this.object.mouseAreaOffset.x;
			offset.y = this.object.mouseAreaOffset.y;
		}

		if (this.mouseDetect === true) {
			this.mouseArea.minX = calculatedX - offset.x;
			this.mouseArea.minY = calculatedY - offset.y;
			this.mouseArea.maxX = calculatedX + calculatedWidth + offset.x;
			this.mouseArea.maxY = calculatedY + calculatedHeight + offset.y;
		}

		return {
			x: calculatedX,
			y: calculatedY,
			width: calculatedWidth,
			height: calculatedHeight,
		};
	}
	minMaxCoordinatesScaled(types) {
		return {
			min: this.minScaled,
			max: this.maxScaled,
		};
	}
	minMax() {
		return { min: { x: this.x.value, y: this.y.value }, max: { x: this.x.value + this.width.value, y: this.y.value + this.height.value } };
	}
}
