import { Data } from './data';

export class HandRail extends Data {
	objectName = 'HandRails';
	static CURRENT = null;
	url = 'HandRails';
	oid = -1;
	description = '';
	info = '';
	defaultChoice = false;
	childFriendly = false;
	sideMounted = false;
	material = {};
	handrailOid = -1;
	get value() {
		return this.handrailOid;
	}
	get caption() {
		return this.description;
	}
}
