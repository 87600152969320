import { Data } from './data';

export class CageLadderMaterials extends Data {
	objectName = 'CageLadderMaterials';
	static CURRENT = null;
	url = 'Settings/cageLadderMaterials';
	settings = null;
	articles = null;

	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}
		super.list(
			data,
			force,
			cacheId,
			urlExtension,
			(res) => {
				this.fetch(res);
				if (typeof thenCallBack === 'function') {
					thenCallBack(res);
				}
			},
			(err) => {
				if (typeof catchCallBack === 'function') {
					catchCallBack(err);
				}
			},
		);
	}
}
