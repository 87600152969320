import { Data } from './data';
import { Configuration } from './configuration';

export class Configurations extends Data {
	static SYSTEM_MANUAL = 0;
	static SYSTEM_CONFIGURATOR = 1;
	static SYSTEM_HICAD = 2;

	VIEWOPTION_2D = '2d';
	VIEWOPTION_3D = '3d';

	objectName = 'Configurations';

	searchWait = null;
	calculationDrawing = null;
	drawingId = 0;
	configDrawingTypes = [];
	searchList = [];
	searchText = '';
	readOnlyFromURL = false;

	searchConfigurations(text, amount, thenCallBack) {
		let lastRequest = this.getLastRequest('list'); // ophalen laatste request
		let request = JSON.parse(JSON.stringify(lastRequest)); // ombouwen naar een zelfstandig object
		if (typeof request.data === 'undefined' || request.data === null) {
			request = { data: {} };
		}
		if (text === '') {
			// Als zoektekst leeg is gewoon 20 ophalen
			request.data.top = amount;
			request.data.company = this.store.companies.selectedCompany.id;
		} else {
			// Anders laatste request parameters gebruiken en text als zoekveld toevoegen.
			request.data.searchText = text; // zoektekst toevoegen
		}
		super.list(
			request.data,
			true,
			-1,
			null,
			(response) => {
				if (typeof thenCallBack === 'function') {
					thenCallBack(response);
				}
			},
			(exception) => {
				console.log(exception);
				if (typeof thenCallBack === 'function') {
					thenCallBack();
				}
			},
		);
	}

	url = 'Configurations';
	viewOption = '2d';

	initializeCanvas3dDone = false;
	initializeCanvas2dDone = false;
	in3DDrawState = true;

	changeViewOption(option) {
		this.viewOption = option;
		this.changeDrawingId(this.getDrawingId(this.viewOption));
	}

	getDrawingId(viewOption) {
		let item = { name: '', id: '', type: -1 };
		if (viewOption == this.VIEWOPTION_3D) {
			item = this.configDrawingTypes.find((x) => x.name.match(/3d/i)) || item;
		} else if (viewOption == this.VIEWOPTION_2D) {
			item = this.configDrawingTypes.find((x) => x.name.match(/2d/i)) || item;
		}
		return item.id;
	}

	changeDrawingId(id) {
		this.drawingId = id;
		this.getCalculationDrawing();
	}

	getCalculationDrawing() {
		this.calculationDrawing = this.configDrawingTypes.find((x) => x.id == this.drawingId);
	}

	getCalculationDrawingTypes() {
		var url = Data.baseUrl + 'CalculationDrawings';
		url += '/drawingTypes?calculationId=' + this.activeConfigId;
		this.get(
			url,
			false,
			(data) => {
				this.configDrawingTypes = data;
				this.changeViewOption(this.VIEWOPTION_2D);
			},
			(err) => {
				console.error(err);
			},
		);
	}

	getCurrentDrawingUrl() {
		var url = Data.baseUrl + 'CalculationDrawings';
		url += '?drawingId=' + this.drawingId;
		return url;
	}

	_activeConfigId = -1;
	selectedConfiguration = new Configuration();
	get activeConfigId() {
		return this._activeConfigId;
	}
	set activeConfigId(value) {
		if (typeof value === 'string') {
			value = parseInt(value);
		}
		this._activeConfigId = value;
		this.viewOption = this.VIEWOPTION_2D;
		this.calculationDrawing = null;
		this.getCalculationDrawingTypes();
	}

	get configurationList() {
		// aparte getter gebruikt omdat list opgehaald wordt met livesearch hierdoor hoeft de lijst zelf niet te weten wat de livesearch-text is
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			return this._list.list;
		} else if (this.listRequest === null) {
			this.list();
		}
		return [];
	}

	get activeConfig() {
		// kijk of de lijst al is opgehaald
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			let gevonden = null;
			// doorloop de lijst om te kijken of er een configuratie is met betreffend id
			this._list.list.forEach((config) => {
				if (config.id === this.activeConfigId) {
					gevonden = config; // bewaar deze
				}
			});
			if (gevonden !== null) {
				// als config gevonden dan teruggeven
				return gevonden;
			}
		}
		// anders default
		return { id: -1, title: '', date: '', state: 0, system: Configurations.SYSTEM_CONFIGURATOR };
	}
	fetchListItem(item) {
		// standaard teruggeven. In andere objecten kan hier een subobject gemaakt worden
		let returnItem = new Configuration();
		returnItem.setStore(this.store);
		returnItem.fetch(item);

		return returnItem;
	}
	setStore(store) {
		super.setStore(store);
		this.selectedConfiguration.setStore(store);
		// registreren dat als de company gewijzigd is ook de offertes opgehaald worden die erbij horen
		store.companies.addOnChangeSelectedCompany(() => {
			for (let key in this.lastRequest) {
				let lastRequest = JSON.parse(JSON.stringify(this.lastRequest[key]));
				lastRequest.data.company = this.store.companies.selectedCompany.id;

				this.queue.push({
					data: lastRequest.data,
					force: false,
					cacheId: key,
					urlExtension: lastRequest.urlExtension,
					thenCallBack: this.lastRequest[key].thenCallBack, // functies gaan niet mee met parse en stringify
					catchCallBack: this.lastRequest[key].catchCallBack, // functies gaan niet mee met parse en stringify
				});
			}
		});
	}

	list(data, force, cacheId) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}

		// als nog geen company gekozen dan ook geen offertes laten zien
		if (this.store.companies.selectedCompany.id === -1) {
			return;
		}

		// company meegeven met request
		data.company = this.store.companies.selectedCompany.id;
		data.system = Configurations.SYSTEM_CONFIGURATOR;

		let list = super.list(data, force, cacheId);
		return list;
	}
	save(data, urlExtension, thenCallBack, catchCallBack) {
		// variabelen op defaultwaarde zetten
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}
		data.calculationId = this.activeConfigId;
		data.quotationId = this.store.quotations.activeQuotation.id;
		data.projectId = this.store.projects.activeProject.id;
		data.companyId = this.store.companies.selectedCompany.id;

		super.save(
			data,
			urlExtension,
			(result) => {
				this.activeConfigId = result.calculationId;
				this.store.quotations.activeId = result.quotationId;
				if (typeof thenCallBack === 'function') {
					thenCallBack(result);
					if (result.state.id === 2 && data.quotation === 'Quotation Request') {
						// Als de state is aangevraagd en quotation is 1, dat laatste word gezet in configuration.js als de save als status 1 bevat (dus aanvragen)
						window.Vue.$router.push({ path: '/dashboard' });
					}
				}
			},
			(exception) => {
				if (typeof catchCallBack === 'function') {
					catchCallBack(exception);
				}
			},
		);
	}

	saveJsonConfiguration(data, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}

		super.save(
			data,
			urlExtension,
			(result) => {
				if (typeof thenCallBack === 'function') {
					thenCallBack(result);
				}
			},
			(error) => {
				if (typeof catchCallBack === 'function') {
					catchCallBack(error);
				}
			},
		);
	}

	remove(data, urlExtension, thenCallBack, catchCallBack) {
		// variabelen op defaultwaarde zetten
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}
		data.calculationId = data.id;

		super.remove(
			data,
			urlExtension,
			(result) => {
				this.activeConfigId = result.calculationId;
				if (typeof thenCallBack === 'function') {
					thenCallBack(result);
				}
			},
			(exception) => {
				if (typeof catchCallBack === 'function') {
					catchCallBack(exception);
				}
			},
		);
	}

	duplicate(newQuotation, company, id, thenCallBack, catchCallBack) {
		let data = {};
		if (id === null || typeof id === 'undefined') {
			catchCallBack();
			return;
		}

		data.calculationId = id;
		data.newQuotation = newQuotation;
		data.companyId = company;
		let urlExtension = 'Configurations/duplicate';
		let url = Data.baseUrl + urlExtension;

		this.store.dataAccess
			.post(url, data)
			.then((result) => {
				if (typeof thenCallBack === 'function') {
					thenCallBack(result);
				}
			})
			.catch((exception) => {
				// if (this.logError === true) {
				// 	Sentry.captureMessage('url: ' + url + ', data: ' + JSON.stringify(data) + ', objectName: ' + this.objectName + ', error: ' + exception.message);
				// }
				console.log(exception);
				if (typeof catchCallBack === 'function') {
					catchCallBack(exception);
				}
			});
	}
	makeRevision(id, thenCallBack, catchCallBack) {
		let data = {};
		if (id === null || typeof id === 'undefined') {
			catchCallBack();
			return;
		}

		data.calculationId = id;
		let urlExtension = 'Configurations/revision';
		let url = Data.baseUrl + urlExtension;

		this.store.dataAccess
			.post(url, data)
			.then((result) => {
				if (typeof thenCallBack === 'function') {
					thenCallBack(result);
				}
			})
			.catch((exception) => {
				// if (this.logError === true) {
				// 	Sentry.captureMessage('url: ' + url + ', data: ' + JSON.stringify(data) + ', objectName: ' + this.objectName + ', error: ' + exception.message);
				// }
				console.log(exception);
				if (typeof catchCallBack === 'function') {
					catchCallBack(exception);
				}
			});
	}

	takePicture(data, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}

		if (typeof urlExtension === 'undefined' || urlExtension === null) {
			urlExtension = 'CalculationDrawings/save';
		}

		let url = Data.baseUrl + urlExtension;

		this.store.dataAccess
			.post(url, data)
			.then((result) => {
				if (typeof thenCallBack === 'function') {
					thenCallBack(result);
				}
			})
			.catch((error) => {
				if (typeof catchCallBack === 'function') {
					catchCallBack(error);
				}
			});
	}

	postJsonDrawing(data, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}

		let url = Data.baseUrl + urlExtension;

		this.store.dataAccess
			.post(url, data)
			.then((result) => {
				if (typeof thenCallBack === 'function') {
					thenCallBack(result);
				}
			})
			.catch((error) => {
				if (typeof catchCallBack === 'function') {
					catchCallBack(error);
				}
			});
	}
}
