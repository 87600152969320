import { Configuration } from '../configuration';
import { Profiles } from '../profiles';
import { RemoveRaster } from '../removeRaster';
import { StairTrimming } from './stairTrimming';
import { Trimmings } from './trimmings';

export class StairTrimmings extends Trimmings {
	objectName = 'StairTrimmings';
	setTrimming(x, y, width, depth, etageId) {
		if (etageId !== null && typeof etageId !== 'undefined') {
			let etage = Configuration.CURRENT.etages.getById(etageId);
			if (etage !== null && typeof etage !== 'undefined') {
				let topLeftRaster = Configuration.CURRENT.raster.getRasterByCoordinate(x, y);
				let bottomLeftRaster = Configuration.CURRENT.raster.getRasterByCoordinate(x, y + depth);
				let topRightRaster = Configuration.CURRENT.raster.getRasterByCoordinate(x + width, y);
				let bottomRightRaster = Configuration.CURRENT.raster.getRasterByCoordinate(x + width, y + depth);

				let startY;
				let endY;
				let startX;
				let endX;

				// LEFT BEAM VERTICAAL
				if (etage.isActiveRaster(new RemoveRaster(topLeftRaster.x, topLeftRaster.y)) || etage.isActiveRaster(new RemoveRaster(bottomLeftRaster.x, bottomLeftRaster.y))) {
					// Bij horizontaal vanaf begin vorige raster tot eind huidige raster.
					if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
						startY = Configuration.CURRENT.raster.getSizeY(topLeftRaster.y - 1);
						endY = Configuration.CURRENT.raster.getSizeY(bottomLeftRaster.y);
					} else {
						// Anders alleen langs eigen trapgat.
						startY = y;
						endY = y + depth;
					}

					// Wanneer linksboven niet actief is dan is beginpunt start van volgende raster.
					// Dit komt voor als je trap naar onder hebt, en hij aan de bovenkant van een raster uitsteekt.
					if (etage.isActiveRaster(new RemoveRaster(topLeftRaster.x, topLeftRaster.y)) === false) {
						startY = Configuration.CURRENT.raster.getSizeY(topLeftRaster.y);
					}

					// Wanneer linksonder niet actief dan eindigen bij eindpunt raster vnuit linksonderhoek gezien
					// Dit komt voor als je een trap naar bove hebt, en hij aan de onderkant van een raster uitsteekt.
					if (etage.isActiveRaster(new RemoveRaster(bottomLeftRaster.x, bottomLeftRaster.y)) === false) {
						endY = Configuration.CURRENT.raster.getSizeY(topLeftRaster.y);
					}

					this.trimmings.push(new StairTrimming({ startX: x, startY: startY, endX: x, endY: endY, position: StairTrimmings.LEFT }));
				}

				// RIGHT BEAM VERTICAAL
				if (etage.isActiveRaster(new RemoveRaster(topRightRaster.x, topRightRaster.y)) || etage.isActiveRaster(new RemoveRaster(bottomRightRaster.x, bottomRightRaster.y))) {
					if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
						startY = Configuration.CURRENT.raster.getSizeY(topRightRaster.y - 1);
						endY = Configuration.CURRENT.raster.getSizeY(bottomRightRaster.y);
					} else {
						startY = y;
						endY = y + depth;
					}

					// Wanneer rechtsboven niet actief is dan is beginpunt start van volgende raster.
					// Dit komt voor als je trap naar onder hebt, en hij aan de bovenkant van een raster uitsteekt.
					if (etage.isActiveRaster(new RemoveRaster(topRightRaster.x, topRightRaster.y)) === false) {
						startY = Configuration.CURRENT.raster.getSizeY(topRightRaster.y);
					}

					// Wanneer rechtsonder raster niet actief is pakken we altijd het begin van het topraster.
					// Dit komt voor als je een trap naar bove hebt, en hij aan de onderkant van een raster uitsteekt.
					if (etage.isActiveRaster(new RemoveRaster(bottomRightRaster.x, bottomRightRaster.y)) === false) {
						endY = Configuration.CURRENT.raster.getSizeY(topRightRaster.y);
					}
					this.trimmings.push(new StairTrimming({ startX: x + width, startY: startY, endX: x + width, endY: endY, position: StairTrimmings.RIGHT }));
				}

				// TOP BEAM HORIZONTAAL
				if (etage.isActiveRaster(new RemoveRaster(topLeftRaster.x, topLeftRaster.y)) || etage.isActiveRaster(new RemoveRaster(topRightRaster.x, topRightRaster.y))) {
					// Bij horizontaal alleen langs de eigen stairwell.
					if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
						startX = x;
						endX = x + width;
					} else {
						// Bij verticaal dan juist tussen begin huidige tot eind huidige raster.
						startX = Configuration.CURRENT.raster.getSizeX(topLeftRaster.x - 1);
						endX = Configuration.CURRENT.raster.getSizeX(topRightRaster.x);
					}

					// Wanneer linksboven niet actief is, dan eind vorig raster is start punt beam.
					// Dit komt voor als je trap naar rechts hebt, en hij steekt aan de linkerkant buiten de vloer uit.
					// Dan is het startpunt het begin van eerstvolgende raster waar hij invalt.
					if (etage.isActiveRaster(new RemoveRaster(topLeftRaster.x, topLeftRaster.y)) === false) {
						startX = Configuration.CURRENT.raster.getSizeX(topLeftRaster.x);
					}

					// Wanneer rechtsboven niet actief is, dan eind vorig raster is eind punt beam.
					// Dit komt voor wanneer een trap naar links en hij aan de rechterkant buiten het raster uitsteekt.
					// In dat geval het einde aanpassen van het raster waar hij dan uit steekt.
					if (etage.isActiveRaster(new RemoveRaster(topRightRaster.x, topRightRaster.y)) === false) {
						endX = Configuration.CURRENT.raster.getSizeX(topLeftRaster.x);
					}

					this.trimmings.push(new StairTrimming({ startX: startX, startY: y, endX: endX, endY: y, position: StairTrimmings.TOP }));
				}

				// BOTTOM BEAM HORIZONTAAL
				if (etage.isActiveRaster(new RemoveRaster(bottomLeftRaster.x, bottomLeftRaster.y)) || etage.isActiveRaster(new RemoveRaster(bottomRightRaster.x, bottomRightRaster.y))) {
					if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
						startX = x;
						endX = x + width;
					} else {
						startX = Configuration.CURRENT.raster.getSizeX(bottomLeftRaster.x - 1);
						endX = Configuration.CURRENT.raster.getSizeX(bottomRightRaster.x);
					}

					// Wanneer linksonder niet actief is, dan eind vorig raster is start punt beam.
					// Dit komt voor als je trap naar rechts hebt, en hij steekt aan de linkerkant buiten de vloer uit.
					// Dan is het startpunt het begin van eerstvolgende raster waar hij invalt.
					if (etage.isActiveRaster(new RemoveRaster(bottomLeftRaster.x, bottomLeftRaster.y)) === false) {
						startX = Configuration.CURRENT.raster.getSizeX(bottomLeftRaster.x);
					}

					// Wanneer rechtsonder niet actief is, dan eind vorig raster is eind punt beam.
					// Dit komt voor wanneer een trap naar links en hij aan de rechterkant buiten het raster uitsteekt.
					// In dat geval het einde aanpassen van het raster waar hij dan uit steekt.
					if (etage.isActiveRaster(new RemoveRaster(bottomRightRaster.x, bottomRightRaster.y)) === false) {
						endX = Configuration.CURRENT.raster.getSizeX(bottomLeftRaster.x);
					}

					this.trimmings.push(new StairTrimming({ startX: startX, startY: y + depth, endX: endX, endY: y + depth, position: StairTrimmings.BOTTOM }));
				}
			}
		}
	}
}
