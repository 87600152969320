import { Line } from '../../draw/line';
import { DrawValue } from '../../draw/drawValue';
import { Configuration } from '../configuration';
import { Profiles } from '../profiles';
import { HoleTrimmings } from './holeTrimmings';
import { Columns } from '../columns';
import { TrimmingTop3D } from '../../draw3d/buildingcolumn/trimming/TrimmingTop3D';
import { TrimmingBottom3D } from '../../draw3d/buildingcolumn/trimming/TrimmingBottom3D';
import { TrimmingLeft3D } from '../../draw3d/buildingcolumn/trimming/TrimmingLeft3D';
import { TrimmingRight3D } from '../../draw3d/buildingcolumn/trimming/TrimmingRight3D';
import { Canvas3D } from '../../draw3d/Canvas3D';
import { Trimming } from './Trimming';
import { Trimmings } from './trimmings';

export class HoleTrimming extends Trimming {
	objectName = 'HoleTrimming';
	addDrawObjects(parentObject) {
		return super.addDrawObjects(this.objectName, parentObject);
	}
	addDrawObjects3d(canvas3d, etage, raster, posY) {
		let drawHeight = posY + etage.getHeight(true);
		let rasterCoords = etage.raster.getRasterByCoordinate(this.startX, this.startY);
		let rowData = Configuration.CURRENT.raster.getRowPositions(rasterCoords.x, rasterCoords.y);
		let span;

		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			if (rasterCoords.x === -1) {
				rasterCoords.x = 0;
			}
			span = etage.raster.spansX.get(rasterCoords.x);
		} else {
			if (rasterCoords.y === -1) {
				rasterCoords.y = 0;
			}
			span = etage.raster.spansY.get(rasterCoords.y);
		}

		let paramsObject = {
			drawHeight: drawHeight,
			rowPositions: rowData.rowPositions,
			placement: rowData.placement,
			profileType: 'mainbeam',
			oid: span.beam.oid,
			mainBeamDepth: Canvas3D.CURRENT.getBeamData(span.beam.oid, 'depth'),
			mainBeamHeight: Canvas3D.CURRENT.getBeamData(span.beam.oid, 'height'),
		};

		switch (this.position) {
			case Trimmings.TOP:
				canvas3d.addDrawObject(new TrimmingTop3D(this.startX, this.startY, this.endX - this.startX, paramsObject), Canvas3D.TYPE_PROFILE);
				break;
			case Trimmings.BOTTOM:
				canvas3d.addDrawObject(new TrimmingBottom3D(this.startX, this.startY, this.endX - this.startX, paramsObject), Canvas3D.TYPE_PROFILE);
				break;
			case Trimmings.LEFT:
				canvas3d.addDrawObject(new TrimmingLeft3D(this.startX, this.startY, this.endY - this.startY, paramsObject), Canvas3D.TYPE_PROFILE);
				break;
			case Trimmings.RIGHT:
				canvas3d.addDrawObject(new TrimmingRight3D(this.startX, this.startY, this.endY - this.startY, paramsObject), Canvas3D.TYPE_PROFILE);
				break;
		}
	}
}
