export class Statics {
	static MEASUREMENT_IMPERIAL = 'imperial';
	static MEASUREMENT_METRIC = 'metric';

	// White for holes, or advanced drawing for objects (like stair in floor or cageLadder / hole in the floor.
	static COLOR_WHITE = '#FFFFFF';

	// The default color for drawing objects:
	static COLOR_GREY = 'grey';

	// Collision for when object collildes with another object.
	// And for other type of errors (height, with, depth, outside floor etc).
	static COLOR_COLLISION = 'red';
	static COLOR_ERROR = 'red';

	// Or when hovering over a position which is made by calling the AddPossible position for the chosen object.
	static COLOR_POSITION_POSSIBLE = '#6BA3FF';
	static COLOR_POSITION_POSSIBLE_HOVERD = 'blue';
	static COLOR_POSSIBLE_POSITION_ACTIVE = 'green';

	// When hovering over an item / object make the outline / color green.
	static COLOR_MOUSE_OVER = 'green';

	// Color of object when not on etage.
	static COLOR_NOT_ON_ETAGE = '#D9D9D9';

	// When configuration object is selected, make the lines blue.
	static COLOR_SELECTED = 'blue';
	static COLOR_SELECTED_FILLCOLOR = '#C2C9F0';

	// Color for drawing mainbeams and trimming for objects (stair, cageladder, trimming).
	static COLOR_MAINBEAM = '#FCC43E';
	static COLOR_CHILDBEAM = '#E5E5E5';

	static LINEDASH = [5, 3];
}
