import { SizeHandle } from './sizeHandle';

export class SizeHandleTotal extends SizeHandle {
	objectName = 'SizeHandleTotal';
	referenceHandle = '';
	constructor(id, order, resizable) {
		super(id, order, resizable);
	}
	addDrawObjects(params) {
		super.addDrawObjects(params);
	}
}
