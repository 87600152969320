import { Data } from './data';
import { Store } from './store';

export class Stairs extends Data {
	objectName = 'Stairs';
	static CURRENT = null;
	url = 'Stairs';

	getDefaultStair() {
		// Kijk of lijst al opgehaald is

		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			// zoek trap die als defaultkeuze is aangevinkt
			let defaultStair = this._list.list.filter(function (stair) {
				return stair.defaultChoice === true;
			});
			if (defaultStair.length > 0) {
				// als defaultStair gevonden
				// return { caption: '', value: '' };
				return { caption: defaultStair[0].caption, value: defaultStair[0].value };
			}
		} else if (this.listRequest === null && Store.CURRENT.companies.selectedCompany.id > -1) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			this.list();
		} // geen lijst of niet gevonden dan leeg object

		return { caption: '', value: '' };
	}

	getItem(id, byType = false) {
		// omdat de hele lijst met list opgehaald wordt hier geen aparte routine of gebruik van get om een item op de server op te halen.
		// Kijk of lijst al opgehaald is

		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			let foundStair = { caption: '', value: '' };

			this._list.list.forEach((stair) => {
				if ((stair.stairOid === id && !byType) || (stair.value === id && byType)) {
					foundStair = stair;
				}
			});

			return foundStair;
		} else if (this.listRequest === null && Store.CURRENT.companies.selectedCompany.id > -1) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			this.list();
		} // geen lijst of niet gevonden dan leeg object
		return { caption: '', value: '' };
	}
	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}

		if (typeof data.companyId === 'undefined' || data.companyId === null || data.companyId === -1) {
			data.companyId = Store.CURRENT.companies.selectedCompany.id;
		}

		return super.list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack);
	}
	isStairChildFriendly(type) {
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			let filterdStairs = this._list.list.filter((stair) => stair.value === type);
			if (filterdStairs.length === 1) {
				return filterdStairs[0].childFriendly;
			}
		} else {
			return false;
		}
	}
}
