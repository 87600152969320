import { Configuration } from '../../../../configurator/configuration';
import { Stair } from '../../../../configurator/stair';
import { Canvas3D } from '../../../Canvas3D';
import { Model3D } from '../../../Model3D';

// TODO: DIT OBJECT NALOPEN
export class StairHandRailChildPole3D extends Model3D {
	objectName = 'StairHandRailChildPole3D';
	hoverTags = ['stairs'];
	constructor(x, y, yPos, params) {
		super(x, 0, y, params);
		this.yPos = yPos;
		this.ralColor = Configuration.CURRENT.colors.stairs.ralColor;
	}
	draw(app) {
		let childPoleWidth = Canvas3D.CURRENT.getModelDataByOid(this.stairData.material.childFriendlyHandRailPost, 'width');
		let plateHeight = Canvas3D.CURRENT.getModelDataByOid(this.stairData.material.handRail, 'height');
		let stingerDepth = Canvas3D.CURRENT.getModelDataByOid(this.stairData.material.stinger, 'depth');
		this.y += this.yPos;
		this.y += this.shared.yPos;

		switch (this.shared.upComing) {
			case Stair.UPCOMING_TOP:
				this.y -= plateHeight / 2;
				if (this.trapboomPosition === 'right') {
					this.x -= this.stairData.step.width;
					this.x += (stingerDepth - childPoleWidth) / 2;
				} else {
					this.x -= stingerDepth;
					this.x += (stingerDepth - childPoleWidth) / 2;
				}
				break;

			case Stair.UPCOMING_LEFT:
			case Stair.UPCOMING_RIGHT:
				if (this.trapboomPosition === 'right') {
					this.z += this.stairData.step.width;
					this.z -= stingerDepth;
					this.z += (stingerDepth - childPoleWidth) / 2;
				} else {
					this.z += (stingerDepth - childPoleWidth) / 2;
				}
				break;

			case Stair.UPCOMING_BOTTOM:
				if (this.trapboomPosition === 'right') {
					this.x += this.stairData.step.width;
					this.x -= stingerDepth;
					this.x += (stingerDepth - childPoleWidth) / 2;
				} else {
					this.x += (stingerDepth - childPoleWidth) / 2;
				}
				break;
		}

		if (this.stairData.place === Stair.PLACE_INFLOOR) {
			switch (this.shared.upComing) {
				case Stair.UPCOMING_TOP:
					this.z -= this.shared.deltaX;
					break;
				case Stair.UPCOMING_BOTTOM:
					this.z += this.shared.deltaX;
					break;
				case Stair.UPCOMING_LEFT:
					this.x -= this.shared.deltaX;
					break;
				case Stair.UPCOMING_RIGHT:
					this.x += this.shared.deltaX;
					break;
			}
		}
		super.draw(app);
	}
}
