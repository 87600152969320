import { Mathematic } from '../helpers/mathematic';
import { DrawObject } from './drawObject';
import { DrawValue } from './drawValue';

export class Circle extends DrawObject {
	objectName = 'Circle';
	// Startposite X en Y
	x = new DrawValue();
	y = new DrawValue();
	depth = new DrawValue();
	// Radius is hoe breedt de circkel word vanaf middenpunt x en y gezien.
	radius = new DrawValue();
	// Starthoek is in graden. In draw omrekenen naar radius.
	// Begin tekenen cirkel altijd vanaf linkse punt gezien.
	startAngle = new DrawValue();
	endAngle = new DrawValue();
	counterClockWise = new DrawValue();
	opacity = 1;
	constructor(x, y, radius = null, depth = 0, startAngle, endAngle, color = null, lineColor = null, mouseDetect = null, object = null, objectParams = null, counterClockWise = false, position) {
		super(x, y, color, null, lineColor, mouseDetect, object, objectParams, false, false, false);
		this.x = x;
		this.y = y;
		this.radius = radius;
		this.depth = depth;
		this.startAngle = startAngle;
		this.endAngle = endAngle;
		this.counterClockWise = counterClockWise;
		this.position = position;
	}
	draw(canvas) {
		if (this.visible === false) {
			return {
				x: -1,
				y: -1,
				width: -1,
				height: -1,
			};
		}

		const calculatedX = this.x.scaledValue(canvas.scaleFactor) + (this.wallOffset ? canvas.offsetWallLeft : canvas.offsetLeft);
		const calculatedY = this.y.scaledValue(canvas.scaleFactor) + (this.wallOffset ? canvas.offsetWallTop : canvas.offsetTop);
		const calculatedRadius = this.radius.scaledValue(canvas.scaleFactor);
		const startAngleValue = this.startAngle - 180;
		const endAngleValue = this.endAngle - 180;

		this.minScaled = {
			x: calculatedX - calculatedRadius / 2,
			y: calculatedY - calculatedRadius / 2,
		};
		this.maxScaled = {
			x: calculatedX + calculatedRadius / 2,
			y: calculatedY + calculatedRadius / 2,
		};

		canvas.ctx.globalAlpha = this.opacity;
		canvas.ctx.strokeStyle = this.lineColor;
		canvas.ctx.beginPath();

		if (this.width !== null && typeof this.width !== 'undefined') {
			canvas.ctx.lineWidth = this.width.scaledValue(canvas.scaleFactor);
		}

		// CaculatedX is gewoon the this.x vanuit object.
		// Circel tekent altijd rond as heen, als we dan ook op this.x moeten beginnen halve circel radius daarbij rekenen.
		// Zelfde geld dan natuurlijk voor verticale halve circkel.
		if (this.position === Mathematic.TOP || this.position === Mathematic.BOTTOM) {
			canvas.ctx.arc(calculatedX + calculatedRadius / 2, calculatedY, calculatedRadius / 2, (startAngleValue * Math.PI) / 180, (endAngleValue * Math.PI) / 180, this.counterClockWise);
		} else if (this.position === Mathematic.LEFT || this.position === Mathematic.RIGHT) {
			canvas.ctx.arc(calculatedX, calculatedY + calculatedRadius / 2, calculatedRadius / 2, (startAngleValue * Math.PI) / 180, (endAngleValue * Math.PI) / 180, this.counterClockWise);
		}
		canvas.ctx.stroke();

		if (this.mouseDetect === true) {
			switch (this.position) {
				case Mathematic.RIGHT:
					this.mouseArea.minX = calculatedX;
					this.mouseArea.maxX = calculatedX + calculatedRadius / 2;
					this.mouseArea.minY = calculatedY;
					this.mouseArea.maxY = calculatedY + calculatedRadius;
					break;
				case Mathematic.LEFT:
					this.mouseArea.minX = calculatedX - calculatedRadius / 2;
					this.mouseArea.maxX = calculatedX;
					this.mouseArea.minY = calculatedY;
					this.mouseArea.maxY = calculatedY + calculatedRadius;
					break;
				case Mathematic.TOP:
					this.mouseArea.minX = calculatedX;
					this.mouseArea.maxX = calculatedX + calculatedRadius;
					this.mouseArea.minY = calculatedY - calculatedRadius / 2;
					this.mouseArea.maxY = calculatedY;
					break;
				case Mathematic.BOTTOM:
					this.mouseArea.minX = calculatedX;
					this.mouseArea.maxX = calculatedX + calculatedRadius;
					this.mouseArea.minY = calculatedY;
					this.mouseArea.maxY = calculatedY + calculatedRadius;
					break;
			}
		}

		return {
			x: calculatedX,
			y: calculatedY,
			width: this.mouseArea.maxX - this.mouseArea.minX,
			height: this.mouseArea.maxY - this.mouseArea.minY,
		};
	}
	minMaxCoordinatesScaled(types) {
		return {
			min: this.minScaled,
			max: this.maxScaled,
		};
	}
	minMax() {
		switch (this.position) {
			case Mathematic.TOP:
				return { min: { x: this.x.value, y: this.y.value - this.depth.value }, max: { x: this.x.value + this.radius.value, y: this.y.value } };
			case Mathematic.BOTTOM:
				return { min: { x: this.x.value, y: this.y.value }, max: { x: this.x.value + this.radius.value, y: this.y.value + this.depth.value } };
			case Mathematic.LEFT:
				return { min: { x: this.x.value - this.depth.value, y: this.y.value }, max: { x: this.x.value, y: this.y.value + this.radius.value } };
			case Mathematic.RIGHT:
				return { min: { x: this.x.value, y: this.y.value }, max: { x: this.x.value + this.depth.value, y: this.y.value + this.radius.value } };
		}
	}
}
