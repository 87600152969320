import { Canvas3D } from '../../Canvas3D';
import { Configuration } from '../../../configurator/configuration';
import { ColumnProtector3D } from '../ColumnProtector3D';

export class ProtectorRight3D extends ColumnProtector3D {
	objectName = 'protectorRight3D';
	rotationY = -90;
	draw() {
		let modelWidth = Canvas3D.CURRENT.getModelDataByOid(this.oid, 'width');
		let modelDepth = Canvas3D.CURRENT.getModelDataByOid(this.oid, 'depth');
		let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');

		this.z += (columnWidth - modelWidth) / 2;
		this.x += modelDepth + columnWidth / 2;

		super.draw();
	}
}
