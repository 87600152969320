export class Mathematic {
	static TOP = 1;
	static RIGHT = 2;
	static BOTTOM = 3;
	static LEFT = 4;
	static overlapRectangles(LeftTop1, RightBottom1, LeftTop2, RightBottom2) {
		// Zie voor berekening https://www.geeksforgeeks.org/find-two-rectangles-overlap/
		// twee vierkanten pak van beide linksboven en Rechtsonder. Noem bij vierkant 1 Linksboven L1 en rechtsonder R1 en bij tweede vierkant L2 en R2
		// Vervolgens geldt dat als: L1.x > R2.x of L2.x > R1.x hij niet overlapt (maar naast elkaar staan) en als L1.y>R2.y of L2.y>R1.y hij ookt niet overlapt (Ze staan boven er)
		// In het voorbeeld staat overigens een fout daar staat < ipv > bij de laatste if

		// If one rectangle is on left side of other
		if (LeftTop1.x > RightBottom2.x || LeftTop2.x > RightBottom1.x) {
			return false;
		}

		// If one rectangle is above other
		if (LeftTop1.y > RightBottom2.y || LeftTop2.y > RightBottom1.y) {
			return false;
		}
		return true;
	}
	static calculateDepth(height, angle) {
		return height / Math.tan((angle * Math.PI) / 180);
	}

	static _2ndPower(integer) {
		return Math.pow(integer, 2);
	}

	static degreesToRadians(degrees) {
		return degrees * (Math.PI / 180);
	}

	static radiansToDegrees(radians) {
		return radians * (180 / Math.PI);
	}

	static pyth(args) {
		let known = ['a', 'b', 'c'].filter((key) => typeof args[key] !== 'undefined');

		if (known.length !== 2) {
			console.log(known, args);
			throw Error('You should know 2 of the 3 sides');
		}

		if (known.includes('c')) {
			const otherSide = args.a || args.b;
			return Math.sqrt(this._2ndPower(args.c) - this._2ndPower(otherSide));
		}

		return Math.sqrt(this._2ndPower(args.a) + this._2ndPower(args.b));
	}

	// TODO uitgebreide versies maken van hoekberekening voor niet-rechthoekige driehoeken
	// https://owlcation.com/stem/Everything-About-Triangles-and-More-Isosceles-Equilateral-Scalene-Pythagoras-Sine-and-Cosine#:~:text=Answer%3A%20If%20you%20know%20two,%2C%20so%20b%20%3D%20cCos%20A.
	static soscastoa(args) {
		let known = ['a', 'o', 's', 'angle'].filter((key) => typeof args[key] !== 'undefined');

		if (known.length < 2) {
			console.log(known, args);
			throw Error('You should know at least 2 properties of the triangle');
		}

		if (!known.includes('angle')) {
			if (known.includes('o') && known.includes('s')) {
				args.angleRad = Math.asin(args.o / args.s);
				args.angle = this.radiansToDegrees(args.angleRad);
				args.a = Math.cos(args.angleRad) * args.s;
				known.push('angle', 'a');
			}

			if (known.includes('a') && known.includes('s')) {
				args.angleRad = Math.acos(args.a / args.s);
				args.angle = this.radiansToDegrees(args.angleRad);
				args.o = Math.sin(args.angleRad) * args.s;
				known.push('angle', 'o');
			}

			if (known.includes('o') && known.includes('a')) {
				args.angleRad = Math.atan(args.o / args.a);
				args.angle = this.radiansToDegrees(args.angleRad);
				args.s = args.o / Math.sin(args.angleRad);
				known.push('angle', 's');
			}
		} else {
			args.angleRad = this.degreesToRadians(args.angle);

			if (known.includes('a')) {
				args.o = Math.tan(args.angleRad) * args.a;
				args.s = args.a / Math.cos(args.angleRad);
				known.push('o', 's');
			}

			if (known.includes('o')) {
				args.a = args.o / Math.tan(args.angleRad);
				args.s = args.o / Math.sin(args.angleRad);
				known.push('a', 's');
			}

			if (known.includes('s')) {
				args.a = Math.cos(args.angleRad) * args.s;
				args.o = Math.sin(args.angleRad) * args.s;
				known.push('a', 'o');
			}
		}

		args.otherAngle = 90 - args.angle;
		args.otherAngleRad = this.degreesToRadians(args.otherAngle);
		return args;
	}

	// Imperial functies.
	//** 1 Feet = 12 inches. */
	//** 1 Inch = 25.4mm. */
	//
	// Inputvales to inches.
	static allInputToInches(inputValues, precision = 16) {
		let inches = 0;

		// Amount of inches from feet.
		inches += inputValues.feet * 12;

		// Add amount of inches given.
		inches += inputValues.inches;

		// Part recalculate to inches.
		inches += inputValues.part / precision;

		return inches;
	}

	static mmToImperial(mmValue, precision = 16) {
		if (mmValue <= 0) {
			return { feet: 0, inches: 0, part: 0 };
		}
		let feetValue = 0;

		// First calculate amount of inches in total.
		let inchesValue = mmValue / 25.4;

		// When more than 12 we have inches, calculate the amount of feets.
		// Because: 12 inches = 1 feet.
		if (inchesValue > 12) {
			feetValue = Math.floor(inchesValue / 12);
			// calculate the rest of the inches, by removing the amount of inches we used to calculate the feets.
			inchesValue -= feetValue * 12;
		}

		let intInches = Math.floor(inchesValue);
		inchesValue -= intInches;

		let inchesFractional = Math.round(inchesValue * precision);

		// TODO: Kijken of dit nodig is om de precision uit te rekenen.
		// TODO: Nu kan er nog 16/16 staan terwijl dit omgerekend moet worden naar een extra inch.
		// // Wanneer precies gelijk aan de precision,
		// // Dan één inch erbij en fractional op 0 zetten.
		// if (inchesFractional === precision) {
		// 	inchesFractional = 0;
		// 	intInches += 1;

		// 	// Wanneer dan inches 12 is dan hebben we +1 feet. en 0 inches.
		// 	if (intInches > 12) {
		// 		feetValue += 1;
		// 		intInches = 0;
		// 	}
		// }

		// console.log(Math.round(inchesValue * precision), new Error());

		return { feet: feetValue, inches: intInches, part: inchesFractional, precision: precision };
	}

	// Bij invoervelden de mm To imperial eerst uitvoeren en dan de teruggegeven waardes goed formatten.
	static ImperialToInputString(mmValue) {
		if (mmValue > 0) {
			let values = Mathematic.mmToImperial(mmValue);
			return values.feet + "'" + values.inches + ' ' + values.part + '/' + values.precision + "''";
		}
		// Wanneer mm value
		return "0'0 0/0''";
	}
	static ImperialToImputStringOnlyInches(mmValue) {
		return Math.round(mmValue / 25.4);
	}

	static ImperialToImputStringOnlyInches(mmValue) {
		return Math.round(mmValue / 25.4);
	}

	static inchesToMM(inches) {
		return inches * 25.4;
	}

	// Functions for Floor surface and KGM2
	static GetM2ConvertedToImperial(m2Surface, precision = 16) {
		if (m2Surface <= 0) {
			return 0;
		}

		let sqFeet = 0;
		let sqInches = 0;
		let sqInchesFractional = 0;

		// Eerst de inches uitrekenen.
		sqInches = m2Surface * 1550.0;

		if (sqInches > 144) {
			// 144 Square inches = 1 Square foot.
			sqFeet = Math.floor(sqInches / 144);
			sqInches -= sqFeet * 144;
		}

		let intsqInches = Math.floor(sqInches);
		sqInches -= intsqInches;

		if (sqInches > 0) {
			sqInchesFractional = Math.round(sqInches * precision).toString() + '/' + precision.toString();
		}

		return sqFeet + 'ft² ' + intsqInches + ' ' + sqInchesFractional + 'in²';
	}

	static KgM2ToImperial(kgM2Value) {
		return Math.round(kgM2Value * 0.20482 * 100) / 100;
	}

	static CalculateDistanceFitObject(minX, maxX, minY, maxY, minZ, maxZ, fov, aspectRatio, pitch, yaw, pivotX, pivotY, pivotZ) {
		let width = maxX - minX;
		let height = maxY - minY;
		let depth = maxZ - minZ;

		// Calculate the center of the bounding box
		let centerX = (minX + maxX) / 2;
		let centerY = (minY + maxY) / 2;
		let centerZ = (minZ + maxZ) / 2;

		// Calculate the offset from the pivot point to the center of the bounding box
		let offsetX = centerX - pivotX;
		let offsetY = centerY - pivotY;
		let offsetZ = centerZ - pivotZ;

		// Convert FOV, pitch, and yaw from degrees to radians
		let fovRadians = (fov * Math.PI) / 180;
		let pitchRadians = (pitch * Math.PI) / 180;
		let yawRadians = (yaw * Math.PI) / 180;

		// Calculate the distance required to fit the width
		let distanceWidth = width / 2 / Math.tan(fovRadians / 2);

		// Calculate the vertical FOV based on the aspect ratio
		let verticalFovRadians = 2 * Math.atan(Math.tan(fovRadians / 2) / aspectRatio);

		// Calculate the distance required to fit the height, considering the pitch
		let distanceHeight = height / 2 / (Math.tan(verticalFovRadians / 2) * Math.cos(pitchRadians));

		// Use the larger of the two distances to ensure the object fits
		let distance = Math.max(distanceWidth, distanceHeight);

		// Adjust the distance based on the offset from the pivot point
		let offsetDistance = Math.sqrt(offsetX ** 2 + offsetY ** 2 + offsetZ ** 2);
		distance += offsetDistance;

		// Adjust the distance based on the pitch angle
		if (Math.abs(pitch) !== 90) {
			let pitchAdjustment = Math.cos(pitchRadians);
			distance /= pitchAdjustment;
		} else {
			distance = offsetDistance + width + depth;
		}

		// Adjust the distance based on the yaw angle
		if (Math.abs(yaw) !== 90 && Math.abs(yaw) !== 270) {
			let yawAdjustment = Math.cos(yawRadians);
			distance /= yawAdjustment;
		}

		if (distance < 0) {
			// Inverse
			distance *= -1;
		}

		return distance;
	}
}
