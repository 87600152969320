import { Data } from './data';
import { Finish } from './finish';

export class Finishes extends Data {
	objectName = 'Finishes';
	static CURRENT = null;
	url = 'Finishes';
	static PowderCoated = 0;
	static Galvanised = 1;
	static None = 2;

	list(data, force, cacheId) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}

		let list = super.list(data, force, cacheId);
		return list;
	}
	fetchListItem(item) {
		let returnItem = new Finish();
		returnItem.setStore(this.store);
		returnItem.fetch(item);
		return returnItem;
	}

	getColorList() {
		return super.list();
	}
}
