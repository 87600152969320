import { Store } from '../../data/store';
import { Canvas } from '../../draw/canvas';
import { Circle } from '../../draw/circle';
import { DrawValue } from '../../draw/drawValue';
import { Functions } from '../../helpers/functions';
import { Mathematic } from '../../helpers/mathematic';
import { Statics } from '../../helpers/statics';
import { CustomError } from '../CustomError';
import { Configuration } from '../configuration';
import { Errors } from '../errors';
import { Stair } from '../stair';

export class CageLadder {
	objectName = 'CageLadder';
	static CAGE_DEPTH = 800;
	static CAGE_WIDTH = 680;
	// We doen dit omdat etageheight inprincipe losstaat van trap verticale hoogte.
	// De trap verticale hoogte is dan het stuk waartussen de trap zit moet bouwen.
	newCageLadder = false;
	id = null;
	drawGroupId = null;
	startHeight = 0;
	endHeight = 0;
	oid = -1;
	stepWidth = 600;
	startEtageIndex = null;
	endEtageIndex = null;
	etageId = null;
	active = true;
	rasters = [];
	x = 0;
	y = 0;
	boundaries = [];
	position = null;
	place = Stair.PLACE_OUTSIDE;
	selected = false;

	errors = new Errors();
	get hasErrors() {
		return this.errors.length > 0;
	}
	getErrors() {
		return this.errors;
	}

	// Get for width and depth based on the position of the cageLadder.
	getWidth() {
		if (this.position === Mathematic.TOP || this.position === Mathematic.BOTTOM) {
			return CageLadder.CAGE_WIDTH;
		} else if (this.position === Mathematic.LEFT || this.position === Mathematic.RIGHT) {
			return CageLadder.CAGE_DEPTH;
		}
	}
	getDepth() {
		if (this.position === Mathematic.TOP || this.position === Mathematic.BOTTOM) {
			return CageLadder.CAGE_DEPTH;
		} else if (this.position === Mathematic.LEFT || this.position === Mathematic.RIGHT) {
			return CageLadder.CAGE_WIDTH;
		}
	}

	constructor(newCageLadderConfiguration, cageLadder) {
		this.id = Functions.uuidv4();
		if (typeof newCageLadderConfiguration !== 'undefined' && newCageLadderConfiguration !== null) {
			this.update(newCageLadderConfiguration);
		}
		if (typeof cageLadder !== 'undefined' && cageLadder !== null) {
			this.oid = cageLadder.oid;
		}
	}

	update(cageLadder) {
		Object.keys(cageLadder).forEach((object, index) => {
			if (typeof this[object] !== 'undefined') {
				this[object] = cageLadder[object];
			}
		});
		this.validate();
	}

	onChange() {
		if (typeof this._onChange === 'function') {
			this._onChange();
		}
	}

	setBoundaries() {
		this.boundaries = [
			{
				topLeft: { x: this.x, y: this.y },
				topRight: { x: this.x + this.getWidth(), y: this.y },
				bottomLeft: { x: this.x, y: this.y + this.getDepth() },
				bottomRight: { x: this.x + this.getWidth(), y: this.y + this.getDepth() },
			},
		];
	}

	onRasterChanged() {
		this.setStartAndEndHeight();
		// Wanneer startHoogte 0 is dan startEtageIndex -1, omdat hij dan vanaf de vloer tekent.
		this.startEtageIndex = this.startHeight === 0 ? -1 : Configuration.CURRENT.etages.getEtageByHeight(this.startHeight).etageIndex;
		this.endEtageIndex = Configuration.CURRENT.etages.getEtageByHeight(this.endHeight).etageIndex;
		// Voor nu altijd 0 pakken, komen er meerdere type cageladders dan Hier goede opzoeken op basis van nieuw select vield of dergelijke.
		this.oid = Store.CURRENT.cageLadders.getListItems()[0].oid;
	}
	onMouseMove(evt, drawObject) {
		Canvas.CURRENT.canvas.style.cursor = 'move';
		drawObject.lineColor = Statics.COLOR_MOUSE_OVER;
		return { stopPropagation: true };
	}
	onMouseLeave(evt, drawObject) {
		Canvas.CURRENT.canvas.style.cursor = 'default';
		drawObject.lineColor = this.selected ? Statics.COLOR_SELECTED : this.hasErrors ? Statics.COLOR_COLLISION : Statics.COLOR_GREY;
		return { stopPropagation: true };
	}

	onMouseUp(evt, drawObject) {
		this.setBoundaries();
		this.addDrawObjects(null);
		this.onChange();

		return { stopPropagation: true };
	}

	insertUniqueRaster(raster) {
		if (raster.x > -1 && raster.y > -1) {
			const rasterFound = this.rasters.find((rst) => rst.x === raster.x && rst.y === raster.y);
			if (typeof rasterFound === 'undefined') {
				this.rasters.push(raster);
			}
		}
	}
	select(parameters) {
		// Updaten van kleur hoeft hier niet omdat in addDrawobjects al de kleur word bepaald op basis van selected.
		if (this.id === parameters.id) {
			this.selected = !this.selected;
		} else {
			// Wanneer nieuwe object niet dit object is dan is het een deselect.
			this.selected = false;
		}
	}
	onClick(evt, drawObject) {
		Configuration.CURRENT.select({ id: this.id });
		return { stopPropagation: true };
	}
	onClickPossiblePosition(drawObject) {
		Configuration.CURRENT.cageLadders.push(drawObject);
		Configuration.CURRENT.editModus.stopEditingObject();
		Configuration.CURRENT.cageLadders.clearConfiguratorSettings();
	}

	getAmountData(compare = true) {
		return {
			id: this.id,
			oid: this.oid,
			name: this.objectName,
			height: this.endHeight - this.startHeight,
			startHeight: this.startHeight,
			endHeight: this.endHeight,
			width: this.totalWidth,
			depth: this.depth,
			// TODO: CageLadder als kleur toevoegen.
			stepWidth: this.stepWidth,
			optional: this.optional,
			amount: 1,
		};
	}

	validate() {}

	getContextMenu() {
		// bij bestaande objecten gaat getContextMenu fout. Daarom niet via variabele maar hier direct in return
		return [
			{ icon: 'edit', action: this.edit.bind(this), active: true },
			{ icon: 'delete', action: this.remove.bind(this), active: true },
		];
	}
	edit() {
		Configuration.CURRENT.contextMenu.hide();
		// omdat contextmenu op de kolom zelf staat is hier edit nodig die komt echter vanuit buildingcolumns vandaar opgelost met meegeven
		if (typeof this._edit === 'function') {
			this._edit(this);
		}
	}
	remove() {
		Configuration.CURRENT.contextMenu.hide();
		// omdat contextMenu op de kolom zelf staat is hier remove  nodig die komt echter vanuit buildingcolumns vandaar opgelost met meegeven
		if (typeof this._remove === 'function') {
			this._remove(this);
		}
		this.onChange();
	}

	onMouseDown() {
		return { stopPropagation: true };
	}
	onMouseUp() {
		this.updateRasters();
		this.setBoundaries();
		this.onChange();
		return { stopPropagation: true };
	}

	setReferences(params) {
		this._onChange = params.onChange;
		this._checkCollisions = params.checkCollisions;
		this._edit = params.edit;
		this._remove = params.remove;

		params.rasters = this.rasters;
	}
	collisionCheck() {
		let collisionCheck = this.checkCollisions(this.boundaries, this);
		let hasNowErrors = this.hasErrors;

		if (collisionCheck.result) {
			this.errors.clear(Errors.ERRORTYPE.collision);
			collisionCheck.errors.forEach((error) => {
				this.errors.add(error);
			});
		} else {
			this.errors.clear(Errors.ERRORTYPE.collision);
		}
		if (hasNowErrors !== this.hasErrors) {
			let drawObject = Configuration.CURRENT.canvas.drawObjects.get(this.drawObject);
			if (drawObject !== null) {
				this.setCollisionDrawObject(drawObject.drawObjects, this.hasErrors);
			}
		}

		return collisionCheck;
	}

	checkCollisions(boundaries, self) {
		if (typeof this._checkCollisions === 'function') {
			return this._checkCollisions(boundaries, self);
		}
	}
	// Collision functie, hier komt hij vanuit een ander object in die check of hij overlap heeft met dit object.
	collisions(boundaries, self) {
		if (this.id === self.id || this.active === false) {
			// zichzelf dus geen collision
			return { result: false, errors: [], objectName: this.objectName };
		}
		let overlap = false;

		boundaries.forEach((boundary) => {
			this.boundaries.forEach((boundaryBuildingColumn) => {
				if (Mathematic.overlapRectangles(boundary.topLeft, boundary.bottomRight, boundaryBuildingColumn.topLeft, boundaryBuildingColumn.bottomRight) === true) {
					overlap = true;
				}
			});
		});

		if (overlap === true) {
			return { result: true, errors: [new CustomError(window.$nuxt.$translate('collision.cageLadder'), Errors.ERRORTYPE.collision, this.objectName)] };
		}

		return { result: false, text: [], objectName: this.objectName };
	}
	getEdgePosition() {
		return { startX: this.boundaries[0].topLeft.x, startY: this.boundaries[0].topLeft.y, endX: this.boundaries[0].topRight.x, endY: this.boundaries[0].bottomLeft.y };
	}
	onHandrailPosition(handRailPosition) {
		if (this.boundaries.length === 0 || handRailPosition === null) {
			return false;
		}
		const edgePosition = this.getEdgePosition();
		return Mathematic.overlapRectangles(
			{ x: edgePosition.startX, y: edgePosition.startY },
			{ x: edgePosition.endX, y: edgePosition.endY },
			{ x: handRailPosition.startX.value, y: handRailPosition.startY.value },
			{ x: handRailPosition.endX.value, y: handRailPosition.endY.value },
		);
	}
	calculateAmount() {}
	setStartAndEndHeight() {
		// Bij wijziging van de etageHoogte komen we hierin.
		// Dan ophalen nieuwe etageHoogte, aan de hand van index ophalen.
		this.startHeight = this.startHeight > 0 ? Configuration.CURRENT.etages.getTotalHeight(this.startEtageIndex, true) : 0;
		this.endHeight = Configuration.CURRENT.etages.getTotalHeight(this.endEtageIndex, true);
	}

	onSizeHandleChangedHorizontal(evt, drawObject, newDimensions, changedDimensionIndex) {
		if (changedDimensionIndex === 0) {
			this.x = newDimensions[changedDimensionIndex];
		} else if (changedDimensionIndex === newDimensions.length - 1) {
			this.x = Configuration.CURRENT.etages.activeEtage().floor.width - newDimensions[changedDimensionIndex] - this.getWidth();
		}
		this.onChange();
	}
	onSizeHandleChangedVertical(evt, drawObject, newDimensions, changedDimensionIndex) {
		if (changedDimensionIndex === 0) {
			this.y = newDimensions[changedDimensionIndex];
		} else if (changedDimensionIndex === newDimensions.length - 1) {
			this.y = Configuration.CURRENT.etages.activeEtage().floor.length - newDimensions[changedDimensionIndex] - this.getDepth();
		}
		this.onChange();
	}
}
