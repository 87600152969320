import { Translations } from '../data/translations';
export default ({ app, store }, inject) => {
	const translate = (string, args, language = null) => {
		if (typeof string === 'undefined') {
			return string;
		}
		if (typeof string !== 'string') {
			return string;
		}

		let translationFromDb = Translations.CURRENT.translate(string, language);

		// gevonden dan tekst terug anders oorspronkelijke waarde
		let result = typeof translationFromDb === 'string' ? translationFromDb : string;
		// vervang eventuele parameters in de tekst door de waarden die meegegeven zijn
		if (typeof args === 'object') {
			for (const key in args) {
				result = result.replace(new RegExp('{' + key + '}', 'g'), args[key]);
			}
		}
		return result;
	};
	inject('translate', translate);
};
