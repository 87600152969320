import { Data } from './data';

export class FallingProtectionStandards extends Data {
	objectName = 'FallingProtectionStandards';
	static CURRENT = null;
	url = 'FallingProtectionStandards';
	getStandard(name, callback) {
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			if (typeof callback === 'function') {
				callback(this._list.list.filter((fps) => fps.value === name)[0]);
			} else {
				return this._list.list.filter((fps) => fps.value === name)[0];
			}
		} else {
			// Wanneer lijst nog niet geladen is dit aanroepen en zichzelf weer aanroepen.
			this.list(null, null, null, null, () => {
				this.getStandard(name);
			});
		}
		return null;
	}
}
