import { Mathematic } from '../helpers/mathematic';
import { Data } from './data';
import { Store } from './store';

export class CenterToCenterDistances extends Data {
	objectName = 'CenterToCenterDistances';
	static CURRENT = null;
	url = 'CenterToCenterDistances';
	getMax() {
		if (typeof this._list.list !== 'undefined' && this._list.list !== null && this._list.list.length > 0) {
			return this._list.list.reduce(function (a, b) {
				return Math.max(a, b);
			});
		}
		return 0;
	}
	getLower(max) {
		let maxCtC = 0;
		this._list.list.forEach((ctc) => {
			if (ctc > maxCtC && ctc <= max) {
				maxCtC = ctc;
			}
		});
		return maxCtC;
	}
	ctcList(filters, possibleCtCDeckingFinish, maxCtCFloorVariableLoad, maxCtCFloorDeflection, maxCtCCountry) {
		if (typeof maxCtCFloorVariableLoad === 'undefined' || maxCtCFloorVariableLoad === null || maxCtCFloorVariableLoad === 0) {
			maxCtCFloorVariableLoad = this.getMax();
		}
		if (typeof maxCtCFloorDeflection === 'undefined' || maxCtCFloorDeflection === null || maxCtCFloorDeflection === 0) {
			maxCtCFloorDeflection = this.getMax();
		}
		if (typeof maxCtCCountry === 'undefined' || maxCtCCountry === null || maxCtCCountry === 0) {
			maxCtCCountry = this.getMax();
		}
		if (typeof filters === 'undefined' || filters === null) {
			return [];
		}
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			const list = [];
			let centerToCenterPossible = null;
			if (typeof possibleCtCDeckingFinish !== 'undefined' && possibleCtCDeckingFinish !== null) {
				centerToCenterPossible = possibleCtCDeckingFinish;
			}

			this._list.list.forEach((item) => {
				list.push({
					value: item,
					caption: Store.CURRENT.currentConfiguration().isImperial() ? Mathematic.ImperialToInputString(item) : `${filters.toNumber(item)} mm`,
					disabled: (centerToCenterPossible !== null && !centerToCenterPossible.includes(item)) || item > maxCtCFloorVariableLoad || item > maxCtCFloorDeflection || item > maxCtCCountry,
				});
			});
			return list;
		} else if (this.listRequest === null) {
			this.list();
		}
		return [];
	}
}
