import { Columns } from '../../configurator/columns';
import { Configuration } from '../../configurator/configuration';
import { Canvas3D } from '../Canvas3D';
import { BasePlate3D } from './basePlate3D';
export class BasePlateVertical3D extends BasePlate3D {
	objectName = 'BasePlateVertical3D';
	draw() {
		let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');

		// Goed zetten van de baseplate op basis van de rowPosition van de kolom.
		if (typeof this.rowPositions !== 'undefined' && this.rowPositions !== null) {
			// Onderste rij baseplates 1/3e naar boven
			if (this.rowPositions.includes(Columns.POSITION_BOTTOM)) {
				this.z -= columnWidth / 3;
			}
			// De bovenste rij baseplate 1/3e naar beneden.
			if (this.rowPositions.includes(Columns.POSITION_TOP)) {
				this.z += columnWidth / 3;
			}
		}
		super.draw();
	}
}
