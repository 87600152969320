import { Configuration } from '../../configurator/configuration';
import { IntermediateLandings } from '../../configurator/intermediateLandings';
import { Stair } from '../../configurator/stair';
import { Stairs } from '../../configurator/stairs';
import { Mathematic } from '../../helpers/mathematic';
import { Canvas3D } from '../Canvas3D';
import { KickEdgeStair3D } from './stair/KickEdgeStair3D';
import { StairHandRail3D } from './stair/railing/StairHandRail3D';
import { Riser3D } from './stair/steps/Riser3D';
import { StairStep3D } from './stair/steps/StairStep3D';
import { YellowNose3D } from './stair/steps/YellowNose3D';
import { StingerStair3D } from './stair/StingerStair3D';

export class Stair3D {
	objectName = 'Stair3D';
	constructor(x, y, params) {
		let calcHeight = params.yPosEnd - params.yPos;
		// Driehoekje, deltaX is de Horizontale, calcheight de vertical, diagonale word hierna uitgerekend.
		let deltaX = Mathematic.soscastoa({ a: calcHeight, angle: 90 - params.stairData.angle }).o;
		let diagonalLength = Mathematic.pyth({ a: deltaX, b: calcHeight });
		let angle = 90 - params.stairData.angle;
		let wellDepth = Mathematic.calculateDepth(Math.min(calcHeight - (Stairs.PASSAGE_HEIGHT + params.stairData.packetHeight), calcHeight), angle);
		let stepsAmount;

		let stairX = x;
		let stairY = y;

		stepsAmount = params.stepsAmount;

		let shared = {
			deltaX: deltaX,
			diagonalLength: diagonalLength,
			angle: angle,
			stairWidth: params.stairData.width, // Width van uit 2D gezien
			stairDepth: params.stairData.depth, // Depth van uit 2D gezien
			wellDepth: wellDepth,
			overhang: params.overhang,
			objupComingTop: { boom: { rotationX: -90, rotationZ: 90, rotationY: -Math.abs(90 - params.stairData.angle) }, step: { rotationY: 180 } },
			objupComingBottom: { boom: { rotationX: 90, rotationZ: 90, rotationY: Math.abs(90 - params.stairData.angle) }, step: {} },
			objupComingLeft: { boom: { rotationY: 180, rotationZ: params.stairData.angle }, step: { rotationY: -90 } },
			objupComingRight: { boom: { rotationZ: -1 * params.stairData.angle }, step: { rotationY: 90 } },
			width: params.stairData.endLanding.width,
			depth: params.stairData.endLanding.depth,
			drawHeight: params.drawHeight,
			endLandingDrawHeight: params.stairData.endHeight,
			yPos: params.yPos,
			yPosEnd: params.yPosEnd,
			upComing: params.upComing,
			firstStair: params.firstStair,
			stairId: params.stairId,
		};

		if (params.stairData.place === Stair.PLACE_OUTSIDE) {
			switch (params.upComing) {
				case Stair.UPCOMING_BOTTOM:
					// Alleen horizontaal in het midden zetten.

					// Wanneer het de eerste stair is en de enaldning is actief, dan de endlanding diepte bij de startY van de stair.
					// Of wanneer het niet de eerste stair is, dan is params.depth landingdiepte, en dan dus de stair de landingdepth opschuiven.
					if (params.firstStair === false || (params.firstStair === true && params.endLandingActive === true)) {
						stairY += params.depth;
					}

					// Wanneer landingData niet null is is het altijd de trap na een intermediate landing.
					// Afhankelijk van type landing dan de X positie van de trap erna updaten.
					if (params.landingData !== null) {
						// bij geen 180 in het midden.
						if (params.landingData.landingType !== IntermediateLandings.oneeightyDegrees) {
							stairX += (params.width - params.stairData.stepWidth) / 2;
						}
						// Is het wel 180 dan bottom altijd rechts uitlijnen.
						else if (params.landingData.landingType === IntermediateLandings.oneeightyDegrees) {
							stairX += params.width - params.stairData.stepWidth;
						}
					}

					break;
				case Stair.UPCOMING_RIGHT:
					// Wanneer het de eerste stair is en de endlanding is actief, dan de endlanding breedte bij de startX van de stair.
					// Of wanneer het niet de eerste stair is, dan is params.width landingwidth, en dan dus de stair de landing width opschuiven.
					if (params.firstStair === false || (params.firstStair === true && params.endLandingActive === true)) {
						stairX += params.width;
					}

					// Wanneer landingData niet nul dan is het altijd de trap na een landing.
					// dan de Y positie updaten
					if (params.landingData !== null) {
						// Verticaal centreren op basis van landingData.
						// Alleen als het geen 180 graden is.
						if (params.landingData.landingType !== IntermediateLandings.oneeightyDegrees) {
							stairY += (params.depth - params.stairData.stepWidth) / 2;
						}
					}

					break;
				case Stair.UPCOMING_TOP:
					// ! Bij top hoeven we niet te kijken naar first stair.
					// ! De coords zijn dan altijd al leftop van de landing, of van de stairpositie.
					// ! We hoeven dan dus niet op te schuiven.

					// Horizontaal in het midden plus breedte van landing erbij omdat alles bij top gespiegeld staat.
					if (params.landingData !== null) {
						// bij geen 180 in het midden.
						if (params.landingData.landingType !== IntermediateLandings.oneeightyDegrees) {
							stairX += params.width;
							stairX -= (params.width - params.stairData.stepWidth) / 2;
						}
						// Is het wel 180 uitlijnen aan recherkant.
						else if (params.landingData.landingType === IntermediateLandings.oneeightyDegrees) {
							stairX += params.stairData.stepWidth;
						}
					}
					// Wanneer stair aan de bovenkant vloer en geen landingsheeft, dan opschuiven omdat we bovenaltijd gespiegeld tekenen.
					// Dan komt de stair dus zijn eigen breedte naar links verder uit.
					else {
						stairX += params.width;
					}
					break;
				case Stair.UPCOMING_LEFT:
					// ! Bij left hoeven we niet te kijken naar first stair.
					// ! De coords zijn dan altijd al leftop van de landing, of van de stairpositie.
					// ! We hoeven dan dus niet op te schuiven.

					if (params.landingData !== null) {
						if (params.landingData.landingType !== IntermediateLandings.oneeightyDegrees) {
							// Alleen verticaal in het midden zetten.
							stairY += (params.depth - params.stairData.stepWidth) / 2;
						} else if (params.landingData.landingType === IntermediateLandings.oneeightyDegrees) {
							stairY += params.depth - params.stairData.stepWidth;
						}
					}
					break;
			}
		} else if (params.stairData.place === Stair.PLACE_INFLOOR) {
			// ALLE STUKKEN TRAPPEN NA EEN MIDDENLANDING.
			if (params.landingData !== null) {
				switch (params.upComing) {
					case Stair.UPCOMING_BOTTOM:
						// Alleen horizontaal in het midden zetten.
						if (params.landingData.landingType !== IntermediateLandings.oneeightyDegrees) {
							stairX += (params.width - params.stairData.stepWidth) / 2;
						} else if (params.landingData.landingType === IntermediateLandings.oneeightyDegrees) {
							// Bij 180 graden altijd rechts uitlijnen
							stairX += params.width - params.stairData.stepWidth;
						}
						if (params.firstStair === false || (params.firstStair === true && params.endLandingActive === true)) {
							// Wanneer eerste trap en geen landing daarvoor heeft.
							stairY += params.depth;
						}
						break;
					case Stair.UPCOMING_RIGHT:
						// Verticaal centreren op basis van landingData.
						// Als wel 180 graden is dan laten staan, want dan komt hij bovenin.
						if (params.landingData.landingType !== IntermediateLandings.oneeightyDegrees) {
							stairY += (params.depth - params.stairData.stepWidth) / 2;
						}
						// Wanneer upComing van de landing naar rechts wijst dan de trap ook naar rechts toe.
						// Dit doen we alleen als we zeker weten dat het niet de First stair is (Dus na een middenlanding)
						// Of als het wel de eerste stair is en de endlanding actief is.
						if (params.firstStair === false || (params.firstStair === true && params.endLandingActive === true)) {
							stairX += params.width;
						}
						break;
					case Stair.UPCOMING_TOP:
						// Horizontaal in het midden plus breedte van landing erbij omdat alles bij top gespiegeld staat.
						if (params.landingData.landingType !== IntermediateLandings.oneeightyDegrees) {
							stairX += params.width;
							stairX -= (params.width - params.stairData.stepWidth) / 2;
						} else if (params.landingData.landingType === IntermediateLandings.oneeightyDegrees) {
							stairX += params.stairData.stepWidth;
						}

						break;
					case Stair.UPCOMING_LEFT:
						// Alleen verticaal in het midden zetten.
						if (params.landingData.landingType !== IntermediateLandings.oneeightyDegrees) {
							stairY += (params.depth - params.stairData.stepWidth) / 2;
						} else if (params.landingData.landingType === IntermediateLandings.oneeightyDegrees) {
							// Wanneer wel 180 graden dan altijd onderkant uitlijnen.
							stairY += params.depth - params.stairData.stepWidth;
						}
						break;
				}
			} else {
				switch (params.upComing) {
					case Stair.UPCOMING_TOP:
						// Horizontaal in het midden plus breedte van landing erbij omdat alles bij top gespiegeld staat.
						stairX += params.width;
						stairX -= params.width - params.stairData.stepWidth;
						// Fix voor 180 graden gedraait en dan fout: https://git.proautnorm.com/configurators/profielnorm/configurator/-/issues/1101
						if (params.firstStair && params.stairData.intermediateLandings.intermediateLandings.length === 0) {
							stairY += params.depth;
						}
						break;
					case Stair.UPCOMING_LEFT:
						// Houd de beginpositie links aan, dus traps + breedte naar rechts.
						// Fix voor: https://git.proautnorm.com/configurators/profielnorm/configurator/-/issues/1118
						if (params.firstStair && params.stairData.intermediateLandings.intermediateLandings.length === 0) {
							stairX += params.width;
						}
						break;
				}
			}
		}

		this.addStair(stairX, stairY, params, shared, stepsAmount);
	}
	addStair(stairX, stairY, params, shared, stepsAmount) {
		// Trapboom genereren.
		Canvas3D.CURRENT.addDrawObject(
			new StingerStair3D(stairX, stairY, {
				stairData: params.stairData,
				trapboomPosition: 'left',
				shared: shared,
				kickEdge:
					params.stairData.type === 222 || params.stairData.type === 221
						? new KickEdgeStair3D(stairX, stairY, {
								stairData: params.stairData,
								trapboomPosition: 'left',
								shared: shared,
								ralColor: Configuration.CURRENT.colors.stairs.ralColor,
								oid: params.stairData.material.kickEdge,
						  })
						: null,
				ralColor: Configuration.CURRENT.colors.stairs.ralColor,
			}),
			Canvas3D.TYPE_STRINGER,
		);
		Canvas3D.CURRENT.addDrawObject(
			new StingerStair3D(stairX, stairY, {
				stairData: params.stairData,
				trapboomPosition: 'right',
				shared: shared,
				kickEdge:
					params.stairData.type === 222 || params.stairData.type === 221
						? new KickEdgeStair3D(stairX, stairY, {
								stairData: params.stairData,
								trapboomPosition: 'right',
								shared: shared,
								ralColor: Configuration.CURRENT.colors.stairs.ralColor,
								oid: params.stairData.material.kickEdge,
						  })
						: null,
				ralColor: Configuration.CURRENT.colors.stairs.ralColor,
			}),
			Canvas3D.TYPE_STRINGER,
		);

		for (let i = 0; i < stepsAmount; i++) {
			Canvas3D.CURRENT.addDrawObject(
				new StairStep3D(stairX, stairY, {
					stairData: params.stairData,
					shared: shared,
					index: i,
					yellowNose: params.stairData.yellowNose
						? new YellowNose3D(stairX, stairY, {
								stairData: params.stairData,
								shared: shared,
								index: i,
						  })
						: null,
					riser: params.stairData.riser
						? new Riser3D(stairX, stairY, {
								stairData: params.stairData,
								shared: shared,
								index: i,
						  })
						: null,
					stepsAmount: stepsAmount,
					lastStep: i === stepsAmount - 1,
					firstStep: i === 0,
				}),
				Canvas3D.TYPE_STAIRSTEP,
			);
		}

		// 1e handrail
		// eslint-disable-next-line no-new
		new StairHandRail3D(stairX, stairY, { stairData: params.stairData, shared: shared, trapboomPosition: 'left' });

		// 2e handrail
		// eslint-disable-next-line no-new
		new StairHandRail3D(stairX, stairY, { stairData: params.stairData, shared: shared, trapboomPosition: 'right' });
	}
}
