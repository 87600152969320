import { Configuration } from '../../configurator/configuration';
import { jsonPalletGate } from '../../configurator/hicad/jsonPalletGate';
import { Store } from '../../data/store';
import { Canvas3D } from '../Canvas3D';
import { Model3D } from '../Model3D';

export class PalletGate3D extends Model3D {
	hoverTags = [];
	complexModel = true;
	constructor(x, y, params) {
		super(x, params.yPosition, y, params);
		this.hoverTags = [this.info.type];
		this.oid = Store.CURRENT.palletGates.find(this.info.type, this.info.width, this.info.height).palletgateId;
	}
	draw(app) {
		let deckingFinishHeight = Configuration.CURRENT.finish.height;
		this.y -= deckingFinishHeight;

		if (this.info.type.toLowerCase().replaceAll(' ', '_') === 'self_closing_gate') {
			this.ralColor = Configuration.CURRENT.colors.palletGates.ralColor;
		} else if (this.info.type.toLowerCase().replaceAll(' ', '_') === 'chain_gate') {
			this.ralColor = Configuration.CURRENT.colors.palletGates.ralColor;
		} else if (this.info.type.toLowerCase().replaceAll(' ', '_') === 'tilt_gate') {
			this.ralColor = Configuration.CURRENT.colors.tiltGates.ralColor;
		}

		super.draw(app);
	}
	convert() {
		return new jsonPalletGate(this);
	}
	applyMappingColors(playCanvasEntity) {
		// Checken of er een mapping file is voor het opgegeven tekenobject
		let mappingFile = Canvas3D.CURRENT.app.assets.find(this.oid + '.mapping.json', 'json');
		if (mappingFile !== null && typeof mappingFile !== 'undefined') {
			let mappingAreas = mappingFile.mapping.mapping;
			let materialRal = Canvas3D.CURRENT.app.assets.find(this.ralColor.toString() + '.json', 'material');

			playCanvasEntity.model.model.meshInstances.forEach((mi, index) => {
				if (mappingAreas[index] !== null && typeof mappingAreas[index] !== 'undefined') {
					let materialName = mappingAreas[index].path.toLowerCase().replaceAll(' ', '_');
					let materialDefault = Canvas3D.CURRENT.app.assets.find(materialName, 'material');

					// Grijze onderdelen moeten de kleur behouden die ze hebben.
					// Light blue van chaingate en self closing gate, golden yellow van tiltgate.
					// Dit zijn de defaultkleuren die veranderd mogen worden.
					if (materialName === 'light_blue.json' || materialName === 'golden_yellow.json') {
						// Wanneer custom color, materiaal de kleur geven die opgehaald is.
						if (materialRal !== null && typeof materialRal !== 'undefined') {
							mi.material = materialRal.resource;
						}
					} else {
						// Wanneer materiaal van het onderdeel niet die in de if statement zijn.
						// Dan standaard materiaal aanhouden
						if (materialDefault !== null && typeof materialDefault !== 'undefined') {
							mi.material = materialDefault.resource;
						}
					}
				}
			});
		}
	}
}
