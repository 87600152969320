import * as pc from 'playcanvas';
import { DrawObjects3D } from '../DrawObjects3D';
import { Canvas3D } from '../Canvas3D';
import { HandRails } from '../../configurator/handRails';

export class LedBar3D extends DrawObjects3D {
	objectName = 'LedBar3D';
	draw() {
		let plateDepth = Canvas3D.CURRENT.getModelDataByOid(this.handRailMaterials.handRail, 'depth');
		let poleWidth = Canvas3D.CURRENT.getModelDataByOid(this.handRailMaterials.post, 'depth');
		const colorValues = Canvas3D.CURRENT.hexToRGB(this.ledColor);
		const material = Canvas3D.CURRENT.createLedMaterial(colorValues);

		// Eerst tekenen van de balk die direct tegen de handrail aan de onderkant van de houder komt.
		let topBarLed = new pc.Entity('topbar-led-strip');
		topBarLed.tags.add('ledbar-3d');
		topBarLed.addComponent('render', {
			type: 'box',
			castShadows: false,
			layers: this.layers,
		});

		topBarLed.render.meshInstances[0].material = material;

		// Extra berekeningen zodat hij niet door een paal heen tekent.
		if (this.position === HandRails.TOP || this.position === HandRails.BOTTOM) {
			this.x += poleWidth;
			this.length -= poleWidth;
			this.z += plateDepth;
			this.z -= plateDepth / 3;
			topBarLed.setLocalPosition(this.x / 1000 + this.length / 1000 / 2, this.y / 1000 + this.middlePoleHeight / 1000 - 0.0025, this.z / 1000 + this.depth / 1000 / 2);
		} else {
			this.x += plateDepth;
			this.x -= plateDepth / 3;
			this.z += poleWidth;
			this.length -= poleWidth;
			this.rotationY = -90;
			topBarLed.setLocalPosition(this.x / 1000, this.y / 1000 + this.middlePoleHeight / 1000 - 0.0025, this.z / 1000 + this.length / 1000 / 2);
		}

		topBarLed.setLocalScale(this.length / 1000, 0.015, plateDepth / 1000);
		topBarLed.rotateLocal(180, this.rotationY, this.rotationZ);

		Canvas3D.CURRENT.app.root.addChild(topBarLed);

		let amountOfPoles = Math.ceil(this.length / 1000 / 1.5) + ((this.length / 1000) * 1000 > 1500 ? 1 : 0);
		let distance = this.length / amountOfPoles;

		if (this.position === HandRails.TOP || this.position === HandRails.BOTTOM) {
			for (let i = 0; i <= amountOfPoles; i++) {
				// Over de lengte verdelen zodat er lampen per stukje schijnen.
				let lightBarLedHorizontal = new pc.Entity('top-bar-light');
				lightBarLedHorizontal.tags.add('ledbar-3d');
				lightBarLedHorizontal.addComponent('light', {
					type: 'spot',
					intensity: 2,
					range: 2,
					color: new pc.Color(colorValues.r / 255, colorValues.g / 255, colorValues.b / 255),
					static: true,
					outerConeAngle: 90,
					innerConeAngle: 90,
					layers: [pc.LAYERID_WORLD, Canvas3D.CURRENT.deckingFinishLayer.id],
				});

				let newX = this.x + (i + 1) * distance - distance / 2;
				lightBarLedHorizontal.setLocalPosition(newX / 1000, this.y / 1000 + this.middlePoleHeight / 1000, this.z / 1000 + this.depth / 1000 / 2);
				Canvas3D.CURRENT.app.root.addChild(lightBarLedHorizontal);
			}
		} else {
			for (let i = 0; i <= amountOfPoles; i++) {
				// Over de lengte verdelen zodat er lampen per stukje schijnen.
				let lightBarLedVertical = new pc.Entity('top-bar-light');
				lightBarLedVertical.tags.add('ledbar-3d');
				lightBarLedVertical.addComponent('light', {
					type: 'spot',
					intensity: 2,
					range: 2,
					color: new pc.Color(colorValues.r / 255, colorValues.g / 255, colorValues.b / 255),
					static: true,
					outerConeAngle: 90,
					innerConeAngle: 90,
					layers: [pc.LAYERID_WORLD, Canvas3D.CURRENT.deckingFinishLayer.id],
				});

				let newZ = this.z + (i + 1) * distance - distance / 2;
				lightBarLedVertical.setLocalPosition(this.x / 1000, this.y / 1000 + this.middlePoleHeight / 1000, newZ / 1000);
				Canvas3D.CURRENT.app.root.addChild(lightBarLedVertical);
			}
		}
	}
}
