import { Rectangle } from '../draw/rectangle';
import { DrawValue } from '../draw/drawValue';
import { IntermediateLanding } from './intermediateLanding';

import { Stairs } from './stairs';
import { Stair } from './stair';
import { IntermediateLandings } from './intermediateLandings';
import { EndlandingTrimmings } from './trimmings/endlandingTrimmings';
import { Statics } from '../helpers/statics';
export class EndLanding extends IntermediateLanding {
	objectName = 'EndLanding';
	// TODO: Endlanding voorzien van trimming.
	trimmings = new EndlandingTrimmings();
	addDrawObjects(params, parentObject, drag = false) {
		if (this.active === false) {
			return [];
		}
		let drawObjects = [];
		let landing = null;

		let boundary = {
			topLeft: { x: 0, y: 0 },
			topRight: { x: 0, y: 0 },
			bottomLeft: { x: 0, y: 0 },
			bottomRight: { x: 0, y: 0 },
		};
		let shiftLanding = 0;
		let width = 0;
		let depth = 0;

		switch (
			params.position // plaats naast vloer
		) {
			// Krijg StairOutSide niet gekoppeld via import dan stack-fout
			case Stair.POSITION_TOP:
				shiftLanding = (this.width - params.stepWidth) / 2;
				if (params.upComing === Stair.UPCOMING_TOP || params.upComing === Stair.UPCOMING_BOTTOM) {
					width = this.width;
					depth = this.depth;
				} else {
					width = this.depth;
					depth = this.width;
				}

				landing = new Rectangle(
					new DrawValue(params.boundary.topLeft.x - shiftLanding),
					new DrawValue(params.boundary.topLeft.y - depth),
					new DrawValue(width),
					new DrawValue(depth),
					params.hasErrors === true ? Statics.COLOR_COLLISION : Statics.COLOR_GREY,
					null,
					null,
					false,
					this,
					{
						type: 'stairLanding',
						color: Statics.COLOR_GREY,
					},
				);
				boundary = {
					topLeft: { x: params.boundary.topLeft.x - shiftLanding, y: params.boundary.topLeft.y - depth },
					topRight: { x: params.boundary.topLeft.x - shiftLanding + width, y: params.boundary.topLeft.y - depth },
					bottomLeft: { x: params.boundary.topLeft.x - shiftLanding, y: params.boundary.topLeft.y },
					bottomRight: { x: params.boundary.topLeft.x - shiftLanding + width, y: params.boundary.topLeft.y },
				};
				break;
			case Stair.POSITION_RIGHT: // Stair.POSITION_RIGHT
				shiftLanding = (this.width - params.stepWidth) / 2;
				if (params.upComing === Stair.UPCOMING_TOP || params.upComing === Stair.UPCOMING_BOTTOM) {
					width = this.width;
					depth = this.depth;
				} else {
					width = this.depth;
					depth = this.width;
				}

				landing = new Rectangle(
					new DrawValue(params.boundary.topLeft.x),
					new DrawValue(params.boundary.topLeft.y),
					new DrawValue(width),
					new DrawValue(depth),
					params.hasErrors === true ? Statics.COLOR_COLLISION : Statics.COLOR_GREY,
					null,
					null,
					false,
					this,
					{
						type: 'stairLanding',
						color: Statics.COLOR_GREY,
					},
				);
				boundary = {
					topLeft: { x: params.boundary.topLeft.x, y: params.boundary.topLeft.y },
					topRight: { x: params.boundary.topLeft.x + width, y: params.boundary.topLeft.y },
					bottomLeft: { x: params.boundary.topLeft.x, y: params.boundary.topLeft.y + depth },
					bottomRight: { x: params.boundary.topLeft.x + width, y: params.boundary.topLeft.y + depth },
				};

				break;
			case Stair.POSITION_BOTTOM:
				shiftLanding = (this.width - params.stepWidth) / 2;
				if (params.upComing === Stair.UPCOMING_TOP || params.upComing === Stair.UPCOMING_BOTTOM) {
					width = this.width;
					depth = this.depth;
				} else {
					width = this.depth;
					depth = this.width;
				}

				landing = new Rectangle(
					new DrawValue(params.boundary.topLeft.x - shiftLanding),
					new DrawValue(params.boundary.topLeft.y),
					new DrawValue(width),
					new DrawValue(depth),
					params.hasErrors === true ? Statics.COLOR_COLLISION : Statics.COLOR_GREY,
					null,
					null,
					false,
					this,
					{
						type: 'stairLanding',
						color: Statics.COLOR_GREY,
					},
				);
				boundary = {
					topLeft: { x: params.boundary.topLeft.x - shiftLanding, y: params.boundary.topLeft.y },
					topRight: { x: params.boundary.topLeft.x - shiftLanding + width, y: params.boundary.topLeft.y },
					bottomLeft: { x: params.boundary.topLeft.x - shiftLanding, y: params.boundary.topLeft.y + depth },
					bottomRight: { x: params.boundary.topLeft.x - shiftLanding + width, y: params.boundary.topLeft.y + depth },
				};

				break;
			case Stair.POSITION_LEFT:
				shiftLanding = (this.width - params.stepWidth) / 2;
				if (params.upComing === Stair.UPCOMING_TOP || params.upComing === Stair.UPCOMING_BOTTOM) {
					width = this.width;
					depth = this.depth;
				} else {
					width = this.depth;
					depth = this.width;
				}

				landing = new Rectangle(
					new DrawValue(params.boundary.topLeft.x - width),
					new DrawValue(params.boundary.topLeft.y - shiftLanding),
					new DrawValue(width),
					new DrawValue(depth),
					params.hasErrors === true ? Statics.COLOR_COLLISION : Statics.COLOR_GREY,
					null,
					null,
					false,
					this,
					{
						type: 'stairLanding',
						color: Statics.COLOR_GREY,
					},
				);
				boundary = {
					topLeft: { x: params.boundary.topLeft.x - width, y: params.boundary.topLeft.y - shiftLanding },
					topRight: { x: params.boundary.topLeft.x, y: params.boundary.topLeft.y - shiftLanding },
					bottomLeft: { x: params.boundary.topLeft.x - width, y: params.boundary.topLeft.y - shiftLanding + depth },
					bottomRight: { x: params.boundary.topLeft.x, y: params.boundary.topLeft.y - shiftLanding + depth },
				};

				break;
		}

		if (landing !== null) {
			landing.opacity = 0.5;
			landing.colorFromParent = false;
			landing.border = true;
			drawObjects.push(landing);
		}

		// Trimmings toevoegen
		// We kunnen het hier in één keer pushen in drawObjects omdat we in de class stair pas over de array om items loopen.
		// Daar voegen we deze pas toe aan een echte drawGroup.
		if (typeof this.trimmings.addDrawObjects === 'function' && !drag) {
			drawObjects.push(this.trimmings.addDrawObjects(parentObject));
		}

		this.boundary = boundary;
		params.boundary = boundary;
		return drawObjects;
	}
	calculateAmount(params) {
		this.trimmings.calculateAmount(params);
	}

	update(crossStairWell, position, upComing, stepWidth, intermediateLandings) {
		let firstLandingType = null;

		if (intermediateLandings !== null && typeof intermediateLandings !== 'undefined') {
			if (intermediateLandings.intermediateLandings.length > 0) {
				firstLandingType = intermediateLandings.intermediateLandings[0].landingType;
			}
		}

		// TODO: Bij boven plaatsen en dan rechts
		// TODO: Crossstairwell uit, maar wel de eerste landing tegenovergesteld van de endlanding.

		// Wanneer deze specifieke kanten dan altijd bordes breedte breder,
		// We tekenen nu trappen namelijk altijd rechtsom.
		// Of als de crossstairwell uit staat maar de eerste landing is dan wel 180 graden.
		if (
			(position === Stair.POSITION_TOP && upComing === Stair.UPCOMING_RIGHT) ||
			(position === Stair.POSITION_RIGHT && upComing === Stair.UPCOMING_BOTTOM) ||
			(position === Stair.POSITION_BOTTOM && upComing === Stair.UPCOMING_LEFT) ||
			(position === Stair.POSITION_LEFT && upComing === Stair.UPCOMING_TOP)
		) {
			if (crossStairWell === true || firstLandingType === IntermediateLandings.oneeightyDegrees) {
				this.oneeightyDegreesLanding(stepWidth);
			}
		}
	}
	updateTrimming(stairObject) {
		this.trimmings.removeTrimmings();
		this.trimmings.setTrimming(stairObject);
	}
}
