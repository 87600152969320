<template>
	<div>
		<Modal :can-close="user.passwordExpired === false" :visible="visible" @cancel="closeChangePasswordPopup()">
			<header>{{ $translate('newpassword.editpassword') }}</header>
			<div class="modalcontent">
				<inputfieldPassword
					:value.sync="oldPassword"
					input-class-name="col-md-12"
					type="password"
					:visible="forced === false"
					:required="forced === false"
					:description="$translate('newpassword.oldpassword')"
					name="oldPassword"
				/>
				<inputfieldPassword
					:value.sync="newPasswordOne"
					input-class-name="col-md-12"
					type="password"
					:visible="true"
					:required="true"
					:description="$translate('newpassword.newpassword')"
					name="newPasswordOne"
				/>
				<inputfieldPassword
					:value.sync="newPasswordTwo"
					input-class-name="col-md-12"
					:visible="true"
					:required="true"
					:description="$translate('newpassword.repeatnewpassword')"
					name="newPasswordTwo"
				/>
				<span class="input-password-error"> {{ $translate(errorText) }} </span>
			</div>
			<footer>
				<modalButton :primary="true" @click="saveNewPassword()"> {{ $translate('save') }}</modalButton>
				<modalButton :primary="false" @click="closeChangePasswordPopup()" v-show="!user.passwordExpired">{{ $translate('cancel') }}</modalButton>
			</footer>
		</Modal>
	</div>
</template>

<script>
import inputfieldPassword from '~/components/inputfields/input-field-password';
import Modal from '~/components/modal';
import modalButton from '~/components/modal-button.vue';
export default {
	components: {
		modalButton,
		inputfieldPassword,
		Modal,
	},
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		forced: {
			type: Boolean,
			default: false,
		},
		user: {
			type: Object,
			default: () => {},
		},
		oldPasswordFromLogin: {
			type: String,
			default: '',
		},
	},
	provide() {
		return {
			inputFields: this.inputFields,
		};
	},
	data: () => {
		return {
			oldPassword: '',
			newPasswordOne: '',
			newPasswordTwo: '',
			errorText: '',
			inputFields: {},
		};
	},
	methods: {
		saveNewPassword() {
			if (this.newPasswordOne !== this.newPasswordTwo) {
				this.errorText = this.$translate('newpassword.notequal');
				this.$message.error(this.$translate('newpassword.notequal'));
				setTimeout(() => {
					this.errorText = '';
				}, 4500);
			} else {
				if (this.forced) {
					// Net ingevulde wachtwoord na inlog, word meegestuurd vanuit de login index
					this.oldPassword = this.oldPasswordFromLogin;
				}
				this.user.newPassword(
					this.newPasswordOne,
					this.oldPassword,
					this.forced,
					(res) => {
						// Succesfully changed
						if (res.isValid) {
							this.user.passwordExpired = false;
							this.$message.success(this.$translate(res.message));
							this.closeChangePasswordPopup();
						} else {
							this.$message.error(this.$translate(res.message));
							this.errorText = res.message;
							setTimeout(() => {
								this.errorText = '';
							}, 4500);
						}
					},
					(error) => {
						// error
						this.errorText = this.$translate('newpassword.changedpassword.error');
						setTimeout(() => {
							this.errorText = '';
						}, 4500);
						this.$message.error(this.$translate('newpassword.changedpassword.error'));
					},
				);
			}
			this.oldPassword = '';
			this.newPasswordOne = '';
			this.newPasswordTwo = '';
		},
		closeChangePasswordPopup() {
			this.$emit('closePopup');
		},
	},
};
</script>
<style lang="less" scoped>
.input-password-error {
	position: relative;
	left: 15px;
}
</style>
