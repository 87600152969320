var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"translationsLoaded":_vm.translations.translationsLoaded},on:{"click":() => {
			if (_vm.showProfilePopup) {
				_vm.closeProfilePopup();
			}
			if (_vm.showLanguageMenu) {
				_vm.showLanguageMenu = false;
			}
		}}},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"menu"},[_c('ul',_vm._l((_vm.pages),function(item,index){return (_vm.pageAllowed(item))?_c('li',{key:index,class:{ active: _vm.activePage === index },on:{"click":function($event){return _vm.updateMenu(index, item.path)}}},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.$translate(item.title))+"\n\t\t\t\t\t\t\t")]):_vm._e()}),0)]),_vm._v(" "),_c('div',{staticClass:"userMenu"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showProfilePopup),expression:"showProfilePopup"}],staticClass:"profilepopup"},[_c('div',{staticClass:"picture"},[_c('span',{staticClass:"material-icons picture"},[_vm._v(" person ")]),_vm._v(" "),_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.user.fullName))]),_vm._v(" "),_c('span',{staticClass:"function"},[_vm._v(" "+_vm._s(_vm.user.function))]),_vm._v(" "),_c('br')]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.user.phone !== null && _vm.user.mobile !== null && _vm.user.email !== null),expression:"user.phone !== null && user.mobile !== null && user.email !== null"}],staticClass:"userinfo"},[_c('span',{staticClass:"phone"},[_vm._v(" "+_vm._s(_vm.user.phone)+" ")]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.user.phone !== null && _vm.user.mobile !== null),expression:"user.phone !== null && user.mobile !== null"}]},[_vm._v(" || ")]),_vm._v(" "),_c('span',{staticClass:"mobile"},[_vm._v(" "+_vm._s(_vm.user.mobile))]),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',{staticClass:"email"},[_vm._v(_vm._s(_vm.user.email)+" ")])]),_vm._v(" "),_c('div',{staticClass:"useractions"},[_c('div',{on:{"click":function($event){_vm.goEditPassword(false);
										_vm.closeProfilePopup();}}},[_c('span',{staticClass:"material-icons icon"},[_vm._v(" vpn_key ")]),_vm._v(" "),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$translate('newpassword.editpassword'))+" ")])]),_vm._v(" "),_c('div',{on:{"click":function($event){return _vm.user.logout()}}},[_c('span',{staticClass:"material-icons icon"},[_vm._v(" logout ")]),_vm._v(" "),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$translate('auth.logout'))+" ")])])])]),_vm._v(" "),_c('div',{staticClass:"userName",on:{"click":function($event){$event.stopPropagation();return _vm.toggleProfilePopup()}}},[_vm._v(_vm._s(_vm.user.showName))]),_vm._v(" "),_c('img',{staticClass:"languageImage",attrs:{"src":_vm.Data.server + _vm.languages.getCurrentLanguageItem().flag},on:{"click":function($event){$event.stopPropagation();_vm.showLanguageMenu = !_vm.showLanguageMenu}}}),_vm._v(" "),_c('i',{staticClass:"material-icons",on:{"click":function($event){return _vm.user.logout()}}},[_vm._v("exit_to_app")])]),_vm._v(" "),(_vm.showLanguageMenu === true)?_c('div',{staticClass:"languageMenu"},_vm._l((_vm.languages.list()),function(language,index){return _c('div',{key:index,staticClass:"item",on:{"click":function($event){return _vm.setLanguage(language.code)}}},[_c('img',{attrs:{"src":_vm.Data.server + language.flag}}),_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(language.name)+"\n\t\t\t\t\t\t")])}),0):_vm._e()])])]),_vm._v(" "),_c('changePassword',{attrs:{"user":_vm.user,"visible":_vm.passwordPopup.visible,"forced":false},on:{"closePopup":function($event){return _vm.closeChangePasswordPopup()}}})],1),_vm._v(" "),_c('div',{staticClass:"bg"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"content"},[_c('nuxt')],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }