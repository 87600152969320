import { Store } from '../data/store';
export class Service {
	objectName = 'Service';
	id = -1;
	_active = false;
	name = '';
	serviceType = -1;
	_optional = false;
	optionalPossible = false;
	_onChange = null;
	_disabled = false;
	constructor(onChange, serviceData) {
		this._onChange = onChange;
		if (typeof serviceData !== 'undefined' && serviceData !== null) {
			this.update(serviceData);
			if (typeof serviceData.data.addByDefault !== 'undefined' && serviceData.data.addByDefault !== null) {
				if (typeof serviceData.data.addByDefault === 'boolean' && serviceData.data.addByDefault !== false) {
					this.active = serviceData.data.addByDefault;
				}
			}
		}
	}
	// Active
	get active() {
		return this._active;
	}
	set active(value) {
		this._active = value;
		this.onChange(null);
	}

	get disabled() {
		return this._disabled;
	}
	set disabled(value) {
		this._disabled = value;
	}

	get optional() {
		return this._optional;
	}
	set optional(value) {
		this._optional = value;
		this.onChange(null);
	}

	getAmount() {
		return { id: this.id, serviceType: this.serviceType, active: this.active, optional: this.optional };
	}

	update(serviceData) {
		// in update alle zaken die in principe iedere keer wijzigen. in constructor settings die eenmalig gezet worden.
		this.name = serviceData.name;
		this.serviceType = serviceData.serviceType;
		this.id = serviceData.id;
		this.description = serviceData.data.description;

		let isDefaultService = Store.CURRENT.companies.selectedCompany.defaultServices.find((dfs) => dfs.ServiceSalesProduct.id === serviceData.id);
		// Wanneer service in de defaultservice lijst staat dan aanzetten en niet meer mogelijk uit te zetten.
		if (isDefaultService !== null && typeof isDefaultService !== 'undefined') {
			this.active = true;
			this.disabled = true;
		}

		if (typeof serviceData.data !== 'undefined' && serviceData.data !== null) {
			if (typeof serviceData.data.optionalPossible !== 'undefined' && serviceData.data.optionalPossible !== null) {
				this.optionalPossible = serviceData.data.optionalPossible;
			}
		}
	}

	setReferences(params) {
		this._onChange = params.save;
	}
	removeReferences() {
		this._onChange = null;
	}
	onChange(status = null) {
		if (typeof this._onChange === 'function') {
			this._onChange(status);
		}
	}

	get possible() {
		return true;
	}
}
