<template>
	<div :translationsLoaded="translations.translationsLoaded">
		<nuxt />
	</div>
</template>

<script>
import { Translations } from '../data/translations';

export default {
	data: function () {
		return {
			translations: Translations.CURRENT,
		};
	},
	transition: {
		name: 'page',
	},
};
</script>

<style lang="less">
body {
	font-family: 'Lato', Helvetica, Arial, sans-serif;
}
</style>
