import { Columns } from '../../configurator/columns';
import { Configuration } from '../../configurator/configuration';
import { jsonColumn } from '../../configurator/hicad/jsonColumn';
import { Profiles } from '../../configurator/profiles';
import { Canvas3D } from '../Canvas3D';
import { Model3D } from '../Model3D';

export class Column3D extends Model3D {
	objectName = 'Column3D';
	hoverTags = ['columns'];
	basePlate = null;
	capPlate = null;
	gussetPlate = null;
	secondGussetPlate = null;

	rowPosition = null;
	placement = null;

	isBottomColumn = false;
	isTopColumn = false;
	mainBeamId = 0;

	constructor(x, y, params) {
		super(x, 0, y, params);

		this.basePlate = params.basePlate;
		this.capPlate = params.capPlate;
		// this.gussetPlate = params.gussetPlate;
		// this.secondGussetPlate = params.secondGussetPlate;

		this.multipleFloors = params.multipleFloors;
		this.info = params.info;

		this.rowPositions = params.info.rowPositions;
		this.placement = params.info.placement;

		this.assetName = params.info.name;
		this.oid = params.info.oid;

		this.ralColor = params.ralColor;
		this.mainBeamId = params.mainBeamId;
	}

	draw(app) {
		if (Configuration.CURRENT.colors.columns.finish === 1 || Configuration.CURRENT.colors.columns.finish === 2) {
			this.ralColor = 7036;
		} else {
			this.ralColor = Configuration.CURRENT.colors.columns.ralColor;
		}

		let capPlateHeight = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.capPlate.oid, 'height');

		if (!this.multipleFloors) {
			this.height -= capPlateHeight;
			this.capPlate.y -= capPlateHeight;
		} else {
			// Rekenen met vaste waardes van etage hoogtes (3000, 6000, als meerdere verdiepingen dan hoogte kolom minder hoog omdat we wel opgegeven waarde gebruiker op willen houden).
			this.height -= Configuration.CURRENT.finish.height;
		}

		this.basePlate.x = this.x;
		this.basePlate.z = this.z;
		this.basePlate.draw(app);

		if (!this.multipleFloors) {
			this.capPlate.x = this.x;
			this.capPlate.z = this.z;
			this.capPlate.draw(app);
		}

		super.draw(app);
	}
	// Convert Column3D naar Hicadcolumn, zodat deze in hicad gebruikt kan worden.
	convert() {
		return new jsonColumn(this);
	}
}
