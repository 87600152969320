import { Mathematic } from '../helpers/mathematic';
import { Statics } from '../helpers/statics';
import { Data } from './data';
import { Store } from './store';

export class FloorVariableLoads extends Data {
	objectName = 'FloorVariableLoads';
	static CURRENT = null;
	url = 'FloorVariableLoads';
	getItem(variableLoad) {
		let vlFind = this._list.list.filter((vl) => vl.value === variableLoad);
		if (vlFind.length > 0) {
			return vlFind[0];
		}
		return null;
	}
	getMaxCtC(variableLoad) {
		if (typeof variableLoad === 'undefined' || variableLoad === null) {
			return 0;
		}
		let vlFind = this._list.list.filter((vl) => vl.value === variableLoad);
		if (vlFind.length > 0) {
			return vlFind[0].maxCtC;
		}
		return 0;
	}
	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}
		if (typeof data.companyId === 'undefined' || data.companyId === null || data.companyId === -1) {
			data.companyId = Store.CURRENT.companies.selectedCompany.id;
		}
		if (typeof data.quotationId === 'undefined' || data.quotationId === null || data.quotationId === -1) {
			data.quotationId = Store.CURRENT.quotations.activeQuotation.id;
		}

		super.list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack);
	}
	get listBox() {
		// Kijk of lijst al opgehaald is
		if (typeof this._list.list !== 'undefined' && this._list.list !== null && this._list.list.length > 0) {
			// dan lijst teruggeven opgemaakt zoals ze in de listbox verschijnen
			return this.mappedList();
		} else if (this.listRequest === null && (Store.CURRENT.companies.selectedCompany.id > -1 || Store.CURRENT.quotations.activeQuotation.id > -1)) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			this.list(null, false, null, '', () => {
				return this.mappedList();
			});
		} else {
			this.list(null, false, null, '', () => {
				return this.mappedList();
			});
		}
		// geen lijst dan lege array
		return [];
	}
	mappedList() {
		return this._list.list.map((vLoad) => {
			vLoad.caption = Store.CURRENT.currentConfiguration().isImperial() ? Mathematic.KgM2ToImperial(vLoad.value) + ' psf' : vLoad.caption;
			return vLoad;
		});
	}
}
