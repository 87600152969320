import { SelectionListOption } from './selectionListOption';
import { HandRails } from './handRails';

export class InsideHandRails {
	objectName = 'InsideHandRails';

	topActive = true;
	rightActive = true;
	bottomActive = true;
	leftActive = true;

	automaticOptions = [
		new SelectionListOption(HandRails.TOP, '', require('~/assets/top.png'), this.topActive, this.toggleAutomaticOption.bind(this)),
		new SelectionListOption(HandRails.BOTTOM, '', require('~/assets/bottom.png'), this.rightActive, this.toggleAutomaticOption.bind(this)),
		new SelectionListOption(HandRails.LEFT, '', require('~/assets/left.png'), this.bottomActive, this.toggleAutomaticOption.bind(this)),
		new SelectionListOption(HandRails.RIGHT, '', require('~/assets/right.png'), this.leftActive, this.toggleAutomaticOption.bind(this)),
	];

	toggleAutomaticOption(option) { 
        switch(option.id) {
            case HandRails.TOP:
				this.topActive = !this.topActive;
                break;
            case HandRails.RIGHT:
				this.rightActive = !this.rightActive;
                break;
            case HandRails.BOTTOM:
				this.bottomActive = !this.bottomActive;
                break;
            case HandRails.LEFT:
				this.leftActive = !this.leftActive;
                break;
        }
    }
}