import { Configuration } from '../../../configurator/configuration';
import { Store } from '../../../data/store';
import { Mathematic } from '../../../helpers/mathematic';
import { Canvas3D } from '../../Canvas3D';
import { Model3D } from '../../Model3D';

export class CageLadderStep3D extends Model3D {
	objectName = 'CageLadderStep3D';
	hoverTags = ['cageLadder'];
	constructor(x, y, z, params) {
		super(x, y, z, params);
		this.oid = Store.CURRENT.cageLadderMaterials.articles.cageLadderStep;
		// Default van de step, staat ook opgeslagen in cageLadder.js -> Configurator.
		this.width = 600;
		this.ralColor = Configuration.CURRENT.colors.cageLadders.ralColor;

		const cageLadderTubeDepth = Canvas3D.CURRENT.getModelDataByOid(Store.CURRENT.cageLadderMaterials.articles.cageLadderTube, 'depth'); // 40
		const stepDepth = Canvas3D.CURRENT.getModelDataByOid(this.oid, 'depth'); // 50

		// Swich op positie en dan omdraaien en goed positioneren.
		switch (params.position) {
			case Mathematic.TOP:
				// Zodat de step niet in de laddertube staat.
				this.x += cageLadderTubeDepth;
				// Zodat step direct aan de laddertube verbonden zit.
				this.z -= stepDepth;
				break;
			case Mathematic.RIGHT:
				this.rotationY = -90;
				this.x += stepDepth;
				this.z += cageLadderTubeDepth;
				break;
			case Mathematic.BOTTOM:
				this.x += cageLadderTubeDepth;
				break;
			case Mathematic.LEFT:
				this.rotationY = -90;
				this.z += cageLadderTubeDepth;

				break;
		}
	}
}
