import { Data } from './data';
import { Languages } from './languages';
export class Leaflet extends Data {
	objectName = 'Leaflet';
	description = '';
	id = -1;
	get fileUrl() {
		return Data.baseUrl + 'leaflets/item?id=' + this.id + '&language=' + Languages.CURRENT.currentLanguage;
	}
	get thumbnailUrl() {
		return Data.baseUrl + 'leaflets/thumbnail?id=' + this.id;
	}
}
