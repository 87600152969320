import { Data } from './data';
import { Store } from './store';

export class CageLadders extends Data {
	objectName = 'CageLadders';
	url = 'cageLadders';
	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}

		if (typeof data.companyId === 'undefined' || data.companyId === null || data.companyId === -1) {
			data.companyId = Store.CURRENT.companies.selectedCompany.id;
		}

		return super.list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack);
	}

	getItemBetweenHeights(startHeight, endHeight) {
		const totalLength = endHeight - startHeight;
		// Voor nu alleen de eerste pakken omdat we maar 1 standaard cageladder hebben.
		const items = this._list.list[0].cageLadderHeightRanges;

		const item = items.find((item) => {
			return (item.minHeight === null || totalLength >= item.minHeight) && (item.maxHeight === null || totalLength < item.maxHeight);
		});
		return item;
	}
	getCageLadder(){
		var items = this.getListItems();
		if (items !== null && typeof items !== 'undefined' && items.length > 0){
			return items[0];
		}
		return null;
	}
	hasCage(startHeight, endHeight) {
		// Item ophalen op basis van start en eindhoogte.
		const itemBetweenHeightRange = this.getItemBetweenHeights(startHeight, endHeight);

		if (itemBetweenHeightRange !== null && typeof itemBetweenHeightRange !== 'undefined') {
			return itemBetweenHeightRange.hasCage;
		}
		// Wanneer geen gevonden geen cage tekenen.
		return false;
	}

	getListItems() {
		if (this._list.list !== null && typeof this._list.list !== 'undefined') {
			if (this._list.list.length > 0) {
				return this._list.list;
			}
		}
	}
}
