export class Functions {
	static uuidv4() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			let r = (Math.random() * 16) | 0;
			let v = c === 'x' ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	}

	static getOrdinalSuffix(number) {
		if (number === 1) return 'st';
		if (number === 2) return 'nd';
		if (number === 3) return 'rd';
		return 'th';
	}
}
