import { Canvas3D } from '../Canvas3D';
import { Model3D } from '../Model3D';
import { jsonBracing } from '@/configurator/hicad/jsonBracing';
import { Configuration } from '../../configurator/configuration';
import { Colors } from '../../configurator/colors';
export class Bracing3D extends Model3D {
	objectName = 'Bracing3D';
	hoverTags = ['bracings'];
	constructor(x, y, params) {
		super(x, params.yPosition, y, params);
		if (Configuration.CURRENT.colors.bracing.finish === Colors.FINSIH_COATED) {
			this.ralColor = Configuration.CURRENT.colors.bracing.ralColor;
		}
	}
	getRotation(app, params, mainBeamHeight, deckingFinishHeight) {
		let min = mainBeamHeight + deckingFinishHeight;

		let overstaandezijde = params.etageHeight - min;
		let aanliggendezijde = params.endColumnPosition - params.startColumnPosition;

		return Math.radians_to_degrees(Math.atan(overstaandezijde / aanliggendezijde));
	}
	getWidth(app, params, mainBeamHeight, deckingFinishHeight) {
		let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');

		let min = mainBeamHeight + deckingFinishHeight;

		// Horizontale lengte min de kolombreedte, omdat we de bracing tussen 2 kolommen in willen hebben.
		let hLength = params.endColumnPosition - params.startColumnPosition - columnWidth;
		let horizontalLengthx2 = hLength * hLength;

		// Verticale lengte min de packetHeight
		let vLength = params.etageHeight - min;
		let verticalLengthx2 = vLength * vLength;

		// Berekenen lengte van de bracing
		return Math.round(Math.sqrt(horizontalLengthx2 + verticalLengthx2));
	}

	convert() {
		return new jsonBracing(this);
	}
}
