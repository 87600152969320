import { Data } from '../data';
import { Store } from '../store';
import { TransportDelivery } from './transportDelivery';

export class TransportDeliveries extends Data {
	objectName = 'TransportDeliveries';

	url = 'TransportDeliveries';
	publicUrl = 'TransportDeliveriesPublic';
	todoPaging = {
		currentPage: 1,
		totalItems: 0,
		pageSize: 20,
		count: 1,
	};
	cancelledPaging = {
		currentPage: 1,
		totalItems: 0,
		pageSize: 20,
		count: 1,
	};
	subscribedPaging = {
		currentPage: 1,
		totalItems: 0,
		pageSize: 20,
		count: 1,
	};
	declinedPaging = {
		currentPage: 1,
		totalItems: 0,
		pageSize: 20,
		count: 1,
	};
	asssignedPaging = {
		currentPage: 1,
		totalItems: 0,
		pageSize: 20,
		count: 1,
	};
	donePaging = {
		currentPage: 1,
		totalItems: 0,
		pageSize: 20,
		count: 1,
	};
	getTransportDeliveryPricesToDo(reload, thenCallBack) {
		return super.list({ top: this.todoPaging.pageSize, page: this.todoPaging.currentPage }, reload, 'todo', '/Todo', thenCallBack, thenCallBack);
	}
	getTransportDeliveryPricesCancelled(reload, thenCallBack) {
		return super.list({ top: this.cancelledPaging.pageSize, page: this.cancelledPaging.currentPage }, reload, 'cancelled', '/Cancelled', thenCallBack, thenCallBack);
	}

	
	getTransportDeliveryPricesSubscribed(reload, thenCallBack) {
		return super.list({top:this.subscribedPaging.pageSize, page:this.subscribedPaging.currentPage}, reload, 'subscribed', '/Subscribed', thenCallBack, thenCallBack);
	}
	getTransportDeliveryPricesDeclined(reload, thenCallBack) {
		return super.list({ top: this.declinedPaging.pageSize, page: this.declinedPaging.currentPage }, reload, 'declined', '/Declined', thenCallBack, thenCallBack);
	}
	getTransportDeliveryPricesAssigned(reload, thenCallBack) {
		return super.list({ top: this.asssignedPaging.pageSize, page: this.asssignedPaging.currentPage }, reload, 'asssigned', '/Assigned', thenCallBack, thenCallBack);
	}
	getTransportDeliveryPricesDone(reload, thenCallBack) {
		return super.list({ top: this.donePaging.pageSize, page: this.donePaging.currentPage }, reload, 'done', '/Done', thenCallBack, thenCallBack);
	}

	getDeliveryInformation(guid, force, thenCallback, catchCallBack) {
		return super.get(Data.baseUrl + this.url + '/deliveryInformation?accessCode=' + guid, force, (r) => thenCallback(this.fetchListItem(r)), catchCallBack, guid, false);
	}
	getDeliveryInformationPublic(guid, force, thenCallback, catchCallBack) {
		return super.get(this.fullPublicUrl + '/deliveryInformation?accessCode=' + guid, force, (r) => thenCallback(this.fetchListItem(r)), catchCallBack, guid, false);
	}
	get fullPublicUrl() {
		return Data.baseUrl + this.publicUrl;
	}
	completeDelivery(guid, thenCallBack, catchCallBack) {
		return this.store.dataAccess
			.post(this.fullPublicUrl + '/completeDelivery?accessCode=' + guid, false, false, null, null, false)
			.then((r) => thenCallBack(this.fetchListItem(r)))
			.catch(catchCallBack);
	}
	fetchListItem(item) {
		// standaard teruggeven. In andere objecten kan hier een subobject gemaakt worden
		let returnItem = new TransportDelivery();
		returnItem.setStore(this.store);
		returnItem.fetch(item);

		return returnItem;
	}
	setPagingItems(result, cacheId) {
		switch (cacheId) {
			case 'todo':
				this.todoPaging = {
					currentPage: result.page,
					totalItems: result.count,
					pageSize: result.top,

					listRequest: null,
				};
				break;
			case 'cancelled':
				this.cancelledPaging = {
					currentPage: result.page,
					totalItems: result.count,
					pageSize: result.top,
					listRequest: null,
				};
			case 'subscribed':
				this.subscribedPaging = {
					currentPage: result.page,
					totalItems: result.count,
					pageSize: result.top,
					listRequest: null,
				};
			case 'declined':
				this.declinedPaging = {
					currentPage: result.page,
					totalItems: result.count,
					pageSize: result.top,
					listRequest: null,
				};
			case 'asssigned':
				this.asssignedPaging = {
					currentPage: result.page,
					totalItems: result.count,
					pageSize: result.top,
					listRequest: null,
				};
			case 'done':
				this.donePaging = {
					currentPage: result.page,
					totalItems: result.count,
					pageSize: result.top,
					listRequest: null,
				};
		}
	}
}
