import { Configuration } from '../../../configurator/configuration';
import { Canvas3D } from '../../Canvas3D';
import { ColumnProtector3D } from '../ColumnProtector3D';

export class ProtectorLeftBottom3D extends ColumnProtector3D {
	objectName = 'ProtectorLeftBottom3D';
	rotationY = -270;
	draw() {
		let modelWidth = Canvas3D.CURRENT.getModelDataByOid(this.oid, 'width');
		let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');

		this.z += modelWidth + columnWidth / 2;
		this.x -= modelWidth - columnWidth / 2;

		super.draw();
	}
}
