import { HicadDrawObject } from './HicadDrawObject';
import { Canvas3D } from '../../draw3d/Canvas3D';

export class jsonProfile extends HicadDrawObject {
	length = 0;
	height = 0;
	thickness = 0;
	type = 0;
	holeClearance = 0;
	isStairWellProfile = false;
	direction = 0;
	position = 0;
	distanceFromBackToHeartLine = 0;

	static TYPE_MAINBEAM = 0;
	static TYPE_CHILDBEAM = 1;

	static DIRECTION_HORIZONTAL = 0;
	static DIRECTION_VERTICAL = 1;

	static POSITION_TOP = 0;
	static POSITION_RIGHT = 1;
	static POSITION_BOTTOM = 2;
	static POSITION_LEFT = 3;

	constructor(profile, params) {
		super(profile);
		this.type = params.type;
		this.direction = params.direction;
		this.position = params.position;
		this.length = profile.width;
		this.thickness = Canvas3D.CURRENT.getBeamData(profile.oid, 'thickness');
		this.isStairWellProfile = profile.isStairWellProfile;
		this.height = Canvas3D.CURRENT.getBeamData(this.oid, 'height');
		this.holeClearance = profile.holeClearance;
		this.distanceFromBackToHeartLine = profile.heartLineDistance;
	}
}
