import { Configuration } from '../../../configurator/configuration';
import { Store } from '../../../data/store';
import { Mathematic } from '../../../helpers/mathematic';
import { Canvas3D } from '../../Canvas3D';
import { Model3D } from '../../Model3D';

export class CageLadderTube3D extends Model3D {
	objectName = 'CageLadderTube3D';
	hoverTags = ['cageLadder'];
	constructor(x, y, z, params) {
		super(x, y, z, params);
		this.height = params.endHeight - params.startHeight;
		this.oid = Store.CURRENT.cageLadderMaterials.articles.cageLadderTube;
		this.ralColor = Configuration.CURRENT.colors.cageLadders.ralColor;

		const cageLadderTubeDepth = Canvas3D.CURRENT.getModelDataByOid(Store.CURRENT.cageLadderMaterials.articles.cageLadderTube, 'depth'); // 40
		const cageLadderTubeWidth = Canvas3D.CURRENT.getModelDataByOid(Store.CURRENT.cageLadderMaterials.articles.cageLadderTube, 'width'); // 60

		switch (params.position) {
			case Mathematic.TOP:
				this.rotationY = 90;
				if (params.side === Mathematic.RIGHT) {
					this.x -= cageLadderTubeDepth;
				}
				break;
			case Mathematic.RIGHT:
				if (params.side === Mathematic.RIGHT) {
					this.z -= cageLadderTubeDepth;
				}
				break;
			case Mathematic.BOTTOM:
				this.rotationY = 90;
				this.z += cageLadderTubeWidth;

				if (params.side === Mathematic.RIGHT) {
					this.x -= cageLadderTubeDepth;
				}
				break;
			case Mathematic.LEFT:
				this.x -= cageLadderTubeWidth;
				if (params.side === Mathematic.RIGHT) {
					this.z -= cageLadderTubeDepth;
				}
				break;
		}
	}
}
