// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../static/images/entresolvloer.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".error{color:red}.background{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat;background-size:cover;z-index:100}.background,.background:after{height:100vh;position:absolute;width:100%}.background:after{background-color:rgba(39,39,39,.4);content:\"\";z-index:101}.content .field{margin:10px 0}.content .field input{background-color:#fff;background-image:none;border:1px solid #bdc3c7;box-shadow:none;color:#34495e;display:block;font-family:Lato,Helvetica,Arial,sans-serif;font-size:15px;height:42px;line-height:1.467;padding:8px 12px;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
