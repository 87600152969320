import { ObjectGroup } from '../../draw/objectGroup';

export class Trimmings {
	objectName = 'Trimmings';
	static LEFT = 0;
	static TOP = 1;
	static RIGHT = 2;
	static BOTTOM = 3;
	trimmings = [];
	get() {
		return this.trimmings;
	}
	removeTrimmings() {
		this.trimmings = [];
	}
	calculateAmount(params) {
		this.trimmings.forEach((trimming) => {
			if (typeof trimming.calculateAmount === 'function') {
				trimming.calculateAmount(params);
			}
		});
	}
	addDrawObjects(parentObject) {
		const drawGroupOfTrimmings = [];
		this.trimmings.forEach((trimming) => {
			const drawObject = trimming.addDrawObjects(parentObject);
			if (drawObject !== null) {
				drawGroupOfTrimmings.push(drawObject);
			}
		});
		return drawGroupOfTrimmings;
	}
	addDrawObjects3d(canvas3d, etage, raster, posY) {
		this.trimmings.forEach((trimming, index) => {
			if (typeof trimming.addDrawObjects3d === 'function') {
				trimming.addDrawObjects3d(canvas3d, etage, raster, posY);
			}
		});
	}
}
