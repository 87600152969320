import { Store } from '../data/store.js';
import { RalColorAsset3D } from '../draw3d/assets/RalColorAsset3D.js';
import { Color } from './color.js';
import { Configuration } from './configuration.js';

export class Colors {
	objectName = 'Colors';
	objectName3d = 'Colors3D';
	static FINSIH_COATED = 0;
	static FINISH_GALVANIZED = 1;
	static FINISH_NONE = 2;

	defaultColor = new Color((type) => {
		this.colorUpdated(type);
	});
	columns = new Color((type) => {
		this.colorUpdated(type);
	});
	profiles = new Color((type) => {
		this.colorUpdated(type);
	});
	stairs = new Color((type) => {
		this.colorUpdated(type);
	});
	bracing = new Color((type) => {
		this.colorUpdated(type);
	});
	bracingColumns = new Color((type) => {
		this.colorUpdated(type);
	});
	handRail = new Color((type) => {
		this.colorUpdated(type);
	});
	palletGates = new Color((type) => {
		this.colorUpdated(type);
	});
	tiltGates = new Color((type) => {
		this.colorUpdated(type);
	});
	cageLadders = new Color((type) => {
		this.colorUpdated(type);
	});
	columnProtectors = new Color((type) => {
		this.colorUpdated(type);
	});
	constructor(onSave) {
		this._onSave = onSave;
	}

	getDefaultList() {
		Store.CURRENT.finishDefaults.list(null, true, null, '', (res) => {
			this.setDefaults(res);
		});
	}

	getColors() {
		let items = {};
		Object.keys(Configuration.CURRENT.colors).forEach((object, index) => {
			if (typeof this[object] === 'object' && this[object] !== null && typeof this[object] !== 'undefined') {
				items[object] = {
					ralColor: this[object].ralColor,
					finish: this[object].finish,
				};
			}
		});
		return items;
	}

	// * Doen we alleen bij nieuwe configuratie.
	// * Slaan alles op wat uit ERP komt.
	// * Zet defaultkleur naar 7016.
	setDefaults(defaultsList) {
		defaultsList.forEach((item) => {
			if (typeof this[item.type] !== 'undefined') {
				this[item.type].setOwnDefaults(item);
			}
		});
	}
	// Aanroepen vanuit Color object,
	// Wanneer type dan default is alle andere kleuren updaten.
	colorUpdated(type) {
		// Wanneer de onchange type default is gebruiken we de defaultcolor dus moeten we de andere kleuren updaten naar eigen waarden.
		if (type === 'default') {
			Object.keys(Configuration.CURRENT.colors).forEach((object, index) => {
				if (Configuration.CURRENT.colors[object] !== null && typeof Configuration.CURRENT.colors[object] === 'object') {
					if (Configuration.CURRENT.colors[object].overrideDefault === false && Configuration.CURRENT.colors[object].colorPossible === true && Configuration.CURRENT.colors[object].changed === false) {
						//* * Speciale functie aanroepen, want deze gebruitk de SET van ralcolor die dan een onchange doet op een eigen type en dan Changed op true zet,
						//* * maar dat is niet nodig bij een verandering van de defaultkleur.
						Configuration.CURRENT.colors[object].setDefaultRal(this.defaultColor.ralColor);
					}
				}
			});
			this.onSave();
		}
	}
	onSave() {
		// Refers to DelayedSave in Configuration.
		if (typeof this._onSave === 'function') {
			this._onSave(null, true);
		}
	}

	setReferences(params) {
		this.onSave = params.save;

		Object.keys(this).forEach((object, index) => {
			if (this[object] !== null && typeof this[object] === 'object' && typeof this[object].setReferences === 'function') {
				this[object].setReferences({
					onChange: (type) => {
						this.colorUpdated(type);
					},
				});
			}
		});
	}
	removeReferences() {
		this._onChange = null;
		this._onSave = null;
		Object.keys(this).forEach((object, index) => {
			if (this[object] !== null && typeof this[object] === 'object' && typeof this[object].removeReferences === 'function') {
				this[object].removeReferences();
			}
		});
	}
	create3DAssets(Canvas3D) {
		Object.keys(this).forEach((object, index) => {
			if (typeof this[object] === 'object' && this[object] !== null) {
				if (typeof this[object].ralColor !== 'undefined' && this[object].ralColor !== null) {
					Canvas3D.addAsset(new RalColorAsset3D(this[object].ralColor.toString()));
				}
			}
		});
		// Overige kleuren toevoegen die we als default gebruiken.
		Canvas3D.addAsset(new RalColorAsset3D('5000'));

		// Invisible box
		Canvas3D.addAsset(new RalColorAsset3D('10002'));
	}
}
