import { Configuration } from '../../../configurator/configuration';
import { Stair } from '../../../configurator/stair';
import { Canvas3D } from '../../Canvas3D';
import { Store } from '../../../data/store';
import { IntermediateLandings } from '../../../configurator/intermediateLandings';
import { HandRail3D } from '../../handrail/handRail3D';
import { HandRails } from '../../../configurator/handRails';

export class MidLandingHandRail3D {
	objectName = 'MidLandingHandRail3D';
	constructor(x, y, z, params) {
		let upComingCurrent = params.upComing;
		let upComingPrev = params.upComingPrev;

		let etageHeightLanding = params.landingData.etageHeightLanding;
		let stairPosition = params.stairData.position;
		let stairUpComing = params.stairData.upComing;
		let crossStairWell = params.stairData.crossStairWell;

		let poleWidth = Canvas3D.CURRENT.getModelDataByOid(params.stairData.material.handRailPost, 'width');

		this.ralColor = Configuration.CURRENT.colors.handRail.ralColor;
		this.plateColor = Configuration.CURRENT.colors.stairs.ralColor;
		this.poleColor = Configuration.CURRENT.colors.stairs.ralColor;

		let paramPreset = {
			childFriendly: Store.CURRENT.stairs.isStairChildFriendly(params.stairData.type),
			handRailType: Configuration.CURRENT.handRailType,
		};

		let sides = [Stair.UPCOMING_TOP, Stair.UPCOMING_RIGHT, Stair.UPCOMING_BOTTOM, Stair.UPCOMING_LEFT];

		let indexCurrent;
		let indexPrev;
		let shortSides;

		// Bij 180 graden maar 1 weggooien uit de array, anders krijgen we fouten.
		if (upComingCurrent === upComingPrev) {
			indexCurrent = sides.indexOf(upComingCurrent);
			sides.splice(indexCurrent, 1);
			let index;

			// TODO: Nu alleen bij crossstairwell open middenlanding (Als het etagelanding is).
			// TODO: Overleggen of we dit per landing op slaan, of eventueel kijken of de landingboundaries (- kleine marge) een raster kan vinden.

			// Alleen bij stairwell en etageHeightLanding
			if (crossStairWell && etageHeightLanding) {
				// Alleen stukje raling niet tekenen als de stairwell langs de vloer loopt.
				switch (stairPosition) {
					case Stair.POSITION_RIGHT:
					case Stair.POSITION_LEFT:
						if (stairUpComing === Stair.UPCOMING_TOP || stairUpComing === Stair.UPCOMING_BOTTOM) {
							// De positie van de trap uit de array halen.
							// We wilen dan juist de tegenovergestlede positie van de stair (Staat hij rechts, linker stuk van de landing) weghalen.
							index = sides.indexOf(Stair.toOppositeUpComing(stairPosition));
							sides.splice(index, 1);
						}
						break;

					case Stair.POSITION_BOTTOM:
					case Stair.POSITION_TOP:
						if (stairUpComing === Stair.UPCOMING_LEFT || stairUpComing === Stair.UPCOMING_RIGHT) {
							// De positie van de trap uit de array halen.
							// We wilen dan juist de tegenovergestlede positie van de stair (Staat hij boven, linker stuk van de landing) weghalen.
							index = sides.indexOf(Stair.toOppositeUpComing(stairPosition));
							sides.splice(index, 1);
						}
						break;
				}
			}

			shortSides = [upComingCurrent];
		} else {
			indexCurrent = sides.indexOf(upComingCurrent);
			sides.splice(indexCurrent, 1);

			indexPrev = sides.indexOf(upComingPrev);
			sides.splice(indexPrev, 1);

			shortSides = [upComingCurrent, upComingPrev];
		}

		// De ondoorbroken stukken tekenen aan de hand van wat de upcomings zijn van deze landing, hier zit dus geen trap in.
		// Dit zijn dus de lange stukken van de landing.
		sides.forEach((side) => {
			if (side === Stair.UPCOMING_TOP) {
				Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z, paramPreset, HandRails.TOP, params.width));
			}
			if (side === Stair.UPCOMING_BOTTOM) {
				Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z + params.depth, paramPreset, HandRails.BOTTOM, params.width));
			}
			if (side === Stair.UPCOMING_LEFT) {
				Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z, paramPreset, HandRails.LEFT, params.depth));
			}

			if (side === Stair.UPCOMING_RIGHT) {
				Canvas3D.CURRENT.addDrawObject(new HandRail3D(x + params.width, y, z, paramPreset, HandRails.RIGHT, params.depth));
			}
		});

		// Het kleine stukje tekenen wat bij de upcoming van de eigen landing hoort.
		// Dit is de upComing van de landing zelf, dus is hij rechts zal hij in de stair.upCming_right komen.

		// Wanneer lengte 3 is betekend het dus 180 graden
		if (params.landingData.landingType === IntermediateLandings.oneeightyDegrees) {
			// In deze loop tekenen we een klein stukje handrail tussen de 2 trappen in aan de kant van upComing.
			shortSides.forEach((side) => {
				switch (side) {
					case Stair.UPCOMING_BOTTOM:
						let bottomLength = params.width - params.stairData.step.width * 2 + poleWidth * 2;
						Canvas3D.CURRENT.addDrawObject(new HandRail3D(x + params.stairData.step.width - poleWidth, y, z + params.depth, paramPreset, HandRails.BOTTOM, bottomLength));
						break;
					case Stair.UPCOMING_LEFT:
						let leftLength = params.depth - params.stairData.step.width * 2 + poleWidth * 2;
						Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z + params.stairData.step.width - poleWidth, paramPreset, HandRails.LEFT, leftLength));
						break;

					case Stair.UPCOMING_RIGHT:
						let rightLength = params.depth - params.stairData.step.width * 2 + poleWidth * 2;
						Canvas3D.CURRENT.addDrawObject(new HandRail3D(x + params.width, y, z + params.stairData.step.width - poleWidth, paramPreset, HandRails.RIGHT, rightLength));
						break;

					case Stair.UPCOMING_TOP:
						let topLength = params.width - params.stairData.step.width * 2 + poleWidth * 2;
						Canvas3D.CURRENT.addDrawObject(new HandRail3D(x + params.stairData.step.width - poleWidth, y, z, paramPreset, HandRails.TOP, topLength));
						break;
				}
			});
		} else {
			// In deze loop, als het dus geen 180 graden is, tekenen we een stukje voor en na de trap.
			shortSides.forEach((side) => {
				switch (side) {
					case Stair.UPCOMING_BOTTOM:
						let bottomLength = (params.width - params.stairData.step.width) / 2 + poleWidth;
						if (bottomLength >= 2 * poleWidth) {
							Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z + params.depth, paramPreset, HandRails.BOTTOM, bottomLength));
							Canvas3D.CURRENT.addDrawObject(new HandRail3D(x + params.width - bottomLength, y, z + params.depth, paramPreset, HandRails.BOTTOM, bottomLength));
						}
						break;
					case Stair.UPCOMING_LEFT:
						let leftLength = (params.depth - params.stairData.step.width) / 2 + poleWidth;
						if (leftLength >= 2 * poleWidth) {
							Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z, paramPreset, HandRails.LEFT, leftLength));
							Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z + params.depth - leftLength, paramPreset, HandRails.LEFT, leftLength));
						}
						break;
					case Stair.UPCOMING_RIGHT:
						let rightLength = (params.depth - params.stairData.step.width) / 2 + poleWidth;
						if (rightLength >= 2 * poleWidth) {
							Canvas3D.CURRENT.addDrawObject(new HandRail3D(x + params.width, y, z, paramPreset, HandRails.RIGHT, rightLength));
							Canvas3D.CURRENT.addDrawObject(new HandRail3D(x + params.width, y, z + params.depth - rightLength, paramPreset, HandRails.RIGHT, rightLength));
						}
						break;
					case Stair.UPCOMING_TOP:
						let topLength = (params.width - params.stairData.step.width) / 2 + poleWidth;
						if (topLength >= 2 * poleWidth) {
							Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z, paramPreset, HandRails.TOP, topLength));
							Canvas3D.CURRENT.addDrawObject(new HandRail3D(x + params.width - topLength, y, z, paramPreset, HandRails.TOP, topLength));
						}
						break;
				}
			});
		}
	}
}
