import { Store } from '../../data/store';
import { Mathematic } from '../../helpers/mathematic';
import { Canvas3D } from '../Canvas3D';
import { CageLadderCageTube3D } from './parts/CageLadderCageTube3D';
import { CageLadderCirculairPart3D } from './parts/CageLadderCirculairPart3D';

export class Cage3D {
	objectName = 'Cage3D';
	constructor(cageLadderObject, cageLadderPosition) {
		const heightCageAboveFloor = Store.CURRENT.cageLadderMaterials.settings.heightCageAboveFloor; //800
		const ladderHeight = cageLadderObject.endHeight + heightCageAboveFloor - cageLadderObject.startHeight;
		const cageStartHeight = Store.CURRENT.cageLadderMaterials.settings.cageStartHeight; //2300
		const distanceBetweenCageCircularParts = Store.CURRENT.cageLadderMaterials.settings.distanceBetweenCageCircularParts; //613;
		const circulairPartHeight = Canvas3D.CURRENT.getModelDataByOid(Store.CURRENT.cageLadderMaterials.articles.cageLadderCageCirculairPart, 'height'); // 40
		const lengthCage = ladderHeight - cageStartHeight - circulairPartHeight;
		const partsNeeded = Math.ceil(lengthCage / distanceBetweenCageCircularParts);
		const distance = lengthCage / partsNeeded;
		const cageStartPositionY = cageLadderObject.startHeight + cageStartHeight;

		const objectParams = {
			position: cageLadderObject.position,
			boundaries: cageLadderPosition,
			startHeight: cageLadderObject.startHeight,
			endHeight: cageLadderObject.endHeight + heightCageAboveFloor,
			ladderHeight: ladderHeight,
			lengthCage: lengthCage,
		};
		let cageTubeParams = objectParams;

		// Positie iets aanpassen, omdat de circulair part aan de buitenkanten 35mm uitsprong heeft.
		switch (cageLadderObject.position) {
			case Mathematic.TOP:
				cageLadderPosition.startX -= 35;
				break;
			case Mathematic.RIGHT:
				cageLadderPosition.startY -= 35;
				break;
			case Mathematic.BOTTOM:
				cageLadderPosition.startX -= 35;
				break;
			case Mathematic.LEFT:
				cageLadderPosition.startY -= 35;
				break;
		}

		for (let i = 0; i <= partsNeeded; i++) {
			let position = cageStartPositionY + i * distance;
			Canvas3D.CURRENT.addDrawObject(new CageLadderCirculairPart3D(cageLadderPosition.startX, position, cageLadderPosition.startY, objectParams), Canvas3D.TYPE_CAGELADDERPART);
		}

		// Toevoegen van tubes die bij de cage horen.
		// Altijd 3 stuks, 1 links, 1 rechts, en 1 aan de achterkant van de cage.

		// LEFT vanaf boven gezien.
		cageTubeParams.side = Mathematic.LEFT;
		Canvas3D.CURRENT.addDrawObject(new CageLadderCageTube3D(cageLadderPosition.startX, cageStartPositionY, cageLadderPosition.startY, objectParams), Canvas3D.TYPE_CAGELADDERPART);

		// RIGHT vanaf boven gezien.
		cageTubeParams.side = Mathematic.RIGHT;
		Canvas3D.CURRENT.addDrawObject(new CageLadderCageTube3D(cageLadderPosition.startX, cageStartPositionY, cageLadderPosition.startY, objectParams), Canvas3D.TYPE_CAGELADDERPART);

		// Top altijd tegeoverstaand.
		cageTubeParams.side = Mathematic.TOP;
		Canvas3D.CURRENT.addDrawObject(new CageLadderCageTube3D(cageLadderPosition.startX, cageStartPositionY, cageLadderPosition.startY, objectParams), Canvas3D.TYPE_CAGELADDERPART);
	}
}
