export class DrawObjects {
	objectName = 'DrawObjects';
	_objects = [];
	get objects() {
		return this._objects;
	}
	set objects(value) {
		this._objects = value;
	}

	draw(canvas) {
		this.objects.forEach((drawObject, index) => {
			drawObject.draw(canvas);
		});
	}
	push(value) {
		this.objects.push(value);
	}
	clear(objectType) {
		if (typeof objectType !== 'undefined' && objectType !== null) {
			this.objects = this.objects.filter((object) => object.objectParams === null || object.objectParams.type !== objectType);
			return;
		}
		this.objects = [];
	}
	clearByDrawId(id) {
		if (typeof id !== 'undefined' && id !== null) {
			this.objects = this.objects.filter((object) => object.id !== id);
		}
	}
	get(id) {
		let foundObject = null;
		this.objects.forEach((object) => {
			if (object.id === id) {
				foundObject = object;
			}
		});
		return foundObject;
	}
	// vanwege historie setLineColor gemaakt ipv setter voor linecolor enz.
	setLineColor(color) {
		if (typeof this.objects !== 'undefined') {
			this.objects.forEach((object) => {
				object.setLineColor(color);
			});
		}
	}
	setFillColor(color) {
		if (typeof this.objects !== 'undefined') {
			this.objects.forEach((object) => {
				object.setFillColor(color);
			});
		}
	}
	setColor(color) {
		if (typeof this.objects !== 'undefined') {
			this.objects.forEach((object) => {
				object.setColor(color);
			});
		}
	}
	getByObjectName(objectName) {
		let foundObject = null;
		this.objects.forEach((object) => {
			if (object.objectName === objectName) {
				foundObject = object;
			}
		});
		return foundObject;
	}
	find(x, y) {
		const foundPositions = [];
		this.objects.forEach((drawObject, index) => {
			if (drawObject.equals(x, y)) {
				foundPositions.push(drawObject);
			}
			if (typeof drawObject.find === 'function') {
				const groupPositions = drawObject.find(x, y);
				if (groupPositions.length > 0) {
					groupPositions.forEach((position) => {
						foundPositions.push(position);
					});
				}
			}
		});
		return foundPositions;
	}
	compare(a, b) {
		let aMousePriority = 100;
		let bMousePriority = 100;
		if (typeof a.object !== 'undefined' && a.object !== null) {
			aMousePriority = a.object.mousePriority;
		}
		if (typeof b.object !== 'undefined' && b.object !== null) {
			bMousePriority = b.object.mousePriority;
		}

		if (aMousePriority < bMousePriority) {
			return -1;
		}
		if (aMousePriority > bMousePriority) {
			return 1;
		}
		return 0;
	}
	onChangeChildBeamLength(raster, delta, evt, mainBeam, canvas, params, actualSize) {
		//	let first = true;
		this.objects.forEach((drawObject, index) => {
			if (typeof drawObject.object !== 'undefined' && drawObject.object !== null && typeof drawObject.object.onChangeChildBeamLength !== 'undefined') {
				drawObject.object.onChangeChildBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params, actualSize);
			}
		});
	}
	onChangeMainBeamLength(raster, delta, evt, mainBeam) {
		this.objects.forEach((drawObject, index) => {
			if (typeof drawObject.object !== 'undefined' && drawObject.object !== null && typeof drawObject.object.onChangeMainBeamLength !== 'undefined') {
				drawObject.object.onChangeMainBeamLength(raster, delta, evt, drawObject, mainBeam);
			}
		});
	}
	onColumnChange(x, y, evt, canvas, params, actualSizeBefore, actualSizeAfter) {
		// actualSizeBefore is bij horizontaal lengte links en after is rechts
		// bij verticaal is dat resp. boven en beneden
		this.objects.forEach((drawObject, index) => {
			if (typeof drawObject.object !== 'undefined' && drawObject.object !== null && typeof drawObject.object.onColumnChange === 'function' && drawObject.object.id === params.id) {
				drawObject.object.onColumnChange(x, y, evt, canvas, params, actualSizeBefore, actualSizeAfter, drawObject);
			}
		});
	}
	findById(id) {
		// zoekt op id van het gekoppelde object (dus id van objecten vanuit de map configurator)
		let result = null;
		this.objects.forEach((drawObject, index) => {
			if (typeof drawObject.object !== 'undefined' && drawObject.object !== null && drawObject.object.id === id) {
				result = drawObject;
			}
		});
		return result;
	}
	findByDrawId(id) {
		// zoekt op id van het drawobject
		let result = null;
		this.objects.forEach((drawObject, index) => {
			if (drawObject.id === id) {
				result = drawObject;
			}
			if (drawObject.objectName === 'ObjectGroup') {
				let objectGroupResult = drawObject.findByDrawId(id);
				if (objectGroupResult !== null) {
					result = objectGroupResult;
				}
			}
		});

		return result;
	}
	findColumn(name) {
		let result = null;
		this.objects.forEach((drawObject, index) => {
			if (typeof drawObject.object !== 'undefined' && drawObject.object !== null && drawObject.object.name === name) {
				result = drawObject;
			}
		});
		return result;
	}
	minMax() {
		let result = { min: { x: -1, y: -1 }, max: { x: -1, y: -1 } };
		this.objects.forEach((drawObject) => {
			if (typeof drawObject.calculateMinMax !== 'undefined' && drawObject.calculateMinMax !== null) {
				if (drawObject.calculateMinMax === true) {
					let minMax = drawObject.minMax();
					if (result.min.x === -1 || result.min.x > minMax.min.x) {
						result.min.x = minMax.min.x;
					}
					if (result.min.y === -1 || result.min.y > minMax.min.y) {
						result.min.y = minMax.min.y;
					}
					if (result.max.x === -1 || result.max.x < minMax.max.x) {
						result.max.x = minMax.max.x;
					}
					if (result.max.y === -1 || result.max.y < minMax.max.y) {
						result.max.y = minMax.max.y;
					}
				}
			}
		});
		return result;
	}
}
