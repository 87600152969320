import { Configuration } from '../../../configurator/configuration';
import { Store } from '../../../data/store';
import { Mathematic } from '../../../helpers/mathematic';
import { Canvas3D } from '../../Canvas3D';
import { Model3D } from '../../Model3D';

export class CageLadderCirculairPart3D extends Model3D {
	objectName = 'CageLadderCirculairPart3D';
	constructor(x, y, z, params) {
		super(x, y, z, params);
		this.oid = Store.CURRENT.cageLadderMaterials.articles.cageLadderCageCirculairPart;
		this.ralColor = Configuration.CURRENT.colors.cageLadders.ralColor;

		const cageLadderCirculairPartDepth = Canvas3D.CURRENT.getModelDataByOid(Store.CURRENT.cageLadderMaterials.articles.cageLadderCageCirculairPart, 'depth'); // 770
		const cageLadderCirculairPartWidth = Canvas3D.CURRENT.getModelDataByOid(Store.CURRENT.cageLadderMaterials.articles.cageLadderCageCirculairPart, 'width'); // 750

		switch (params.position) {
			case Mathematic.TOP:
				this.z -= cageLadderCirculairPartDepth;
				break;

			case Mathematic.RIGHT:
				this.rotationY = -90;
				this.x += cageLadderCirculairPartDepth;
				break;

			case Mathematic.BOTTOM:
				this.rotationY = 180;
				this.z += cageLadderCirculairPartDepth;
				this.x += cageLadderCirculairPartWidth;
				break;

			case Mathematic.LEFT:
				this.rotationY = 90;
				this.x -= cageLadderCirculairPartDepth;
				this.z += cageLadderCirculairPartWidth;
				break;
		}
	}
}
