import { Data } from './data';
import { Languages } from './languages';
import { Leaflet } from './leaflet';
export class Leaflets extends Data {
	objectName = 'Leaflets';
	static CURRENT = null;
	url = 'leaflets';
	loadedLeaflets = [];
	loadAll(force, cacheId) {
		let data = {
			language: Languages.CURRENT.currentLanguage,
		};
		this.list(data, force, cacheId, '', (data) => {
			let newList = [];
			for (const item of data) {
				let newItem = new Leaflet();
				newItem.id = item.id;
				newItem.description = item.description;
				newItem.thumbnail = item.thumbnail;
				newList.push(newItem);
			}
			this.loadedLeaflets = newList;
		});
	}
}
