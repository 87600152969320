import { Data } from './data';
import * as Sentry from '@sentry/browser';
export class RalColors extends Data {
	objectName = 'RalColors';
	static CURRENT = null;
	url = 'RalColors';

	get ralColors() {
		return this.ralColors;
	}

	set ralColors(value) {}

	colorRanges = [];
	ralColors = [];

	allRalColors() {
		if (typeof this._list.list === 'undefined' || this._list.list === null) {
			this.list();
			return [];
		}
		return this.ralColors;
	}
	unique(value, index, self) {
		return self.indexOf(value.range) === index;
	}

	// Teruggeven de kleurgroepen en kleuren die erbij horen.
	// Geven alleen de groepen terug als er meer dan één kleur in zit (op basis van zoekende kleur).
	// Anders gewoon alles teruggeven
	getRangesWithColors(searchColor = '') {
		this.colorRange();
		if (searchColor.length === 0 || searchColor === '') {
			let ranges = [...this.colorRanges];
			ranges.forEach((range, index) => {
				range.colors = this._list.list.filter(function (ralColor) {
					return ralColor.range === range.type;
				});
			});
			return ranges;
		} else {
			// Bij zoeken eerst de kleuren bepalen die er zijn, dus die filteren.
			let colors = this._list.list.filter(function (ralColor) {
				return ralColor.ral.includes(searchColor);
			});

			// Groepen die we moeten returnen.
			let colorGroups = [...new Set(colors.map((color) => color.range))].sort(function (a, b) {
				return a - b;
			});

			let groups = [];
			// Kleuren toevoegen aan de groep.
			colorGroups.forEach((group) => {
				groups.push({
					colors: colors.filter((c) => c.range === group),
					type: group,
				});
			});

			return groups;
		}
	}
	getSingleRange(rangeName) {
		return this.colorRanges.filter((item) => item.type === rangeName);
	}
	// Ophalen kleuren range,
	colorRange() {
		if (this.colorRanges.length > 0) {
			return this.colorRanges;
		}
		if (typeof this._list.list === 'undefined' || this._list.list === null) {
			this.list();
		}
		this._list.list.forEach((item) => {
			let isInArray = this.colorRanges.filter(function (range) {
				return range.type === item.range;
			});
			if (isInArray.length === 0 && item.range !== null) {
				this.colorRanges.push({ type: item.range });
			}
		});
		return this.colorRanges;
	}
	hexColor(ral) {
		if (typeof this._list.list === 'undefined' || this._list.list === null) {
			this.list();
		}
		return this._list.list.filter((x) => x.ral === ral);
	}
}
