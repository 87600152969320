import { Configuration } from './configuration';
import { Hole } from './hole';
import { RemoveRaster } from './removeRaster';
import { Rectangle } from '../draw/rectangle';
import { Serializer } from './serializer';
import { DrawValue } from '../draw/drawValue';
import { Canvas } from '../draw/canvas';
import { Statics } from '../helpers/statics';

export class Holes {
	objectName = 'Holes';
	holes = [];
	showConfigurator = false;
	_onChange = null;
	_redraw = null;
	_edit = null;
	_remove = null;
	// possiblePossitionsVisible = false;
	configuratorSettings = new Hole();

	constructor(onChange, redraw) {
		this._onChange = onChange;
		this._redraw = redraw;

		setTimeout(() => {
			this.init();
		});
	}
	init() {
		if (typeof this.onMainBeamDirectionChange === 'function') {
			Configuration.CURRENT.profiles.onMainBeamDirectionChangeEvents.push((oldValue, newValue) => {
				this.onMainBeamDirectionChange(oldValue, newValue);
			});
		}
	}
	setReferences(params) {
		this._onChange = params.onChange;
		this._redraw = params.redraw;
		this._checkCollisions = params.checkCollisions;

		params.edit = this.edit.bind(this);
		params.remove = this.remove.bind(this);
		params.onChange = this.onChange.bind(this);

		this.holes.forEach((hole) => {
			hole.setReferences(params);
		});
	}
	removeReferences() {
		this._onChange = null;
		this._redraw = null;
		this.holes.forEach((hole) => {
			hole.removeReferences();
		});
	}
	onChange(status = null) {
		if (typeof this._onChange === 'function') {
			this._onChange(status);
		}
	}

	redraw() {
		if (this._redraw !== null && typeof this._redraw === 'function') {
			this._redraw();
		}
	}

	afterReconstruct() {
		this.showConfigurator = false; // false slaat hij niet altijd op omdat cancel niet een wijziging in de configuratie tot gevolg heeft. Maar bij reconstruct altijd configurator verbergen
	}

	addDrawObjects() {
		this.holes.forEach((hole) => {
			hole.addDrawObjects();
		});
	}

	addDrawObjects3d(canvas3d, etage, raster, posY) {
		this.holes.forEach((hole, index) => {
			hole.addDrawObjects3d(canvas3d, etage, raster, posY);
		});
	}

	get() {
		return this.holes;
	}

	// Na submit van edit-hole component.
	create() {
		this.showConfigurator = false;
		this.configuratorSettings.oldValues = '';

		// Bij nieuw gat kijken naar configuratorSettings.
		if (this.configuratorSettings.newHole === true) {
			// Wanneer x en y nog 0 zijn dan moet possible position click nog plaatsvinden.
			if (this.configuratorSettings.x === 0 && this.configuratorSettings.y === 0) {
				Configuration.CURRENT.editModus.setEditingObject('holes');
				return;
			}
		} else {
			this.onChange();
		}
	}

	showPossiblePostion(object) {
		object.opacity = 0.5;
	}
	hidePossiblePostion(object) {
		object.opacity = 0;
	}

	// Openen van de popup.
	newHole() {
		this.showConfigurator = true;
		this.configuratorSettings = new Hole({ newHole: true, x: 0, y: 0, width: 1000, depth: 1000, etageId: Configuration.CURRENT.etages.activeEtage().id });
		let serializer = new Serializer();
		this.configuratorSettings.oldValues = serializer.stringify(this.configuratorSettings);
	}
	cancel() {
		let serializer = new Serializer();
		this.configuratorSettings.update(serializer.parse(this.configuratorSettings.oldValues));
	}
	onRasterChanged(params) {
		this.holes.forEach((hole) => {
			hole.calculate();
		});
	}

	calculateAmount(params) {
		this.holes.forEach((hole) => {
			hole.calculateAmount(params);
		});
	}

	findByProfilePosition(coordinates, etageHeight) {
		return this.holes.filter((h) => h.onProfilePosition(coordinates, etageHeight) === true);
	}

	select(parameters) {
		this.holes.forEach((hole) => {
			hole.select(parameters);
		});
	}

	remove(object) {
		const indexToRemove = this.holes.findIndex((hole) => hole.id === object.id);
		this.holes.splice(indexToRemove, 1);
		Configuration.CURRENT.contextMenu.hide();
		this.onChange();
	}

	edit(holeObject) {
		this.showConfigurator = true;
		this.configuratorSettings = holeObject;
		this.configuratorSettings.newHole = false;
		let serializer = new Serializer();
		this.configuratorSettings.oldValues = serializer.stringify(this.configuratorSettings);
	}

	push(holeConfiguration, params) {
		const referenceParams = { edit: this.edit.bind(this), remove: this.remove.bind(this), onChange: this.onChange.bind(this), redraw: this.redraw.bind(this), checkCollisions: this._checkCollisions };
		this.holes.push(new Hole(holeConfiguration, params));
		this.holes[this.holes.length - 1].setReferences(referenceParams);
	}

	onClick(evt, drawObject) {
		this.configuratorSettings.newHole = false;
		this.push(this.configuratorSettings, drawObject.objectParams);

		Configuration.CURRENT.editModus.stopEditingObject();
		Configuration.CURRENT.select({ id: this.holes[this.holes.length - 1].id });
		this.onChange();

		Configuration.CURRENT.showToolsMenu = true;
		return { stopPropagation: true };
	}
	onMouseUp(evt, drawObject) {
		this.onChange();
		return { stopPropagation: true };
	}
	// Bij kiezen positie nieuw gat in de vloer
	// Wanneer over raster word gehoverd dat raster dan minder opactiy om duidelijk te maken dat dit gekozen kan worden.
	// Object is hier dan ook toegevoegde rectangle van addPossiblePositions
	onMouseMove(evt, drawObject) {
		const lengthX = Configuration.CURRENT.raster.spansX.get(drawObject.objectParams.x);
		const lengthY = Configuration.CURRENT.raster.spansY.get(drawObject.objectParams.y);
		if (lengthX !== null && lengthY !== null) {
			drawObject.opacity = 0.5;
		}
		return { stopPropagation: true };
	}
	// Bij verlaten van tekenobject van possiblePositions.
	// Dan opacity terugzetten.
	onMouseLeave(evt, drawObject) {
		const lengthX = Configuration.CURRENT.raster.spansX.get(drawObject.objectParams.x);
		const lengthY = Configuration.CURRENT.raster.spansY.get(drawObject.objectParams.y);
		if (lengthX !== null && lengthY !== null) {
			drawObject.opacity = 0.2;
		}
		return { stopPropagation: true };
	}

	// Als accessoiresType Holes is dan toevoegen van possible Positions voor dit object.
	addPossiblePositions(canvas, params) {
		let possiblePositionFound = false;
		const activeEtage = Configuration.CURRENT.etages.activeEtage();
		Configuration.CURRENT.raster.spansX.getSpans().forEach((spanX, indexX) => {
			Configuration.CURRENT.raster.spansY.getSpans().forEach((spanY, indexY) => {
				if (activeEtage.isActiveRaster(new RemoveRaster(indexX, indexY)) === true) {
					let raster = new Rectangle(
						new DrawValue(params.raster.getSizeX(indexX - 1), 1),
						new DrawValue(params.raster.getSizeY(indexY - 1), 1),
						new DrawValue(spanX.value, -2),
						new DrawValue(spanY.value, -2),

						Statics.COLOR_GREY,
						null,
						null,
						true,
						this,
						{ x: indexX, y: indexY },
						false,
						true,
					);
					raster.opacity = 0.2;
					possiblePositionFound = true;

					Canvas.CURRENT.addDrawObject(raster);
				}
			});
		});

		if (possiblePositionFound === false) {
			this.possiblePossitionsVisible = '';
			Configuration.CURRENT.editModus.stopEditingObject();
		}
	}

	collisions(boundaries, self) {
		let collisionsDetect = false;
		let errorResult = [];

		this.holes.forEach((hole) => {
			let holeResult = hole.collisions(boundaries, self);

			if (holeResult.result === true) {
				collisionsDetect = true;
				holeResult.errors.forEach((error) => {
					errorResult.push(error);
				});
			}
		});
		return { result: collisionsDetect, errors: errorResult, objectName: this.objectName };
	}
	overlap(boundaries) {
		let overlap = false;

		this.holes.forEach((hole, index) => {
			if (hole.overlap(boundaries) === true) {
				overlap = true;
			}
		});

		return overlap;
	}
	collisionCheck() {
		let collisions = false;
		let errorResult = [];
		this.holes.forEach((hole, index) => {
			let collisionCheck = hole.collisionCheck();
			if (collisionCheck.result === true) {
				collisions = true;
				collisionCheck.errors.forEach((error) => {
					errorResult.push(error);
				});
			}
		});
		return { result: collisions, errors: errorResult };
	}
}
