import { Configuration } from './configuration';

export class Notification {
	objectName = 'Notification';
	visible = false;
	text = '';
	closeButton = true;
	afterReconstruct() {
		this.visible = false; // false slaat hij niet altijd op omdat cancel niet een wijziging in de configuratie tot gevolg heeft. Maar bij reconstruct altijd configurator verbergen
		this.text = '';
	}
	hide() {
		this.visible = false;
		this.text = '';
	}
	close() {
		if (typeof this._close === 'function') {
			this._close();
		}
		this.visible = false;
	}
	show(text, timeOut, close, closeButton) {
		this.text = text;
		this.visible = true;
		this._close = close;
		if (typeof timeOut !== 'undefined' && timeOut !== null) {
			this.timeOut = setTimeout(() => {
				this.visible = false;
			}, timeOut);
		}
		if (typeof closeButton !== 'undefined' && closeButton !== null) {
			this.closeButton = closeButton;
		}
	}
}
