import { Configuration } from '../../../configurator/configuration';
import { Store } from '../../../data/store';
import { Mathematic } from '../../../helpers/mathematic';
import { Canvas3D } from '../../Canvas3D';
import { Model3D } from '../../Model3D';

export class CageLadderCageTube3D extends Model3D {
	objectName = 'CageLadderCageTube3D';
	constructor(x, y, z, params) {
		super(x, y, z, params);
		this.oid = Store.CURRENT.cageLadderMaterials.articles.cageLadderCageTube;
		this.ralColor = Configuration.CURRENT.colors.cageLadders.ralColor;
		this.height = params.lengthCage;

		const circulairPartDepth = Canvas3D.CURRENT.getModelDataByOid(Store.CURRENT.cageLadderMaterials.articles.cageLadderCageCirculairPart, 'depth'); // 770
		const circulairPartWidth = Canvas3D.CURRENT.getModelDataByOid(Store.CURRENT.cageLadderMaterials.articles.cageLadderCageCirculairPart, 'width'); // 750
		const circulairPartHeight = Canvas3D.CURRENT.getModelDataByOid(Store.CURRENT.cageLadderMaterials.articles.cageLadderCageCirculairPart, 'height'); // 40
		const stripWidth = Canvas3D.CURRENT.getModelDataByOid(this.oid, 'width'); // 40
		const stripDepth = Canvas3D.CURRENT.getModelDataByOid(this.oid, 'depth'); // 5

		switch (params.position) {
			case Mathematic.TOP:
				// CageLadder aan de bovenkant van de vloer,
				// Eerste tube voor de cage links, dan 90 graden linksom draaien.
				if (params.side === Mathematic.LEFT) {
					this.rotationY = -90;
					this.z += stripWidth;
					this.z -= circulairPartWidth / 2;
				} else if (params.side === Mathematic.RIGHT) {
					this.rotationY = -90;
					this.x += circulairPartWidth;
					this.z -= circulairPartWidth / 2;
				} else if (params.side === Mathematic.TOP) {
					this.z -= circulairPartDepth;
					this.x += circulairPartWidth / 2;
					this.x -= stripWidth / 2;
				}
				break;
			case Mathematic.RIGHT:
				if (params.side === Mathematic.LEFT) {
					this.x += circulairPartDepth / 2;
					this.x -= stripWidth;
				} else if (params.side === Mathematic.RIGHT) {
					this.z += circulairPartWidth;
					this.x += circulairPartDepth / 2;
					this.x -= stripWidth;
					this.z -= stripDepth;
				} else if (params.side === Mathematic.TOP) {
					this.rotationY = 90;
					this.z += stripWidth / 2;
					this.x += circulairPartDepth;
					this.z += circulairPartWidth / 2;
					this.x -= stripDepth;
				}
				break;

			case Mathematic.BOTTOM:
				// Let op, LEFT is hier eigenlijk rechts, maar we houden de posities aan zoals boven, dus is rechts eigenlijk links en links eigenlijk rechts.
				if (params.side === Mathematic.LEFT) {
					this.rotationY = -90;
					this.x += circulairPartWidth;
					this.z += circulairPartWidth / 2;
					this.z -= stripWidth;
				} else if (params.side === Mathematic.RIGHT) {
					this.rotationY = -90;
					this.z -= stripWidth;
					this.z += circulairPartWidth / 2;
					this.x += stripDepth;
				} else if (params.side === Mathematic.TOP) {
					this.z += circulairPartDepth;
					this.x += circulairPartWidth / 2;
					this.x -= stripWidth / 2;
					this.z -= stripDepth;
				}
				break;

			case Mathematic.LEFT:
				if (params.side === Mathematic.LEFT) {
					this.z += circulairPartWidth;
					this.x -= circulairPartDepth / 2;
					this.x += stripWidth;
					// this.x -= stripWidth;
					this.z -= stripDepth;
				} else if (params.side === Mathematic.RIGHT) {
					this.x -= circulairPartDepth / 2;
					this.x += stripWidth;
				} else if (params.side === Mathematic.TOP) {
					this.rotationY = -90;
					this.z += circulairPartWidth / 2;
					this.x -= circulairPartDepth;
					this.x += stripDepth;
				}

				break;
		}
	}
}
