import { CageLadders } from "../cageLadders/cageLadders"

export class CageLaddersStairsUtil {

	static checkIfObjectExistsInList(list, objectToCheck, classToCompare, returnFunc) {
		// loop over de items van de list heen
		for (let i = 0; i < list.length; i++) {
			// stair item op basis van de index
			const obj = list[i];
			// boolean die bijhoudt of alle properties values overeenkomen met de huidige stair[index]
			let allPropertiesMatch = true;
			// loop over elk property van het object heen (object to check is de trap die vergeleken worden met de list item)
      let lastCheckedProperty = null;
			for (const property in objectToCheck) {
        lastCheckedProperty = property;
				// wanneer item niet gecompared mag worden moet hier niet op gecheckt worden. (id, upcoming), dan naar volgend item in de lijst
				if (classToCompare === CageLadders) {
          if (CageLadders.comparible.filter((n) => n === property).length <= 0) continue;
        }

				if (classToCompare.notCompareable.filter((n) => n === property).length > 0) {
					continue;
				}

				// als item een object is, kan het een array zijn. zoals bijv. endlanding || intermediatelandings
				if (typeof objectToCheck[property] === 'object') {
					// wanneer het een array is moeten we over elk item loopen in de onderstaande functie
					if (Array.isArray(objectToCheck[property])) {
						if (!this.arraysMatch(obj[property], objectToCheck[property], classToCompare, returnFunc)) {
							allPropertiesMatch = false;
							break;
						}
					} else {
						// het is een object, dus recursief object checken of deze objecten hetzelfde zijn
						if ((obj[property] !== null && objectToCheck[property] === null) || (obj[property] === null && objectToCheck[property] !== null)) {
							allPropertiesMatch = false;
							break;
						}

						if (obj[property] !== null && !this.checkIfObjectExistsInList([obj[property]], objectToCheck[property], classToCompare, returnFunc).exist) {
							allPropertiesMatch = false;
							break;
						}
					}
				} else if ((obj === null && objectToCheck !== null) || (objectToCheck === null && obj !== null)) {
					// Wanneer objectType niet gelijk is, dan heeft de ene stair het object wel, het andere niet.
					// Dan matchen de trappen dus ook niet.
					allPropertiesMatch = false;
					break;
				} else if (obj[property] !== objectToCheck[property]) {
					// wanneer het een primitive is, zoals string of number vergelijken of de property van de huidig item in list en objectToCheck gelijk zijn aan elkaar
					allPropertiesMatch = false;
					break;
				}
			}
			if (allPropertiesMatch) {
				// alles hetzelfde, dus bestaat al in de list, geeft object terug en true dat deze bestaat.
				return returnFunc(obj);
			}
		}

		// bestaat nog niet in de lijst, dus exist is false
		return { exist: false };
	}

	static arraysMatch(arr1, arr2, classToCompare, returnFunc) {
		// check allereerst of de arrays gelijk zijn aan elkaar
		if (arr1.length !== arr2.length) {
			return false;
		}

		// loop over de array heen, is van item in de list, arr2 is item to check
		for (let i = 0; i < arr1.length; i++) {
			if (typeof arr1[i] === 'object') {
				// check hierin of de intermediatelanding niet gelijk is aan elkaar, dan return false. Wordt in de functie checkIfObjectExistsInList allPropertiesMatch op false gezet en wordt deze nieuw toegevoegd aan de lijst.
				if (!this.checkIfObjectExistsInList(arr2, arr1[i], classToCompare, returnFunc).exist) {
					return false;
				}
			} else if (!arr2.includes(arr1[i])) {
				// check of elementen bestaan in de array
				return false;
			}
		}
		return true;
	}
}
