/* eslint-disable no-new */
import { Configuration } from '../../../configurator/configuration';
import { Profiles } from '../../../configurator/profiles';
import { RemoveRaster } from '../../../configurator/removeRaster';
import { Stair } from '../../../configurator/stair';
import { StairTrimmings } from '../../../configurator/trimmings/stairTrimmings';
import { Canvas3D } from '../../Canvas3D';
import { MainBeamBottom3D } from '../../profiles/mainbeams/standard/MainBeamBottom3D';
import { MainBeamLeft3D } from '../../profiles/mainbeams/standard/MainBeamLeft3D';
import { MainBeamRight3D } from '../../profiles/mainbeams/standard/MainBeamRight3D';
import { MainBeamTop3D } from '../../profiles/mainbeams/standard/MainBeamTop3D';

export class StairInFloorProfiles3D {
	objectName = 'StairInFloorProfiles3D';
	constructor(stairData, raster, etage) {
		let mainBeamHeight;
		let mainBeamDepth;
		let beamData;

		// Wanneer verticaal dan is de mainBeam de SpanY, daarom hier verschil maken.
		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_VERTICAL) {
			beamData = raster.spansY.getSpans()[stairData.rasters[0].y].beam.oid;
		} else {
			beamData = raster.spansX.getSpans()[stairData.rasters[0].x].beam.oid;
		}

		// Hoogte en diepte van de mainbeam ophalen zodat we nog kunnen opschuiven.
		mainBeamHeight = Canvas3D.CURRENT.getBeamData(beamData, 'height');
		mainBeamDepth = Canvas3D.CURRENT.getBeamData(beamData, 'depth');

		let paramsObject = {
			raster: raster,
			etage: etage,
			drawHeight: stairData.endHeight - mainBeamHeight - Configuration.CURRENT.finish.height,
			profileType: 'mainbeam',
			doubleDouble: false,
			ralColor: Configuration.CURRENT.colors.profiles.ralColor,
			isStairWellProfile: true,
			mainBeamHeight: mainBeamHeight,
			oid: beamData,
		};

		let x = stairData.stairWell.startX;
		let y = stairData.stairWell.startY;

		let stairWellWidth = stairData.stairWell.width;
		let stairWellDepth = stairData.stairWell.depth;

		let rasterLeft = Configuration.CURRENT.raster.getRasterByCoordinate(stairData.stairWell.startX, stairData.stairWell.startY);
		let rasterRight = Configuration.CURRENT.raster.getRasterByCoordinate(stairData.stairWell.startX + stairWellWidth, stairData.stairWell.startY);
		let rasterBottom = Configuration.CURRENT.raster.getRasterByCoordinate(stairData.stairWell.startX, stairData.stairWell.startY + stairWellDepth);

		// Kijken of X en Z in een actief raster liggen, het kan voorkomen dat een StairInFloor niet geheel in de vloer staat.
		switch (stairData.upComing) {
			case Stair.UPCOMING_RIGHT:
				// Trap linker punt ligt in een niet actief raster.
				if (etage.isActiveRaster(new RemoveRaster(rasterLeft.x, rasterLeft.y)) === false) {
					// Dan halen we het startPunt op op basis van volgende raster.
					x = Configuration.CURRENT.raster.getSizeX(rasterLeft.x);
					stairWellWidth = stairData.stairWell.startX + stairData.stairWell.width - x;
				}
				break;
			case Stair.UPCOMING_LEFT:
				// Trap rechter punt niet in een actief raster.
				if (etage.isActiveRaster(new RemoveRaster(rasterRight.x, rasterRight.y)) === false) {
					// Dan terugrekenen, dus beginpunt niet veranderen maar lengte uitrekenen.
					stairWellWidth = Configuration.CURRENT.raster.getSizeX(rasterLeft.x) - stairData.stairWell.startX;
				}
				break;
			case Stair.UPCOMING_TOP:
				// Trap onderste punt niet in een actief raster.
				if (etage.isActiveRaster(new RemoveRaster(rasterBottom.x, rasterBottom.y)) === false) {
					// Dan terugrekenen, dus beginpunt niet veranderen maar lengte uitrekenen.
					stairWellDepth = Configuration.CURRENT.raster.getSizeY(rasterLeft.y) - y;
				}
				break;
			case Stair.UPCOMING_BOTTOM:
				// Trap bovenste punt niet in een actief raster.
				if (etage.isActiveRaster(new RemoveRaster(rasterLeft.x, rasterLeft.y)) === false) {
					// Dan terugrekenen, dus beginpunt niet veranderen maar lengte uitrekenen.
					y = Configuration.CURRENT.raster.getSizeY(rasterLeft.y);
					stairWellDepth = stairData.stairWell.startY + stairData.stairWell.depth - y;
				}
				break;
		}

		// Rond het trapgat per trimming die berekent is Beams in 3D toevoegen.
		// Omdat we met rotatie in 3D zitten altijd tegenovergestelde type beam tekenen.
		// Dan is het bevestigingsstuk van de beam altijd tegen het trapgat aan.
		stairData.stairWell.trimmings.trimmings.forEach((trimming, index) => {
			switch (trimming.position) {
				case StairTrimmings.TOP:
					new MainBeamBottom3D(trimming.startX, trimming.startY, trimming.endX - trimming.startX, paramsObject);
					break;
				case StairTrimmings.BOTTOM:
					new MainBeamTop3D(trimming.startX, trimming.endY, trimming.endX - trimming.startX, paramsObject);
					break;
				case StairTrimmings.LEFT:
					new MainBeamRight3D(trimming.startX, trimming.startY, trimming.endY - trimming.startY, paramsObject);
					break;
				case StairTrimmings.RIGHT:
					new MainBeamLeft3D(trimming.startX, trimming.startY, trimming.endY - trimming.startY, paramsObject);
					break;
			}
		});
	}
}
