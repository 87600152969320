import { Stair } from '../../configurator/stair';
import { Stairs } from '../../data/stairs';
import { Store } from '../../data/store';
import { Mathematic } from '../../helpers/mathematic';
import { Canvas3D } from '../Canvas3D';
import { Cage3D } from './Cage3D';
import { CageLadderStep3D } from './parts/CageLadderStep3D';
import { CageLadderTube3D } from './parts/CageLadderTube3D';
// Hoofd object, één cageladder tekenen, hier benodige onderdelen aanroepen.
export class CageLadder3D {
	objectName = 'CageLadder3D';
	constructor(cageLadderObject) {
		// Hier komen we in vanaf single cageladder, sturen hele object mee.
		const cageLadderPosition = cageLadderObject.boundaries[0];
		const heightCageAboveFloor = Store.CURRENT.cageLadderMaterials.settings.heightCageAboveFloor; //800
		const ladderHeight = cageLadderObject.endHeight + heightCageAboveFloor - cageLadderObject.startHeight;
		const cageLadderPositions = this.getPosition(cageLadderObject);

		const objectParams = {
			position: cageLadderObject.position,
			boundaries: cageLadderPosition,
			startHeight: cageLadderObject.startHeight,
			endHeight: cageLadderObject.endHeight + heightCageAboveFloor,
		};

		// Afstand tussen de treden is 263mm,
		// Aantal bepalen door hoogte van de trap te delen door 263.
		const distanceBetweenSteps = Store.CURRENT.cageLadderMaterials.settings.distanceBetweenSteps; //263;
		const amountOfSteps = Math.floor((ladderHeight - heightCageAboveFloor) / distanceBetweenSteps);

		// Op het startpunt van de cageLadder een tube plaatsen
		objectParams.side = Mathematic.LEFT;
		Canvas3D.CURRENT.addDrawObject(new CageLadderTube3D(cageLadderPositions.startX, cageLadderObject.startHeight, cageLadderPositions.startY, objectParams), Canvas3D.TYPE_CAGELADDERPART);

		// 2e tube plaatsen.
		objectParams.side = Mathematic.RIGHT;
		Canvas3D.CURRENT.addDrawObject(new CageLadderTube3D(cageLadderPositions.endX, cageLadderObject.startHeight, cageLadderPositions.endY, objectParams), Canvas3D.TYPE_CAGELADDERPART);

		// Steps toevoegen.
		for (let i = 1; i <= amountOfSteps; i++) {
			Canvas3D.CURRENT.addDrawObject(
				new CageLadderStep3D(cageLadderPositions.startX, cageLadderObject.startHeight + i * distanceBetweenSteps, cageLadderPositions.startY, objectParams),
				Canvas3D.TYPE_CAGELADDERPART,
			);
		}

		// In ERP met checkbox/boolean staat opgeslagen of dit SPS een cage nodig heeft.
		if (Store.CURRENT.cageLadders.hasCage(cageLadderObject.startHeight, cageLadderObject.endHeight) === true) {
			new Cage3D(cageLadderObject, cageLadderPositions);
		}
	}

	getPosition(cageLadderObject) {
		const cageLadderBoundary = cageLadderObject.boundaries[0];
		let cageLadderPositions;
		if (cageLadderObject.place === Stair.PLACE_OUTSIDE) {
			switch (cageLadderObject.position) {
				case Mathematic.TOP:
					cageLadderPositions = {
						startX: cageLadderBoundary.bottomLeft.x,
						startY: cageLadderBoundary.bottomLeft.y - cageLadderObject.getDepth(),
						endX: cageLadderBoundary.bottomRight.x,
						endY: cageLadderBoundary.bottomRight.y - cageLadderObject.getDepth(),
					};
					break;
				case Mathematic.RIGHT:
					cageLadderPositions = {
						startX: cageLadderBoundary.topLeft.x,
						startY: cageLadderBoundary.topLeft.y,
						endX: cageLadderBoundary.bottomLeft.x,
						endY: cageLadderBoundary.bottomLeft.y,
					};
					break;
				case Mathematic.BOTTOM:
					cageLadderPositions = {
						startX: cageLadderBoundary.topLeft.x,
						startY: cageLadderBoundary.topLeft.y,
						endX: cageLadderBoundary.topRight.x,
						endY: cageLadderBoundary.topRight.y,
					};
					break;
				case Mathematic.LEFT:
					cageLadderPositions = {
						startX: cageLadderBoundary.topRight.x - cageLadderObject.getDepth(),
						startY: cageLadderBoundary.topRight.y,
						endX: cageLadderBoundary.bottomRight.x - cageLadderObject.getDepth(),
						endY: cageLadderBoundary.bottomRight.y,
					};
					break;
			}
		} else if (cageLadderObject.place === Stair.PLACE_INFLOOR) {
			switch (cageLadderObject.position) {
				case Mathematic.TOP:
					cageLadderPositions = {
						startX: cageLadderBoundary.bottomLeft.x,
						startY: cageLadderBoundary.bottomLeft.y,
						endX: cageLadderBoundary.bottomRight.x,
						endY: cageLadderBoundary.bottomRight.y,
					};
					break;
				case Mathematic.RIGHT:
					cageLadderPositions = {
						startX: cageLadderBoundary.topLeft.x,
						startY: cageLadderBoundary.topLeft.y,
						endX: cageLadderBoundary.bottomLeft.x,
						endY: cageLadderBoundary.bottomLeft.y,
					};
					break;
				case Mathematic.BOTTOM:
					cageLadderPositions = {
						startX: cageLadderBoundary.topLeft.x,
						startY: cageLadderBoundary.topLeft.y,
						endX: cageLadderBoundary.topRight.x,
						endY: cageLadderBoundary.topRight.y,
					};
					break;
				case Mathematic.LEFT:
					cageLadderPositions = {
						startX: cageLadderBoundary.topRight.x,
						startY: cageLadderBoundary.topRight.y,
						endX: cageLadderBoundary.bottomRight.x,
						endY: cageLadderBoundary.bottomRight.y,
					};
					break;
			}
		}

		return cageLadderPositions;
	}
}
