import { Store } from '../data/store';
import { DrawValue } from '../draw/drawValue';
import { Line } from '../draw/line';
import { ObjectGroup } from '../draw/objectGroup';
import { Asset3D } from '../draw3d/assets/Asset3D';
import { ColumnProtector } from './columnProtector';
import { Configuration } from '@/configurator/configuration';
import { Columns } from './columns';
import { Statics } from '../helpers/statics';

export class ColumnProtectors {
	objectName = 'ColumnProtectors';
	objectName3d = 'ColumnProtectors3D';
	columnProtectors = [];
	_onChange = null;
	// offset = { x: 30, y: 30 };
	mouseAreaOffset = { x: 0, y: 0 };
	mousePriority = 5;

	static LT = 'lt';
	static RT = 'rt';
	static LB = 'lb';
	static RB = 'rb';

	static T = 't';
	static B = 'b';
	static L = 'l';
	static R = 'r';

	columnProtectorsReplace = [new ColumnProtector('t', ['lt', 'rt']), new ColumnProtector('b', ['lb', 'rb']), new ColumnProtector('l', ['lt', 'lb']), new ColumnProtector('r', ['rb', 'rt'])];

	constructor(columnProtectors, onChange) {
		if (typeof columnProtectors !== 'undefined' && columnProtectors !== null) {
			columnProtectors.forEach((columnProtector) => {
				this.columnProtectors.push(columnProtector);
			});
		}
		this._onChange = onChange;
	}
	setReferences(params) {
		this._onChange = params.onChange;
	}
	removeReferences() {
		this._onChange = null;
		this.columnProtectors.forEach((columnProtector) => {
			if (typeof columnProtector.removeReferences === 'function') {
				// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
				columnProtector.removeReferences();
			}
		});
	}
	onChange() {
		if (typeof this._onChange === 'function') {
			this._onChange();
		}
	}
	get(id) {
		if (typeof id === 'undefined' || id === null) {
			return this.columnProtectors;
		}
		return null;
	}
	getAmount() {
		let amount = { ColumnProtectors: [] };

		// this.braceColumns.forEach(braceColumn => {
		// 	amount.BraceColumns.push({ name: 'BraceColumn', length: braceColumn.length });
		// });

		return amount;
	}

	onClick(evt, object) {
		this.toggleColumnProtector(object.objectParams, object);

		// wanneer dit result nog niet aanwezig is, eerste keer lijst ophalen.
		if (Store.CURRENT.columnProtectorsModels.result === null || typeof Store.CURRENT.columnProtectorsModels.result === 'undefined') {
			Store.CURRENT.columnProtectorsModels.firstList(Configuration.CURRENT.columns.column.oid, true);
		}

		this.onChange();
		return { stopPropagation: true };
	}
	calculateColumnProtectors() {
		this.columnProtectorsReplace.forEach((cp, index) => {
			this.columnProtectors.forEach((columnProtector, index) => {
				if (cp.samePositions.includes(columnProtector.position) === true) {
					let anotherCP = cp.samePositions.find((item) => item.includes(columnProtector.position) === false);
					let anotherItemFound = this.columnProtectors.find((item) => item.position === anotherCP);

					// Als het andere item is gevonden die in CPReplace staan
					if (typeof anotherItemFound !== 'undefined') {
						// Beide hoekjes weghalen want hier komt een halve voor terug.
						let firstItem = this.columnProtectors.findIndex((item) => item.position === columnProtector.position);
						this.columnProtectors.splice(firstItem, 1);

						let secondItem = this.columnProtectors.findIndex((item) => item.position === anotherCP);
						this.columnProtectors.splice(secondItem, 1);

						this.columnProtectors.push(new ColumnProtector(cp.position, cp.samePositions, false));
					}
				}
			});
		});
	}

	toggleColumnProtector(toggleColumnProtector) {
		let foundIndex = -1;
		this.columnProtectors.forEach((columnProtector, index) => {
			if (columnProtector.position === toggleColumnProtector.position) {
				foundIndex = index;
			}
		});

		if (foundIndex === -1) {
			// onder de betreffende column plaatsen waardoor deze hem intekend in zijn addrawobjects
			Configuration.CURRENT.columns.columns
				.find((column) => column.id === toggleColumnProtector.columnId)
				.columnProtectors.columnProtectors.push(new ColumnProtector(toggleColumnProtector.position, null, toggleColumnProtector.position.length > 1));
		} else {
			this.columnProtectors.splice(foundIndex, 1);
		}
		this.onChange();
	}
	splitColumnProtectors() {
		this.columnProtectorsReplace.forEach((cp) => {
			this.columnProtectors.forEach((columnProtector, index) => {
				// Als er een halve is gevonden
				if (cp.position === columnProtector.position) {
					// De halve weghalen
					this.columnProtectors.splice(index, 1);
					// Voor elk item in samePosition een nieuwe CP toevoegen aan de array
					cp.samePositions.forEach((samePos) => {
						this.columnProtectors.push(new ColumnProtector(samePos, null, true));
					});
				}
			});
		});
	}

	onMouseMove(evt, drawObject) {
		// Mouse move donkerblauwe lijn voor stukje waar je overheen gaat.
		drawObject.lineColor = Statics.COLOR_POSITION_POSSIBLE_HOVERD;
		return { stopPropagation: true };
	}
	onMouseLeave(evt, drawObject) {
		if (this.columnProtectors.filter((item) => item.position === drawObject.objectParams.position).length === 0) {
			// Wanneer niet actief en verlaten met de muis, naar lichtblauw zetten.
			drawObject.lineColor = Statics.COLOR_POSITION_POSSIBLE;
		} else {
			// Kleur voor items die actief zijn naar donkergroen.
			drawObject.lineColor = Statics.COLOR_POSSIBLE_POSITION_ACTIVE;
		}
	}
	addPossiblePositions(column) {
		this.splitColumnProtectors();
		const columnSize = Columns.COLUMN_SIZE;
		let horizontal;
		let vertical;

		let color = Statics.COLOR_POSITION_POSSIBLE;
		if (this.columnProtectors.find((item) => item.position === 'lt')) {
			color = Statics.COLOR_POSSIBLE_POSITION_ACTIVE;
		}
		const drawGroupLT = new ObjectGroup(color, null, null, true, column.columnProtectors, {
			position: ColumnProtectors.LT,
			columnId: column.id,
		});

		// ** LEFT TOP COLUMNPROTECTOR
		const ltPositionStart = column.getPosition();
		const ltPositionEnd = column.getPosition();
		const ltPositionStart1 = column.getPosition();
		const ltPositionEnd1 = column.getPosition();

		ltPositionStart.startX.offsetScaled -= 9;
		ltPositionStart.startY.offsetScaled -= 9;
		ltPositionEnd.startX.offsetScaled += columnSize / 3;
		ltPositionEnd.startY.offsetScaled -= 9;

		ltPositionStart1.startX.offsetScaled -= 9;
		ltPositionStart1.startY.offsetScaled -= 9;
		ltPositionEnd1.startX.offsetScaled -= 9;
		ltPositionEnd1.startY.offsetScaled += columnSize / 3;

		horizontal = new Line(ltPositionStart.startX, ltPositionStart.startY, ltPositionEnd.startX, ltPositionEnd.startY, new DrawValue(0, 3), null, color, null, null, false);
		vertical = new Line(ltPositionStart1.startX, ltPositionStart1.startY, ltPositionEnd1.startX, ltPositionEnd1.startY, new DrawValue(0, 3), null, color, null, null, false);

		drawGroupLT.push(horizontal);
		drawGroupLT.push(vertical);
		Configuration.CURRENT.canvas.addDrawObject(drawGroupLT);

		// ** RIGHT TOP COLUMNPROTECTOR
		color = Statics.COLOR_POSITION_POSSIBLE;
		if (this.columnProtectors.find((item) => item.position === 'rt')) {
			color = Statics.COLOR_POSSIBLE_POSITION_ACTIVE;
		}
		const drawGroupRT = new ObjectGroup(color, null, null, true, column.columnProtectors, {
			position: ColumnProtectors.RT,
			columnId: column.id,
		});
		const rtPositionStart = column.getPosition();
		const rtPositionEnd = column.getPosition();
		const rtPositionStart1 = column.getPosition();
		const rtPositionEnd1 = column.getPosition();

		rtPositionStart.endX.offsetScaled -= columnSize / 3;
		rtPositionStart.startY.offsetScaled -= 9;
		rtPositionEnd.endX.offsetScaled += 9;
		rtPositionEnd.startY.offsetScaled -= 9;

		rtPositionStart1.endX.offsetScaled += 9;
		rtPositionStart1.startY.offsetScaled -= 9;
		rtPositionEnd1.endX.offsetScaled += 9;
		rtPositionEnd1.startY.offsetScaled += columnSize / 3;

		horizontal = new Line(rtPositionStart.endX, rtPositionStart.startY, rtPositionEnd.endX, rtPositionEnd.startY, new DrawValue(0, 3), null, color, null, null, false);
		vertical = new Line(rtPositionStart1.endX, rtPositionStart1.startY, rtPositionEnd1.endX, rtPositionEnd1.startY, new DrawValue(0, 3), null, color, null, null, false);

		drawGroupRT.push(horizontal);
		drawGroupRT.push(vertical);
		Configuration.CURRENT.canvas.addDrawObject(drawGroupRT);

		// ** RIGHT BOTTOM COLUMNPROTECTOR
		color = Statics.COLOR_POSITION_POSSIBLE;
		if (this.columnProtectors.find((item) => item.position === 'rb')) {
			color = Statics.COLOR_POSSIBLE_POSITION_ACTIVE;
		}
		const drawGroupRB = new ObjectGroup(color, null, null, true, column.columnProtectors, {
			position: ColumnProtectors.RB,
			columnId: column.id,
		});
		const rbPositionStart = column.getPosition();
		const rbPositionEnd = column.getPosition();
		const rbPositionStart1 = column.getPosition();
		const rbPositionEnd1 = column.getPosition();

		rbPositionStart.endX.offsetScaled -= columnSize / 3;
		rbPositionStart.endY.offsetScaled += 9;
		rbPositionEnd.endX.offsetScaled += 9;
		rbPositionEnd.endY.offsetScaled += 9;

		rbPositionStart1.endX.offsetScaled += 9;
		rbPositionStart1.endY.offsetScaled -= columnSize / 3;
		rbPositionEnd1.endX.offsetScaled += 9;
		rbPositionEnd1.endY.offsetScaled += 9;

		horizontal = new Line(rbPositionStart.endX, rbPositionStart.endY, rbPositionEnd.endX, rbPositionEnd.endY, new DrawValue(0, 3), null, color, null, null, false);
		vertical = new Line(rbPositionStart1.endX, rbPositionStart1.endY, rbPositionEnd1.endX, rbPositionEnd1.endY, new DrawValue(0, 3), null, color, null, null, false);

		drawGroupRB.push(horizontal);
		drawGroupRB.push(vertical);
		Configuration.CURRENT.canvas.addDrawObject(drawGroupRB);

		// ** LEFT BOTTOM COLUMNPROTECTOR
		color = Statics.COLOR_POSITION_POSSIBLE;
		if (this.columnProtectors.find((item) => item.position === 'lb')) {
			color = Statics.COLOR_POSSIBLE_POSITION_ACTIVE;
		}
		const drawGroupLB = new ObjectGroup(color, null, null, true, column.columnProtectors, {
			position: ColumnProtectors.LB,
			columnId: column.id,
		});
		const lbPositionStart = column.getPosition();
		const lbPositionEnd = column.getPosition();
		const lbPositionStart1 = column.getPosition();
		const lbPositionEnd1 = column.getPosition();

		lbPositionStart.startX.offsetScaled -= 9;
		lbPositionStart.endY.offsetScaled -= columnSize / 3;
		lbPositionEnd.startX.offsetScaled -= 9;
		lbPositionEnd.endY.offsetScaled += 9;

		lbPositionStart1.startX.offsetScaled -= 9;
		lbPositionStart1.endY.offsetScaled += 9;
		lbPositionEnd1.startX.offsetScaled += columnSize / 3;
		lbPositionEnd1.endY.offsetScaled += 9;

		horizontal = new Line(lbPositionStart.startX, lbPositionStart.endY, lbPositionEnd.startX, lbPositionEnd.endY, new DrawValue(0, 3), null, color, null, null, true, false);
		vertical = new Line(lbPositionStart1.startX, lbPositionStart1.endY, lbPositionEnd1.startX, lbPositionEnd1.endY, new DrawValue(0, 3), null, color, null, null, true, false);

		drawGroupLB.push(horizontal);
		drawGroupLB.push(vertical);
		Configuration.CURRENT.canvas.addDrawObject(drawGroupLB);
	}
	addDrawObjects(column) {
		let drawObjects = [];
		this.columnProtectors.forEach((columnProtector, index) => {
			const drawObjectsToAdd = columnProtector.addDrawObjects(column);
			drawObjectsToAdd.forEach((object, index) => {
				drawObjects.push(object);
			});
		});
		return drawObjects;
	}
	addDrawObjects3d(column, columnInfo, columnPosition) {
		this.columnProtectors.forEach((columnProtector, index) => {
			columnProtector.addDrawObjects3d(column, columnInfo, columnPosition);
		});
	}
	create3DAssets(canvas3d, columnInfo) {
		this.columnProtectors.forEach((columnProtector, index) => {
			let protectorOid = Store.CURRENT.columnProtectorsModels.getByColumnProtectorOidByPosition(columnProtector.position);
			canvas3d.addAsset(new Asset3D(columnProtector.position.length === 1 ? 'columnprotector' : 'columnprotector_corner', protectorOid));
		});
	}
}
