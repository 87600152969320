import { ObjectGroup } from '../../draw/objectGroup';
import { Configuration } from '../configuration';
import { Profiles } from '../profiles';
import { Stair } from '../stair';
import { EndLandingTrimming } from './EndlandingTrimming';
import { Trimmings } from './trimmings';

export class EndlandingTrimmings extends Trimmings {
	objectName = 'EndlandingTrimmings';
	setTrimming(stairObject) {
		if (stairObject.endLanding.active === true && Object.keys(stairObject.endLanding.boundary).length > 0) {
			if (typeof stairObject.position !== 'undefined' && stairObject.position !== null) {
				const etage = Configuration.CURRENT.etages.get(stairObject.etageIndex);
				const endLandingPos = stairObject.endLanding.boundary;
				const mainBeamDirectionVertical = Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_VERTICAL;
				const topLeftX = endLandingPos.topLeft.x;
				const topLeftY = endLandingPos.topLeft.y;
				const topRightX = endLandingPos.topRight.x;
				const topRightY = endLandingPos.topRight.y;
				const bottomLeftX = endLandingPos.bottomLeft.x;
				const bottomLeftY = endLandingPos.bottomLeft.y;
				const bottomRightX = endLandingPos.bottomRight.x;
				const bottomRightY = endLandingPos.bottomRight.y;
				const startXRaster = etage.raster.getSizeX(stairObject.rasters[0].x - 1);
				const startYRaster = etage.raster.getSizeY(stairObject.rasters[0].y - 1);
				let lengthBeam = 0;

				// Met for loop rasterlengte min vorig raster ophalen.
				// Wanneer in meerdere rasters dan rekent hij het op per raster.
				if (stairObject.position === Stair.POSITION_LEFT || stairObject.position === Stair.POSITION_RIGHT) {
					for (let i = 0; i < stairObject.rasters.length; i++) {
						lengthBeam += etage.raster.getSizeY(stairObject.rasters[i].y) - etage.raster.getSizeY(stairObject.rasters[i].y - 1);
					}
				} else if (stairObject.position === Stair.POSITION_TOP || stairObject.position === Stair.POSITION_BOTTOM) {
					for (let i = 0; i < stairObject.rasters.length; i++) {
						lengthBeam += etage.raster.getSizeX(stairObject.rasters[i].x) - etage.raster.getSizeX(stairObject.rasters[i].x - 1);
					}
				}

				// Korte stukken toevoegen aan de buitenkant van de landing die altijd nodig zijn.
				// Hiervoor geen voorwaarden van mainbeamDirection.
				switch (stairObject.position) {
					case Stair.POSITION_TOP:
						// Bij een trap aan de bovenkant altijd een kort stuk aan de bovenkant van de endlanding.
						// Breedte = die van de endlanding.
						// Positie van de beam is bovenaan (Y).
						this.trimmings.push(
							new EndLandingTrimming({
								startX: topLeftX,
								startY: topLeftY,
								endX: topRightX,
								endY: topRightY,
								position: Trimmings.TOP,
							}),
						);
						// Wanneer de trap boven is en de mainbeamDirecton is verticaal dan moeten we een extra lange mainbeam toevoegen waar de trap tegen gemonteerd word, bij top is dit aan de onderkant (zuiden) van de landing.
						// Dan is de startX het begin van het eerste raster waarvan hij aan de linkerkant invalt, tot aan het laatste raster waar hij invalt. (Dit is: lengthBeam, ookwel de lengte tot aan laaste raster).
						// Bij een trap aan de bovenaknt gebruiken we dan de bottom-variabelen omdat hij aan de onderkant van de landing moet komen.
						if (mainBeamDirectionVertical) {
							this.trimmings.push(
								new EndLandingTrimming({
									startX: startXRaster,
									startY: bottomLeftY,
									endX: startXRaster + lengthBeam,
									endY: bottomRightY,
									position: Trimmings.BOTTOM,
								}),
							);
						}
						break;
					case Stair.POSITION_BOTTOM:
						// Bij een trap aan de onderkant doen we eigenlijk hetzelfde als bij de trap top, alleen komt het korte stuk mainbeam dan aan de onderkant van de landing.
						// Dus hier alle bottom variabelen gebruiken.
						this.trimmings.push(
							new EndLandingTrimming({
								startX: bottomLeftX,
								startY: bottomLeftY,
								endX: bottomRightX,
								endY: bottomRightY,
								position: Trimmings.TOP,
							}),
						);

						// Wanneer de trap boven is en de mainbeamDirecton is verticaal dan moeten we een extra lange mainbeam toevoegen waar de trap tegen gemonteerd word, bij bottom is dit aan de bovenkant (noorden) van de landing.
						// Dan is de startX het begin van het eerste raster waarvan hij aan de linkerkant invalt, tot aan het laatste raster waar hij invalt. (Dit is: lengthBeam, ookwel de lengte tot aan laaste raster).
						// Bij een trap aan de onderkant gebruiken we dan de top-variabelen omdat hij aan de bovenkant van de landing moet komen.
						if (mainBeamDirectionVertical) {
							this.trimmings.push(
								new EndLandingTrimming({
									startX: startXRaster,
									startY: topLeftY,
									endX: startXRaster + lengthBeam,
									endY: topRightY,
									position: Trimmings.BOTTOM,
								}),
							);
						}
						break;
					case Stair.POSITION_RIGHT:
						// Bij een trap aan de rechterkant plaatsen we een stukje mainbeam aan de rechterkant van de landing. (oostkant).
						// Dus hier alle rechtse variabelen gebruiken.
						this.trimmings.push(
							new EndLandingTrimming({
								startX: topRightX,
								startY: topRightY,
								endX: bottomRightX,
								endY: bottomRightY,
								position: Trimmings.RIGHT,
							}),
						);

						// Wanneer de trap rechts is en de mainbeamDirecton is niet verticaal dan moeten we een extra lange mainbeam toevoegen waar de trap tegen gemonteerd word, bij rechts is dit aan de bovenkant (westkant) van de landing.
						// Dan is de startY het begin van het eerste raster waarvan hij aan de linkerkant invalt, tot aan het laatste raster waar hij invalt. (Dit is: lengthBeam, ookwel de lengte tot aan laaste raster).
						// Bij een trap aan de rechterkant gebruiken we dan de left-variabelen omdat hij aan de linkerkant van de landing moet komen.
						if (!mainBeamDirectionVertical) {
							this.trimmings.push(
								new EndLandingTrimming({
									startX: topLeftX,
									startY: startYRaster,
									endX: bottomLeftX,
									endY: startYRaster + lengthBeam,
									position: Trimmings.LEFT,
								}),
							);
						}
						break;
					case Stair.POSITION_LEFT:
						// Bij een trap aan de linkerkant plaatsen we een stukje mainbeam aan de linkerkant van de landing. (westkant).
						// Dus hier alle linkse variabelen gebruiken.
						this.trimmings.push(
							new EndLandingTrimming({
								startX: topLeftX,
								startY: topLeftY,
								endX: bottomLeftX,
								endY: bottomLeftY,
								position: Trimmings.LEFT,
							}),
						);
						// Wanneer de trap links is en de mainbeamDirecton is niet verticaal dan moeten we een extra lange mainbeam toevoegen waar de trap tegen gemonteerd word, bij links is dit aan de rechterkant (oostkant) van de landing.
						// Dan is de startY het begin van het eerste raster waarvan hij aan de linkerkant invalt, tot aan het laatste raster waar hij invalt. (Dit is: lengthBeam, ookwel de lengte tot aan laaste raster).
						// Bij een trap aan de linkerkant gebruiken we dan de right-variabelen omdat hij aan de rechterkant van de landing moet komen.
						if (!mainBeamDirectionVertical) {
							this.trimmings.push(
								new EndLandingTrimming({
									startX: topRightX,
									startY: startYRaster,
									endX: bottomRightX,
									endY: startYRaster + lengthBeam,
									position: Trimmings.RIGHT,
								}),
							);
						}
						break;
				}
			}
		}
	}
}
