import { Configuration } from '../../configurator/configuration';
import { Canvas3D } from '../Canvas3D';
import { Model3D } from '../Model3D';
export class GussetPlateVertical3D extends Model3D {
	objectName = 'GussetPlateVertical3D';
	rotationY = 90;
	constructor(x, y, z, params) {
		super(x, y, z, params);
		this.rowPositions = params.info.rowPositions;
		this.placement = params.info.placement;

		this.assetName = params.gussetPlate.name;
		this.oid = params.gussetPlate.oid;
	}
	draw(app) {
		let deckingFinishHeight = Configuration.CURRENT.finish.height;
		let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');
		let gussetPlateWidth = Canvas3D.CURRENT.getModelDataByOid(this.oid, 'width');
		let gussetPlateHeight = Canvas3D.CURRENT.getModelDataByOid(this.oid, 'height');

		this.z -= (gussetPlateWidth - columnWidth) / 2;
		this.y -= deckingFinishHeight;

		if (this.mainBeamHeight > gussetPlateHeight) {
			// Als mainbeam hoger is dan gussplate (omdat model nog niet correct is)
			// Dan uitlijnen onderaan profiel / op de topplate van het profiel.
			this.y -= this.mainBeamHeight;
		} else {
			// Als de gussetplate te groot is tekent hij naar onder door de topplate heen (valt minder op)
			// In plaats van door de deckingfinish aan de bovenkant.
			this.y -= gussetPlateHeight;
		}

		// Door geroteerd model opschuiven van deze gussetPlate
		this.z += gussetPlateWidth;
		super.draw(app);
	}
}
