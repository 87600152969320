import { Configuration } from '../configurator/configuration';
import { Functions } from '../helpers/functions';

export class DrawObject {
	objectName = 'DrawObject';
	x = 0;
	y = 0;
	mouseOffset = { x: 0, y: 0 };
	mouseArea = { x: 0, y: 0, width: 0, height: 0 };
	minScaled = { x: 0, y: 0 };
	maxScaled = { x: 0, y: 0 };
	visible = true;
	mouseDetect = false;
	calculateMinMax = true; // Meegeven of minMax van canvas door dit object aangepast moet worden.
	color = '#FFFFFF';
	fillColor = '#FFFFFF';
	lineColor = '#FFFFFF';
	setLineColor(color) {
		this.lineColor = color;
		this.colorFromParent = false;
	}
	setFillColor(color) {
		this.fillColor = color;
		this.colorFromParent = false;
	}
	setColor(color) {
		this.color = color;
		this.colorFromParent = false;
	}
	object = null;
	objectParams = null;
	id = null;
	colorFromParent = true; // als een parent aanwezig dan erft hij automatisch over
	opacity = 1; // 1 is ondoorzichtig. Kleiner dan 1 is doorzichtiger
	hide() {
		this.visible = false;
	}
	show() {
		this.visible = true;
	}
	constructor(x, y, color = null, fillColor = null, lineColor = null, mouseDetect = null, object = null, objectParams = null, dashedLine = false, border = false, wallOffset = false) {
		this.id = Functions.uuidv4();
		if (color === null) {
			color = '#FFFFFF';
		}
		if (fillColor === null) {
			fillColor = color;
		}
		if (lineColor === null) {
			lineColor = color;
		}
		if (mouseDetect === null) {
			mouseDetect = false;
		}
		this.x = x;
		this.y = y;

		this.color = color;
		this.fillColor = fillColor;
		this.lineColor = lineColor;
		this.dashedLine = dashedLine;
		this.border = border;
		this.wallOffset = wallOffset;
		this.mouseDetect = mouseDetect;
		this.object = object;
		this.objectParams = objectParams;
	}
	minMax() {
		return { min: { x: this.x.value, y: this.y.value }, max: { x: this.x.value, y: this.y.value } };
	}
	draw(canvas) {}
	equals(x, y) {
		return this.mouseDetect === true && x >= this.mouseArea.minX && x <= this.mouseArea.maxX && y >= this.mouseArea.minY && y <= this.mouseArea.maxY;
	}
}
