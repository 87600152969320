import { Filters } from '../configurator/filters';

import { Configuration } from '../configurator/configuration';
import { Functions } from '../helpers/functions';
import { SizeHandles } from './sizeHandles';
import { DrawObjects } from './drawObjects';

import { Store } from '../data/store';

export class Canvas {
	objectName = 'Canvas';
	static CURRENT = null;
	_canvas = null;
	interval = 0;
	onDraw = null;
	colors = { background: '#ffffff' };
	scaleFactor = 0;
	offsetLeft = 0;
	offsetTop = 0;
	offset = 30;
	offsetWallTop = 0;
	offsetWallLeft = 0;
	columnSize = 6;
	min = { x: -1, y: -1 };
	max = { x: -1, y: -1 };
	drag = false;
	draggingDrawObjects = null;
	dragStart = { x: 0, y: 0 };
	sizeHandles = new SizeHandles();

	dragLastPosition = { x: 0, y: 0 };
	mouseDown = false;
	lastMouseMovePositions = [];
	drawObjects = new DrawObjects();
	_ctx = null;

	constructor() {
		this.id = Functions.uuidv4();
		Canvas.CURRENT = this;
	}

	/*
	 *
	 * GETTERS AND SETTERS
	 *
	 */

	get width() {
		return this.max.x - this.min.x;
	}
	get height() {
		return this.max.y - this.min.y;
	}

	get canvas() {
		if (this._canvas === null) {
			this._canvas = document.getElementById(this.canvasId);
		}
		return this._canvas;
	}
	set canvas(canvas) {
		this._canvas = canvas;
	}

	get ctx() {
		if (this._ctx === null && this.canvas !== null) {
			this._ctx = this.canvas.getContext(this.canvasId);
		}
		return this._ctx;
	}
	set ctx(ctx) {
		this._ctx = ctx;
	}

	/*
	 *
	 * EVENT FUNCTION FOR THE CANVAS
	 *
	 */

	onClick(mouseClickEvent) {
		if (Configuration.CURRENT.readOnly === true || Store.CURRENT.configurations.readOnlyFromURL === true) {
			return; // readonly modus geen acties
		}

		// this.drag houd bij wanneer na het slepen word losgelaten is het een klik dus komt hij hierin
		// Als de actie die bezig was (drag) is, dan voeren we geen onclick logica uit.
		if (this.drag === true) {
			// dragstart wordt bij mousedown gezet. Als niet meer zelfde positie dan drag ipv click
			this.drag = false;
			this.dragStart = { x: 0, y: 0 };
			return;
		}

		// Huidige drawObject zoeken op basis van klik muispositie.
		let drawObjectsOnClickedMousePosition = this.drawObjects.find(mouseClickEvent.layerX, mouseClickEvent.layerY);
		drawObjectsOnClickedMousePosition = drawObjectsOnClickedMousePosition.sort(this.drawObjects.compare);

		let propagation = true;
		let contextMenuItems = [];
		let showContextMenu = false;
		let configurationObject = null;
		let minMaxCoordinates = null;

		drawObjectsOnClickedMousePosition.forEach((drawObject, index) => {
			if (propagation === true && typeof drawObject.object !== 'undefined' && drawObject.object != null && typeof drawObject.object.onClick === 'function') {
				configurationObject = drawObject.object;

				let result = configurationObject.onClick(mouseClickEvent, drawObject);
				if (typeof result !== 'undefined' && result !== null) {
					if (typeof result.stopPropagation !== 'undefined' && result.stopPropagation !== null) {
						propagation = result.stopPropagation === false;
					}

					if (typeof configurationObject.selected !== 'undefined' && configurationObject.selected !== null) {
						showContextMenu = configurationObject.selected;
						// Als de functie bestaat om de het contextmenu op te halen.
						if (typeof configurationObject.getContextMenu === 'function') {
							contextMenuItems = configurationObject.getContextMenu();
						}

						if (configurationObject.selected === true) {
							minMaxCoordinates = drawObject.minMaxCoordinatesScaled();
						}
					}
				}
			}
		});

		// Bij selecteren kolom is er geen contextMenu maar is hij wel geselecteerd.
		// Als er dus geen items zijn het menu niet laten zien.
		if (contextMenuItems.length === 0) {
			showContextMenu = false;
		}

		Configuration.CURRENT.contextMenu.setItems(contextMenuItems);
		Configuration.CURRENT.contextMenu.visible = showContextMenu;

		if (minMaxCoordinates !== null) {
			// Positie updaten van contextmenu nadat de gebruiker een object aanklikt.
			// DIt is nodig omdat we dan kunnen slepen van objecten zonder dat ze geselecteerd zijn.
			// Dat is dus vorige positie en dan de hoeveelheid verschuiving erbij opgeteld.
			let newX = minMaxCoordinates.max.x;
			let newY = minMaxCoordinates.max.y;
			Configuration.CURRENT.contextMenu.updatePosition(newX, newY);
		}

		// Na onClick selecteren we een element, dat veranderd van kleur
		// Dan forceren van nieuwe draw.
		this.draw();
	}
	onMouseDown(mouseDownEvent) {
		if (Configuration.CURRENT.readOnly === true || Store.CURRENT.configurations.readOnlyFromURL === true) {
			return; // readonly modus geen acties
		}

		this.mouseDown = true;
		this.dragStart = { x: mouseDownEvent.layerX, y: mouseDownEvent.layerY };
		this.dragLastPosition = { x: mouseDownEvent.layerX, y: mouseDownEvent.layerY };

		// Opzoeken welk drawObject er op huidige positie zit als we muisklik uitvoeren.
		let drawObjectsOnMouseDownPosition = this.drawObjects.find(mouseDownEvent.layerX, mouseDownEvent.layerY);
		drawObjectsOnMouseDownPosition = drawObjectsOnMouseDownPosition.sort(this.drawObjects.compare);

		let propagation = true;
		let configurationObject = null;

		this.draggingDrawObjects = [];
		drawObjectsOnMouseDownPosition.forEach((drawObject, index) => {
			if (propagation === true && typeof drawObject.object !== 'undefined' && drawObject.object != null && typeof drawObject.object.onMouseDown === 'function') {
				configurationObject = drawObject.object;

				let result = configurationObject.onMouseDown(mouseDownEvent, drawObject);
				// Opslaan welke drawObjects we eventueel gaan slepen.
				this.draggingDrawObjects.push(drawObject);
				if (typeof result !== 'undefined' && typeof result.stopPropagation !== 'undefined') {
					propagation = result.stopPropagation === false;
				}
			}
		});
	}
	onMouseUp(mouseUpEvent) {
		if (Configuration.CURRENT.readOnly === true || Store.CURRENT.configurations.readOnlyFromURL === true) {
			return; // readonly modus geen acties
		}
		if (this.drag === false) {
			this.mouseDown = false;
			return; // als geen drag en onmouseup dan is het onclick. Wordt daar afgehandeld
		}

		// Verschuiving in vergelijking met vorige verschuiving.
		mouseUpEvent.delta = { x: mouseUpEvent.layerX - this.dragLastPosition.x, y: mouseUpEvent.layerY - this.dragLastPosition.y };

		// Totale verschuiving vanaf beginPositie die we opgeslagen hebben in onMouseDown.
		mouseUpEvent.deltaStart = { x: mouseUpEvent.layerX - this.dragStart.x, y: mouseUpEvent.layerY - this.dragStart.y };

		this.mouseDown = false;

		// Wanneer we slepen en we stoppen met slepen geven we aan het event de drawObjectsmee die we slepende waren. (Slaan we op in onMouseDown).
		mouseUpEvent.draggingDrawObjects = this.draggingDrawObjects;

		let propagation = true;
		let configurationObject = null;
		let minMaxCoordinates = null;
		let contextMenuItems = [];

		mouseUpEvent.draggingDrawObjects.forEach((drawObject, index) => {
			if (propagation === true && typeof drawObject.object !== 'undefined' && drawObject.object != null && typeof drawObject.object.onMouseUp === 'function') {
				configurationObject = drawObject.object;
				let result = configurationObject.onMouseUp(mouseUpEvent, drawObject);
				if (typeof result !== 'undefined' && result !== null) {
					if (typeof result.stopPropagation !== 'undefined' && result.stopPropagation !== null) {
						propagation = result.stopPropagation === false;
					}
					if (typeof configurationObject.getContextMenu === 'function') {
						contextMenuItems = configurationObject.getContextMenu();
					}

					// Wanneer object geselecteerd is dan de van het drawobject de minMax ophalen.
					if (configurationObject.selected === true) {
						minMaxCoordinates = drawObject.minMaxCoordinatesScaled();
					}
				}
			}
		});

		if (minMaxCoordinates !== null) {
			// Na slepen contextmenu weer zichtbaar maken.
			// Alleen als er items inzitten en hij al open was voor het slepen.
			if (Configuration.CURRENT.contextMenu.wasOpen === true && contextMenuItems.length > 0) {
				Configuration.CURRENT.contextMenu.show();
				Configuration.CURRENT.contextMenu.setItems(contextMenuItems);
			}
			// Positie updaten van contextmenu op basis van dragLastPosition
			// Dat is dus vorige positie en dan de hoeveelheid verschuiving erbij opgeteld.
			let newX = (minMaxCoordinates.max.x += mouseUpEvent.delta.x);
			let newY = (minMaxCoordinates.max.y += mouseUpEvent.delta.y);

			Configuration.CURRENT.contextMenu.updatePosition(newX, newY);
		}

		this.draw();
	}
	onMouseDrag(mouseDragEvent) {
		if (Configuration.CURRENT.readOnly === true || Store.CURRENT.configurations.readOnlyFromURL === true) {
			return; // readonly modus geen acties
		}

		// Verschuiving in vergelijking met vorige verschuiving.
		mouseDragEvent.delta = { x: mouseDragEvent.layerX - this.dragLastPosition.x, y: mouseDragEvent.layerY - this.dragLastPosition.y };

		// Totale verschuiving vanaf beginPositie die we opgeslagen hebben in onMouseDown.
		mouseDragEvent.deltaStart = { x: mouseDragEvent.layerX - this.dragStart.x, y: mouseDragEvent.layerY - this.dragStart.y };

		// Tijdens slepen slaan we op wat de laatste positie is waar we naartoe slepen.
		// Dus laatste positie van de drag.
		this.dragLastPosition = { x: mouseDragEvent.layerX, y: mouseDragEvent.layerY };

		// Geven de slepende drawObjects mee met event zodat we checks kunnen uitvoeren in de ConfigurationObjects waar dit nodig is. (profiles)
		mouseDragEvent.draggingDrawObjects = this.draggingDrawObjects;

		let propagation = true;
		// Loop over de drawObjects die we slepen.
		// In de loop dan voor elk object de onMouseDrag uitvoeren.
		mouseDragEvent.draggingDrawObjects.forEach((drawObject, index) => {
			if (propagation === true && typeof drawObject.object !== 'undefined' && drawObject.object != null && typeof drawObject.object.onMouseDrag === 'function') {
				// Contextmenu niet zichbaar maken voor makkelijk slepen zonder dat je door het menutje heen gaat met de muis.
				Configuration.CURRENT.contextMenu.hide(true);

				let result = drawObject.object.onMouseDrag(mouseDragEvent, drawObject);
				if (typeof result !== 'undefined') {
					if (typeof result.stopPropagation !== 'undefined') {
						propagation = result.stopPropagation === false;
					}
				}
			}
		});
		this.draw();
	}
	onMouseMove(mouseMoveEvent) {
		if (Configuration.CURRENT.readOnly === true) {
			// if (Configuration.CURRENT.readOnly === true) {
			return; // readonly modus geen acties
		}

		// zoek alle posities op die overeenkomen met x,y
		if (this.mouseDown === true) {
			this.drag = true;
			this.onMouseDrag(mouseMoveEvent);
			return;
		}
		this.drag = false;

		const drawObjectsOnMouseMovePosition = this.drawObjects.find(mouseMoveEvent.layerX, mouseMoveEvent.layerY);
		drawObjectsOnMouseMovePosition.sort(this.drawObjects.compare);

		let gewijzigd = false;
		let propagation = true;

		// controleer of er posities zijn die nu de vorige move gevonden waren en nu niet meer. die moeten een mouseleave krijgen
		this.lastMouseMovePositions.forEach((drawObjectLastPosition, index) => {
			let gevonden = false;
			drawObjectsOnMouseMovePosition.forEach((drawObject, index) => {
				if (drawObject === drawObjectLastPosition) {
					gevonden = true;
				}
			});
			if (gevonden === false) {
				if (propagation === true && typeof drawObjectLastPosition.object !== 'undefined' && drawObjectLastPosition.object != null && typeof drawObjectLastPosition.object.onMouseLeave === 'function') {
					let result = drawObjectLastPosition.object.onMouseLeave(mouseMoveEvent, drawObjectLastPosition);
					if (typeof result !== 'undefined' && typeof result.stopPropagation !== 'undefined') {
						propagation = result.stopPropagation === false;
					}
					gewijzigd = true;
				}
			}
		});

		propagation = true;
		drawObjectsOnMouseMovePosition.forEach((drawObject, index) => {
			if (typeof drawObject.object !== 'undefined' && drawObject.object != null && typeof drawObject.object.onMouseMove === 'function') {
				let result = true;
				if (propagation === true) {
					result = drawObject.object.onMouseMove(mouseMoveEvent, drawObject);
				} else {
					result = drawObject.object.onMouseLeave(mouseMoveEvent, drawObject);
				}
				if (propagation === true && typeof result !== 'undefined' && typeof result.stopPropagation !== 'undefined') {
					propagation = result.stopPropagation === false;
				}
				gewijzigd = true;
			}
		});
		this.lastMouseMovePositions = drawObjectsOnMouseMovePosition;
		if (gewijzigd === true) {
			this.draw();
		}
	}
	onMouseLeave(mouseLeaveEvent) {
		if (Configuration.CURRENT.readOnly === true || Store.CURRENT.configurations.readOnlyFromURL === true) {
			return; // readonly modus geen acties
		}
		// final check nog
		this.lastMouseMovePositions = [];
	}

	/*
	 *
	 * CANVAS FUNCTIONS
	 *
	 */

	// Init functie die maar 1x word uitgevoerd.
	// Verteld de applicatie in welk canvas hij moet tekenen.
	init(id = '2d') {
		this.canvasId = id;
		this.canvas = document.getElementById(id);
		this.ctx = this.canvas.getContext(id);

		// even groot maken als beschikbare scherm
		window.onresize = this.onWindowResize.bind(this);
		this.onWindowResize();
		this.draw();
	}
	// Bij resizen van scherm canvas rescalen.
	onWindowResize() {
		this.canvas.width = window.innerWidth - 100;
		this.canvas.height = window.innerHeight;

		this.setScaleFactor();
		this.setOffset();
		this.draw();
	}
	redraw() {
		// tekenvlak gereed maken en achtergrondkleur geven.
		// Dit altijd uitvoeren voordat we drawObjects tekenen.
		if (this.canvas !== null && typeof this.canvas !== 'undefined') {
			this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
			this.ctx.fillStyle = this.colors.background;
			this.drawRectScaled(0, 0, this.canvas.width, this.canvas.height);
		}
	}
	draw(type = null) {
		// Leegmaken huidige canvas.
		this.redraw();

		// Minmax en scalefactor bepalen op basis van drawobject minimale x en y en maximale x en y.
		this.setMinMax();
		this.setScaleFactor();

		// Offset begin van canvas op basis van breedte en scalefactor etc.
		this.setOffset();

		this.drawObjects.draw(this);

		this.drawObjects.id = Functions.uuidv4();
		this.ctx.stroke();
	}
	drawRectScaled(x, y, w, h) {
		this.ctx.beginPath();
		this.ctx.rect(x, y, w, h);
		this.ctx.closePath();
		this.ctx.fill();
	}
	addDrawObjects(objects) {
		objects.forEach((object, index) => {
			this.drawObjects.push(object);
		});
	}
	addDrawObject(object) {
		this.drawObjects.push(object);
	}
	scaled(value) {
		return value * this.scaleFactor;
	}
	setScaleFactor() {
		// bepaal de kleinste schaling
		this.scaleFactor = Math.min(
			(this.canvas.width - this.sizeHandles.totalSpace() - this.offset * 2) / this.width,
			(this.canvas.height - this.sizeHandles.totalSpace() - this.offset * 2) / this.height,
		);

		if (this.scaleFactor > 0.1) {
			// Niet groter dan 0.1
			this.scaleFactor = 0.1;
		}
		if (this.scaleFactor < 0) {
			// Niet negatief dan wordt de tekening gespiegeld
			this.scaleFactor = 0;
		}
	}
	setOffset() {
		// Tekening op het midden van het scherm
		this.offsetLeft = (this.canvas.width - this.width * this.scaleFactor) / 2;
		this.offsetTop = (this.canvas.height - this.height * this.scaleFactor) / 2 + 10;
		let wallWidthLeft = 20;
		let wallWidthTop = 10;

		this.offsetWallLeft = (this.canvas.width - (this.width - wallWidthLeft) * this.scaleFactor) / 2 + wallWidthLeft;
		this.offsetWallTop = (this.canvas.height - (this.height - wallWidthTop) * this.scaleFactor) / 2 + 10 + wallWidthTop;

		if (this.min.y < 0) {
			this.offsetTop -= this.min.y * this.scaleFactor;
		}
		if (this.min.x < 0) {
			this.offsetLeft -= this.min.x * this.scaleFactor;
			if (this.scaleFactor < 0.1) {
				this.offsetLeft += this.sizeHandles.totalSpace() / 4;
			}
		}
	}
	setMinMax() {
		this.min = { x: -1, y: -1 };
		this.max = { x: -1, y: -1 };
		let minMax = this.drawObjects.minMax();

		if (this.min.x === -1 || this.min.x > minMax.min.x) {
			this.min.x = minMax.min.x;
		}
		if (this.min.y === -1 || this.min.y > minMax.min.y) {
			this.min.y = minMax.min.y;
		}
		if (this.max.x === -1 || this.max.x < minMax.max.x) {
			this.max.x = minMax.max.x;
		}
		if (this.max.y === -1 || this.max.y < minMax.max.y) {
			this.max.y = minMax.max.y;
		}
	}

	/*
	 *
	 * RECONSTRUCT FUNCTIONS
	 *
	 */
	reconstruct() {
		// na reconstruct (JSON inlezen en opnieuw objecten van maken) canvasId leeg maken zodat bij een eerste init opnieuw canvas wordt opgezocht
		this.canvasId = null;
		Canvas.CURRENT = this;
	}
	afterReconstruct() {
		// Waardes resetten.
		this.drag = false;
		this.mouseDown = false;
		this.canvasId = null;
		let params = {
			redraw: () => {
				this.draw();
			},
		};
		this.sizeHandles.setReferences(params);
	}
	removeReferences() {
		if (typeof this.sizeHandles.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.sizeHandles.removeReferences();
		}
	}
}
