import { Data } from './data';
import { Store } from './store';
import { Languages } from './languages';
import { Mathematic } from '../helpers/mathematic';

export class DeckingFinishes extends Data {
	objectName = 'DeckingFinishes';

	url = 'DeckingFinishes';
	deckingFinishList() {
		let currentLanguage = 'EN';
		if (Languages.CURRENT !== null) {
			currentLanguage = Languages.CURRENT.currentLanguage;
		}
		// Kijk of lijst al opgehaald is
		if (
			typeof this._list.list !== 'undefined' &&
			this._list.list !== null &&
			typeof this.lastRequest.list !== 'undefined' &&
			this.lastRequest.list !== null &&
			typeof this.lastRequest.list.data !== 'undefined' &&
			this.lastRequest.list.data !== null &&
			this.lastRequest.list.data.language === currentLanguage
		) {
			// dan lijst teruggeven opgemaakt zoals ze in het menu verschijnen
			return this.mappedList();
		} else if (this.listRequest === null && Store.CURRENT.companies.selectedCompany.id > -1) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			this.list();
		}
		// geen lijst dan lege array
		return [];
	}
	get listBox() {
		// Kijk of lijst al opgehaald is
		if (typeof this._list.list !== 'undefined' && this._list.list !== null && this._list.list.length > 0) {
			// dan lijst teruggeven opgemaakt zoals ze in de listbox verschijnen
			return this.mappedList();
		} else if (this.listRequest === null && Store.CURRENT.companies.selectedCompany.id > -1) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			this.list(null, false, null, '', () => {
				return this.mappedList();
			});
		} else {
			this.list(null, false, null, '', () => {
				return this.mappedList();
			});
		}
		// geen lijst dan lege array
		return [];
	}

	mappedList() {
		return this._list.list.map((finish) => {
			return {
				id: finish.id,
				caption: finish.caption,
				text: Store.CURRENT.currentConfiguration().isImperial() ? finish.caption + ' ' + Mathematic.ImperialToInputString(finish.height) : finish.caption + ' ' + finish.info,
				info: Store.CURRENT.currentConfiguration().isImperial() ? Mathematic.ImperialToInputString(finish.height) : finish.info,
				height: finish.height,
				centerToCenter: finish.centerToCenter,
			};
		});
	}
	getById(id, thenCallBack) {
		if (typeof this._list.list !== 'undefined' && this._list.list !== null && id !== null) {
			// dan lijst teruggeven opgemaakt zoals ze in de listbox verschijnen
			let toReturn = null;
			this._list.list.forEach((element) => {
				if (element.id === id) {
					toReturn = element;
				}
			});

			if (typeof thenCallBack === 'function' && toReturn !== null) {
				thenCallBack({
					id: toReturn.id,
					caption: toReturn.caption + '&' + toReturn.info,
					text: Store.CURRENT.currentConfiguration().isImperial() ? toReturn.caption + ' ' + Mathematic.ImperialToInputString(toReturn.height) : toReturn.caption + ' ' + toReturn.info,
					name: toReturn.caption,
					height: toReturn.height,
					centerToCenter: toReturn.centerToCenter,
				});
			} else if (toReturn === null && typeof thenCallBack === 'function') {
				thenCallBack(null);
			}
		} else {
			this.list(null, null, null, null, (res) => {
				let findFinish = res.find((f) => f.id === id);
				if (typeof thenCallBack === 'function' && typeof findFinish !== 'undefined' && findFinish !== null) {
					thenCallBack(findFinish);
				}
			});
		}
	}
	getFinish(name, height, thenCallBack) {
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			let toReturn = null;
			this._list.list.forEach((element) => {
				if (element.caption === name && element.height === height) {
					toReturn = element;
				}
			});

			if (typeof thenCallBack === 'function' && toReturn !== null) {
				thenCallBack({
					id: toReturn.id,
					caption: toReturn.caption + '&' + toReturn.info,
					text: toReturn.caption + ' ' + toReturn.info,
					name: toReturn.caption,
					height: toReturn.height,
					centerToCenter: toReturn.centerToCenter,
				});
			}
		}
		return null;
	}

	getItem(id) {
		// omdat de hele lijst met list opgehaald wordt hier geen aparte routine of gebruik van get om een item op de server op te halen.
		// Kijk of lijst al opgehaald is
		if (typeof this._list.list !== 'undefined' && this._list.list !== null && id !== null) {
			// dan lijst teruggeven opgemaakt zoals ze in de listbox verschijnen
			let toReturn = null;

			this._list.list.forEach((element) => {
				if (element.id === id) {
					toReturn = element;
				}
			});
			return toReturn;
		} else if (this.listRequest === null && Store.CURRENT.companies.selectedCompany.id > -1) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			this.list();
		} // geen lijst dan null
		return null;
	}

	getFirstItem(force = false, thenCallBack) {
		if (typeof this._list.list !== 'undefined' && this._list.list !== null && this._list.list.length > 0 && !force) {
			// dan lijst teruggeven opgemaakt zoals ze in de listbox verschijnen
			if (typeof thenCallBack === 'function') {
				thenCallBack(this._list.list[0]);
			}
		} else if (this.listRequest === null && Store.CURRENT.companies.selectedCompany.id > -1 && force) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			this.list(null, force, null, '', (res) => {
				if (typeof thenCallBack === 'function') {
					thenCallBack(this._list.list[0]);
				}
			});
		} else {
			this.list(null, force, 'list', '', (res) => {
				if (typeof thenCallBack === 'function') {
					thenCallBack(this._list.list[0]);
				}
			});
		}
	}

	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}
		if (typeof data.companyId === 'undefined' || data.companyId === null || data.companyId === -1) {
			data.companyId = Store.CURRENT.companies.selectedCompany.id;
		}
		data.language = Languages.CURRENT.currentLanguage;

		if (data.companyId !== -1) {
			super.list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack);
		}
	}
}
