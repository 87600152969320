import * as pc from 'playcanvas';
import { Configuration } from '../configurator/configuration';

import { DrawObjects3D } from './DrawObjects3D';
import { Canvas3D } from './Canvas3D';

export class Model3D extends DrawObjects3D {
	objectName = 'Model3D';
	draw() {
		this.x = this.x / 1000;
		this.y = this.y / 1000;
		this.z = this.z / 1000;

		// Default waarde model is 1, als dat veranderd is is dat altijd vanuit 2D gebeurd.
		// In 2D rekenen we met mm, dus dan moeten we voor 3D altijd / 1000.
		if (this.height !== 1) {
			this.height = this.height / 1000;
		}

		if (this.width !== 1) {
			this.width = this.width / 1000;
		}

		if (this.depth !== 1) {
			this.depth = this.depth / 1000;
		}

		// Starten met een pc entity
		let playCanvasEntity = new pc.Entity(this.objectName);

		// Type entity
		playCanvasEntity.addComponent('model', {
			layers: this.layers,
		});

		// Eerst opzoeken model op OID als dat er is,
		if (this.oid !== null && typeof this.oid !== 'undefined') {
			let jsonModelByOid = Canvas3D.CURRENT.app.assets._assets.find((asset) => asset.oid === this.oid);
			if (typeof jsonModelByOid !== 'undefined' && jsonModelByOid !== null) {
				if (jsonModelByOid.resource !== null && typeof jsonModelByOid.resource !== 'undefined') {
					playCanvasEntity.model.model = jsonModelByOid.resource.clone();
				} else {
					// Wanneer asset er wel is maar model is niet gevuld.
					return;
				}
			} else {
				// Wanneer asset niet gevonden met dit oid;
				return;
			}
		} else {
			// Vinden van model
			let jsonModel = Canvas3D.CURRENT.app.assets.find(this.assetName + '.json', 'model');
			if (typeof jsonModel !== 'undefined' && jsonModel !== null) {
				// Model check en toewijzen json aan model.
				if (jsonModel.resource !== null && typeof jsonModel.resource !== 'undefined') {
					playCanvasEntity.model.model = jsonModel.resource.clone();
				} else {
					// Wanneer op basis van assetname ook geen model gevonden is.
					return;
				}
			} else {
				// Als het model niet gevonden kan worden gaan we het fallback gebruiken.
				let jsonModelFallback = Canvas3D.CURRENT.app.assets.find(this.fallBackName + '.json', 'model');
				if (typeof jsonModelFallback !== 'undefined' && jsonModelFallback !== null) {
					if (jsonModelFallback.resource !== null && typeof jsonModelFallback.resource !== 'undefined') {
						playCanvasEntity.model.model = jsonModelFallback.resource.clone();
					} else {
						// Wanneer fallback niet in de assets lijst zit.
						return;
					}
				} else {
					// Wanneer ook de fallback niet gevonden kan worden :(
					return;
				}
			}
		}

		// Set model scale
		playCanvasEntity.setLocalScale(this.width, this.height, this.depth);

		// Set position
		playCanvasEntity.setPosition(this.x, this.y, this.z);

		if (typeof this.rotationX !== 'undefined' && typeof this.rotationY !== 'undefined' && typeof this.rotationZ !== 'undefined') {
			playCanvasEntity.rotateLocal(this.rotationX, this.rotationY, this.rotationZ);
		}

		// Apply texture to model.
		if (this.texture !== null && typeof this.texture !== 'undefined') {
			let textureFile = Canvas3D.CURRENT.app.assets.find(this.texture.name, 'texture');
			if (textureFile !== null && typeof textureFile !== 'undefined') {
				let material = new pc.StandardMaterial();
				material.diffuseMap = textureFile.resource;

				let transform = new pc.Vec4(7, 7, 7, 7);
				material.diffuseMapOffset.set(0, 0.25);
				if (material !== null && typeof material !== 'undefined') {
					if (playCanvasEntity.model.model !== null && typeof playCanvasEntity.model.model !== 'undefined') {
						if (playCanvasEntity.model.meshInstances.length > 0) {
							playCanvasEntity.model.meshInstances[0].setParameter('texture_diffuseMapTransform', transform.data);
							material.update();
							playCanvasEntity.model.meshInstances[0].material = material;
						}
					}
				}
			}
		}

		// Apply color to model
		else if (this.ralColor !== null && typeof this.ralColor !== 'undefined' && (this.complexModel === null || typeof this.complexModel === 'undefined')) {
			let colorAsset = Canvas3D.CURRENT.app.assets.find(this.ralColor + '.json', 'material');
			if (colorAsset !== null && typeof colorAsset !== 'undefined' && colorAsset.resources.length > 0) {
				playCanvasEntity.model.meshInstances.forEach((mesh, index) => {
					playCanvasEntity.model.meshInstances[index].material = colorAsset.resources[0];
				});
			}
		} else if (this.complexModel === null || typeof this.complexModel === 'undefined' || this.complexModel === false) {
			let colorAsset = Canvas3D.CURRENT.app.assets.find(this.ralColor + '.json', 'material');
			if (colorAsset !== null && typeof colorAsset !== 'undefined' && colorAsset.resources.length > 0) {
				playCanvasEntity.model.meshInstances.forEach((mesh, index) => {
					// Alle onderdelen van 1 model die kleur geven.
					if (mesh.node.name.startsWith('Koker') || mesh.node.name.startsWith('koker') || mesh.node.name.startsWith('Strip_') || mesh.node.name.startsWith('strip_')) {
						playCanvasEntity.model.meshInstances[index].material = colorAsset.resources[0];
					}
				});
			}
		} else if (this.complexModel === true && typeof this.applyMappingColors === 'function') {
			this.applyMappingColors(playCanvasEntity);
		}

		// Add model to canvas
		Canvas3D.CURRENT.app.root.addChild(playCanvasEntity);
	}
}
