import { DrawValue } from '../../draw/drawValue';
import { Line } from '../../draw/line';
import { Profiles } from '../profiles';
import { Stairs } from '../stairs';
import { StairTrimmings } from './stairTrimmings';
import { Columns } from '../columns';
import { Configuration } from '../configuration';
import { Trimming } from './Trimming';

export class StairTrimming extends Trimming {
	objectName = 'StairTrimming';

	addDrawObjects(parentObject) {
		return super.addDrawObjects(this.objectName, parentObject);
	}

	calculateAmount(params) {
		let raster = params.raster.getRasterByCoordinate(this.startX, this.startY);
		let span;

		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			span = params.raster.spansX.get(raster.x);
		} else {
			span = params.raster.spansY.get(raster.y);
		}

		// Check of undefined of null span,
		// Nodig omdat wanneer trap in vloer staat en dat raster word weggehaald dan moet de trap nog wel getekend worden.
		// Hij word wel rood, maar de berekeningen van beams word nog steeds gedaan.
		// Wanneer zijn eigen raster er dus niet (meer) is dan kan hij ook geen beams vinden.
		if (span !== null && typeof span !== 'undefined') {
			if (this.startX === this.endX) {
				// Verticale balken bij top / bottom geroteerde trap.
				// Horizontale balken bij links / rechts geroteerde trap.
				Configuration.CURRENT.profiles.addAmountMainBeam(Math.round(this.endY - this.startY), 1, span);
			} else {
				// Horizontale balken bij top / bottom geroteerde trap.
				// Verticale balken bij links / rechts geroteerde trap.
				Configuration.CURRENT.profiles.addAmountMainBeam(Math.round(this.endX - this.startX), 1, span);
			}
		}
	}
	getAmountData() {
		let mainBeam = Configuration.CURRENT.profiles.mainBeam;
		let length = this.endX - this.startX;
		if (length === 0) {
			length = this.endY - this.startY;
		}
		return { id: mainBeam.salesProductId, name: mainBeam.name, length: length, amount: 1, color: Configuration.CURRENT.colors.profiles.ralColor, finish: Configuration.CURRENT.colors.profiles.finish };
	}
}
