export class Color {
	objectName = 'Color';
	ralColor = '7016';
	_finish = 0;
	type = 'default';
	// vanuit ERP om aan te geven dat kleur mogelijk is op dit type artikel.
	colorPossible = true;

	overrideDefault = false;
	changed = false;

	constructor(onChange) {
		this._onChange = onChange;
	}

	/*
  	! Setters and Getters.
	*/

	set finish(value) {
		if (value !== this._finish) {
			this._finish = value;
			this.onChange();
		}
	}
	get finish() {
		return this._finish;
	}

	/*
		! Functions
	*/

	//* * Functie die wanneer de defaultkleur aangepast word de eigen kleur aanpast. Hier geen onChange aanroepen maar na de loep uit colors.js
	setDefaultRal(value) {
		if (this.ralColor !== value) {
			this.ralColor = value;
		}
	}

	//* * Functie die aangeroepen word vanuit component, dan triggeren we een onchange met changedbydefault op false, die dan changed in colors.js op true zet.   */
	setColorByOwn(value) {
		if (this.ralColor !== value) {
			this.ralColor = value;
			this.changed = true;
			this.onChange();
		}
	}

	//* * Functie die vanuit setDefaults word aangeroepen, hiermee triggeren we dan niet de onSave, maar zet wel zijn eigen waarden goed. (Wanneer de onsave getriggerd word dan alsnog de juiste kleuren ophalen) */
	setOwnDefaults(item) {
		this._finish = item.finish;
		this.ralColor = item.color.toString();
		this.overrideDefault = item.overrideDefault;
		this.colorPossible = item.colorPossible;
		this._type = item.type;
	}

	/*
		! References to other objects.
	*/

	setReferences(params) {
		this._onChange = params.onChange;
	}
	removeReferences() {
		this._onChange = null;
		this._onSave = null;
	}

	// Onchange is the colorUpdated from Colors.js
	onChange() {
		if (typeof this._onChange === 'function' && typeof this.type !== 'undefined') {
			this._onChange(this.type);
		}
	}
}
