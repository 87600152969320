import { Store } from '../../data/store';
import { Canvas } from '../../draw/canvas';
import { DrawValue } from '../../draw/drawValue';
import { Line } from '../../draw/line';
import { Canvas3D } from '../../draw3d/Canvas3D';
import { ModelAsset3D } from '../../draw3d/assets/ModelAsset3D';
import { CageLadder3D } from '../../draw3d/cageLadders/cageLadder3D';
import { Mathematic } from '../../helpers/mathematic';
import { Configuration } from '../configuration';
import { Errors } from '../errors';
import { RemoveRaster } from '../removeRaster';
import { Serializer } from '../serializer';
import { Stair } from '../stair';
import { Stairs } from '../stairs';
import { CageLadderDynamic } from './cageLadderDynamic';
import { CageLadder } from './cageLadder';
import { CageLaddersStairsUtil } from '../util/cageLaddersStairsUtil';

export class CageLadders {
	objectName = 'CageLadders';
	showConfigurator = false;
	configuratorSettings = new CageLadder();
	cageLadders = [];
	mousePriority = 10;
	static notCompareable = ['id'];
	static comparible = ['startHeight', 'endHeight', 'oid', 'width', 'depth', 'stepWidth'];
	constructor(onChange, checkCollisions) {
		this._onChange = onChange;
		this._checkCollisions = checkCollisions;
	}
	select(parameters) {
		this.cageLadders.forEach((cageLadder) => {
			if (cageLadder.id === parameters.id) {
				cageLadder.selected = !cageLadder.selected;
			} else {
				cageLadder.selected = false;
			}
		});
	}
	onChange() {
		if (typeof this._onChange === 'function') {
			this._onChange();
		}
	}

	setReferences(params) {
		this._onChange = params.onChange;
		this._checkCollisions = params.checkCollisions;
		this._redraw = params.redraw;
		params.edit = this.edit.bind(this);
		params.remove = this.remove.bind(this);
		this.cageLadders.forEach((cageLadder) => {
			if (typeof cageLadder.setReferences === 'function') {
				cageLadder.setReferences(params);
			}
		});
	}
	removeReferences() {
		this._onChange = null;
		this._checkCollisions = null;
		this._redraw = null;
		this.cageLadders.forEach((cageLadder) => {
			if (typeof cageLadder.removeReferences === 'function') {
				// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
				cageLadder.removeReferences();
			}
		});
	}
	afterReconstruct() {
		// this.cageLadders.newCageLadder = true;
		this.showConfigurator = false; // false slaat hij niet altijd op omdat cancel niet een wijziging in de configuratie tot gevolg heeft. Maar bij reconstruct altijd configurator verbergen
	}
	collisionCheck() {
		let collisions = false;
		let errorResult = [];
		this.cageLadders.forEach((cageLadder, index) => {
			let collisionCheck = cageLadder.collisionCheck();
			if (collisionCheck.result === true) {
				collisions = true;
				collisionCheck.errors.forEach((error) => {
					errorResult.push(error);
				});
			}
		});
		return { result: collisions, errors: errorResult };
	}
	hasErrors() {
		let hasErrors = 0;
		// 'akker' over de errors per stair.
		this.cageLadders.forEach((cageLadder, index) => {
			let objectHasErrors = cageLadder.hasErrors;
			if (objectHasErrors === true) {
				hasErrors++;
			}
		});
		return hasErrors > 0;
	}
	getErrors() {
		let errors = new Errors();
		this.cageLadders.forEach((cageLadder, index) => {
			let objectErrors = cageLadder.getErrors();
			if (typeof objectErrors !== 'undefined' && objectErrors !== null) {
				objectErrors.getAll().forEach((error) => {
					error.source = 'CageLadders';
					error.sourceDescription = window.$nuxt.$translate('cageLadder', { index: index });
					errors.push(error);
				});
			}
		});
		return errors;
	}

	addDrawObjects() {
		this.cageLadders.forEach((cageLadder, index) => {
			// Voor nu cageLadder alleen tekeken op etage waar hij is toegevoegd.
			if (cageLadder.endEtageIndex === Configuration.CURRENT.etages.activeEtageIndex) {
				cageLadder.addDrawObjects();
			}
		});
		return { stopPropagation: true };
	}
	addDrawObjects3d() {
		this.cageLadders.forEach((cageLadder) => {
			if (cageLadder.active && cageLadder.position !== null && typeof cageLadder.position !== 'undefined') {
				new CageLadder3D(cageLadder);
			}
		});
	}
	create3DAssets() {
		// Toevoegen van modellen die in de cageLadder settings zitten.
		const cageLadderMaterials = Store.CURRENT.cageLadderMaterials.articles;

		if (cageLadderMaterials !== null && typeof cageLadderMaterials !== 'undefined') {
			Object.keys(cageLadderMaterials).forEach((key) => {
				Canvas3D.CURRENT.addAsset(new ModelAsset3D(key, cageLadderMaterials[key]));
			});
		}
	}

	addPossiblePositions() {
		if (typeof this.configuratorSettings.addPossiblePositions === 'function') {
			this.configuratorSettings.addPossiblePositions(this);
		}
	}

	onMouseUp() {}

	// On click possiblepositions.
	onClick(evt, drawObject) {
		if (typeof this.configuratorSettings.onClickPossiblePosition === 'function') {
			this.configuratorSettings.onClickPossiblePosition(drawObject);
		}
		return { stopPropagation: true };
	}
	// Mouse move voor over possible positions.
	onMouseMove(evt, drawObject) {
		if (typeof this.configuratorSettings.onMouseMovePossiblePosition === 'function') {
			this.configuratorSettings.onMouseMovePossiblePosition(evt, drawObject);
		}
		return { stopPropagation: true };
	}
	// Mouse leave voor over possible positions.
	onMouseLeave(evt, drawObject) {
		if (typeof this.configuratorSettings.onMouseLeavePossiblePosition === 'function') {
			this.configuratorSettings.onMouseLeavePossiblePosition(evt, drawObject);
		}
		return { stopPropagation: true };
	}

	onRasterChanged(params) {
		this.cageLadders.forEach((cageLadder) => {
			cageLadder.onRasterChanged(params);
		});
	}
	// Initializeren popup edit-cageLadder
	newCageLadder(place = Stair.PLACE_OUTSIDE) {
		this.showConfigurator = true;
		this.configuratorSettings = new CageLadderDynamic(place, {
			newCageLadder: true,
			stepWidth: 600,
			width: 600,
			place: place,
			position: Mathematic.TOP,
			startHeight: Configuration.CURRENT.etages.getTotalHeight(Configuration.CURRENT.etages.activeEtageIndex - 1, true),
			endHeight: Configuration.CURRENT.etages.getTotalHeight(Configuration.CURRENT.etages.activeEtageIndex, true),
			etageId: Configuration.CURRENT.etages.activeEtage().id,
			startEtageIndex: Configuration.CURRENT.etages.activeEtageIndex - 1,
			endEtageIndex: Configuration.CURRENT.etages.activeEtageIndex,
		});

		let serializer = new Serializer();
		this.configuratorSettings.oldValues = serializer.stringify(this.configuratorSettings);
	}
	// Na submit button edit-cageLadder
	create() {
		this.showConfigurator = false;
		this.configuratorSettings.oldValues = '';

		// When new cageLadder setup the edit modus.
		if (this.configuratorSettings.newCageLadder) {
			Configuration.CURRENT.editModus.setEditingObject('cageLadders');
		} else {
			// When its not a new cageLadder, stopEditingObject and execute onChange to save to ERP.
			this.onChange();
		}
	}
	// Na klik op possibleposition, positie toewijzen in cageladder.js en nieuwe item in array zetten.
	push(drawObject) {
		this.cageLadders.push(new CageLadderDynamic(drawObject.objectParams.place, this.configuratorSettings, drawObject, Store.CURRENT.cageLadders.getCageLadder()));
		const referenceParams = { onChange: this.onChange.bind(this), checkCollisions: this._checkCollisions.bind(this), edit: this.edit.bind(this), remove: this.remove.bind(this) };
		this.cageLadders[this.cageLadders.length - 1].setReferences(referenceParams);
	}

	// Na klik op possibleposition, positie toewijzen in cageladder.js en terug verwijzen naar dit object wat dan de onchange uitvoert.
	clearConfiguratorSettings() {
		this.configuratorSettings = new CageLadder();
		this.onChange();
	}
	remove(object) {
		const indexToRemove = this.cageLadders.findIndex((cageLadder) => cageLadder.id === object.id);
		this.cageLadders.splice(indexToRemove, 1);
		this.onChange();
	}
	edit(item) {
		this.showConfigurator = true;
		this.configuratorSettings = item;
		this.configuratorSettings.newCageLadder = false;
		let serializer = new Serializer();
		this.configuratorSettings.oldValues = serializer.stringify(this.configuratorSettings);
	}
	collisions(boundaries, self) {
		let collisionsDetect = false;
		let errorResult = [];

		this.cageLadders.forEach((cageLadder) => {
			let cageLadderResult = cageLadder.collisions(boundaries, self);

			if (cageLadderResult.result === true) {
				collisionsDetect = true;
				cageLadderResult.errors.forEach((error) => {
					errorResult.push(error);
				});
			}
		});
		return { result: collisionsDetect, errors: errorResult, objectName: this.objectName };
	}

	findByHandrailPosition(coordinates) {
		return this.cageLadders.filter((cageLadder) => cageLadder.active === true && cageLadder.onHandrailPosition(coordinates) === true);
	}
	cancel() {
		let serializer = new Serializer();
		this.configuratorSettings.update(serializer.parse(this.configuratorSettings.oldValues));
		this.showConfigurator = false;
	}

	calculateAmount() {
		this.cageLadders.forEach((cageLadder) => {
			cageLadder.calculateAmount();
		});
	}

	arraysMatch(arr1, arr2) {
		// check allereerst of de arrays gelijk zijn aan elkaar
		if (arr1.length !== arr2.length) {
			return false;
		}

		// loop over de array heen, is van item in de list, arr2 is item to check
		for (let i = 0; i < arr1.length; i++) {
			if (typeof arr1[i] === 'object') {
				// check hierin of de intermediatelanding niet gelijk is aan elkaar, dan return false. Wordt in de functie checkIfObjectExistsInList allPropertiesMatch op false gezet en wordt deze nieuw toegevoegd aan de lijst.
				if (!this.checkIfObjectExistsInList(arr2, arr1[i]).exist) {
					return false;
				}
			} else if (!arr2.includes(arr1[i])) {
				// check of elementen bestaan in de array
				return false;
			}
		}
		return true;
	}

	getAmount() {
		let amount = { CageLadders: [] };

		// loop over de cageLadders heen
		this.cageLadders.forEach((cageLadder) => {
			// voeg de huidige lijst toe en het huidige item in de list
			let checkCageLadder = CageLaddersStairsUtil.checkIfObjectExistsInList(amount.CageLadders, cageLadder.getAmountData(), CageLadders, (obj) => {
				return { cageLadder: obj, exist: true };
			});
			// Wanneer cageLadder niet bestaat,dan nieuwe pushen.
			if (!checkCageLadder.exist) {
				amount.CageLadders.push(cageLadder.getAmountData());
			} else {
				// Bestaat al in de lijst en zoek hiervoor de index op en dan amount increasen.
				let index = amount.CageLadders.findIndex((obj) => {
					return Object.is(obj, checkCageLadder.cageLadder);
				});
				amount.CageLadders[index].amount++;
			}
		});
		return amount;
	}
}
