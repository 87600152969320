import { Line } from '../../draw/line';
import { DrawValue } from '../../draw/drawValue';
import { TrimmingTop3D } from '../../draw3d/buildingcolumn/trimming/TrimmingTop3D';
import { TrimmingBottom3D } from '../../draw3d/buildingcolumn/trimming/TrimmingBottom3D';
import { TrimmingLeft3D } from '../../draw3d/buildingcolumn/trimming/TrimmingLeft3D';
import { TrimmingRight3D } from '../../draw3d/buildingcolumn/trimming/TrimmingRight3D';
import { Configuration } from '../configuration';
import { Columns } from '../columns';

import { Profiles } from '../profiles';
import { Canvas3D } from '../../draw3d/Canvas3D';
import { Trimming } from './Trimming';
import { Trimmings } from './trimmings';

export class BuildingColumnTrimming extends Trimming {
	objectName = 'BuildingColumnTrimming';

	calculateAmount(params) {
		let raster = params.raster.getRasterByCoordinate(this.startX, this.startY);
		let lastRaster;
		let firstRaster;
		let margin = 50;
		let span = Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL ? params.raster.spansX.getSpans()[raster.x] : params.raster.spansY.getSpans()[raster.y];

		// Gebouwkolom steekt rechts buiten de vloer.
		if (this.startX >= params.etages.floor.width) {
			if (span === null || typeof span === 'undefined') {
				lastRaster = params.raster.getRasterByCoordinate(params.etages.floor.width - margin, this.startY);
				span = Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL ? params.raster.spansX.getSpans()[lastRaster.x] : params.raster.spansY.getSpans()[lastRaster.y];
			}
		}
		// Gebouwkolom steekt links buiten de vloer.
		else if (this.startX <= 0) {
			if (span === null || typeof span === 'undefined') {
				firstRaster = params.raster.getRasterByCoordinate(0, this.startY);
				span = Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL ? params.raster.spansX.getSpans()[firstRaster.x] : params.raster.spansY.getSpans()[firstRaster.y];
			}
		}
		// Gebouwkolom steekt onder buiten de vloer.
		if (this.startY >= params.etages.floor.length) {
			if (span === null || typeof span === 'undefined') {
				lastRaster = params.raster.getRasterByCoordinate(0, params.etages.floor.length - margin);
				span = Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL ? params.raster.spansX.getSpans()[lastRaster.x] : params.raster.spansY.getSpans()[lastRaster.y];
			}
		}
		// Gebouwkolom steekt boven buiten de vloer.
		else if (this.startY <= 0) {
			if (span === null || typeof span === 'undefined') {
				firstRaster = params.raster.getRasterByCoordinate(this.startX, 0);
				span = Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL ? params.raster.spansX.getSpans()[firstRaster.x] : params.raster.spansY.getSpans()[firstRaster.y];
			}
		}

		if (this.startX === this.endX) {
			Configuration.CURRENT.profiles.addAmountMainBeam(Math.round(this.endY - this.startY), 1, span);
		} else {
			Configuration.CURRENT.profiles.addAmountMainBeam(Math.round(this.endX - this.startX), 1, span);
		}
	}
	addDrawObjects(parentObject) {
		return super.addDrawObjects(this.objectName, parentObject);
	}
	addDrawObjects3d(drawHeight) {
		let multipleFloors = Configuration.CURRENT.etages.length > 1;
		let rasterCoords = Configuration.CURRENT.raster.getRasterByCoordinate(this.startX, this.startY);
		let rowData = Configuration.CURRENT.raster.getRowPositions(rasterCoords.x, rasterCoords.y);
		let span;

		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			if (rasterCoords.x === -1) {
				rasterCoords.x = 0;
			}
			span = Configuration.CURRENT.raster.spansX.get(rasterCoords.x);
		} else {
			if (rasterCoords.y === -1) {
				rasterCoords.y = 0;
			}
			span = Configuration.CURRENT.raster.spansY.get(rasterCoords.y);
		}

		let paramsObject = {
			drawHeight: drawHeight,
			multipleFloors: multipleFloors,
			rowPositions: rowData.rowPositions,
			placement: rowData.placement,
			oid: Configuration.CURRENT.profiles.mainBeam.oid,
			assetName: Configuration.CURRENT.profiles.mainBeam.description,
			profileType: 'mainbeam',
			oid: span.beam.oid,
			mainBeamDepth: Canvas3D.CURRENT.getBeamData(span.beam.oid, 'depth'),
			mainBeamHeight: Canvas3D.CURRENT.getBeamData(span.beam.oid, 'height'),
		};
		switch (this.position) {
			case Trimmings.TOP:
				new TrimmingTop3D(this.startX, this.startY, this.endX - this.startX, paramsObject);
				break;
			case Trimmings.BOTTOM:
				new TrimmingBottom3D(this.startX, this.startY, this.endX - this.startX, paramsObject);
				break;
			case Trimmings.LEFT:
				new TrimmingLeft3D(this.startX, this.startY, this.endY - this.startY, paramsObject);
				break;
			case Trimmings.RIGHT:
				new TrimmingRight3D(this.startX, this.startY, this.endY - this.startY, paramsObject);
				break;
		}
	}
}
