import { ObjectGroup } from '../../draw/objectGroup';
import { Configuration } from '../configuration';
import { Profiles } from '../profiles';
import { Columns } from '../columns';
import { RemoveRaster } from '../removeRaster';
import { BuildingColumnTrimming } from './buildingColumnTrimming';
import { Trimmings } from './trimmings';

export class BuildingColumnTrimmings extends Trimmings {
	objectName = 'BuildingColumnTrimmings';
	setTrimming(x, y, width, depth, etage) {
		// Removen van kolommen gebeurd in object Buildingcolumn zelf,
		// Dit kan op meerdere etages, en daarom op etage leven vanuit dat object.

		// Kolom buiten de vloer dan niets doen
		if (
			(x < 0 && x + width < 0) ||
			(x > Configuration.CURRENT.raster.getSizeX() && x + width > Configuration.CURRENT.raster.getSizeX()) ||
			(y < 0 && y + depth < 0) ||
			(y > Configuration.CURRENT.raster.getSizeY() && y + depth > Configuration.CURRENT.raster.getSizeY())
		) {
			return;
		}

		let topLeftRaster = Configuration.CURRENT.raster.getRasterByCoordinate(x, y);
		let bottomLeftRaster = Configuration.CURRENT.raster.getRasterByCoordinate(x, y + depth);
		let topRightRaster = Configuration.CURRENT.raster.getRasterByCoordinate(x + width, y);
		let bottomRightRaster = Configuration.CURRENT.raster.getRasterByCoordinate(x + width, y + depth);

		let columnSize = Columns.COLUMN_SIZE / Configuration.CURRENT.canvas.scaleFactor;

		let startY;
		let endY;
		let startX;
		let endX;

		// LEFT BEAM VERTICAAL
		if (etage.isActiveRaster(new RemoveRaster(topLeftRaster.x, topLeftRaster.y)) || etage.isActiveRaster(new RemoveRaster(bottomLeftRaster.x, bottomLeftRaster.y))) {
			if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
				startY = Configuration.CURRENT.raster.getSizeY(topLeftRaster.y - 1);
				endY = Configuration.CURRENT.raster.getSizeY(bottomLeftRaster.y);
			} else {
				startY = y;
				endY = y + depth;
			}

			// Wanneer linksboven niet actief is dan is beginpunt start van volgende raster.
			if (etage.isActiveRaster(new RemoveRaster(topLeftRaster.x, topLeftRaster.y)) === false) {
				startY = Configuration.CURRENT.raster.getSizeY(topLeftRaster.y);
			}

			// Wanneer linksonder niet actief dan eindigen bij eindpunt raster vnuit linksonderhoek gezien
			if (etage.isActiveRaster(new RemoveRaster(bottomLeftRaster.x, bottomLeftRaster.y)) === false) {
				endY = Configuration.CURRENT.raster.getSizeY(topLeftRaster.y);
			}

			this.trimmings.push(new BuildingColumnTrimming({ startX: x, startY: startY, endX: x, endY: endY, position: Trimmings.RIGHT, etageId: etage.id }));
		}

		// RIGHT BEAM VERTICAAL
		if (etage.isActiveRaster(new RemoveRaster(topRightRaster.x, topRightRaster.y)) || etage.isActiveRaster(new RemoveRaster(bottomRightRaster.x, bottomRightRaster.y))) {
			if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
				startY = Configuration.CURRENT.raster.getSizeY(topRightRaster.y - 1);
				endY = Configuration.CURRENT.raster.getSizeY(bottomRightRaster.y);
			} else {
				startY = y;
				endY = y + depth;
			}

			// Wanneer rechtsboven niet actief is dan is beginpunt start van volgende raster.
			if (etage.isActiveRaster(new RemoveRaster(topRightRaster.x, topRightRaster.y)) === false) {
				startY = Configuration.CURRENT.raster.getSizeY(topRightRaster.y);
			}

			// Wanneer rechtsonder raster niet actief is pakken we altijd het einde van de topRaster, dat is dan het altijd het raster waar de gebouwkolom eindigt.
			if (etage.isActiveRaster(new RemoveRaster(bottomRightRaster.x, bottomRightRaster.y)) === false) {
				endY = Configuration.CURRENT.raster.getSizeY(topRightRaster.y);
			}
			this.trimmings.push(new BuildingColumnTrimming({ startX: x + width, startY: startY, endX: x + width, endY: endY, position: Trimmings.RIGHT, etageId: etage.id }));
		}

		// TOP BEAM HORIZONTAAL
		if (etage.isActiveRaster(new RemoveRaster(topLeftRaster.x, topLeftRaster.y)) || etage.isActiveRaster(new RemoveRaster(topRightRaster.x, topRightRaster.y))) {
			if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
				startX = x;
				endX = x + width;
			} else {
				startX = Configuration.CURRENT.raster.getSizeX(topLeftRaster.x - 1);
				endX = Configuration.CURRENT.raster.getSizeX(topRightRaster.x);
			}

			// Wanneer linksboven niet actief is, dan eind vorig raster is start punt beam.
			if (etage.isActiveRaster(new RemoveRaster(topLeftRaster.x, topLeftRaster.y)) === false) {
				startX = Configuration.CURRENT.raster.getSizeX(topLeftRaster.x);
				if (topLeftRaster.x === -1) {
					startX = columnSize;
				}
			}

			// Wanneer rechtsboven niet actief is, dan eind vorig raster is eind punt beam.
			if (etage.isActiveRaster(new RemoveRaster(topRightRaster.x, topRightRaster.y)) === false) {
				endX = Configuration.CURRENT.raster.getSizeX(topLeftRaster.x);
			}

			this.trimmings.push(new BuildingColumnTrimming({ startX: startX, startY: y, endX: endX, endY: y, position: Trimmings.TOP, etageId: etage.id }));
		}

		// BOTTOM BEAM HORIZONTAAL
		if (etage.isActiveRaster(new RemoveRaster(bottomLeftRaster.x, bottomLeftRaster.y)) || etage.isActiveRaster(new RemoveRaster(bottomRightRaster.x, bottomRightRaster.y))) {
			if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
				startX = x;
				endX = x + width;
			} else {
				startX = Configuration.CURRENT.raster.getSizeX(bottomLeftRaster.x - 1);
				endX = Configuration.CURRENT.raster.getSizeX(bottomRightRaster.x);
			}

			// Wanneer linksonder niet actief is, dan eind vorig raster is start punt beam.
			if (etage.isActiveRaster(new RemoveRaster(bottomLeftRaster.x, bottomLeftRaster.y)) === false) {
				startX = Configuration.CURRENT.raster.getSizeX(bottomLeftRaster.x);
				if (bottomLeftRaster.x === -1) {
					startX = columnSize;
				}
			}

			// Wanneer rechtsonder niet actief is, dan eind vorig raster is eind punt beam.
			if (etage.isActiveRaster(new RemoveRaster(bottomRightRaster.x, bottomRightRaster.y)) === false) {
				endX = Configuration.CURRENT.raster.getSizeX(bottomLeftRaster.x);
			}

			this.trimmings.push(new BuildingColumnTrimming({ startX: startX, startY: y + depth, endX: endX, endY: y + depth, position: Trimmings.BOTTOM, etageId: etage.id }));
		}
	}
	addDrawObjects(parentObject, activeEtageId) {
		const drawGroupOfTrimmings = [];
		this.trimmings.forEach((trimming) => {
			// Als meegegeven etage overeenkomt met de etage van de trimming, dan tekenen.
			// Dit omdat we buildingcolumntrimming op meerdere verdiepingen hebben.
			if (trimming.etageId === activeEtageId) {
				let drawObject = trimming.addDrawObjects(parentObject);
				if (drawObject !== null) {
					drawGroupOfTrimmings.push(drawObject);
				}
			}
		});
		return drawGroupOfTrimmings;
	}
	// Los van parent omdat buildingColumn per etage trimming moet tekenen.
	addDrawObjects3d() {
		this.trimmings.forEach((buildingColumnTrimming, index) => {
			let etageIndex = null;
			Configuration.CURRENT.etages.etages.forEach((etage, index) => {
				if (etage.id === buildingColumnTrimming.etageId) {
					etageIndex = index;
				}
			});
			let drawHeight = Configuration.CURRENT.etages.getTotalHeight(etageIndex, true);
			buildingColumnTrimming.addDrawObjects3d(drawHeight);
		});
	}
}
