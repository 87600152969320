import { CageLadder } from './cageLadder';
import { CageLadderInFloor } from './cageLadderInFloor';
import { CageLadderOutSide } from './cageLadderOutside';

export class CageLadderDynamic {
	objectName = 'CageLadderDynamic';
	static classes = {
		outside: CageLadderOutSide,
		inFloor: CageLadderInFloor,
	};
	constructor(typeCageLadder, objectConfiguration, drawObject, cageLadder) {
		if (typeof CageLadderDynamic.classes[typeCageLadder] !== 'undefined') {
			return new CageLadderDynamic.classes[typeCageLadder](objectConfiguration, drawObject, cageLadder);
		}
		return new CageLadder(objectConfiguration,  cageLadder);
	}
}
