import * as pc from 'playcanvas';
import { Columns } from '../../configurator/columns';
import { Configuration } from '../../configurator/configuration';
import { jsonFinish } from '../../configurator/hicad/jsonFinish';
import { Canvas3D } from '../Canvas3D';
import { Model3D } from '../Model3D';

export class WoodPlate3D extends Model3D {
	objectName = 'WoodPlate3D';
	hoverTags = ['deckingfinish'];
	// eslint-disable-next-line no-useless-constructor
	constructor(x, y, params) {
		super(x, params.drawHeight, y, params);
	}
	draw() {
		// get texture for current deckingfinish, this is a file with oid in asset, gets added in deckingfinish.js
		this.texture = { name: this.oid + '_texture' };
		this.y -= Configuration.CURRENT.finish.height;
		if (this.calculate) {
			let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');

			if (typeof this.rowPositions !== 'undefined' && this.rowPositions !== null) {
				if (this.rowPositions.includes(Columns.POSITION_TOP)) {
					this.z -= this.overhang;
					this.depth += this.overhang;
				}
				if (this.rowPositions.includes(Columns.POSITION_BOTTOM)) {
					this.depth += this.overhang;
					// this.depth += columnWidth;
				}
				if (this.rowPositions.includes(Columns.POSITION_RIGHT)) {
					this.width += this.overhang;
				}
				if (this.rowPositions.includes(Columns.POSITION_LEFT)) {
					this.x -= this.overhang;
					this.width += this.overhang;
				}
			}
		}
		super.draw();
	}
	convert() {
		return new jsonFinish(this, jsonFinish.TYPE_WOODPLATE);
	}
}
