// import { StairOutSide } from '../../configurator/stairOutSide';
import { Configuration } from '../../configurator/configuration';
import { Canvas3D } from '../Canvas3D';
import { Model3D } from '../Model3D';

// OBEJCT PUUR GEBRUIKEN ALS ER EXTRA KOLOMMEN ZIJN MET BASEPLATE DIE ALTIJD IN HET MIDDEN KOMEN (DUS VOOR TRAPKOLOMMEN ETC)
export class BasePlate3D extends Model3D {
	objectName = 'BasePlate3D';
	draw() {
		let basePlateWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.basePlate.oid, 'width');
		let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');

		// Plaatje recht onder kolom, x en z positie zijn overgenomen uit de kolom.
		this.x -= (basePlateWidth - columnWidth) / 2;
		this.z -= (basePlateWidth - columnWidth) / 2;

		super.draw();
	}
}
