import { ObjectGroup } from '../draw/objectGroup';
import { Rectangle } from '../draw/rectangle';
import { DrawValue } from '../draw/drawValue';
import { Store } from '../data/store';

import { Line } from '../draw/line';
import { TorsionalSupportHorizontal3D } from '../draw3d/TorsionalSupport/TorsionalSupportHorizontal3D';
import { TorsionalSupportVertical3D } from '../draw3d/TorsionalSupport/TorsionalSupportVertical3D';
import { Functions } from '../helpers/functions';
import { Mathematic } from '../helpers/mathematic';

import { Canvas3D } from '../draw3d/Canvas3D';
import { ProfileAsset3D } from '../draw3d/assets/ProfileAsset3D';

import { HorizontalMainBeamTop3D } from '../draw3d/profiles/mainbeams/HorizontalMainBeamTop3D';
import { HorizontalMainBeamBottom3D } from '../draw3d/profiles/mainbeams/HorizontalMainBeamBottom3D';
import { VerticalMainBeamLeft3D } from '../draw3d/profiles/mainbeams/VerticalMainBeamLeft3D';
import { VerticalMainBeamRight3D } from '../draw3d/profiles/mainbeams/VerticalMainBeamRight3D';
import { VerticalChildBeam3D } from '../draw3d/profiles/childbeams/VerticalChildBeam3D';
import { HorizontalChildBeam3D } from '../draw3d/profiles/childbeams/HorizontalChildBeam3D';
import { RemoveRaster } from './removeRaster';
import { Columns } from './columns';
import { Configuration } from './configuration';
import { Raster } from './raster';
import { OverSpan } from './overspan';
import { ModelAsset3D } from '../draw3d/assets/ModelAsset3D';
import { Stair } from './stair';
import { SizeHandle } from '../draw/sizeHandle';
import { Canvas } from '../draw/canvas';
import { Statics } from '../helpers/statics';

export class Profiles {
	objectName = 'Profiles';
	objectName3d = 'profile';
	static MB_HORIZONTAL = 'x';
	static MB_VERTICAL = 'y';
	static WIDTH = 200;
	// is de offset die gedaan moet worden voor de mb tot hartlijn van sb om kpp te plaatsen
	static KPPSTARTOFFSET = 75;
	drawSize = Columns.COLUMN_SIZE / 2;
	beamdirection = 'vertical';
	name = 'profiles';
	actieveEtage = null;
	maxMainBeam = 8000;
	maxChildBeam = 12500;
	minPossibleHeight = 0;
	overSpan = new OverSpan();
	get torsionalSupport() {
		return Configuration.CURRENT.finish.articleGroup === 'DeckingFinishSteelGrating';
	}
	torsionalSupportArticle = {
		id: 0,
		description: '',
		article: {
			oid: 0,
		},
	};
	mainBeams = [];
	childBeams = [];

	maxPossibleHeight = 650;
	_centerToCenter = 800;
	onMainBeamDirectionChangeEvents = [];
	directions = [
		{ value: 'x', caption: 'grid.horizontal' },
		{ value: 'y', caption: 'grid.vertical' },
	];
	get centerToCenter() {
		if (this._centerToCenter === 0) {
			if (Configuration.CURRENT.newConfiguration) {
				this._centerToCenter = Store.CURRENT.countries.getMaxCtC(Configuration.CURRENT.countryCode);
			} else {
				this._centerToCenter = Store.CURRENT.centerToCenterDistances.getMax();
			}
		}
		return this._centerToCenter;
	}

	set centerToCenter(value) {
		this._centerToCenter = value;
		this.onChanged();
	}
	moveTotal = { x: 0, y: 0 };
	_doubleDoublePossible = false;
	get doubleDoublePossible() {
		return this._doubleDoublePossible;
	}
	set doubleDoublePossible(value) {
		this._doubleDoublePossible = value;
		this.onChanged();
	}
	_hotFormedPossible = false;
	maxBeamHeight = 0;
	get hotFormedPossible() {
		return this._hotFormedPossible;
	}
	set hotFormedPossible(value) {
		this._hotFormedPossible = value;
		this.onChanged();
	}
	get mouseAreaOffset() {
		if (this.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			return { x: 0, y: 10 };
		} else {
			return { x: 10, y: 0 };
		}
	}
	debugInfo = {
		mainBeamParameters: {
			variableLoad: 0,
			centerToCenterDistance: 0,
			safetyFactorPermanentLoad: 0,
			safetFactorPermanentLoadCountry: 0.0,
			deflection: 0,
			materialFactorCf: 0.0,
			materialFactorHf: 0.0,
			safetyFactorVariableLoad: 0.0,
		},
		childBeamParameters: {
			variableLoad: 0,
			centerToCenterDistance: 0,
			safetyFactorPermanentLoad: 0,
			safetFactorPermanentLoadCountry: 0.0,
			deflection: 0,
			materialFactorCf: 0.0,
			materialFactorHf: 0.0,
			safetyFactorVariableLoad: 0.0,
		},
		beams: [],
		debug: false,
	};

	totalWeight = 0;
	mousePriority = 30;
	_mainBeamDirection = 'x';
	mainBeam = { name: '', height: 0, weight: 0, width: 100 };
	childBeam = { name: '', height: 0, weight: 0, width: 100 };

	_sameHeight = false; // maximale hoogte van in te zetten beams.
	get sameHeight() {
		return this._sameHeight;
	}
	set sameHeight(value) {
		this._sameHeight = value;
		this.onChanged();
	}
	_maxHeight = 650; // maximale hoogte van in te zetten beams.
	get maxHeight() {
		return this._maxHeight;
	}
	set maxHeight(value) {
		this._maxHeight = value;
		this.onChanged();
	}
	_deflection = 300; // maximale hoogte van in te zetten beams.
	get deflection() {
		return this._deflection;
	}
	set deflection(value) {
		let maxCtC = Store.CURRENT.floorDeflections.getMaxCtC(value);

		if (maxCtC > 0 && this.centerToCenter > maxCtC) {
			let newCenterToCenter = Store.CURRENT.centerToCenterDistances.getLower(maxCtC);
			window.Vue.$confirm({
				isHtml: true,
				title: window.$nuxt.$translate('confirm.title.selectedValueNotValid'),
				content: window.$nuxt.$translate('confirm.content.deflection', {
					currentCenterToCenter: this.centerToCenter,
					newCenterToCenter: newCenterToCenter,
				}),
				okText: window.$nuxt.$translate('confirm.button.yes'),
				cancelText: window.$nuxt.$translate('confirm.button.no'),
			})
				.then((success) => {
					// Ok button pressed
					this._deflection = value;
					this.centerToCenter = newCenterToCenter;
					this.onChanged();
				})
				.catch((cancel) => {
					// Cancel button pressed
				});
		} else {
			this._deflection = value;
			this.onChanged();
		}
	}
	get mainBeamDirection() {
		return this._mainBeamDirection;
	}
	set mainBeamDirection(value) {
		let oldValue = this._mainBeamDirection;
		if (oldValue !== value) {
			this._mainBeamDirection = value;

			this.onMainBeamDirectionChangeEvents.forEach((evt) => {
				if (typeof evt === 'function') {
					evt(oldValue, value);
				}
				// event(oldValue, value);
			});

			this.onChanged();
		}
	}
	reconstruct() {
		this.onMainBeamDirectionChangeEvents = [];
	}
	beamNameVisible = false;
	amounts = { mb: [], sb: [] };
	doubleDouble = false;
	mainBeamHotFormed = false;

	setMaxOverSpan(afterSet, params) {
		const maxMainBeam = this.mainBeamDirection === Profiles.MB_HORIZONTAL ? Configuration.CURRENT.raster.getMaxOverSpanHorizontal() : Configuration.CURRENT.raster.getMaxOverSpanVertical();
		const maxChildBeam = this.mainBeamDirection === Profiles.MB_HORIZONTAL ? Configuration.CURRENT.raster.getMaxOverSpanVertical() : Configuration.CURRENT.raster.getMaxOverSpanHorizontal();

		let data = {
			countryCode: Configuration.CURRENT.countryCode,
			maxHeight: this.maxHeight,
			useHotFormed: this.hotFormedPossible,
			useDoubleDouble: this.doubleDoublePossible,
			profileSameHeight: this.sameHeight,
			step: Configuration.CURRENT.step,
			deflection: this.deflection,
			variableLoad: Configuration.CURRENT.variableLoad,
			centerToCenterDistance: this.centerToCenter,
			maxMainBeam: maxMainBeam,
			maxChildBeam: maxChildBeam,
			debugMode: Configuration.CURRENT.debugMode,
			calculateWithNotInContract: Configuration.CURRENT.calculateWithNotInContract,
			companyId: Store.CURRENT.companies.selectedCompany.id,
			consequenceClass: Configuration.CURRENT.consequenceClass,
			deckingFinishId: Configuration.CURRENT.finish.id,
			multipleProfiles: Configuration.CURRENT.multipleProfiles ?? false,
		};
		let requestID = Functions.uuidv4();

		Configuration.CURRENT.startCalculationRequest(requestID);
		Configuration.CURRENT.noMainbeamFound = false;
		Configuration.CURRENT.noChildbeamFound = false;

		Store.CURRENT.calculate.calculateMaxBeamLength(
			data,
			(res) => {
				this.maxMainBeam = res.maxMainBeam;
				this.maxChildBeam = res.maxChildBeam;

				if (this.mainBeamDirection === Profiles.MB_HORIZONTAL) {
					Configuration.CURRENT.raster.spansX.setMax(this.maxMainBeam, params);
					Configuration.CURRENT.raster.spansY.setMax(this.maxChildBeam, params);
				} else {
					Configuration.CURRENT.raster.spansY.setMax(this.maxMainBeam, params);
					Configuration.CURRENT.raster.spansX.setMax(this.maxChildBeam, params);
				}

				data.overspans = this.overSpan.distinct();

				Store.CURRENT.calculate.getProfiles(
					data,
					(response) => {
						if (typeof response.debug !== 'undefined' && response.debug !== null && response.debug) {
							this.setProfiles(response.beams);
							this.debugInfo = {
								mainBeamParameters: {
									variableLoad: response.mainBeamParameters.variableLoad,
									centerToCenterDistance: response.mainBeamParameters.centerToCenterDistance,
									safetyFactorPermanentLoad: response.mainBeamParameters.safetyFactorPermanentLoad,
									safetFactorPermanentLoadCountry: response.mainBeamParameters.safetFactorPermanentLoadCountry,
									deflection: response.mainBeamParameters.deflection,
									materialFactorCf: response.mainBeamParameters.materialFactorCf,
									materialFactorHf: response.mainBeamParameters.materialFactorHf,
									safetyFactorVariableLoad: response.mainBeamParameters.safetyFactorVariableLoad,
								},
								childBeamParameters: {
									variableLoad: response.childBeamParameters.variableLoad,
									centerToCenterDistance: response.childBeamParameters.centerToCenterDistance,
									safetyFactorPermanentLoad: response.childBeamParameters.safetyFactorPermanentLoad,
									safetFactorPermanentLoadCountry: response.childBeamParameters.safetFactorPermanentLoadCountry,
									deflection: response.childBeamParameters.deflection,
									materialFactorCf: response.childBeamParameters.materialFactorCf,
									materialFactorHf: response.childBeamParameters.materialFactorHf,
									safetyFactorVariableLoad: response.childBeamParameters.safetyFactorVariableLoad,
								},
								beams: response.beams,
								debug: response.debug,
							};
						} else {
							this.setProfiles(response);
							this.debugInfo = {
								mainBeamParameters: {
									variableLoad: 0,
									centerToCenterDistance: 0,
									safetyFactorPermanentLoad: 0,
									safetFactorPermanentLoadCountry: 0.0,
									deflection: 0,
									materialFactorCf: 0.0,
									materialFactorHf: 0.0,
									safetyFactorVariableLoad: 0.0,
								},
								childBeamParameters: {
									variableLoad: 0,
									centerToCenterDistance: 0,
									safetyFactorPermanentLoad: 0,
									safetFactorPermanentLoadCountry: 0.0,
									deflection: 0,
									materialFactorCf: 0.0,
									materialFactorHf: 0.0,
									safetyFactorVariableLoad: 0.0,
								},
								beams: [],
								debug: false,
							};
						}
						if (typeof afterSet === 'function') {
							afterSet();
						}

						Configuration.CURRENT.endCalculationRequest(requestID);
					},
					(error) => {
						if (typeof afterSet === 'function') {
							afterSet();
						}
						Configuration.CURRENT.endCalculationRequest();
					},
				);
			},
			(err) => {
				console.log(err);
				Configuration.CURRENT.endCalculationRequest();
			},
		);
	}
	mainBeamExists(mainBeam) {
		let foundBeam = this.mainBeams.find((item) => item.oid === mainBeam.oid);
		return typeof foundBeam !== 'undefined';
	}
	childBeamExists(childBeam) {
		let foundBeam = this.childBeams.find((item) => item.oid === childBeam.oid);
		return typeof foundBeam !== 'undefined';
	}
	beamExists(beam) {
		let foundBeam = this.beams.find((item) => item.oid === beam.oid);
		return typeof foundBeam !== 'undefined';
	}

	getDistanceFromBackToHeartLine(oid) {
		let beam = this.beams.find((p) => p.oid === oid);
		if (beam === null || typeof beam === 'undefined') {
			return 0;
		}

		return beam.distanceFromBackToHeartLine;
	}

	setProfiles(rasters) {
		if (rasters.length === 0) {
			Configuration.CURRENT.noMainbeamFound = true;
			Configuration.CURRENT.noChildbeamFound = true;
			window.Vue.$alert({ isHtml: true, title: window.$nuxt.$translate('alert.title.noBeamFound'), content: window.$nuxt.$translate('alert.content.noBeamFound') });
			return;
		}

		if (this.torsionalSupport) {
			//voorlopig hier en zo gemaakt. Zodra meerdere kinderbalken hier beter over nadenken
			// Mogelijk combinaties ophalen maar ook onthouden
			//als torsionalsupport dan uitrekenen welke torsiesteun. En die opslaan voor later gebruik
			var oid = rasters[0].childBeam.profile.oid;
			Store.CURRENT.torsionalSupportArticle.calculate({ beamOne: oid, beamTwo: oid }, (article) => {
				this.torsionalSupportArticle = article;
			});
		}

		let spansX = Configuration.CURRENT.raster.spansX.getSpans();
		let spansY = Configuration.CURRENT.raster.spansY.getSpans();
		let mainBeamDirectionHorizontal = Configuration.CURRENT.profiles.mainBeamDirection === 'x';
		this.mainBeams = [];
		this.childBeams = [];
		this.beams = [];

		// Toevoegen aan de mainbeams en childbeams zodat we deze bij create3dAssets kunnen gebruiken.
		rasters.forEach((raster) => {
			// Wanneer mainbeam nog niet opgeslagen in de array
			if (this.mainBeamExists(raster.mainBeam.profile) === false) {
				this.mainBeams.push(raster.mainBeam.profile);
			}
			if (this.childBeamExists(raster.childBeam.profile) === false) {
				this.childBeams.push(raster.childBeam.profile);
			}
			if (this.beamExists(raster.childBeam.profile) === false) {
				this.beams.push(raster.childBeam.profile);
			}
			if (this.beamExists(raster.mainBeam.profile) === false) {
				this.beams.push(raster.mainBeam.profile);
			}
		});

		this.totalWeight = 0;
		spansX.forEach((spanX) => {
			spansY.forEach((spanY) => {
				rasters.forEach((raster) => {
					let childbeam,
						mainbeam = null;

					if (mainBeamDirectionHorizontal) {
						if (spanX.value === raster.mainbeamLength && spanY.value === raster.childbeamLength) {
							childbeam = raster.childBeam;
							mainbeam = raster.mainBeam;

							spanX.beam = mainbeam.profile;
							spanX.salesProductId = mainbeam.salesProductId;

							this.totalWeight += (spanX.beam.weight / 1000) * spanX.value;

							spanY.beam = childbeam.profile;
							spanY.salesProductId = childbeam.salesProductId;

							this.totalWeight += (spanY.beam.weight / 1000) * spanY.value;
						}
					} else {
						if (spanY.value === raster.mainbeamLength && spanX.value === raster.childbeamLength) {
							childbeam = raster.childBeam;
							mainbeam = raster.mainBeam;

							spanX.beam = childbeam.profile;
							spanX.salesProductId = childbeam.salesProductId;

							this.totalWeight += (spanX.beam.weight / 1000) * spanX.value;

							spanY.beam = mainbeam.profile;
							spanY.salesProductId = mainbeam.salesProductId;

							this.totalWeight += (spanY.beam.weight / 1000) * spanY.value;
						}
					}
				});
			});
		});
	}
	createNewConfiguration(data) {
		if (typeof data.mainBeamDirection !== 'undefined' && data.mainBeamDirection !== null) {
			this.mainBeamDirection = data.mainBeamDirection;
		}
	}
	onMouseMove(evt, drawObject) {
		if (
			(drawObject.objectParams.x > 0 && drawObject.objectParams.x < Configuration.CURRENT.raster.spansX.length) ||
			(drawObject.objectParams.y > 0 && drawObject.objectParams.y < Configuration.CURRENT.raster.spansY.length)
		) {
			Canvas.CURRENT.canvas.style.cursor = 'move';
			drawObject.fillColor = Statics.COLOR_MOUSE_OVER;
		}

		return { stopPropagation: true };
	}
	onMouseLeave(evt, drawObject) {
		drawObject.fillColor = Statics.COLOR_MAINBEAM;
		Canvas.CURRENT.canvas.style.cursor = 'default';
	}
	getAmount() {
		let totalAmount = { MainBeams: [], ChildBeams: [] };
		this.amounts.mb.forEach((amount) => {
			totalAmount.MainBeams.push({
				id: amount.oid,
				name: amount.name,
				length: amount.length,
				amount: amount.amount,
			});
		});

		this.amounts.sb.forEach((amount) => {
			totalAmount.ChildBeams.push({
				id: amount.oid,
				name: amount.name,
				length: amount.length,
				amount: amount.amount,
				torsionalSupport: this.torsionalSupport,
				torsionalSupportArticle: this.torsionalSupportArticle?.id,
			});
		});

		this.amounts.mb.sort((a, b) => (a.length > b.length ? -1 : b.length > a.length ? -1 : 0));
		this.amounts.sb.sort((a, b) => (a.length > b.length ? -1 : b.length > a.length ? -1 : 0));

		return totalAmount;
	}
	getMaxProfileHeight() {
		// voorlopig vanuit profiles. Als er verschillende profielen zijn moet dit vanuit etage gedaan worden
		if (typeof this.maxBeamHeight === 'undefined' || this.maxBeamHeight === null || this.maxBeamHeight < 0) {
			this.maxBeamHeight = 0;
		}
		return this.maxBeamHeight;
	}
	countProfiles(positions) {
		let count = 0;
		positions.forEach((position, index) => {
			if (typeof position.object !== 'undefined' && position.object !== null) {
				if (position.object.name === 'profiles') {
					count++;
				}
			}
		});
		return count;
	}
	onMouseUp(evt, drawObject) {
		let params = {};
		if (this.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			const rasterNext = drawObject.objectParams.y;
			const rasterPrev = drawObject.objectParams.y - 1;
			if (rasterPrev < 0 || rasterNext > Configuration.CURRENT.raster.spansY.length - 1) {
				return;
			}
			let newValue = Math.round(this.dragRasterNext - this.moveTotal.y);
			let newRoundValue = Math.floor(newValue / 50) * 50;

			Configuration.CURRENT.raster.spansY.get(rasterNext).value = newRoundValue;
			Configuration.CURRENT.raster.spansY.get(rasterPrev).value = Math.round(this.dragRasterPrev + this.moveTotal.y) + (newValue - newRoundValue);
			params.beamDirection = 'y';
			params.rasterIndex = rasterNext - 1;
		} else {
			const rasterNext = drawObject.objectParams.x;
			const rasterPrev = drawObject.objectParams.x - 1;

			if (rasterPrev < 0 || rasterNext > Configuration.CURRENT.raster.spansX.length - 1) {
				return;
			}

			let newValue = Math.round(this.dragRasterNext - this.moveTotal.x);
			let newRoundValue = Math.floor(newValue / 50) * 50;

			Configuration.CURRENT.raster.spansX.get(rasterNext).value = newRoundValue;
			Configuration.CURRENT.raster.spansX.get(rasterPrev).value = Math.round(this.dragRasterPrev + this.moveTotal.x) + (newValue - newRoundValue);

			params.beamDirection = 'x';
			params.rasterIndex = rasterNext;
		}

		this.onChanged(params);
		return { stopPropagation: true };
	}
	onChangeMainBeamLength(raster, delta, evt, drawObject) {
		if (drawObject.objectParams.type === 'childBeam') {
			const objectX = drawObject.objectParams.raster.x;
			const objectY = drawObject.objectParams.raster.y;
			if ((raster.x > -1 && raster.x - 1 === objectX) || (raster.y > -1 && raster.y - 1 === objectY)) {
				drawObject.width.value += delta.x;
				drawObject.height.value += delta.y;
			}
			if ((raster.x > -1 && raster.x === objectX) || (raster.y > -1 && raster.y === objectY)) {
				drawObject.x.value += delta.x;
				drawObject.y.value += delta.y;
				drawObject.width.value -= delta.x;
				drawObject.height.value -= delta.y;
			}
		}
		if (drawObject.objectParams.type === 'mainBeam') {
			const objectX = drawObject.objectParams.x;
			const objectY = drawObject.objectParams.y;

			if ((raster.x > -1 && raster.x === objectX) || (raster.y > -1 && raster.y === objectY)) {
				drawObject.drawObjects.forEach((drawObject, index) => {
					drawObject.y.value += delta.y;
					drawObject.x.value += delta.x;
				});
			}
		}
		// Voor profiles moet childbeammove niets doen.
	}
	onChangeChildBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params) {
		// toegevoegd om tijdens drag teken gedeelte snel mee te laten schakelen. Doet niets met de rasterberekening
	}
	moveProfilePossible(params) {
		let activeEtage = Configuration.CURRENT.etages.activeEtage();
		let maxBeam = 0;
		let raster = 0;
		let spans;

		// We willen mainbeams dan naar boven of beneden verplaatsen.
		if (this.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			// Als we naar onder of boven verslepen veranderd de lengte van de kinderbalk.
			// Dus maximale van beam is dan de maximale childbeam.
			maxBeam = this.maxChildBeam;
			raster = params.y;
			spans = Configuration.CURRENT.raster.spansY.getSpans();
		}
		// We willen mainbeams dan naar links of rechts verplaatsen.
		else if (this.mainBeamDirection === Profiles.MB_VERTICAL) {
			// Als we naar links en rechts verplaatsen moeten we rekening houden met wat de maximale childbeam opnieuw is.
			maxBeam = this.maxMainBeam;
			raster = params.x;
			spans = Configuration.CURRENT.raster.spansX.getSpans();
		}

		return (
			raster - 1 >= 0 &&
			raster <= spans.length - 1 &&
			params.newLengthPrevDimension > Raster.MIN_SIZE &&
			params.newLengthNextDimension > Raster.MIN_SIZE &&
			params.newLengthPrevDimension <= maxBeam &&
			params.newLengthNextDimension <= maxBeam &&
			activeEtage.checkMoveProfilePossible(params) === true
		);
	}
	onMouseDrag(evt, drawObject) {
		// bereken movemouse tov start. ivm latency Meest natuurlijk gedrag
		// als andere profielen bereikt of raster te klein dan niet meer verschuiven
		if (this.countProfiles(evt.draggingDrawObjects) > 1) {
			// als meerder profielen op deze positie dan overlap en niet verder slepen
			return;
		}

		// Wanneer verslepen naar boven of beneden.
		if (this.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			// mainBeam horizontaal
			const rasterNext = drawObject.objectParams.y;
			const rasterPrev = drawObject.objectParams.y - 1;

			let moveYTotal = evt.deltaStart.y / Canvas.CURRENT.scaleFactor;
			// als er geen raster boven is dan niet verslepen
			// nooit meer dan maximale lengte van het raster verrslepen en nooit minder dan 0
			// als raster - teverplaatsen groter is dan max lengte childbeam.
			// Teverplaatsen is positief (profiel naar beneden) dan wordt raster kleiner en rasterBoven groter.
			// te verplaatsen is negatief (profiel naar boven)dan wordt raster groter en rasterBoven kleiner
			// als rasterBoven + teverplaatsen groter is dan max lengthe childbeam
			// dan niets doen
			if (
				rasterPrev < 0 ||
				rasterNext > Configuration.CURRENT.raster.spansY.length - 1 ||
				this.dragRasterNext - moveYTotal < 0 ||
				this.dragRasterPrev + moveYTotal < 0 ||
				this.dragRasterNext - moveYTotal > this.maxChildBeam ||
				this.dragRasterPrev + moveYTotal > this.maxChildBeam
			) {
				return;
			}

			this.moveTotal.y = moveYTotal;
			const moveY = evt.delta.y / Canvas.CURRENT.scaleFactor;

			drawObject.mouseArea.minY += evt.delta.y;
			drawObject.mouseArea.maxY += evt.delta.y;
			// Geef nu actualSize mee en wordt gebruikt voor text maar zou op alles toegepast moeten worden omdat heen en weer bewegen terwijl maximum is bereikt zorgt voor een onjuiste moveY

			Canvas.CURRENT.drawObjects.onChangeMainBeamLength({ x: -1, y: drawObject.objectParams.y }, { x: 0, y: moveY }, evt, drawObject);
		} else {
			const rasterNext = drawObject.objectParams.x;
			const rasterPrev = drawObject.objectParams.x - 1;
			const moveXTotal = evt.deltaStart.x / Canvas.CURRENT.scaleFactor;

			if (
				rasterPrev < 0 ||
				rasterNext > Configuration.CURRENT.raster.spansX.length - 1 ||
				this.dragRasterNext - moveXTotal < 0 ||
				this.dragRasterPrev + moveXTotal < 0 ||
				this.dragRasterNext - moveXTotal > this.maxChildBeam ||
				this.dragRasterPrev + moveXTotal > this.maxChildBeam
			) {
				return;
			}

			this.moveTotal.x = moveXTotal;
			const moveX = evt.delta.x / Canvas.CURRENT.scaleFactor;

			// Verschuvien van profielen in tekening.
			drawObject.mouseArea.minX += evt.delta.x;
			drawObject.mouseArea.maxX += evt.delta.x;

			Canvas.CURRENT.drawObjects.onChangeMainBeamLength({ x: drawObject.objectParams.x, y: -1 }, { x: moveX, y: 0 }, evt, drawObject);
		}
		return { stopPropagation: true };
	}
	onMouseDown(evt, drawObject) {
		// bijhouden actieve etage om bij drag niet steeds op te zoeken
		this.moveTotal.y = 0;
		this.moveTotal.x = 0;

		// onthoud huidige lengte rasters zodat bij drag de delta tov start gebruikt kan worden ivm latency
		if (this.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			const rasterNext = drawObject.objectParams.y;
			const rasterPrev = drawObject.objectParams.y - 1;

			// Buitenste mainbeam mag niet versleept worden, als ook niet de eerste.
			if (rasterNext > Configuration.CURRENT.raster.spansY.length - 1 || rasterPrev < 0) {
				return;
			}

			// Opslaan start sleep positie.
			this.dragRasterPrev = Configuration.CURRENT.raster.spansY.get(rasterPrev).value;
			this.dragRasterNext = Configuration.CURRENT.raster.spansY.get(rasterNext).value;
		} else {
			// Right en links van geselecteerde mainbeam.
			const rasterNext = drawObject.objectParams.x;
			const rasterPrev = drawObject.objectParams.x - 1;

			// Buitenste mainbeam mag niet versleept worden, als ook niet de eerste.
			if (rasterNext > Configuration.CURRENT.raster.spansX.length - 1 || rasterPrev < 0) {
				return;
			}

			// Opslaan start sleep positie.
			this.dragRasterPrev = Configuration.CURRENT.raster.spansX.get(rasterPrev).value;
			this.dragRasterNext = Configuration.CURRENT.raster.spansX.get(rasterNext).value;
		}

		return { stopPropagation: true };
	}
	onClick() {
		return { stopPropagation: true };
	}
	onChanged(params) {
		if (typeof this._onChanged === 'function') {
			this._onChanged(params, true);
		}
	}
	constructor(onChanged) {
		this._onChanged = onChanged;
	}
	setReferences(params) {
		this._onChanged = params.onChange;
	}
	removeReferences() {
		this._onChanged = null;
	}
	getTotalWeight() {
		return this.totalWeight;
	}
	calculateAmount(params) {
		this.amounts = { mb: [], sb: [] };
		this.maxBeamHeight = 0;

		params.etages.getEtages().forEach((etage, index) => {
			etage.calculateMainBeamAmount(this.mainBeamDirection, this.mainBeamHotFormed, this.doubleDouble, this);
			etage.calculateChildBeamAmount(this.mainBeamDirection, this, params.centerToCenter);
		});
	}
	addAmountMainBeam(length, addAmount, span) {
		let found = false;
		// Checken of de amounts.mb al bestaat, dan de totale lengte toevoegen.
		this.amounts.mb.forEach((amount) => {
			if (amount.length === length && amount.name === span.beam.description) {
				amount.amount += addAmount;
				found = true;
			}
		});
		// Zo niet, nieuwe pushen
		if (found === false) {
			this.amounts.mb.push({ length: length, amount: addAmount, name: span.beam.description, oid: span.salesProductId });
		}
		this.maxBeamHeight = this.maxBeamHeight < span.beam.height ? span.beam.height : this.maxBeamHeight;
	}
	addAmountChildBeam(length, addAmount, span, mainBeamSpan) {
		length = length - mainBeamSpan.beam.width * 2;
		let found = false;
		this.amounts.sb.forEach((amount) => {
			if (amount.length === length && amount.name === span.beam.description) {
				amount.amount += addAmount;
				found = true;
			}
		});
		if (found === false) {
			this.amounts.sb.push({ length: length, amount: addAmount, name: span.beam.description, oid: span.salesProductId });
		}
		this.maxBeamHeight = this.maxBeamHeight < span.beam.height ? span.beam.height : this.maxBeamHeight;
	}
	addDrawObjects(canvas, params) {
		switch (this.mainBeamDirection) {
			case Profiles.MB_HORIZONTAL:
				this.addMainBeamsHorizontal(params.actieveEtage, params.raster, canvas);
				this.addChildBeamsVertical(params.actieveEtage, params.raster, canvas, params.centerToCenter);
				break;
			case Profiles.MB_VERTICAL:
				this.addMainBeamsVertical(params.actieveEtage, params.raster, canvas);
				this.addChildBeamsHorizontal(params.actieveEtage, params.raster, canvas, params.centerToCenter);
				break;
		}
	}

	onRasterChanged(params) {}
	addChildBeamsHorizontal(etage, raster, canvas, centerToCenter) {
		const drawOffset = this.mainBeamHotFormed === true ? 0 : this.drawSize;
		const childBeamCount = Math.ceil(raster.getSizeY() / centerToCenter) + 1; // +1 begin beam
		const beamOffset = this.doubleDouble === true ? 8 : 4;
		raster.spansX.getSpans().forEach((spanX, indexX) => {
			for (let childBeam = 0; childBeam < childBeamCount; childBeam++) {
				const rasterBeginX = new DrawValue(raster.getSizeX(indexX - 1), drawOffset + beamOffset);

				const lengteRaster = new DrawValue(spanX.value, -beamOffset * 2 - this.drawSize * 2);

				const childBeamPosition = childBeam * centerToCenter;
				const rasterY = raster.spansY.getRaster(childBeamPosition - 50);
				const rasterBeginY = new DrawValue(childBeamPosition, this.drawSize - 1);
				const heightValue = spanX.beam.weight / 10;
				let previousRaster = -1;
				if (etage.isActiveRaster(new RemoveRaster(indexX, rasterY))) {
					canvas.addDrawObject(
						new Rectangle(rasterBeginX, rasterBeginY, lengteRaster, new DrawValue(0, 1), Statics.COLOR_CHILDBEAM, null, null, null, this, {
							type: 'childBeam',
							raster: {
								x: indexX,
								y: rasterY,
							},
						}),
					);
					if (childBeam > 0 && this.torsionalSupport === true) {
						// als torsiesteunen tekenen en het niet de beginbalk is. We tekenen ze terug
						let distance = lengteRaster.value / 3;
						let lengthTorsionalSupport = centerToCenter;
						if (!etage.isActiveRaster(new RemoveRaster(indexX, rasterY - 1)) && rasterY > 0) {
							// als voorgaande raster niet actief en 1e kinderbalk van dit raster
							if (rasterY !== previousRaster) {
								lengthTorsionalSupport = childBeamPosition - raster.getSizeY(rasterY - 1);
							}
						}
						// 1e torsiesteun
						canvas.addDrawObject(
							new Line(
								new DrawValue(raster.getSizeX(indexX - 1) + distance, this.drawSize + beamOffset),
								new DrawValue(childBeamPosition, this.drawSize),
								new DrawValue(raster.getSizeX(indexX - 1) + distance, this.drawSize + beamOffset),
								new DrawValue(childBeamPosition - lengthTorsionalSupport, this.drawSize),

								new DrawValue(0, heightValue),
								null,
								Statics.COLOR_CHILDBEAM,
								null,
								null,
								true,
								this,
								{
									type: 'torsionalSupport',
								},
							),
						);
						// 2e torsiesteun
						canvas.addDrawObject(
							new Line(
								new DrawValue(raster.getSizeX(indexX - 1) + distance * 2, this.drawSize + beamOffset),
								new DrawValue(childBeamPosition, this.drawSize),
								new DrawValue(raster.getSizeX(indexX - 1) + distance * 2, this.drawSize + beamOffset),
								new DrawValue(childBeamPosition - lengthTorsionalSupport, this.drawSize),
								new DrawValue(0, heightValue),
								null,
								Statics.COLOR_CHILDBEAM,
								null,
								null,
								true,
								this,
								{
									type: 'torsionalSupport',
								},
							),
						);
					}
				}
				previousRaster = rasterY;
			}
		});
		// eventuele begin en eindbalken tekenen
		raster.spansX.getSpans().forEach((spanX, indexX) => {
			raster.spansY.getSpans().forEach((spanY, indexY) => {
				if (etage.isActiveRaster(new RemoveRaster(indexX, indexY)) === true) {
					const rasterBeginX = new DrawValue(raster.getSizeX(indexX - 1), drawOffset + beamOffset);
					const lengteRaster = new DrawValue(spanX.value, -beamOffset * 2 - this.drawSize * 2);
					const heightValue = spanX.beam.weight / 10;
					// als voorgaande niet actief altijd extra profiel aan begin toevoegen. Namelijk 2 opties: 1. profiel valt precies op de maat (hoort dus bij vorige raster)
					// of valt over de maat. Nu geen rekening met offset maar aan begin is dan geen profiel
					//	profiles.addAmountChildBeam(spanX.value, 1);
					if (indexY > 0 && etage.isActiveRaster(new RemoveRaster(indexX, indexY - 1)) === false) {
						const rasterBeginY = new DrawValue(raster.getSizeY(indexY - 1), this.drawSize - 1);

						canvas.addDrawObject(
							new Rectangle(rasterBeginX, rasterBeginY, lengteRaster, new DrawValue(0, heightValue), Statics.COLOR_CHILDBEAM, null, null, null, this, {
								type: 'childBeam',
								raster: {
									x: indexX,
									y: indexY,
								},
							}),
						);
						// hier geen torsiesteunen want we tekenen childProfile naar 1 terug.
					}
					// als bij laatste raster nog lengte rasters is niet deelbaar door centerToCenter over is dan extra profiel
					if (indexY + 1 === raster.spansY.length && raster.getSizeY(indexY) % centerToCenter > 0) {
						const rasterBeginY = new DrawValue(raster.getSizeY(indexY), this.drawSize - 1);

						canvas.addDrawObject(
							new Rectangle(rasterBeginX, rasterBeginY, lengteRaster, new DrawValue(0, heightValue), Statics.COLOR_CHILDBEAM, null, null, null, this, {
								type: 'childBeam',
								raster: {
									x: indexX,
									y: indexY,
								},
							}),
						);
						if (this.torsionalSupport === true) {
							// als torsiesteunen tekenen en het niet de beginbalk is. We tekenen ze terug
							let distance = lengteRaster.value / 3;

							let lengthTorsionalSupport = raster.getSizeY(indexY) - Math.floor(raster.getSizeY() / centerToCenter) * centerToCenter;
							// teken ze nu langer maar in deze tekening toch niet zichtbaar. Dus niet erg.
							// 1e torsiesteun
							canvas.addDrawObject(
								new Line(
									new DrawValue(raster.getSizeX(indexX - 1) + distance, this.drawSize + beamOffset),
									new DrawValue(raster.getSizeY(indexY), this.drawSize),
									new DrawValue(raster.getSizeX(indexX - 1) + distance, this.drawSize + beamOffset),
									new DrawValue(raster.getSizeY(indexY) - lengthTorsionalSupport, this.drawSize),
									new DrawValue(0, heightValue),
									null,
									Statics.COLOR_CHILDBEAM,
									null,
									null,
									true,
									this,
									{
										type: 'torsionalSupport',
									},
								),
							);
							// // 2e torsiesteun
							canvas.addDrawObject(
								new Line(
									new DrawValue(raster.getSizeX(indexX - 1) + distance * 2, this.drawSize + beamOffset),
									new DrawValue(raster.getSizeY(indexY), this.drawSize),
									new DrawValue(raster.getSizeX(indexX - 1) + distance * 2, this.drawSize + beamOffset),
									new DrawValue(raster.getSizeY(indexY) - lengthTorsionalSupport, this.drawSize),
									new DrawValue(0, heightValue),
									null,
									Statics.COLOR_CHILDBEAM,
									null,
									null,
									true,
									this,
									{
										type: 'torsionalSupport',
									},
								),
							);
						}
					}
					// als nog niet laatste en volgende raster is niet actief en lengte inclusief huidige raster is niet deelbaar door centerToCenter dan extra profiel
					//	profiles.addAmountChildBeam(spanX.value, 1);
					if (indexY + 1 < raster.spansY.length && etage.isActiveRaster(new RemoveRaster(indexX, indexY + 1)) === false && raster.getSizeY(indexY) % centerToCenter > 0) {
						const rasterBeginY = new DrawValue(raster.getSizeY(indexY), this.drawSize - 1);

						canvas.addDrawObject(
							new Rectangle(rasterBeginX, rasterBeginY, lengteRaster, new DrawValue(0, heightValue), Statics.COLOR_CHILDBEAM, null, null, null, this, {
								type: 'childBeam',
								raster: {
									x: indexX,
									y: indexY,
								},
							}),
						);

						if (this.torsionalSupport === true) {
							// als torsiesteunen tekenen en het niet de beginbalk is. We tekenen ze terug
							let distance = lengteRaster.value / 3;
							let lengthTorsionalSupport = raster.getSizeY(indexY) - Math.floor(raster.getSizeY(indexY) / centerToCenter) * centerToCenter;
							// 1e torsiesteun
							canvas.addDrawObject(
								new Line(
									new DrawValue(raster.getSizeX(indexX - 1) + distance, this.drawSize + beamOffset),
									new DrawValue(raster.getSizeY(indexY), this.drawSize),
									new DrawValue(raster.getSizeX(indexX - 1) + distance, this.drawSize + beamOffset),
									new DrawValue(raster.getSizeY(indexY) - lengthTorsionalSupport, this.drawSize),
									new DrawValue(0, heightValue),
									null,
									Statics.COLOR_CHILDBEAM,
									null,
									null,
									true,
									this,
									{
										type: 'torsionalSupport',
									},
								),
							);
							canvas.addDrawObject(
								new Line(
									new DrawValue(raster.getSizeX(indexX - 1) + distance * 2, this.drawSize + beamOffset),
									new DrawValue(raster.getSizeY(indexY), this.drawSize),
									new DrawValue(raster.getSizeX(indexX - 1) + distance * 2, this.drawSize + beamOffset),
									new DrawValue(raster.getSizeY(indexY) - lengthTorsionalSupport, this.drawSize),
									new DrawValue(0, heightValue),
									null,
									Statics.COLOR_CHILDBEAM,
									null,
									null,
									true,
									this,
									{
										type: 'torsionalSupport',
									},
								),
							);
						}
					}
				}
			});
		});
	}
	addChildBeamsVertical(etage, raster, canvas, centerToCenter) {
		this.drawSize = canvas.columnSize / 2;

		const childBeamCount = Math.ceil(raster.getSizeX() / centerToCenter) + 1; // +1 begin beam
		const beamOffset = this.doubleDouble === true ? 8 : 4;
		let previousRaster = -1;
		raster.spansY.getSpans().forEach((spanY, indexY) => {
			for (let childBeam = 0; childBeam < childBeamCount; childBeam++) {
				const rasterBeginY = new DrawValue(raster.getSizeY(indexY - 1), this.drawSize + beamOffset);

				const lengteRaster = new DrawValue(spanY.value, -beamOffset * 2 - this.drawSize * 2);
				const childBeamPosition = childBeam * centerToCenter;
				const rasterX = raster.spansX.getRaster(childBeamPosition - 50);
				const rasterBeginX = new DrawValue(childBeamPosition, -this.drawSize);
				const heightValue = spanY.beam.weight / 10;

				if (etage.isActiveRaster(new RemoveRaster(rasterX, indexY))) {
					canvas.addDrawObject(
						new Rectangle(
							rasterBeginX,
							rasterBeginY,
							new DrawValue(0, heightValue),
							lengteRaster,

							Statics.COLOR_CHILDBEAM,
							null,
							null,
							false,
							this,
							{
								type: 'childBeam',
								raster: {
									x: rasterX,
									y: indexY,
								},
							},
						),
					);
					if (childBeam > 0 && this.torsionalSupport === true) {
						// als torsiesteunen tekenen en het niet de beginbalk is. We tekenen ze terug
						let distance = lengteRaster.value / 3;
						let lengthTorsionalSupport = centerToCenter;
						if (!etage.isActiveRaster(new RemoveRaster(rasterX - 1, indexY)) && rasterX > 0) {
							// als voorgaande raster niet actief en 1e kinderbalk van dit raster
							if (rasterX !== previousRaster) {
								lengthTorsionalSupport = childBeamPosition - raster.getSizeX(rasterX - 1);
							}
						}
						// 1e torsiesteun
						canvas.addDrawObject(
							new Line(
								new DrawValue(childBeamPosition, -this.drawSize),
								new DrawValue(raster.getSizeY(indexY - 1) + distance, this.drawSize + beamOffset),
								new DrawValue(childBeamPosition - lengthTorsionalSupport, -this.drawSize),
								new DrawValue(raster.getSizeY(indexY - 1) + distance, this.drawSize + beamOffset),
								new DrawValue(0, heightValue),
								null,
								Statics.COLOR_CHILDBEAM,
								null,
								null,
								true,
								this,
								{
									type: 'torsionalSupport',
								},
							),
						);
						// 2e torsiesteun
						canvas.addDrawObject(
							new Line(
								new DrawValue(childBeamPosition, -this.drawSize),
								new DrawValue(raster.getSizeY(indexY - 1) + distance * 2, this.drawSize + beamOffset),
								new DrawValue(childBeamPosition - lengthTorsionalSupport, -this.drawSize),
								new DrawValue(raster.getSizeY(indexY - 1) + distance * 2, this.drawSize + beamOffset),
								new DrawValue(0, heightValue),
								null,
								Statics.COLOR_CHILDBEAM,
								null,
								null,
								true,
								this,
								{
									type: 'torsionalSupport',
								},
							),
						);
					}
				}
				previousRaster = rasterX;
			}
		});
		// eventuele begin en eindbalken tekenen
		raster.spansX.getSpans().forEach((spanX, indexX) => {
			raster.spansY.getSpans().forEach((spanY, indexY) => {
				const rasterBeginY = new DrawValue(raster.getSizeY(indexY - 1), this.drawSize + beamOffset);
				const lengteRaster = new DrawValue(spanY.value, -beamOffset * 2 - this.drawSize * 2);
				const heightValue = spanY.beam.weight / 10;
				if (etage.isActiveRaster(new RemoveRaster(indexX, indexY)) === true) {
					// huidige raster actief

					if (indexX > 0 && etage.isActiveRaster(new RemoveRaster(indexX - 1, indexY)) === false) {
						// als voorgaande niet actief altijd extra profiel aan begin toevoegen. Namelijk 2 opties: 1. profiel valt precies op de maat (hoort dus bij vorige raster)
						// of valt over de maat. Nu geen rekening met offset maar aan begin is dan geen profiel
						const rasterBeginX = new DrawValue(raster.getSizeX(indexX - 1), -this.drawSize);
						canvas.addDrawObject(
							new Rectangle(rasterBeginX, rasterBeginY, new DrawValue(0, heightValue), lengteRaster, Statics.COLOR_CHILDBEAM, null, null, null, this, {
								type: 'childBeam',
								raster: {
									x: indexX,
									y: indexY,
								},
							}),
						);
						// hier geen torsiesteunen want we tekenen childProfile naar 1 terug.
					}

					if (indexX + 1 === raster.spansX.length && raster.getSizeX(indexX) % centerToCenter > 0) {
						// als bij laatste raster nog lengte rasters is niet deelbaar door centerToCenter over is dan extra profiel
						const rasterBeginX = new DrawValue(raster.getSizeX(indexX), -this.drawSize);
						canvas.addDrawObject(
							new Rectangle(rasterBeginX, rasterBeginY, new DrawValue(0, 1), lengteRaster, Statics.COLOR_CHILDBEAM, null, null, null, this, {
								type: 'childBeam',
								raster: {
									x: indexX,
									y: indexY,
								},
							}),
						);
						if (this.torsionalSupport === true) {
							// als torsiesteunen tekenen en het niet de beginbalk is. We tekenen ze terug
							let distance = lengteRaster.value / 3;
							let lengthTorsionalSupport = raster.getSizeX(indexX) - Math.floor(raster.getSizeX() / centerToCenter) * centerToCenter;
							// 1e torsiesteun
							canvas.addDrawObject(
								new Line(
									new DrawValue(raster.getSizeX(indexX), -this.drawSize),
									new DrawValue(raster.getSizeY(indexY - 1) + distance, this.drawSize + beamOffset),
									new DrawValue(raster.getSizeX(indexX) - lengthTorsionalSupport, -this.drawSize),
									new DrawValue(raster.getSizeY(indexY - 1) + distance, this.drawSize + beamOffset),
									new DrawValue(0, heightValue),
									null,
									Statics.COLOR_CHILDBEAM,
									null,
									null,
									true,
									this,
									{
										type: 'torsionalSupport',
									},
								),
							);
							// 2e torsiesteun
							canvas.addDrawObject(
								new Line(
									new DrawValue(raster.getSizeX(indexX), -this.drawSize),
									new DrawValue(raster.getSizeY(indexY - 1) + distance * 2, this.drawSize + beamOffset),
									new DrawValue(raster.getSizeX(indexX) - lengthTorsionalSupport, -this.drawSize),
									new DrawValue(raster.getSizeY(indexY - 1) + distance * 2, this.drawSize + beamOffset),
									new DrawValue(0, heightValue),
									null,
									Statics.COLOR_CHILDBEAM,
									null,
									null,
									true,
									this,
									{
										type: 'torsionalSupport',
									},
								),
							);
						}
					}
					if (indexX + 1 < raster.spansX.length && etage.isActiveRaster(new RemoveRaster(indexX + 1, indexY)) === false && raster.getSizeX(indexX) % centerToCenter > 0) {
						// als nog niet laatste en volgende raster is niet actief en lengte inclusief huidige raster is niet deelbaar door centerToCenter dan extra profiel
						const rasterBeginX = new DrawValue(raster.getSizeX(indexX), -this.drawSize);
						canvas.addDrawObject(
							new Rectangle(rasterBeginX, rasterBeginY, new DrawValue(0, 1), lengteRaster, Statics.COLOR_CHILDBEAM, null, null, null, this, {
								type: 'childBeam',
								raster: {
									x: indexX,
									y: indexY,
								},
							}),
						);
						if (this.torsionalSupport === true) {
							// als torsiesteunen tekenen en het niet de beginbalk is. We tekenen ze terug
							let distance = lengteRaster.value / 3;
							let lengthTorsionalSupport = raster.getSizeX(indexX) - Math.floor(raster.getSizeX(indexX) / centerToCenter) * centerToCenter;
							// 1e torsiesteun
							canvas.addDrawObject(
								new Line(
									new DrawValue(raster.getSizeX(indexX), -this.drawSize),
									new DrawValue(raster.getSizeY(indexY - 1) + distance, this.drawSize + beamOffset),
									new DrawValue(raster.getSizeX(indexX) - lengthTorsionalSupport, -this.drawSize),
									new DrawValue(raster.getSizeY(indexY - 1) + distance, this.drawSize + beamOffset),
									new DrawValue(0, heightValue),
									null,
									Statics.COLOR_CHILDBEAM,
									null,
									null,
									true,
									this,
									{
										type: 'torsionalSupport',
									},
								),
							);
							// 2e torsiesteun
							canvas.addDrawObject(
								new Line(
									new DrawValue(raster.getSizeX(indexX), -this.drawSize),
									new DrawValue(raster.getSizeY(indexY - 1) + distance * 2, this.drawSize + beamOffset),
									new DrawValue(raster.getSizeX(indexX) - lengthTorsionalSupport, -this.drawSize),
									new DrawValue(raster.getSizeY(indexY - 1) + distance * 2, this.drawSize + beamOffset),
									new DrawValue(0, heightValue),
									null,
									Statics.COLOR_CHILDBEAM,
									null,
									null,
									true,
									this,
									{
										type: 'torsionalSupport',
									},
								),
							);
						}
					}
				}
			});
		});
	}
	addMainBeamHorizontal(currentRaster, etage, raster, canvas, drawGroup) {
		// controleer of raster actief is. Anders niet tekenen

		// profiel aan bovenkant raster tekenen
		// tekenen vanaf x=(lengte linksliggende rasters), y=lengte bovenliggende rasters, w=lengte raster + hoogte balken, d=1"

		const offsetScaled = { x: 0, y: 0 };
		// drawOffset zorgt ervoor dat de kolommen tussen de profielen getekend worden. Bovenaan profiel halve kolom verder tekenen en onderaan halve kolom eerder

		const drawOffset = this.mainBeamHotFormed === true ? 0 : this.drawSize;

		offsetScaled.x = this.drawSize;
		offsetScaled.y = canvas.offsetTop + this.drawOffset;

		const rasterBeginX = new DrawValue(raster.getSizeX(currentRaster.indexX - 1), -this.drawSize);
		const rasterBeginY = new DrawValue(raster.getSizeY(currentRaster.indexY - 1), drawOffset);
		const rasterEindY = new DrawValue(raster.getSizeY(currentRaster.indexY - 1), -drawOffset);
		const lengteRaster = new DrawValue(currentRaster.spanX.value, this.drawSize);
		const heightValue = this.mainBeams.length > 1 ? currentRaster.spanX.beam.weight / 10 : 2;

		if (etage.isActiveRaster(new RemoveRaster(currentRaster.indexX, currentRaster.indexY)) === true) {
			drawGroup.push(
				new Rectangle(
					rasterBeginX,
					rasterBeginY,

					lengteRaster,
					new DrawValue(0, heightValue),
				),
			);
		}

		// profiel aan onderkant vorige raster tekenen ivm samenstellen mouseArea
		// tekenen vanaf x=(lengte linksliggende rasters), y=lengte bovenliggende rasters + huidige raster, w=lengte raster + hoogte balken, d=1
		// verschil met bovenkant is dus huidige raster

		if (etage.isActiveRaster(new RemoveRaster(currentRaster.indexX, currentRaster.indexY - 1)) === true) {
			drawGroup.push(
				new Rectangle(
					rasterBeginX,
					rasterEindY,

					lengteRaster,
					new DrawValue(0, heightValue),
				),
			);
		}

		if (this.doubleDouble === true) {
			const rasterBeginYdd = new DrawValue(rasterBeginY.value, rasterBeginY.offsetScaled + 3);
			const rasterEindYdd = new DrawValue(rasterEindY.value, rasterEindY.offsetScaled - 3);
			if (etage.isActiveRaster(new RemoveRaster(currentRaster.indexX, currentRaster.indexY)) === true) {
				drawGroup.push(
					new Rectangle(
						rasterBeginX,
						rasterBeginYdd,

						lengteRaster,
						new DrawValue(0, heightValue),
					),
				);
			}
			if (etage.isActiveRaster(new RemoveRaster(currentRaster.indexX, currentRaster.indexY - 1)) === true) {
				drawGroup.push(
					new Rectangle(
						rasterBeginX,
						rasterEindYdd,

						lengteRaster,
						new DrawValue(0, heightValue),
					),
				);
			}
		}
	}
	addMainBeamsHorizontal(etage, raster, canvas) {
		raster.spansY.getSpans().forEach((spanY, indexY) => {
			const drawGroup = new ObjectGroup(Statics.COLOR_MAINBEAM, null, null, !Configuration.CURRENT.showEditModus, this, { x: 0, y: indexY, type: 'mainBeam' });
			raster.spansX.getSpans().forEach((spanX, indexX) => {
				this.addMainBeamHorizontal({ indexX: indexX, indexY: indexY, spanX: spanX, spanY: spanY }, etage, raster, canvas, drawGroup);
			});
			if (drawGroup.containsItems() === true) {
				Canvas.CURRENT.addDrawObject(drawGroup);
			}
		});
		const drawGroup = new ObjectGroup(Statics.COLOR_MAINBEAM, null, null, !Configuration.CURRENT.editModus.showEditModus, this, { x: 0, y: raster.spansY.length });
		// toevoegen onderste mainBeam
		raster.spansX.getSpans().forEach((spanX, indexX) => {
			this.addMainBeamHorizontal({ indexX: indexX, indexY: raster.spansY.length, spanX: spanX, spanY: 0 }, etage, raster, canvas, drawGroup);
		});
		if (drawGroup.containsItems() === true) {
			Canvas.CURRENT.addDrawObject(drawGroup);
		}
	}
	addMainBeamVertical(currentRaster, etage, raster, canvas, drawGroup) {
		// currentRaster heeft extra offset omdat laatste kolommen iets naar binnen getekend worden

		// controleer of raster actief is. Anders niet tekenen
		const drawOffset = this.mainBeamHotFormed === true ? 0 : this.drawSize;
		// profiel aan linkerkant raster tekenen
		// tekenen vanaf x=(lengte linksliggende rasters), y=lengte bovenliggende rasters, w=1, d=lengte raster + hoogte balken"
		const rasterBeginX = new DrawValue(raster.getSizeX(currentRaster.indexX - 1), drawOffset + currentRaster.offset);

		const rasterEindX = new DrawValue(raster.getSizeX(currentRaster.indexX - 1), -drawOffset - currentRaster.offset);
		const rasterBeginY = new DrawValue(raster.getSizeY(currentRaster.indexY - 1), -1);
		const lengteRaster = new DrawValue(currentRaster.spanY.value, this.drawSize);
		const heightValue = this.mainBeams.length > 1 ? currentRaster.spanY.beam.weight / 10 : 2;
		if (etage.isActiveRaster(new RemoveRaster(currentRaster.indexX, currentRaster.indexY)) === true) {
			// drawOffset zorgt ervoor dat de kolommen tussen de profielen getekend worden. Bovenaan profiel halve kolom verder tekenen en onderaan halve kolom eerder
			drawGroup.push(
				new Rectangle(
					rasterBeginX,
					rasterBeginY,
					new DrawValue(0, heightValue),
					lengteRaster,

					Statics.COLOR_MAINBEAM,
				),
			);
		}

		// profiel aan rechter zijkant raster van vorige raster tekenen
		// tekenen vanaf x=(lengte linksliggende rasters), y=lengte bovenliggende rasters , w=lengte raster + hoogte balken, d=1
		if (etage.isActiveRaster(new RemoveRaster(currentRaster.indexX - 1, currentRaster.indexY)) === true) {
			drawGroup.push(
				new Rectangle(
					rasterEindX,
					rasterBeginY,
					new DrawValue(0, heightValue),
					lengteRaster,

					Statics.COLOR_MAINBEAM,
				),
			);
		}

		if (this.doubleDouble === true) {
			const rasterBeginXdd = new DrawValue(rasterBeginX.value, rasterBeginX.offsetScaled + 3);
			const rasterEindXdd = new DrawValue(rasterEindX.value, rasterEindX.offsetScaled - 3);
			if (etage.isActiveRaster(new RemoveRaster(currentRaster.indexX, currentRaster.indexY)) === true) {
				drawGroup.push(
					new Rectangle(
						rasterBeginXdd,
						rasterBeginY,
						new DrawValue(0, heightValue),
						lengteRaster,

						Statics.COLOR_MAINBEAM,
					),
				);
			}
			if (etage.isActiveRaster(new RemoveRaster(currentRaster.indexX - 1, currentRaster.indexY)) === true) {
				drawGroup.push(
					new Rectangle(
						rasterEindXdd,
						rasterBeginY,
						new DrawValue(0, heightValue),
						lengteRaster,

						Statics.COLOR_MAINBEAM,
					),
				);
			}
		}
	}
	addMainBeamsVertical(etage, raster, canvas) {
		raster.spansX.getSpans().forEach((spanX, indexX) => {
			const drawGroup = new ObjectGroup(Statics.COLOR_MAINBEAM, null, null, !Configuration.CURRENT.editModus.showEditModus, this, { x: indexX, y: 0, type: 'mainBeam' });
			raster.spansY.getSpans().forEach((spanY, indexY) => {
				this.addMainBeamVertical({ indexX: indexX, indexY: indexY, spanX: spanX, spanY: spanY, offset: 0 }, etage, raster, canvas, drawGroup);
			});
			if (drawGroup.containsItems() === true) {
				Canvas.CURRENT.addDrawObject(drawGroup);
			}
		});
		// toevoegen rechtse mainBeam alleen te verslepen als accessoiriestoevoegen uit staat
		const drawGroup = new ObjectGroup(Statics.COLOR_MAINBEAM, null, null, !Configuration.CURRENT.editModus.showEditModus, this, { x: raster.spansX.length, y: 0 });
		raster.spansY.getSpans().forEach((spanY, indexY) => {
			this.addMainBeamVertical({ indexX: raster.spansX.length, indexY: indexY, spanX: 0, spanY: spanY, offset: this.drawSize }, etage, raster, canvas, drawGroup);
		});
		if (drawGroup.containsItems() === true) {
			Canvas.CURRENT.addDrawObject(drawGroup);
		}
	}

	//********************** */ 3D // ******************** */
	getBeamLengths(oid) {
		let lengths = [];
		let lengthsFound = this.beamLengths.filter((bl) => bl.oid === oid);
		lengthsFound.forEach((lf, index) => {
			lengths.push(lf.length);
		});
		return lengths;
	}
	getBeamName(oid) {
		let found = this.beams.find((b) => b.oid === oid);
		if (found !== null && typeof found !== 'undefined') {
			return found.description;
		}
	}

	create3DAssets() {
		// this.mainBeamLengths = [];
		// this.childBeamLengths = [];
		this.beamLengths = [];

		// let useMainBeamFallBacks;
		// let useChildBeamFallBacks;

		// Mainbeams lengtes defieniëren zodat er in de addDrawobjects gerekend word met deze lengtes.
		this.mainBeams.forEach((mainBeam, index) => {
			Configuration.CURRENT.startLoading3dAsset();
			Store.CURRENT.profileModels.getById(
				mainBeam.oid,
				true,
				(response) => {
					if (response.model !== null && response.model.length > 0) {
						response.model.forEach((model, index) => {
							// lengte ingevoerd maar geen model erbij
							if (model.model !== null) {
								Canvas3D.CURRENT.addAsset(new ProfileAsset3D(mainBeam.description, mainBeam.oid));
								// Model vanuit ERP
								this.beamLengths.push({
									oid: mainBeam.oid,
									length: model.length,
								});
							}
						});
					} else {
						// Er zijn geen mainbeams vanuit ERP beschikbaar dus laden we de fallbacks in.
						// this.mainBeamLengths = [350, 50];
						// useMainBeamFallBacks = true;
					}
					Configuration.CURRENT.stopLoading3dAsset();
				},
				(error) => {
					console.log(error);
					Configuration.CURRENT.stopLoading3dAsset();
				},
				this.mainBeam.description,
			);
		});

		this.childBeams.forEach((childBeam, index) => {
			Configuration.CURRENT.startLoading3dAsset();
			Store.CURRENT.profileModels.getById(
				childBeam.oid,
				true,
				(response) => {
					if (response.model !== null && response.model.length > 0) {
						response.model.forEach((model, index) => {
							// lengte ingevoerd maar geen model erbij
							if (model.model !== null) {
								Canvas3D.CURRENT.addAsset(new ProfileAsset3D(childBeam.description, childBeam.oid));
								// Model vanuit ERP
								this.beamLengths.push({
									oid: childBeam.oid,
									length: model.length,
								});
							}
						});
					} else {
						// Er zijn geen childbeams vanuit ERP beschikbaar dus laad fallback in.
						// this.childBeamLengths = [350, 50];
						// useChildBeamFallBacks = true;
					}
					Configuration.CURRENT.stopLoading3dAsset();
				},
				(error) => {
					console.log(error);
					Configuration.CURRENT.stopLoading3dAsset();
				},
				this.childBeam.description,
			);
		});
		if (this.torsionalSupport === true) {
			Canvas3D.CURRENT.addAsset(new ModelAsset3D(this.torsionalSupportArticle?.description, this.torsionalSupportArticle?.article.oid));
		}
	}
	addDrawObjects3d(canvas3D, raster, activeEtage, activeEtageIndex) {
		let startDrawHeight = 0;
		let amountofEtages = Configuration.CURRENT.etages.length;
		let multipleFloors = amountofEtages > 1;

		// Modeldata ophalen, we gebruiken hier lengths[0] omdat de profielen alleen verschillen van lengte en hoogte en diepte hetzelfde zijn.
		let modelParams = {
			columnWidth: Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width'),
			gussetplateWidth: Canvas3D.CURRENT.getModelDataByOid(this.mainBeams[0].gussetPlate.oid, 'width'),
			gussetplateDepth: Canvas3D.CURRENT.getModelDataByOid(this.mainBeams[0].gussetPlate.oid, 'depth'),
		};

		Configuration.CURRENT.etages.getEtages().forEach((etage, index) => {
			let drawHeight = startDrawHeight + etage.getHeight(true);
			switch (this.mainBeamDirection) {
				case Profiles.MB_HORIZONTAL:
					this.addMainBeamsHorizontal3d(canvas3D, raster, etage, drawHeight, amountofEtages, multipleFloors, modelParams);
					this.addChildBeamVertical3d(canvas3D, raster, etage, drawHeight, amountofEtages, multipleFloors, modelParams);
					break;
				case Profiles.MB_VERTICAL:
					this.addMainBeamsVertical3d(canvas3D, raster, etage, drawHeight, amountofEtages, multipleFloors, modelParams);
					this.addChildBeamsHorizontal3d(canvas3D, raster, etage, drawHeight, amountofEtages, multipleFloors, modelParams);
					break;
			}
			startDrawHeight += etage.getHeight(true);
		});
	}
	getLengthInterruptions(startX, startY, endX, endY, direction, etage, etageHeight) {
		// Create object
		let profilePosition = { startX, startY, endX, endY };
		let lengthInterruptions = [];

		Configuration.CURRENT.buildingColumns.findByProfilePosition(profilePosition, etageHeight).forEach((bc) => {
			let bcPosition = bc.getEdgePosition();
			lengthInterruptions.push(bcPosition);
		});

		etage.holes.findByProfilePosition(profilePosition).forEach((hole) => {
			let holePosition = hole.getEdgePosition();
			lengthInterruptions.push(holePosition);
		});

		etage.stairs.findByProfilePosition(profilePosition).forEach((stair) => {
			let stairPosition = stair.getPositionProfiles();
			lengthInterruptions.push(stairPosition);
		});

		lengthInterruptions.sort((a, b) => {
			// sorteren in juiste volgorde van links naar rechts
			if (a.startX === a.endX) {
				if (a.startY < b.startY) {
					return -1;
				}
				if (a.startY > b.startY) {
					return 1;
				}
				return 0;
			} else {
				if (a.startX < b.startX) {
					return -1;
				}
				if (a.startX > b.startX) {
					return 1;
				}
				return 0;
			}
		});

		return lengthInterruptions;
	}

	addMainBeamsHorizontal3d(canvas3d, raster, etage, etageHeight, amountofEtages, multipleFloors, modelParams) {
		let interruptions = [];
		raster.spansX.getSpans().forEach((spanX, indexX) => {
			let mainBeamHeight = Canvas3D.CURRENT.getBeamData(spanX.beam.oid, 'height');
			let mainBeamDepth = Canvas3D.CURRENT.getBeamData(spanX.beam.oid, 'depth');
			raster.spansY.getSpans().forEach((spanY, indexY) => {
				if (etage.isActiveRaster(new RemoveRaster(indexX, indexY)) === true) {
					let rowData = raster.getRowPositions(indexX, indexY);
					let rowPositions = rowData.rowPositions;
					let placement = rowData.placement;

					let drawPositionTop = {
						x: 0,
						y: 0,
					};
					let drawPositionTopEnd = {
						x: 0,
						y: 0,
					};

					let drawPositionBottom = {
						x: 0,
						y: 0,
					};
					let drawPositionBottomEnd = {
						x: 0,
						y: 0,
					};

					drawPositionTop.x = raster.getSizeX(indexX - 1);
					drawPositionTop.y = raster.getSizeY(indexY - 1);

					drawPositionTopEnd.x = raster.getSizeX(indexX);
					drawPositionTopEnd.y = raster.getSizeY(indexY - 1) + mainBeamDepth;

					drawPositionBottom.x = raster.getSizeX(indexX - 1);
					drawPositionBottom.y = raster.getSizeY(indexY);
					drawPositionBottomEnd.x = raster.getSizeX(indexX);
					drawPositionBottomEnd.y = raster.getSizeY(indexY);

					if (multipleFloors) {
						drawPositionTop.y += modelParams.columnWidth;
						drawPositionTopEnd.y += modelParams.columnWidth;
						drawPositionBottomEnd.y += modelParams.columnWidth;
					}

					if (!etage.isActiveRaster(new RemoveRaster(indexX + 1, indexY)) && indexX + 1 < raster.spansX.getSpans().length) {
						// wanneer het volgende raster niet actief is zorg ervoor dat de kolom bedekt is (dus halve gussetplate) toevoegen aan eindpositie
						drawPositionTopEnd.x += modelParams.gussetplateWidth / 2;
						drawPositionBottomEnd.x += modelParams.gussetplateWidth / 2;
					}

					if (!etage.isActiveRaster(new RemoveRaster(indexX - 1, indexY)) && indexX > 0) {
						// wanneer het vorige raster niet actief is zorg ervoor dat de kolom bedekt is (dus halve gussetplate) van startpositie halen
						drawPositionTop.x -= modelParams.gussetplateWidth / 2;
						drawPositionBottom.x -= modelParams.gussetplateWidth / 2;
					}

					let objTop = {
						startX: drawPositionTop.x,
						startY: drawPositionTop.y,
						endX: drawPositionTopEnd.x,
						endY: drawPositionTopEnd.y,
						interruptions: this.getLengthInterruptions(drawPositionTop.x, drawPositionTop.y, drawPositionTopEnd.x, drawPositionTopEnd.y, Profiles.MB_HORIZONTAL, etage, etageHeight),
						rowPositions: rowPositions,
						placement: placement,
						name: 'top',
						indexX: indexX,
						indexY: indexY,
						beamOid: spanX.beam.oid,
						mainBeamHeight: mainBeamHeight,
						mainBeamDepth: mainBeamDepth,
						heartLineDistance: this.getDistanceFromBackToHeartLine(spanX.beam.oid),
					};
					interruptions.push(objTop);

					let objBottom = {
						startX: drawPositionBottom.x,
						startY: drawPositionBottom.y,
						endX: drawPositionBottomEnd.x,
						endY: drawPositionBottomEnd.y,
						interruptions: this.getLengthInterruptions(drawPositionBottom.x, drawPositionBottom.y, drawPositionBottomEnd.x, drawPositionBottomEnd.y, Profiles.MB_HORIZONTAL, etage, etageHeight),
						rowPositions: rowPositions,
						placement: placement,
						name: 'bottom',
						indexX: indexX,
						indexY: indexY,
						beamOid: spanX.beam.oid,
						mainBeamHeight: mainBeamHeight,
						mainBeamDepth: mainBeamDepth,
						heartLineDistance: this.getDistanceFromBackToHeartLine(spanX.beam.oid),
					};

					interruptions.push(objBottom);
				}
			});
		});

		let paramsObject = {
			drawHeight: etageHeight,
			profileType: 'mainbeam',
			beamdirection: this.mainBeamDirection,
			doubleDouble: this.doubleDouble,
			ralColor: Configuration.CURRENT.colors.profiles.ralColor,
			etageId: etage.id,
		};

		let deckingFinishHeight = Configuration.CURRENT.finish.height;
		let drawHeightTop;
		let drawHeightBottom;
		interruptions.forEach((item) => {
			paramsObject.drawHeight = etageHeight;
			paramsObject.drawHeight -= deckingFinishHeight;
			drawHeightTop = paramsObject.drawHeight - item.mainBeamHeight;
			drawHeightBottom = paramsObject.drawHeight;

			paramsObject.oid = item.beamOid;
			paramsObject.rowPositions = item.rowPositions;
			paramsObject.placement = item.placement;
			paramsObject.indexX = item.indexX;
			paramsObject.indexY = item.indexY;
			paramsObject.heartLineDistance = item.heartLineDistance;

			if (item.interruptions.length > 0) {
				if (item.startX < item.interruptions[0].startX) {
					if (item.name === 'top') {
						paramsObject.drawHeight = drawHeightTop;
						new HorizontalMainBeamTop3D(item.startX, item.startY, item.interruptions[0].startX - item.startX, paramsObject);
						if (this.doubleDouble) {
							new HorizontalMainBeamTop3D(item.startX, item.startY + item.mainBeamDepth, item.interruptions[0].startX - item.startX, paramsObject);
						}
					} else {
						paramsObject.drawHeight = drawHeightBottom;
						new HorizontalMainBeamBottom3D(item.startX, item.startY, item.interruptions[0].startX - item.startX, paramsObject);
						if (this.doubleDouble) {
							new HorizontalMainBeamBottom3D(item.startX, item.startY - item.mainBeamDepth, item.interruptions[0].startX - item.startX, paramsObject);
						}
					}
				}
				if (item.interruptions.length > 1) {
					// meer dan 1 tussen interuptions checken
					for (let i = 0; i < item.interruptions.length - 1; i++) {
						if (item.interruptions[i].endX < item.interruptions[i + 1].startX) {
							if (item.name === 'top') {
								paramsObject.drawHeight = drawHeightTop;
								new HorizontalMainBeamTop3D(item.interruptions[i].endX, item.startY, item.interruptions[i + 1].startX - item.interruptions[i].endX, paramsObject);
								if (this.doubleDouble) {
									new HorizontalMainBeamTop3D(item.interruptions[i].endX, item.startY + item.mainBeamDepth, item.interruptions[i + 1].startX - item.interruptions[i].endX, paramsObject);
								}
							} else {
								paramsObject.drawHeight = drawHeightBottom;
								new HorizontalMainBeamBottom3D(item.interruptions[i].endX, item.startY, item.interruptions[i + 1].startX - item.interruptions[i].endX, paramsObject);
								if (this.doubleDouble) {
									new HorizontalMainBeamBottom3D(item.interruptions[i].endX, item.startY - item.mainBeamDepth, item.interruptions[i + 1].startX - item.interruptions[i].endX, paramsObject);
								}
							}
						}
					}
				}

				if (item.interruptions[item.interruptions.length - 1].endX < item.endX) {
					if (item.name === 'top') {
						paramsObject.drawHeight = drawHeightTop;
						new HorizontalMainBeamTop3D(item.interruptions[item.interruptions.length - 1].endX, item.startY, item.endX - item.interruptions[item.interruptions.length - 1].endX, paramsObject);
						if (this.doubleDouble) {
							new HorizontalMainBeamTop3D(
								item.interruptions[item.interruptions.length - 1].endX,
								item.startY + item.mainBeamDepth,
								item.endX - item.interruptions[item.interruptions.length - 1].endX,
								paramsObject,
							);
						}
					} else {
						paramsObject.drawHeight = drawHeightBottom;
						new HorizontalMainBeamBottom3D(item.interruptions[item.interruptions.length - 1].endX, item.startY, item.endX - item.interruptions[item.interruptions.length - 1].endX, paramsObject);
						if (this.doubleDouble) {
							new HorizontalMainBeamBottom3D(
								item.interruptions[item.interruptions.length - 1].endX,
								item.startY - item.mainBeamDepth,
								item.endX - item.interruptions[item.interruptions.length - 1].endX,
								paramsObject,
							);
						}
					}
				}
			} else if (item.name === 'top') {
				paramsObject.drawHeight = drawHeightTop;
				new HorizontalMainBeamTop3D(item.startX, item.startY, item.endX - item.startX, paramsObject);
				if (this.doubleDouble) {
					new HorizontalMainBeamTop3D(item.startX, item.startY + item.mainBeamDepth, item.endX - item.startX, paramsObject);
				}
			} else {
				paramsObject.drawHeight = drawHeightBottom;
				new HorizontalMainBeamBottom3D(item.startX, item.startY, item.endX - item.startX, paramsObject);
				if (this.doubleDouble) {
					new HorizontalMainBeamBottom3D(item.startX, item.startY - item.mainBeamDepth, item.endX - item.startX, paramsObject);
				}
			}
		});
	}

	addMainBeamsVertical3d(canvas3d, raster, etage, etageHeight, amountofEtages, multipleFloors, modelParams) {
		let interuptions = [];
		raster.spansX.getSpans().forEach((spanX, indexX) => {
			raster.spansY.getSpans().forEach((spanY, indexY) => {
				// kijk of het raster actief is anders geen hoofdbalken tekenen
				if (etage.isActiveRaster(new RemoveRaster(indexX, indexY)) === true) {
					let rowData = raster.getRowPositions(indexX, indexY);
					let rowPositions = rowData.rowPositions;
					let placement = rowData.placement;

					let drawPositionLeft = {
						x: raster.getSizeX(indexX - 1),
						y: raster.getSizeY(indexY - 1),
					};

					let drawPositionLeftEnd = {
						x: raster.getSizeX(indexX - 1),
						y: raster.getSizeY(indexY),
					};

					let drawPositionRight = {
						x: raster.getSizeX(indexX),
						y: raster.getSizeY(indexY - 1),
					};

					let drawPositionRightEnd = {
						x: raster.getSizeX(indexX),
						y: raster.getSizeY(indexY),
					};

					if (multipleFloors) {
						if (indexX === 0) {
							drawPositionLeft.x = drawPositionLeft.x;
						} else {
							drawPositionLeft.x += modelParams.columnWidth / 2;
						}
						drawPositionLeftEnd.x += modelParams.columnWidth / 2;
						drawPositionRight.x += modelParams.columnWidth / 2;
						drawPositionRightEnd.x += modelParams.columnWidth / 2;
					}

					if (!etage.isActiveRaster(new RemoveRaster(indexX, indexY + 1)) && indexY + 1 < raster.spansY.getSpans().length) {
						// wanneer het volgende raster niet actief is, zorg ervoor dat de gussetplate is bedekt daarom eindpositie + halve gussetplate
						drawPositionLeftEnd.y += modelParams.gussetplateWidth / 2;
						drawPositionLeftEnd.y += modelParams.gussetplateWidth / 2;
					}

					if (!etage.isActiveRaster(new RemoveRaster(indexX, indexY - 1)) && indexY > 0) {
						// wanneer het vorige raster niet actief is, zorg ervoor dat de gussetplate is bedekt startpositie daarom - halve gussetplate
						drawPositionLeft.y -= modelParams.gussetplateWidth / 2;
						drawPositionRight.y -= modelParams.gussetplateWidth / 2;
					}

					let objLeft = {
						startX: drawPositionLeft.x,
						startY: drawPositionLeft.y,
						endX: drawPositionLeftEnd.x,
						endY: drawPositionLeftEnd.y,
						interruptions: this.getLengthInterruptions(drawPositionLeft.x, drawPositionLeft.y, drawPositionLeftEnd.x, drawPositionLeftEnd.y, Profiles.MB_VERTICAL, etage, etageHeight),
						rowPositions: rowPositions,
						placement: placement,
						name: 'left',
						indexX: indexX,
						indexY: indexY,
						beamOid: spanY.beam.oid,
						mainBeamHeight: Canvas3D.CURRENT.getBeamData(raster.spansY.getSpans()[indexY].beam.oid, 'height'),
						mainBeamDepth: Canvas3D.CURRENT.getBeamData(raster.spansY.getSpans()[indexY].beam.oid, 'depth'),
						heartLineDistance: this.getDistanceFromBackToHeartLine(spanY.beam.oid),
					};
					interuptions.push(objLeft);
					let objRight = {
						startX: drawPositionRight.x,
						startY: drawPositionRight.y,
						endX: drawPositionLeftEnd.x,
						endY: drawPositionLeftEnd.y,
						interruptions: this.getLengthInterruptions(drawPositionRight.x, drawPositionRight.y, drawPositionRightEnd.x, drawPositionRightEnd.y, Profiles.MB_VERTICAL, etage, etageHeight),
						rowPositions: rowPositions,
						placement: placement,
						name: 'right',
						indexX: indexX,
						indexY: indexY,
						beamOid: spanY.beam.oid,
						mainBeamHeight: Canvas3D.CURRENT.getBeamData(raster.spansY.getSpans()[indexY].beam.oid, 'height'),
						mainBeamDepth: Canvas3D.CURRENT.getBeamData(raster.spansY.getSpans()[indexY].beam.oid, 'depth'),
						heartLineDistance: this.getDistanceFromBackToHeartLine(spanY.beam.oid),
					};
					interuptions.push(objRight);
				}
			});
		});

		let paramsObject = {
			drawHeight: etageHeight,
			multipleFloors: multipleFloors,
			profileType: 'mainbeam',
			beamdirection: this.mainBeamDirection,
			doubleDouble: this.doubleDouble,
			ralColor: Configuration.CURRENT.colors.profiles.ralColor,
			etageId: etage.id,
		};

		let deckingFinishHeight = Configuration.CURRENT.finish.height;
		let drawHeightRight;
		let drawHeightLeft;
		interuptions.forEach((item) => {
			paramsObject.drawHeight = etageHeight;
			paramsObject.drawHeight -= deckingFinishHeight;
			drawHeightRight = paramsObject.drawHeight - item.mainBeamHeight;
			drawHeightLeft = paramsObject.drawHeight;

			paramsObject.oid = item.beamOid;
			paramsObject.rowPositions = item.rowPositions;
			paramsObject.placement = item.placement;
			paramsObject.indexX = item.indexX;
			paramsObject.indexY = item.indexY;
			paramsObject.heartLineDistance = item.heartLineDistance;
			if (item.interruptions.length > 0) {
				if (item.startY < item.interruptions[0].startY) {
					if (item.name === 'right') {
						paramsObject.drawHeight = drawHeightRight;
						new VerticalMainBeamRight3D(item.startX, item.startY, item.interruptions[0].startY - item.startY, paramsObject);
						if (this.doubleDouble) {
							new VerticalMainBeamRight3D(item.startX - item.mainBeamDepth, item.startY, item.interruptions[0].startY - item.startY, paramsObject);
						}
					} else {
						paramsObject.drawHeight = drawHeightLeft;
						new VerticalMainBeamLeft3D(item.startX, item.startY, item.interruptions[0].startY - item.startY, paramsObject);
						if (this.doubleDouble) {
							new VerticalMainBeamLeft3D(item.startX + item.mainBeamDepth, item.startY, item.interruptions[0].startY - item.startY, paramsObject);
						}
					}
				}
				if (item.interruptions.length > 1) {
					// meer dan 1 tussen interuptions checken
					for (let i = 0; i < item.interruptions.length - 1; i++) {
						if (item.interruptions[i].endY < item.interruptions[i + 1].startY) {
							if (item.name === 'right') {
								paramsObject.drawHeight = drawHeightRight;
								new VerticalMainBeamRight3D(item.interruptions[i].endX, item.startY, item.interruptions[i + 1].startY - item.interruptions[i].endY, paramsObject);
								if (this.doubleDouble) {
									new VerticalMainBeamRight3D(item.interruptions[i].endX - item.mainBeamDepth, item.startY, item.interruptions[i + 1].startY - item.interruptions[i].endY, paramsObject);
								}
							} else {
								paramsObject.drawHeight = drawHeightLeft;
								new VerticalMainBeamLeft3D(item.interruptions[i].endX, item.startY, item.interruptions[i + 1].startY - item.interruptions[i].endY, paramsObject);
								if (this.doubleDouble) {
									new VerticalMainBeamLeft3D(item.interruptions[i].endX + item.mainBeamDepth, item.startY, item.interruptions[i + 1].startY - item.interruptions[i].endY, paramsObject);
								}
							}
						}
					}
				}
				if (item.interruptions[item.interruptions.length - 1].endY < item.endY) {
					if (item.name === 'right') {
						paramsObject.drawHeight = drawHeightRight;
						new VerticalMainBeamRight3D(item.startX, item.interruptions[item.interruptions.length - 1].endY, item.endY - item.interruptions[item.interruptions.length - 1].endY, paramsObject);
						if (this.doubleDouble) {
							new VerticalMainBeamRight3D(
								item.startX - item.mainBeamDepth,
								item.interruptions[item.interruptions.length - 1].endY,
								item.endY - item.interruptions[item.interruptions.length - 1].endY,
								paramsObject,
							);
						}
					} else {
						paramsObject.drawHeight = drawHeightLeft;
						new VerticalMainBeamLeft3D(item.startX, item.interruptions[item.interruptions.length - 1].endY, item.endY - item.interruptions[item.interruptions.length - 1].endY, paramsObject);
						if (this.doubleDouble) {
							new VerticalMainBeamLeft3D(
								item.startX + item.mainBeamDepth,
								item.interruptions[item.interruptions.length - 1].endY,
								item.endY - item.interruptions[item.interruptions.length - 1].endY,
								paramsObject,
							);
						}
					}
				}
			} else if (item.name === 'right') {
				paramsObject.drawHeight = drawHeightRight;
				new VerticalMainBeamRight3D(item.startX, item.startY, item.endY - item.startY, paramsObject);
				if (this.doubleDouble) {
					new VerticalMainBeamRight3D(item.startX - item.mainBeamDepth, item.startY, item.endY - item.startY, paramsObject);
				}
			} else {
				paramsObject.drawHeight = drawHeightLeft;
				new VerticalMainBeamLeft3D(item.startX, item.startY, item.endY - item.startY, paramsObject);
				if (this.doubleDouble) {
					new VerticalMainBeamLeft3D(item.startX + item.mainBeamDepth, item.startY, item.endY - item.startY, paramsObject);
				}
			}
		});
	}

	addChildBeamVertical3d(canvas3d, raster, etage, etageHeight, amountofEtages, multipleFloors, modelParams) {
		const childBeamCount = Math.ceil(raster.getSizeX() / this._centerToCenter) + 1; // +1 begin beam

		let startY = 0;
		let previousRaster = -1;
		let interuptions = [];
		raster.spansY.getSpans().forEach((spanY, indexY) => {
			let childBeamDepth = Canvas3D.CURRENT.getBeamData(spanY.beam.oid, 'depth');
			let rowData = raster.getRowPositions(0, indexY);
			let rowPositions = rowData.rowPositions;
			let placement = rowData.placement;
			for (let childBeam = 0; childBeam < childBeamCount; childBeam++) {
				const childBeamPosition = childBeam * this._centerToCenter - childBeamDepth;
				const rasterX = raster.spansX.getRaster(childBeamPosition);

				if (etage.isActiveRaster(new RemoveRaster(rasterX, indexY))) {
					let drawPositionStart = {
						y: 0,
					};
					let drawPositionEnd = {
						y: 0,
					};
					drawPositionStart.y = raster.getSizeY(indexY - 1);
					drawPositionEnd.y = raster.getSizeY(indexY);

					if (multipleFloors) {
						if (indexY === 0) {
							drawPositionEnd.y += modelParams.columnWidth / 2;
						} else if (indexY === raster.spansY.getSpans().length - 1) {
							drawPositionStart.y += modelParams.columnWidth / 2;
							drawPositionEnd.y += modelParams.columnWidth;
						} else {
							drawPositionStart.y += modelParams.columnWidth / 2;
							drawPositionEnd.y += modelParams.columnWidth / 2;
						}
					}

					let obj = {
						startX: childBeamPosition + (Profiles.KPPSTARTOFFSET - this.getDistanceFromBackToHeartLine(spanY.beam.oid)),
						startY: drawPositionStart.y,
						endX: childBeamPosition,
						endY: drawPositionEnd.y,
						interruptions: this.getLengthInterruptions(childBeamPosition, drawPositionStart.y, childBeamPosition + childBeamDepth, drawPositionEnd.y, Profiles.MB_VERTICAL, etage, etageHeight),
						rowPositions: rowPositions,
						placement: placement,
						indexY: indexY,
						indexX: rasterX,
						beamOid: spanY.beam.oid,
						mainBeamDepth: Canvas3D.CURRENT.getBeamData(raster.spansX.getSpans()[rasterX].beam.oid, 'depth'),
						mainBeamHeight: Canvas3D.CURRENT.getBeamData(raster.spansX.getSpans()[rasterX].beam.oid, 'height'),
						heartLineDistance: this.getDistanceFromBackToHeartLine(spanY.beam.oid),
						childBeamId: childBeam,
						isLast: childBeam === childBeamCount - 1,
					};
					interuptions.push(obj);

					var positionBeamOne = null;
					if (rasterX > 0 && !etage.isActiveRaster(new RemoveRaster(rasterX - 1, indexY)) && childBeamPosition - raster.getSizeX(rasterX - 1) < this._centerToCenter) {
						// als voorgaande raster niet actief  en "1e" childbeam (komt later nog een andere 1e voor) en deze komt niet aan het begin van het raster  dan vanaf begin raster rekenen
						positionBeamOne = raster.getSizeX(rasterX - 1) + this.getDistanceFromBackToHeartLine(spanY.beam.oid) + Canvas3D.CURRENT.getBeamData(spanY.beam.oid, 'thickness');
					}

					this.addHorizontalTorisonalSupport(childBeam, spanY.value / 3, spanY.beam.oid, drawPositionStart.y, etageHeight, canvas3d, positionBeamOne);
				}
				previousRaster = rasterX;
			}

			startY += spanY.value;
		});

		// UITZONDERINGEN
		raster.spansX.getSpans().forEach((spanX, indexX) => {
			raster.spansY.getSpans().forEach((spanY, indexY) => {
				let mainBeamDepth = Canvas3D.CURRENT.getBeamData(spanX.beam.oid, 'depth');
				let mainBeamHeight = Canvas3D.CURRENT.getBeamData(spanX.beam.oid, 'height');
				let rowData = raster.getRowPositions(indexX, indexY);
				let rowPositions = rowData.rowPositions;
				let placement = rowData.placement;
				let childBeamDepth = Canvas3D.CURRENT.getBeamData(spanY.beam.oid, 'depth');
				let childBeamHeight = Canvas3D.CURRENT.getBeamData(spanY.beam.oid, 'height');
				if (etage.isActiveRaster(new RemoveRaster(indexX, indexY)) === true) {
					if (indexX > 0 && etage.isActiveRaster(new RemoveRaster(indexX - 1, indexY)) === false) {
						//* * als voorgaande niet actief altijd extra profiel aan begin toevoegen. Namelijk 2 opties:
						//* * 1. profiel valt precies op de maat (hoort dus bij vorige raster)
						//* * of valt over de maat. Nu geen rekening met offset maar aan begin is dan geen profiel
						let beginX = raster.getSizeX(indexX - 1) - childBeamDepth;
						let beginY = raster.getSizeY(indexY - 1);
						let endY = raster.getSizeY(indexY);

						if (multipleFloors) {
							if (indexY === raster.spansY.getSpans().length - 1) {
								endY += modelParams.columnWidth;
							} else if (indexY !== 0) {
								beginY += modelParams.columnWidth / 2;
								endY += modelParams.columnWidth / 2;
							} else {
								endY += modelParams.columnWidth / 2;
							}
						}

						let obj = {
							startX: beginX + (Profiles.KPPSTARTOFFSET - this.getDistanceFromBackToHeartLine(spanY.beam.oid)),
							startY: beginY,
							endX: beginX + childBeamDepth,
							endY: endY,
							rowPositions: rowPositions,
							placement: placement,
							indexY: indexY,
							indexX: indexX,
							interruptions: this.getLengthInterruptions(beginX, beginY, beginX + childBeamDepth, endY, Profiles.MB_VERTICAL, etage, etageHeight),
							beamOid: spanY.beam.oid,
							mainBeamDepth: mainBeamDepth,
							mainBeamHeight: mainBeamHeight,
							heartLineDistance: this.getDistanceFromBackToHeartLine(spanY.beam.oid),
						};
						interuptions.push(obj);
					}
					if (indexX + 1 === raster.spansX.length && raster.getSizeX(indexX) % this.centerToCenter > 0) {
						//* * Als bij laatste raster nog lengte rasters is niet deelbaar door centerToCenter over is dan extra profiel
						mainBeamDepth = Canvas3D.CURRENT.getBeamData(spanX.beam.oid, 'depth');
						mainBeamHeight = Canvas3D.CURRENT.getBeamData(spanX.beam.oid, 'height');
						let beginX = raster.getSizeX(indexX) - childBeamDepth;
						let beginY = raster.getSizeY(indexY - 1);
						let endY = raster.getSizeY(indexY);

						if (multipleFloors) {
							if (indexY === raster.spansY.getSpans().length - 1) {
								endY += modelParams.columnWidth;
							} else if (indexY !== 0) {
								beginY += modelParams.columnWidth / 2;
								endY += modelParams.columnWidth / 2;
							} else {
								endY += modelParams.columnWidth / 2;
							}
						}
						let obj = {
							startX: beginX - (Profiles.KPPSTARTOFFSET - this.getDistanceFromBackToHeartLine(spanY.beam.oid)),
							startY: beginY,
							endX: beginX,
							endY: endY,
							rowPositions: rowPositions,
							placement: placement,
							indexY: indexY,
							indexX: indexX,
							interruptions: this.getLengthInterruptions(beginX, beginY, beginX + childBeamDepth, endY, Profiles.MB_VERTICAL, etage, etageHeight),
							beamOid: spanY.beam.oid,
							mainBeamDepth: mainBeamDepth,
							mainBeamHeight: mainBeamHeight,
							heartLineDistance: this.getDistanceFromBackToHeartLine(spanY.beam.oid),
							isLast: true,
						};
						interuptions.push(obj);
						//positionBeamTwo is niet ctc afstand (want is hier uitzondering) die dus meegeven
						this.addHorizontalTorisonalSupport(
							childBeamCount - 1,
							spanY.value / 3,
							spanY.beam.oid,
							beginY,
							etageHeight,
							canvas3d,
							null,
							beginX - (Profiles.KPPSTARTOFFSET - this.getDistanceFromBackToHeartLine(spanY.beam.oid)),
						);
					}
					if (indexX + 1 < raster.spansX.length && etage.isActiveRaster(new RemoveRaster(indexX + 1, indexY)) === false && raster.getSizeX(indexX) % this.centerToCenter > 0) {
						// LET OP: DEZE IS GEROTEERD OMDAT HET DE EERSTE IS
						//* * Als nog niet laatste en volgende raster is niet actief en lengte inclusief huidige raster is niet deelbaar door centerToCenter dan extra profiel
						mainBeamDepth = Canvas3D.CURRENT.getBeamData(spanX.beam.oid, 'depth');
						mainBeamHeight = Canvas3D.CURRENT.getBeamData(spanX.beam.oid, 'height');
						let beginX = raster.getSizeX(indexX);
						let beginY = raster.getSizeY(indexY - 1);
						let endY = raster.getSizeY(indexY);

						if (multipleFloors) {
							if (indexY === raster.spansY.getSpans().length - 1) {
								endY += modelParams.columnWidth;
							} else if (indexY !== 0) {
								beginY += modelParams.columnWidth / 2;
								endY += modelParams.columnWidth / 2;
							} else {
								endY += modelParams.columnWidth / 2;
							}
						}

						let obj = {
							startX: beginX - childBeamDepth - (Profiles.KPPSTARTOFFSET - this.getDistanceFromBackToHeartLine(spanY.beam.oid)),
							startY: beginY,
							endX: beginX + childBeamDepth,
							endY: endY,
							rowPositions: rowPositions,
							placement: placement,
							indexY: indexY,
							indexX: indexX,
							interruptions: this.getLengthInterruptions(beginX, beginY, beginX + childBeamDepth, endY, Profiles.MB_VERTICAL, etage, etageHeight),
							beamOid: spanY.beam.oid,
							mainBeamDepth: mainBeamDepth,
							mainBeamHeight: mainBeamHeight,
							heartLineDistance: this.getDistanceFromBackToHeartLine(spanY.beam.oid),
							isLast: true,
						};
						interuptions.push(obj);
					}
				}
			});
		});

		let paramsObject = {
			drawHeight: etageHeight,
			multipleFloors: multipleFloors,
			profileType: 'childbeam',
			beamdirection: this.mainBeamDirection,
			doubleDouble: this.doubleDouble,
			ralColor: Configuration.CURRENT.colors.profiles.ralColor,
			etageId: etage.id,
		};

		interuptions.forEach((item, index) => {
			paramsObject.oid = item.beamOid;
			paramsObject.rowPositions = item.rowPositions;
			paramsObject.placement = item.placement;
			paramsObject.indexX = item.indexX;
			paramsObject.indexY = item.indexY;
			paramsObject.loopIndex = index;
			paramsObject.exception = typeof item.exception === 'undefined' ? false : true;
			paramsObject.heartLineDistance = item.heartLineDistance;
			paramsObject.isLast = typeof item.isLast !== 'undefined' ? item.isLast : false;
			paramsObject.mainBeamDepth = item.mainBeamDepth;
			paramsObject.mainBeamHeight = item.mainBeamHeight;
			if (item.interruptions.length > 0) {
				if (item.startY < item.interruptions[0].startY) {
					new VerticalChildBeam3D(item.startX, item.startY, item.interruptions[0].startY - item.startY, paramsObject);
				}
				if (item.interruptions.length > 1) {
					for (let i = 0; i < item.interruptions.length - 1; i++) {
						if (item.interruptions[i].endY < item.interruptions[i + 1].startY) {
							new VerticalChildBeam3D(item.interruptions[i].endX, item.startY, item.interruptions[i + 1].startY - item.interruptions[i].endY, paramsObject);
						}
					}
				}
				if (item.interruptions[item.interruptions.length - 1].endY < item.endY) {
					new VerticalChildBeam3D(item.startX, item.interruptions[item.interruptions.length - 1].endY, item.endY - item.interruptions[item.interruptions.length - 1].endY, paramsObject);
				}
			} else {
				new VerticalChildBeam3D(item.startX, item.startY, item.endY - item.startY, paramsObject);
			}
		});
	}

	addChildBeamsHorizontal3d(canvas3d, raster, etage, etageHeight, amountofEtages, multipleFloors, modelParams) {
		const childBeamCount = Math.ceil(raster.getSizeY() / this._centerToCenter) + 1;

		let startX = 0;
		let previousRaster = -1;
		let interruptions = [];
		raster.spansX.getSpans().forEach((spanX, indexX) => {
			let rowData = raster.getRowPositions(indexX, 0);
			let childBeamDepth = Canvas3D.CURRENT.getBeamData(spanX.beam.oid, 'depth');
			for (let childBeam = 0; childBeam < childBeamCount; childBeam++) {
				const childBeamPosition = childBeam * this._centerToCenter;
				const rasterY = raster.spansY.getRaster(childBeamPosition);
				if (etage.isActiveRaster(new RemoveRaster(indexX, rasterY))) {
					let drawPositionStart = Configuration.CURRENT.columns.getPosition(indexX, 0);

					if (multipleFloors) {
						if (indexX !== 0) {
							drawPositionStart.x += modelParams.columnWidth / 2;
						}
					}

					let obj = {
						startX: drawPositionStart.x,
						startY: childBeamPosition + (Profiles.KPPSTARTOFFSET - this.getDistanceFromBackToHeartLine(spanX.beam.oid)),
						endX: drawPositionStart.x + spanX.value,
						endY: childBeamPosition + childBeamDepth,
						interruptions: this.getLengthInterruptions(
							drawPositionStart.x,
							childBeamPosition,
							drawPositionStart.x + spanX.value,
							childBeamPosition + childBeamDepth,
							Profiles.MB_HORIZONTAL,
							etage,
							etageHeight,
						),
						rowPositions: rowData.rowPositions,
						place: rowData.placement,
						indexY: rasterY,
						indexX: indexX,
						beamOid: spanX.beam.oid,
						mainBeamHeight: Canvas3D.CURRENT.getBeamData(raster.spansY.getSpans()[rasterY].beam.oid, 'height'),
						mainBeamDepth: Canvas3D.CURRENT.getBeamData(raster.spansY.getSpans()[rasterY].beam.oid, 'depth'),
						heartLineDistance: this.getDistanceFromBackToHeartLine(spanX.beam.oid),
						isLast: childBeam === childBeamCount - 1,
					};

					interruptions.push(obj);
					//als torsionalsupport dan per kinderbalk toevoegen. Vanaf de tweede naar de voorliggende
					var positionBeamOne = null;
					if (rasterY > 0 && !etage.isActiveRaster(new RemoveRaster(indexX, rasterY - 1)) && childBeamPosition - raster.getSizeY(rasterY - 1) < this._centerToCenter) {
						//als voorgaande raster niet actief  en "1e" childbeam (komt later nog een andere 1e voor) en deze komt niet aan het begin van het raster  dan vanaf begin raster rekenen
						positionBeamOne = raster.getSizeY(rasterY - 1) + this.getDistanceFromBackToHeartLine(spanX.beam.oid) + Canvas3D.CURRENT.getBeamData(spanX.beam.oid, 'thickness'); //TODO: Hij lijkt hier niet helemaal met het raster mee te lopen nog uitzoeken
					}

					this.addVerticalTorsionalSupport(childBeam, spanX.value / 3, spanX.beam.oid, drawPositionStart.x, etageHeight, canvas3d, positionBeamOne);
				}
				previousRaster = rasterY;
			}
			startX += spanX.value;
		});

		// UITZONDERINGEN

		raster.spansX.getSpans().forEach((spanX, indexX) => {
			raster.spansY.getSpans().forEach((spanY, indexY) => {
				let childBeamDepth = Canvas3D.CURRENT.getBeamData(spanX.beam.oid, 'depth');
				let mainBeamDepth;
				let mainBeamHeight;
				if (this.mainBeamDirection === Profiles.MB_HORIZONTAL) {
					mainBeamDepth = Canvas3D.CURRENT.getBeamData(spanX.beam.oid, 'depth');
					mainBeamHeight = Canvas3D.CURRENT.getBeamData(spanX.beam.oid, 'height');
				} else {
					mainBeamDepth = Canvas3D.CURRENT.getBeamData(spanY.beam.oid, 'depth');
					mainBeamHeight = Canvas3D.CURRENT.getBeamData(spanY.beam.oid, 'height');
				}

				let rowData = raster.getRowPositions(indexX, indexY);
				let rowPositions = rowData.rowPositions;
				let placement = rowData.placement;

				if (etage.isActiveRaster(new RemoveRaster(indexX, indexY)) === true) {
					// huidige raster actief
					if (indexY > 0 && etage.isActiveRaster(new RemoveRaster(indexX, indexY - 1)) === false) {
						childBeamDepth = Canvas3D.CURRENT.getBeamData(spanX.beam.oid, 'depth');
						//* * als voorgaande niet actief altijd extra profiel aan begin toevoegen. Namelijk 2 opties:
						//* * 1. profiel valt precies op de maat (hoort dus bij vorige raster)
						//* * of valt over de maat. Nu geen rekening met offset maar aan begin is dan geen profiel

						let beginX = raster.getSizeX(indexX - 1);
						let beginY = raster.getSizeY(indexY - 1);
						let endX = raster.getSizeX(indexX);
						let endY = raster.getSizeY(indexY - 1) + childBeamDepth;

						let obj = {
							startX: beginX,
							startY: beginY + (Profiles.KPPSTARTOFFSET - this.getDistanceFromBackToHeartLine(spanX.beam.oid)),
							endX: endX,
							endY: endY,
							interruptions: this.getLengthInterruptions(beginX, beginY, endX, endY, Profiles.MB_HORIZONTAL, etage, etageHeight),
							rowPositions: rowPositions,
							placement: placement,
							indexY: indexY,
							indexX: indexX,
							beamOid: spanX.beam.oid,
							mainBeamHeight: mainBeamHeight,
							mainBeamDepth: mainBeamDepth,
							heartLineDistance: this.getDistanceFromBackToHeartLine(spanX.beam.oid),
						};
						interruptions.push(obj);

						//bij deze uitzondering geen torsiesupport omdat we redeneren vanuit het tweede profiel en dit wordt aan het begin toegevoegd
					}

					if (indexY + 1 === raster.spansY.length && raster.getSizeY(indexY) % this.centerToCenter > 0) {
						//* * als bij laatste raster nog lengte rasters is niet deelbaar door centerToCenter over is dan extra profiel
						childBeamDepth = Canvas3D.CURRENT.getBeamData(spanX.beam.oid, 'depth');
						let beginY = raster.getSizeY(indexY);
						let endY = raster.getSizeY(indexY) - childBeamDepth;

						let drawPositionStart = Configuration.CURRENT.columns.getPosition(indexX, 0);

						if (multipleFloors) {
							if (indexX !== 0) {
								drawPositionStart.x += modelParams.columnWidth / 2;
							}
						}

						let obj = {
							startX: drawPositionStart.x,
							startY: beginY - (Profiles.KPPSTARTOFFSET - this.getDistanceFromBackToHeartLine(spanX.beam.oid)),
							endX: drawPositionStart.x + spanX.value,
							endY: endY,

							interruptions: this.getLengthInterruptions(
								drawPositionStart.x,
								beginY - (Profiles.KPPSTARTOFFSET - this.getDistanceFromBackToHeartLine(spanX.beam.oid)),
								drawPositionStart.x + spanX.value,
								endY,
								Profiles.MB_HORIZONTAL,
								etage,
								etageHeight,
							),
							rowPositions: rowPositions,
							placement: placement,
							indexY: indexY,
							indexX: indexX,
							beamOid: spanX.beam.oid,
							mainBeamHeight: mainBeamHeight,
							mainBeamDepth: mainBeamDepth,
							heartLineDistance: this.getDistanceFromBackToHeartLine(spanX.beam.oid),
							isLast: true,
						};
						interruptions.push(obj);
						//positionBeamTwo is niet ctc afstand (want is hier uitzondering) die dus meegeven
						this.addVerticalTorsionalSupport(
							childBeamCount - 1,
							spanX.value / 3,
							spanX.beam.oid,
							drawPositionStart.x,
							etageHeight,
							canvas3d,
							null,
							beginY - (Profiles.KPPSTARTOFFSET - this.getDistanceFromBackToHeartLine(spanX.beam.oid)),
						);
					}
					if (indexY + 1 < raster.spansY.length && etage.isActiveRaster(new RemoveRaster(indexX, indexY + 1)) === false && raster.getSizeY(indexY) % this.centerToCenter > 0) {
						//* * als nog niet laatste en volgende raster is niet actief en lengte inclusief huidige raster is niet deelbaar door centerToCenter dan extra profiel
						childBeamDepth = Canvas3D.CURRENT.getBeamData(spanX.beam.oid, 'depth');
						let beginX = raster.getSizeX(indexX - 1);
						let beginY = raster.getSizeY(indexY);
						let endX = raster.getSizeX(indexX);
						let endY = raster.getSizeY(indexY) + childBeamDepth;

						if (multipleFloors) {
							if (indexX !== 0) {
								beginX += modelParams.columnWidth / 2;
							}
						}

						let obj = {
							startX: beginX,
							startY: beginY - (Profiles.KPPSTARTOFFSET - this.getDistanceFromBackToHeartLine(spanX.beam.oid)),
							endX: endX,
							endY: endY,
							interruptions: this.getLengthInterruptions(beginX, beginY, endX, endY, Profiles.MB_HORIZONTAL, etage, etageHeight),
							rowPositions: rowPositions,
							placement: placement,
							indexY: indexY,
							indexX: indexX,
							beamOid: spanX.beam.oid,
							mainBeamHeight: mainBeamHeight,
							mainBeamDepth: mainBeamDepth,
							heartLineDistance: this.getDistanceFromBackToHeartLine(spanX.beam.oid),
							isLast: true,
						};
						interruptions.push(obj);
						let childBeamDepth = Canvas3D.CURRENT.getBeamData(spanX.beam.oid, 'depth');
						//Positie van beam2 is in dit geval profiel + diepte profiel omdat hij gedraaid is en vandaaruit distancefrombacktoheart
						this.addVerticalTorsionalSupport(
							Math.floor(raster.getSizeY(indexY) / this.centerToCenter),
							spanX.value / 3,
							spanX.beam.oid,
							beginX,
							etageHeight,
							canvas3d,
							null,
							endY + childBeamDepth - (Profiles.KPPSTARTOFFSET - this.getDistanceFromBackToHeartLine(spanX.beam.oid)),
						);

						if (this.torsionalSupport === true && etage.isActiveRaster(new RemoveRaster(indexX, indexY)) === true) {
							let distance = spanX.value / 3;
							let lengthTorsionalSupport = raster.getSizeY(indexY) - Math.floor(raster.getSizeY(indexY) / this.centerToCenter) * this.centerToCenter;

							if (this.hasStairCollision(beginX + distance, beginY, 100, this._centerToCenter, etage) === false) {
								let torsiesteunLeft3D = new TorsionalSupportVertical3D(beginX + distance, beginY, {
									yPosition: etageHeight,
									width: lengthTorsionalSupport,
									multipleFloors: multipleFloors,
									layers: [Canvas3D.CURRENT.deckingFinishLayer.id],
								});
								canvas3d.addDrawObject(torsiesteunLeft3D, Canvas3D.TYPE_TORSISUPPORT);
							}

							if (this.hasStairCollision(beginX + distance * 2, beginY, 100, this._centerToCenter, etage) === false) {
								let torsiesteunRight3D = new TorsionalSupportVertical3D(beginX + distance * 2, beginY, {
									yPosition: etageHeight,
									width: lengthTorsionalSupport,
									multipleFloors: multipleFloors,
									layers: [Canvas3D.CURRENT.deckingFinishLayer.id],
								});
								canvas3d.addDrawObject(torsiesteunRight3D, Canvas3D.TYPE_TORSISUPPORT);
							}
						}
					}
				}
			});
		});

		let paramsObject = {
			drawHeight: etageHeight,
			multipleFloors: multipleFloors,
			profileType: 'childbeam',
			beamdirection: this.mainBeamDirection,
			doubleDouble: this.doubleDouble,
			ralColor: Configuration.CURRENT.colors.profiles.ralColor,
			etageId: etage.id,
		};

		interruptions.forEach((item, index) => {
			paramsObject.oid = item.beamOid;
			paramsObject.placement = item.placement;
			paramsObject.rowPositions = item.rowPositions;
			paramsObject.indexX = item.indexX;
			paramsObject.indexY = item.indexY;
			paramsObject.loopIndex = index;

			paramsObject.mainBeamDepth = item.mainBeamDepth;
			paramsObject.mainBeamHeight = item.mainBeamHeight;
			paramsObject.isLast = typeof item.isLast !== 'undefined' ? item.isLast : false;

			if (item.interruptions.length > 0) {
				if (item.startX < item.interruptions[0].startX) {
					new HorizontalChildBeam3D(item.startX, item.startY, item.interruptions[0].startX - item.startX, paramsObject);
				}
				if (item.interruptions.length > 1) {
					// meer dan 1 tussen interuptions checken
					for (let i = 0; i < item.interruptions.length - 1; i++) {
						if (item.interruptions[i].endX < item.interruptions[i + 1].startX) {
							new HorizontalChildBeam3D(item.interruptions[i].endX, item.startY, item.interruptions[i + 1].startX - item.interruptions[i].endX, paramsObject);
						}
					}
				}
				if (item.interruptions[item.interruptions.length - 1].endX < item.endX) {
					new HorizontalChildBeam3D(item.interruptions[item.interruptions.length - 1].endX, item.startY, item.endX - item.interruptions[item.interruptions.length - 1].endX, paramsObject);
				}
			} else {
				new HorizontalChildBeam3D(item.startX, item.startY, item.endX - item.startX, paramsObject);
			}
		});
	}

	addHorizontalTorisonalSupport(childBeam, distance, beamOid, startY, height, canvas3d, positionBeamOne, positionBeamTwo) {
		if (this.torsionalSupport === true && childBeam > 0) {
			let childBeamHeight = Canvas3D.CURRENT.getBeamData(beamOid, 'height');
			let childBeamThickness = Canvas3D.CURRENT.getBeamData(beamOid, 'thickness');
			if (positionBeamOne === null || typeof positionBeamOne === 'undefined') {
				positionBeamOne = (childBeam - 1) * this._centerToCenter + this.getDistanceFromBackToHeartLine(beamOid) + childBeamThickness;
			}

			if (positionBeamTwo === null || typeof positionBeamTwo === 'undefined') {
				positionBeamTwo = childBeam * this._centerToCenter + this.getDistanceFromBackToHeartLine(beamOid) + childBeamThickness;
			}
			length = positionBeamTwo - positionBeamOne;
			// Eerste rij horizontaal
			canvas3d.addDrawObject(
				//TODO juiste positionering meenemen met juiste torsionsupport inladen
				new TorsionalSupportHorizontal3D(positionBeamOne, startY + distance, {
					yPosition: height,
					width: length,
					childBeamHeight: childBeamHeight,
					oid: this.torsionalSupportArticle?.article?.oid,
					layers: [Canvas3D.CURRENT.deckingFinishLayer.id],
				}),
				Canvas3D.TYPE_TORSISUPPORT,
			);

			// Tweede rij horizontaal
			canvas3d.addDrawObject(
				//TODO juiste positionering meenemen met juiste torsionsupport inladen
				new TorsionalSupportHorizontal3D(positionBeamOne, startY + distance * 2, {
					yPosition: height,
					width: length,
					childBeamHeight: childBeamHeight,
					oid: this.torsionalSupportArticle?.article?.oid,
					layers: [Canvas3D.CURRENT.deckingFinishLayer.id],
				}),
				Canvas3D.TYPE_TORSISUPPORT,
			);
		}
	}
	addVerticalTorsionalSupport(childBeam, distance, beamOid, startX, height, canvas3d, positionBeamOne, positionBeamTwo) {
		if (this.torsionalSupport === true && childBeam > 0) {
			let childBeamHeight = Canvas3D.CURRENT.getBeamData(beamOid, 'height');
			let childBeamThickness = Canvas3D.CURRENT.getBeamData(beamOid, 'thickness');

			// Eerste rij horizontaal
			if (positionBeamOne === null || typeof positionBeamOne === 'undefined') {
				positionBeamOne = (childBeam - 1) * this._centerToCenter + this.getDistanceFromBackToHeartLine(beamOid) + childBeamThickness;
			}

			if (positionBeamTwo === null || typeof positionBeamTwo === 'undefined') {
				positionBeamTwo = childBeam * this._centerToCenter + this.getDistanceFromBackToHeartLine(beamOid) + childBeamThickness;
			}
			length = positionBeamTwo - positionBeamOne;
			canvas3d.addDrawObject(
				new TorsionalSupportVertical3D(startX + distance, positionBeamOne, {
					yPosition: height,
					width: length,
					childBeamHeight: childBeamHeight,
					oid: this.torsionalSupportArticle?.article?.oid,
					layers: [Canvas3D.CURRENT.deckingFinishLayer.id],
				}),
				Canvas3D.TYPE_TORSISUPPORT,
			);

			// Tweede rij horizontaal
			canvas3d.addDrawObject(
				new TorsionalSupportVertical3D(startX + distance * 2, positionBeamOne, {
					yPosition: height,
					width: length,
					childBeamHeight: childBeamHeight,
					oid: this.torsionalSupportArticle?.article?.oid,
					layers: [Canvas3D.CURRENT.deckingFinishLayer.id],
				}),
				Canvas3D.TYPE_TORSISUPPORT,
			);
		}
	}
	hasStairCollision(x, y, width, depth, etage) {
		let stairinRaster = etage.stairs.stairs.filter((stair) => stair.place === Stair.PLACE_INFLOOR);
		let hasCollision = false;

		stairinRaster.forEach((stair, index) => {
			let lt = { x: stair.stairWell.startX, y: stair.stairWell.startY };
			let rb = { x: stair.stairWell.startX + stair.stairWell.width, y: stair.stairWell.startY + stair.stairWell.depth };

			let lt2 = { x: x, y: y };
			let rb2 = { x: x + width, y: y + depth };

			if (Mathematic.overlapRectangles(lt, rb, lt2, rb2)) {
				hasCollision = true;
			}
		});

		return hasCollision;
	}
}
