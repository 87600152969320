import { Store } from '../data/store';
import { DrawText } from '../draw/drawText';
import { DrawValue } from '../draw/drawValue';
import { Line } from '../draw/line';
import { Configuration } from './configuration';
import { CustomError } from './CustomError';
import { Errors } from './errors';
import { RemoveRaster } from './removeRaster';

export class BracingRules {
	objectName = 'BracingRules';

	bracingAmounts = { minBracings: 0, minPerDirection: 0, currentBracings: 0, currentHorizontal: 0, currentVertical: 0 };

	minBracing = 0;
	minHorizontal = 0;
	minVertical = 0;

	currentAmount = 0;
	vertical = 0;
	horizontal = 0;
	countKwadrant = 0;
	minBracingKwadrant = 0;
	amountOfKwadrants = 3;
	perKwadrant = false;

	amountLeftTop = 0;
	amountRightTop = 0;
	amountLeftBottom = 0;
	amountRightBottom = 0;

	static LEFT_TOP = 0;
	static RIGHT_TOP = 1;
	static LEFT_BOTTOM = 2;
	static RIGHT_BOTTOM = 3;

	constructor(onChange) {
		this._onChange = onChange;
	}
	setReferences(params) {
		this._onChange = params.onChange;
	}
	removeReferences() {
		this._onChange = null;
	}
	onChange() {
		if (typeof _onChange === 'function') {
			this._onChange();
		}
	}

	hasError() {
		if (this.currentAmount < this.minBracing || this.horizontal < this.minHorizontal || this.vertical < this.minVertical || this.countKwadrant < this.amountOfKwadrants) {
			return true;
		}
		return false;
	}

	checkKwadrantIsActive(etage, position) {
		let kwadrantPosition = Configuration.CURRENT.etages.etages[Configuration.CURRENT.etages.activeEtageIndex].getRasterWidthAndLength();

		let halfX = kwadrantPosition.startX + kwadrantPosition.width / 2;
		let halfY = kwadrantPosition.startY + kwadrantPosition.depth / 2;

		let rastersInKwadrant = 0;

		etage.raster.spansY.getSpans().forEach((spanY, indexY) => {
			etage.raster.spansX.getSpans().forEach((spanX, indexX) => {
				// xSize is lengte tot aan huidige raster horiontaal.
				const xSize = etage.raster.getSizeX(indexX);
				// ySize is lengte tot aan huidige raster verticaal.
				const ySize = etage.raster.getSizeY(indexY);

				// Bij linksboven dan moet zowel de X en Y kleiner zijn dan de helft van het vloer oppervlakte.
				// Bij rechtsboven dan moet de x groter zijn dan de helftX en kleiner dan helftY.
				// Bij linksonder dan moet de x kleiner zijn dan de helftX en groter dan helftY.
				// Bij rechts onder dan moet zowel de X en Y waardes groter zijn dan de helft van het vloeroppervlak.
				if (
					(position === BracingRules.LEFT_TOP && xSize <= halfX && ySize <= halfY) ||
					(position === BracingRules.RIGHT_TOP && xSize >= halfX && ySize <= halfY) ||
					(position === BracingRules.LEFT_BOTTOM && xSize <= halfX && ySize >= halfY) ||
					(position === BracingRules.RIGHT_BOTTOM && xSize >= halfX && ySize >= halfY)
				) {
					// Alleen als raster actief is optellen bij dit kwadrant.
					if (etage.isActiveRaster(new RemoveRaster(indexX, indexY))) {
						rastersInKwadrant++;
					}
				}
			});
		});

		return rastersInKwadrant !== 0;
	}
	addDrawObjects(canvas) {
		this.getErrors();
		// Alleen hulplijnen tekenen wanneer in Edit modus voor bracings zit.
		if (Configuration.CURRENT.editModus.isEditingBracings) {
			let kwadrantPosition = Configuration.CURRENT.etages.etages[Configuration.CURRENT.etages.activeEtageIndex].getRasterWidthAndLength();
			const kwadrantMargin = 1250;

			// Horizontaal
			canvas.addDrawObject(
				new Line(
					new DrawValue(kwadrantPosition.startX - kwadrantMargin),
					new DrawValue(kwadrantPosition.startY + kwadrantPosition.depth / 2),
					new DrawValue(kwadrantPosition.startX + kwadrantPosition.width + kwadrantMargin),
					new DrawValue(kwadrantPosition.startY + kwadrantPosition.depth / 2),
					new DrawValue(3, 0, true),
					null,
					'#9C00C2',
					null,
					null,
					null,
					null,
					null,
					false,
				),
			);

			// Verticaal
			canvas.addDrawObject(
				new Line(
					new DrawValue(kwadrantPosition.startX + kwadrantPosition.width / 2),
					new DrawValue(kwadrantPosition.startY - kwadrantMargin),
					new DrawValue(kwadrantPosition.startX + kwadrantPosition.width / 2),
					new DrawValue(kwadrantPosition.startY + kwadrantPosition.depth + kwadrantMargin),
					new DrawValue(3, 0, true),
					null,
					'#9C00C2',
					null,
					null,
					null,
					null,
					null,
					false,
				),
			);

			// Kwadrant linksboven hoeveelheid aangeven.
			canvas.addDrawObject(
				new DrawText(
					new DrawValue(kwadrantPosition.startX - kwadrantMargin),
					new DrawValue(kwadrantPosition.startY - kwadrantMargin),
					this.amountLeftTop,
					new DrawValue(100),
					'#000',
					null,
					null,
					'bold 20px Arial',
				),
			);

			// Kwadrant rechtsboven hoeveelheid aangeven.
			canvas.addDrawObject(
				new DrawText(
					new DrawValue(kwadrantPosition.startX + kwadrantPosition.width + kwadrantMargin),
					new DrawValue(kwadrantPosition.startY - kwadrantMargin),
					this.amountRightTop,
					new DrawValue(100),
					'#000',
					null,
					null,
					'bold 20px Arial',
				),
			);
			// Kwadrant linksonder hoeveelheid aangeven.
			canvas.addDrawObject(
				new DrawText(
					new DrawValue(kwadrantPosition.startX - kwadrantMargin),
					new DrawValue(kwadrantPosition.startY + kwadrantPosition.depth + kwadrantMargin),
					this.amountLeftBottom,
					new DrawValue(100),
					'#000',
					null,
					null,
					'bold 20px Arial',
				),
			);

			canvas.addDrawObject(
				new DrawText(
					new DrawValue(kwadrantPosition.startX + kwadrantPosition.width + kwadrantMargin),
					new DrawValue(kwadrantPosition.startY + kwadrantPosition.depth + kwadrantMargin),
					this.amountRightBottom,
					new DrawValue(100),
					'#000',
					null,
					null,
					'bold 20px Arial',
				),
			);
		}
	}

	getErrors() {
		let etage = Configuration.CURRENT.etages.etages[Configuration.CURRENT.etages.activeEtageIndex];
		let portalBracings = etage.portalBracings;
		portalBracings.getAmount();
		let bracings = etage.bracings;
		bracings.getAmount();
		let braceColumns = etage.braceColumns;
		braceColumns.getAmount();
		let surface = etage.surface;
		let maxBraceSurfacePerBrace;

		let variableLoad = Store.CURRENT.floorVariableLoads.getItem(Configuration.CURRENT.variableLoad);

		if (variableLoad !== null) {
			maxBraceSurfacePerBrace = variableLoad.maxBraceSurfacePerBrace;
			// 50 m2 als minimum Brace surface per brace. Als stamdata niet goed is gevuld komt er anders infinity
			if (maxBraceSurfacePerBrace === 0) {
				maxBraceSurfacePerBrace = 50;
			}

			this.bracingAmounts.minPerDirection = Math.ceil(surface / maxBraceSurfacePerBrace);
			this.bracingAmounts.minBracings = this.bracingAmounts.minPerDirection * 2;
			this.minBracing = this.bracingAmounts.minBracings;
		}
		if (this.bracingAmounts.minBracings < 3) {
			// absoluut minimum = 3
			this.bracingAmounts.minBracings = 3;
			this.minBracing = 3;
		}
		let KwadrantLeftTopActive = this.checkKwadrantIsActive(etage, BracingRules.LEFT_TOP);
		let KwadrantRightTopActive = this.checkKwadrantIsActive(etage, BracingRules.RIGHT_TOP);
		let KwadrantLeftBottomActive = this.checkKwadrantIsActive(etage, BracingRules.LEFT_BOTTOM);
		let KwadrantRightBottomActive = this.checkKwadrantIsActive(etage, BracingRules.RIGHT_BOTTOM);

		this.minBracingKwadrant = Math.floor(this.bracingAmounts.minBracings / 4) - 1;

		if (this.minBracingKwadrant < 0) {
			this.minBracingKwadrant = 0;
		}
		this.perKwadrant =
			(KwadrantLeftTopActive === false || portalBracings.amountLeftTop + bracings.amountLeftTop + braceColumns.amountLeftTop >= this.minBracingKwadrant) &&
			(KwadrantRightTopActive === false || portalBracings.amountRightTop + bracings.amountRightTop + braceColumns.amountRightTop >= this.minBracingKwadrant) &&
			(KwadrantLeftBottomActive === false || portalBracings.amountLeftBottom + bracings.amountLeftBottom + braceColumns.amountLeftBottom >= this.minBracingKwadrant) &&
			(KwadrantRightBottomActive === false || portalBracings.amountRightBottom + bracings.amountRightBottom + braceColumns.amountRightBottom >= this.minBracingKwadrant);

		this.countKwadrant = 0;
		if (portalBracings.amountLeftTop + bracings.amountLeftTop + braceColumns.amountLeftTop > 0) {
			this.countKwadrant++;
		}
		if (portalBracings.amountRightTop + bracings.amountRightTop + braceColumns.amountRightTop > 0) {
			this.countKwadrant++;
		}
		if (portalBracings.amountLeftBottom + bracings.amountLeftBottom + braceColumns.amountLeftBottom > 0) {
			this.countKwadrant++;
		}
		if (portalBracings.amountRightBottom + bracings.amountRightBottom + braceColumns.amountRightBottom > 0) {
			this.countKwadrant++;
		}
		// extra check voor bracings

		this.currentAmount = portalBracings.count() + bracings.count() + braceColumns.count();
		this.horizontal = bracings.amountHorizontal + braceColumns.amountHorizontal + portalBracings.amountHorizontal;
		this.vertical = bracings.amountVertical + braceColumns.amountVertical + portalBracings.amountVertical;

		this.bracingAmounts.currentBracings = portalBracings.count() + bracings.count() + braceColumns.count();
		this.bracingAmounts.currentVertical = bracings.amountVertical + braceColumns.amountVertical + portalBracings.amountVertical;
		this.bracingAmounts.currentHorizontal = bracings.amountHorizontal + braceColumns.amountHorizontal + portalBracings.amountHorizontal;

		this.minHorizontal = this.bracingAmounts.minPerDirection;
		this.minVertical = this.bracingAmounts.minPerDirection;

		this.amountLeftTop = portalBracings.amountLeftTop + bracings.amountLeftTop + braceColumns.amountLeftTop;
		this.amountRightTop = portalBracings.amountRightTop + bracings.amountRightTop + braceColumns.amountRightTop;
		this.amountLeftBottom = portalBracings.amountLeftBottom + bracings.amountLeftBottom + braceColumns.amountLeftBottom;
		this.amountRightBottom = portalBracings.amountRightBottom + bracings.amountRightBottom + braceColumns.amountRightBottom;

		if (
			this.bracingAmounts.currentBracings < this.bracingAmounts.minBracings ||
			this.bracingAmounts.currentHorizontal < this.bracingAmounts.minPerDirection ||
			this.bracingAmounts.currentVertical < this.bracingAmounts.minPerDirection ||
			this.perKwadrant === false ||
			this.countKwadrant < 3 ||
			this.bracingAmounts.currentBracings < this.bracingAmounts.minBracings
		) {
			Configuration.CURRENT.etages.etages[Configuration.CURRENT.etages.activeEtageIndex].hasBraceErrors = true;
			let braceError = new CustomError(
				window.$nuxt.$translate('bracing.errorText', this.bracingAmounts),
				Errors.ERRORTYPE.validate,
				window.$nuxt.$translate('bracing.simpleError', this.bracingAmounts),
			);
			braceError.source = 'Bracings';
			braceError.sourceDescription = window.$nuxt.$translate('bracing.title');
			if (this.perKwadrant === false || this.countKwadrant < 3) {
				// Niet verdeelt over configuratie.
				Configuration.CURRENT.etages.etages[Configuration.CURRENT.etages.activeEtageIndex].kwadrantError = true;
			}
			return braceError;
		} else {
			Configuration.CURRENT.etages.etages[Configuration.CURRENT.etages.activeEtageIndex].hasBraceErrors = false;
			Configuration.CURRENT.etages.etages[Configuration.CURRENT.etages.activeEtageIndex].kwadrantError = false;
		}
	}
}
