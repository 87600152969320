/**
 *
 * Add tween lib to playcanvas
 *
 * @param {*} pc playcanvas
 */
export function addTween(pc) {
	pc.extend(
		pc,
		(function () {
			/**
			 * @name pc.TweenManager
			 * @description Handles updating tweens
			 * @param {pc.AppBase} app - The AppBase instance.
			 */
			var TweenManager = function (app) {
				this._app = app;
				this._tweens = [];
				this._add = []; // to be added
			};

			TweenManager.prototype = {
				add: function (tween) {
					this._add.push(tween);
					return tween;
				},

				update: function (dt) {
					var i = 0;
					var n = this._tweens.length;
					while (i < n) {
						if (this._tweens[i].update(dt)) {
							i++;
						} else {
							this._tweens.splice(i, 1);
							n--;
						}
					}

					// add any tweens that were added mid-update
					if (this._add.length) {
						for (let i = 0; i < this._add.length; i++) {
							if (this._tweens.indexOf(this._add[i]) > -1) continue;
							this._tweens.push(this._add[i]);
						}
						this._add.length = 0;
					}
				},
			};

			/**
			 * @name  pc.Tween
			 * @param {object} target - The target property that will be tweened
			 * @param {pc.TweenManager} manager - The tween manager
			 * @param {pc.Entity} entity - The pc.Entity whose property we are tweening
			 */
			var Tween = function (target, manager, entity) {
				pc.events.attach(this);

				this.manager = manager;

				if (entity) {
					this.entity = null; // if present the tween will dirty the transforms after modify the target
				}

				this.time = 0;

				this.complete = false;
				this.playing = false;
				this.stopped = true;
				this.pending = false;

				this.target = target;

				this.duration = 0;
				this._currentDelay = 0;
				this.timeScale = 1;
				this._reverse = false;

				this._delay = 0;
				this._yoyo = false;

				this._count = 0;
				this._numRepeats = 0;
				this._repeatDelay = 0;

				this._from = false; // indicates a "from" tween

				// for rotation tween
				this._slerp = false; // indicates a rotation tween
				this._fromQuat = new pc.Quat();
				this._toQuat = new pc.Quat();
				this._quat = new pc.Quat();

				this.easing = pc.Linear;

				this._sv = {}; // start values
				this._ev = {}; // end values
			};

			var _parseProperties = function (properties) {
				var _properties;
				if (properties instanceof pc.Vec2) {
					_properties = {
						x: properties.x,
						y: properties.y,
					};
				} else if (properties instanceof pc.Vec3) {
					_properties = {
						x: properties.x,
						y: properties.y,
						z: properties.z,
					};
				} else if (properties instanceof pc.Vec4) {
					_properties = {
						x: properties.x,
						y: properties.y,
						z: properties.z,
						w: properties.w,
					};
				} else if (properties instanceof pc.Quat) {
					_properties = {
						x: properties.x,
						y: properties.y,
						z: properties.z,
						w: properties.w,
					};
				} else if (properties instanceof pc.Color) {
					_properties = {
						r: properties.r,
						g: properties.g,
						b: properties.b,
					};
					if (properties.a !== undefined) {
						_properties.a = properties.a;
					}
				} else {
					_properties = properties;
				}
				return _properties;
			};
			Tween.prototype = {
				// properties - js obj of values to update in target
				to: function (properties, duration, easing, delay, repeat, yoyo) {
					this._properties = _parseProperties(properties);
					this.duration = duration;

					if (easing) this.easing = easing;
					if (delay) {
						this.delay(delay);
					}
					if (repeat) {
						this.repeat(repeat);
					}

					if (yoyo) {
						this.yoyo(yoyo);
					}

					return this;
				},

				from: function (properties, duration, easing, delay, repeat, yoyo) {
					this._properties = _parseProperties(properties);
					this.duration = duration;

					if (easing) this.easing = easing;
					if (delay) {
						this.delay(delay);
					}
					if (repeat) {
						this.repeat(repeat);
					}

					if (yoyo) {
						this.yoyo(yoyo);
					}

					this._from = true;

					return this;
				},

				rotate: function (properties, duration, easing, delay, repeat, yoyo) {
					this._properties = _parseProperties(properties);

					this.duration = duration;

					if (easing) this.easing = easing;
					if (delay) {
						this.delay(delay);
					}
					if (repeat) {
						this.repeat(repeat);
					}

					if (yoyo) {
						this.yoyo(yoyo);
					}

					this._slerp = true;

					return this;
				},

				start: function () {
					var prop, _x, _y, _z;

					this.playing = true;
					this.complete = false;
					this.stopped = false;
					this._count = 0;
					this.pending = this._delay > 0;

					if (this._reverse && !this.pending) {
						this.time = this.duration;
					} else {
						this.time = 0;
					}

					if (this._from) {
						for (prop in this._properties) {
							if (this._properties.hasOwnProperty(prop)) {
								this._sv[prop] = this._properties[prop];
								this._ev[prop] = this.target[prop];
							}
						}

						if (this._slerp) {
							this._toQuat.setFromEulerAngles(this.target.x, this.target.y, this.target.z);

							_x = this._properties.x !== undefined ? this._properties.x : this.target.x;
							_y = this._properties.y !== undefined ? this._properties.y : this.target.y;
							_z = this._properties.z !== undefined ? this._properties.z : this.target.z;
							this._fromQuat.setFromEulerAngles(_x, _y, _z);
						}
					} else {
						for (prop in this._properties) {
							if (this._properties.hasOwnProperty(prop)) {
								this._sv[prop] = this.target[prop];
								this._ev[prop] = this._properties[prop];
							}
						}

						if (this._slerp) {
							_x = this._properties.x !== undefined ? this._properties.x : this.target.x;
							_y = this._properties.y !== undefined ? this._properties.y : this.target.y;
							_z = this._properties.z !== undefined ? this._properties.z : this.target.z;

							if (this._properties.w !== undefined) {
								this._fromQuat.copy(this.target);
								this._toQuat.set(_x, _y, _z, this._properties.w);
							} else {
								this._fromQuat.setFromEulerAngles(this.target.x, this.target.y, this.target.z);
								this._toQuat.setFromEulerAngles(_x, _y, _z);
							}
						}
					}

					// set delay
					this._currentDelay = this._delay;

					// add to manager when started
					this.manager.add(this);

					return this;
				},

				pause: function () {
					this.playing = false;
				},

				resume: function () {
					this.playing = true;
				},

				stop: function () {
					this.playing = false;
					this.stopped = true;
				},

				delay: function (delay) {
					this._delay = delay;
					this.pending = true;

					return this;
				},

				repeat: function (num, delay) {
					this._count = 0;
					this._numRepeats = num;
					if (delay) {
						this._repeatDelay = delay;
					} else {
						this._repeatDelay = 0;
					}

					return this;
				},

				loop: function (loop) {
					if (loop) {
						this._count = 0;
						this._numRepeats = Infinity;
					} else {
						this._numRepeats = 0;
					}

					return this;
				},

				yoyo: function (yoyo) {
					this._yoyo = yoyo;
					return this;
				},

				reverse: function () {
					this._reverse = !this._reverse;

					return this;
				},

				chain: function () {
					var n = arguments.length;

					while (n--) {
						if (n > 0) {
							arguments[n - 1]._chained = arguments[n];
						} else {
							this._chained = arguments[n];
						}
					}

					return this;
				},

				onUpdate: function (callback) {
					this.on('update', callback);
					return this;
				},

				onComplete: function (callback) {
					this.on('complete', callback);
					return this;
				},

				onLoop: function (callback) {
					this.on('loop', callback);
					return this;
				},

				update: function (dt) {
					if (this.stopped) return false;

					if (!this.playing) return true;

					if (!this._reverse || this.pending) {
						this.time += dt * this.timeScale;
					} else {
						this.time -= dt * this.timeScale;
					}

					// delay start if required
					if (this.pending) {
						if (this.time > this._currentDelay) {
							if (this._reverse) {
								this.time = this.duration - (this.time - this._currentDelay);
							} else {
								this.time -= this._currentDelay;
							}
							this.pending = false;
						} else {
							return true;
						}
					}

					var _extra = 0;
					if ((!this._reverse && this.time > this.duration) || (this._reverse && this.time < 0)) {
						this._count++;
						this.complete = true;
						this.playing = false;
						if (this._reverse) {
							_extra = this.duration - this.time;
							this.time = 0;
						} else {
							_extra = this.time - this.duration;
							this.time = this.duration;
						}
					}

					var elapsed = this.duration === 0 ? 1 : this.time / this.duration;

					// run easing
					var a = this.easing(elapsed);

					// increment property
					var s, e;
					for (var prop in this._properties) {
						if (this._properties.hasOwnProperty(prop)) {
							s = this._sv[prop];
							e = this._ev[prop];
							this.target[prop] = s + (e - s) * a;
						}
					}

					if (this._slerp) {
						this._quat.slerp(this._fromQuat, this._toQuat, a);
					}

					// if this is a entity property then we should dirty the transform
					if (this.entity) {
						this.entity._dirtifyLocal();

						// apply element property changes
						if (this.element && this.entity.element) {
							this.entity.element[this.element] = this.target;
						}

						if (this._slerp) {
							this.entity.setLocalRotation(this._quat);
						}
					}

					this.fire('update', dt);

					if (this.complete) {
						var repeat = this._repeat(_extra);
						if (!repeat) {
							this.fire('complete', _extra);
							if (this.entity) this.entity.off('destroy', this.stop, this);
							if (this._chained) this._chained.start();
						} else {
							this.fire('loop');
						}

						return repeat;
					}

					return true;
				},

				_repeat: function (extra) {
					// test for repeat conditions
					if (this._count < this._numRepeats) {
						// do a repeat
						if (this._reverse) {
							this.time = this.duration - extra;
						} else {
							this.time = extra; // include overspill time
						}
						this.complete = false;
						this.playing = true;

						this._currentDelay = this._repeatDelay;
						this.pending = true;

						if (this._yoyo) {
							// swap start/end properties
							for (var prop in this._properties) {
								var tmp = this._sv[prop];
								this._sv[prop] = this._ev[prop];
								this._ev[prop] = tmp;
							}

							if (this._slerp) {
								this._quat.copy(this._fromQuat);
								this._fromQuat.copy(this._toQuat);
								this._toQuat.copy(this._quat);
							}
						}

						return true;
					}
					return false;
				},
			};

			/**
			 * Easing methods
			 */

			var Linear = function (k) {
				return k;
			};

			var QuadraticIn = function (k) {
				return k * k;
			};

			var QuadraticOut = function (k) {
				return k * (2 - k);
			};

			var QuadraticInOut = function (k) {
				if ((k *= 2) < 1) {
					return 0.5 * k * k;
				}
				return -0.5 * (--k * (k - 2) - 1);
			};

			var CubicIn = function (k) {
				return k * k * k;
			};

			var CubicOut = function (k) {
				return --k * k * k + 1;
			};

			var CubicInOut = function (k) {
				if ((k *= 2) < 1) return 0.5 * k * k * k;
				return 0.5 * ((k -= 2) * k * k + 2);
			};

			var QuarticIn = function (k) {
				return k * k * k * k;
			};

			var QuarticOut = function (k) {
				return 1 - --k * k * k * k;
			};

			var QuarticInOut = function (k) {
				if ((k *= 2) < 1) return 0.5 * k * k * k * k;
				return -0.5 * ((k -= 2) * k * k * k - 2);
			};

			var QuinticIn = function (k) {
				return k * k * k * k * k;
			};

			var QuinticOut = function (k) {
				return --k * k * k * k * k + 1;
			};

			var QuinticInOut = function (k) {
				if ((k *= 2) < 1) return 0.5 * k * k * k * k * k;
				return 0.5 * ((k -= 2) * k * k * k * k + 2);
			};

			var SineIn = function (k) {
				if (k === 0) return 0;
				if (k === 1) return 1;
				return 1 - Math.cos((k * Math.PI) / 2);
			};

			var SineOut = function (k) {
				if (k === 0) return 0;
				if (k === 1) return 1;
				return Math.sin((k * Math.PI) / 2);
			};

			var SineInOut = function (k) {
				if (k === 0) return 0;
				if (k === 1) return 1;
				return 0.5 * (1 - Math.cos(Math.PI * k));
			};

			var ExponentialIn = function (k) {
				return k === 0 ? 0 : Math.pow(1024, k - 1);
			};

			var ExponentialOut = function (k) {
				return k === 1 ? 1 : 1 - Math.pow(2, -10 * k);
			};

			var ExponentialInOut = function (k) {
				if (k === 0) return 0;
				if (k === 1) return 1;
				if ((k *= 2) < 1) return 0.5 * Math.pow(1024, k - 1);
				return 0.5 * (-Math.pow(2, -10 * (k - 1)) + 2);
			};

			var CircularIn = function (k) {
				return 1 - Math.sqrt(1 - k * k);
			};

			var CircularOut = function (k) {
				return Math.sqrt(1 - --k * k);
			};

			var CircularInOut = function (k) {
				if ((k *= 2) < 1) return -0.5 * (Math.sqrt(1 - k * k) - 1);
				return 0.5 * (Math.sqrt(1 - (k -= 2) * k) + 1);
			};

			var ElasticIn = function (k) {
				var s,
					a = 0.1,
					p = 0.4;
				if (k === 0) return 0;
				if (k === 1) return 1;
				if (!a || a < 1) {
					a = 1;
					s = p / 4;
				} else s = (p * Math.asin(1 / a)) / (2 * Math.PI);
				return -(a * Math.pow(2, 10 * (k -= 1)) * Math.sin(((k - s) * (2 * Math.PI)) / p));
			};

			var ElasticOut = function (k) {
				var s,
					a = 0.1,
					p = 0.4;
				if (k === 0) return 0;
				if (k === 1) return 1;
				if (!a || a < 1) {
					a = 1;
					s = p / 4;
				} else s = (p * Math.asin(1 / a)) / (2 * Math.PI);
				return a * Math.pow(2, -10 * k) * Math.sin(((k - s) * (2 * Math.PI)) / p) + 1;
			};

			var ElasticInOut = function (k) {
				var s,
					a = 0.1,
					p = 0.4;
				if (k === 0) return 0;
				if (k === 1) return 1;
				if (!a || a < 1) {
					a = 1;
					s = p / 4;
				} else s = (p * Math.asin(1 / a)) / (2 * Math.PI);
				if ((k *= 2) < 1) return -0.5 * (a * Math.pow(2, 10 * (k -= 1)) * Math.sin(((k - s) * (2 * Math.PI)) / p));
				return a * Math.pow(2, -10 * (k -= 1)) * Math.sin(((k - s) * (2 * Math.PI)) / p) * 0.5 + 1;
			};

			var BackIn = function (k) {
				var s = 1.70158;
				return k * k * ((s + 1) * k - s);
			};

			var BackOut = function (k) {
				var s = 1.70158;
				return --k * k * ((s + 1) * k + s) + 1;
			};

			var BackInOut = function (k) {
				var s = 1.70158 * 1.525;
				if ((k *= 2) < 1) return 0.5 * (k * k * ((s + 1) * k - s));
				return 0.5 * ((k -= 2) * k * ((s + 1) * k + s) + 2);
			};

			var BounceOut = function (k) {
				if (k < 1 / 2.75) {
					return 7.5625 * k * k;
				} else if (k < 2 / 2.75) {
					return 7.5625 * (k -= 1.5 / 2.75) * k + 0.75;
				} else if (k < 2.5 / 2.75) {
					return 7.5625 * (k -= 2.25 / 2.75) * k + 0.9375;
				}
				return 7.5625 * (k -= 2.625 / 2.75) * k + 0.984375;
			};

			var BounceIn = function (k) {
				return 1 - BounceOut(1 - k);
			};

			var BounceInOut = function (k) {
				if (k < 0.5) return BounceIn(k * 2) * 0.5;
				return BounceOut(k * 2 - 1) * 0.5 + 0.5;
			};

			return {
				TweenManager: TweenManager,
				Tween: Tween,
				Linear: Linear,
				QuadraticIn: QuadraticIn,
				QuadraticOut: QuadraticOut,
				QuadraticInOut: QuadraticInOut,
				CubicIn: CubicIn,
				CubicOut: CubicOut,
				CubicInOut: CubicInOut,
				QuarticIn: QuarticIn,
				QuarticOut: QuarticOut,
				QuarticInOut: QuarticInOut,
				QuinticIn: QuinticIn,
				QuinticOut: QuinticOut,
				QuinticInOut: QuinticInOut,
				SineIn: SineIn,
				SineOut: SineOut,
				SineInOut: SineInOut,
				ExponentialIn: ExponentialIn,
				ExponentialOut: ExponentialOut,
				ExponentialInOut: ExponentialInOut,
				CircularIn: CircularIn,
				CircularOut: CircularOut,
				CircularInOut: CircularInOut,
				BackIn: BackIn,
				BackOut: BackOut,
				BackInOut: BackInOut,
				BounceIn: BounceIn,
				BounceOut: BounceOut,
				BounceInOut: BounceInOut,
				ElasticIn: ElasticIn,
				ElasticOut: ElasticOut,
				ElasticInOut: ElasticInOut,
			};
		})(),
	);

	// Expose prototype methods and create a default tween manager on the AppBase
	(function () {
		// Add pc.AppBase#addTweenManager method
		pc.AppBase.prototype.addTweenManager = function () {
			this._tweenManager = new pc.TweenManager(this);

			this.on('update', function (dt) {
				this._tweenManager.update(dt);
			});
		};

		// Add pc.AppBase#tween method
		pc.AppBase.prototype.tween = function (target) {
			return new pc.Tween(target, this._tweenManager);
		};

		// Add pc.Entity#tween method
		pc.Entity.prototype.tween = function (target, options) {
			var tween = this._app.tween(target);
			tween.entity = this;

			this.once('destroy', tween.stop, tween);

			if (options && options.element) {
				// specifiy a element property to be updated
				tween.element = options.element;
			}
			return tween;
		};

		// Create a default tween manager on the AppBase
		var AppBase = pc.AppBase.getApplication();
		if (AppBase) {
			AppBase.addTweenManager();
		}
	})();
}
