import * as pc from 'playcanvas';
import { HandRails } from '../../configurator/handRails';
import { Stair } from '../../configurator/stair';
import { Store } from '../../data/store';
import { Box3D } from '../box3D';
import { Canvas3D } from '../Canvas3D';
import { HandRailChildPole3D } from './HandRailChildPole3D';
import { HandRailChildPoleHolder3D } from './HandRailChildPoleHolder3D';
import { HandRailPlateMiddle3D } from './handrailplate/HandRailPlateMiddle3D';
import { HandRailPlateTop3D } from './handrailplate/HandRailPlateTop3D';
import { HandRailPole3D } from './HandRailPole3D';
import { HandRailKickEdge3D } from './kickedge/HandRailKickEdge3D';
import { Configuration } from '@/configurator/configuration';
import { LedBar3D } from './LedBar3D';

export class HandRail3D {
	objectName = 'HandRail3D';
	constructor(x, y, z, params, position, length, flippedKickEdge = false) {
		// wanneer er van company geswitched is, kan het voorkomen dat het oid niet meer voorkomt in de lijst.
		// Omdat de salesproducts dan anders kunnen zijn, we
		// anders de eerste uit de lijst pakken
		if (!Store.CURRENT.handRails.inList(params.handRailType)) {
			params.handRailType = Configuration.CURRENT.handrailType;
		}
		this.handRailMaterials = Store.CURRENT.handRails.getMaterials(params.handRailType);

		this.poleWidth = Canvas3D.CURRENT.getModelDataByOid(this.handRailMaterials.post, 'width');
		this.childPoleWidth = Canvas3D.CURRENT.getModelDataByOid(this.handRailMaterials.childFriendlyPost, 'width');
		this.mainBeamHeight = params.mainBeamHeight;

		// Tekenen handrail eindigt altijd op laatste punt, maar in 3D teken je dan buiten de vloer. dus altijd 1 pole terug tekenen.
		length -= this.poleWidth;

		this.totalPoles = this.calculatePaaltes(length / 1000);
		this.distance = length / this.totalPoles;
		this.length = length;

		this.middlePoleLength = 1050 + (params.sideMounted === true ? this.mainBeamHeight : 0);
		this.cornerPoleLength = 1100 + (params.sideMounted === true ? this.mainBeamHeight : 0);
		this.position = position;
		this.childFriendly = params.childFriendly;
		this.flippedKickEdge = flippedKickEdge;

		// Rechterkant dan altijd 1 pole naar binnen tekenen.
		if (this.position === HandRails.RIGHT) {
			x -= this.poleWidth;
		}

		// Positie onderkant is altijd tot onderste punt, dus dan 1 paaltje terug naar boven tekenen.
		if (this.position === HandRails.BOTTOM) {
			z -= this.poleWidth;
		}

		// Parameters hier toekennen voor blokken profielnorm configurator.
		this.x = x;
		this.y = y;
		this.z = z;

		// Los bijhouden wat precies het 0 punt is op de vloer, bij sidemounted de rest wel lager tekenen.

		this.onFloorPosition = y;
		// this.distance = distance;

		Object.keys(params).forEach((param) => {
			this[param] = params[param];
		});

		this.childPoleHolderParams = {
			width: this.distance,
			length: length,
			position: this.position,
			handRailMaterials: this.handRailMaterials,
		};

		this.globalParams = {
			cornerPole: false,
			pole: 0,
			width: this.distance,
			length: length,
			middlePoleHeight: this.middlePoleLength,
			cornerPoleHeight: this.cornerPoleLength,
			handRailMaterials: this.handRailMaterials,
		};

		this.addLeds = params.addLeds;

		// Hoogte die het kindvriendelijke paaltje van de grond moet staan.
		this.childPoleDistanceFromGround = 90;

		// Aantal kinderpaaltjes tussen normale paaltjes in.
		this.amountofChildPoles = Math.floor(this.distance / 100) + (this.totalPoles > 1 ? 1 : 0);

		// Ruimte tussen nomale pole en eerste of laatste childpole.
		this.childPoleDistance = this.distance - 70;

		// Standaard hoogte van childpole
		this.childPoleHeight = 825;

		// Standaard hoogte vanaf de grond tot childpoleholder onder
		this.childPoleHolderHeightFromGround = 30;

		this.childPoleHolderHeight = Canvas3D.CURRENT.getModelData('childfriendly_pole_holder', 'height');

		if (params.sideMounted === true) {
			this.drawSideMountedHandRail(x, y, z);
		} else {
			this.drawHandRail(x, y, z);
		}
	}
	drawSideMountedHandRail(x, y, z) {
		// Profielhoogte eraf omdat hij daar tegen gemonteerd moet worden.
		y -= this.mainBeamHeight;
		// Met switch ervoor zorgen dat hij aan de juiste kant er precies buiten valt.
		switch (this.position) {
			case HandRails.TOP:
				z -= this.poleWidth;
				break;
			case HandRails.BOTTOM:
				z += this.poleWidth;
				break;
			case HandRails.RIGHT:
				x += this.poleWidth;
				break;
			case HandRails.LEFT:
				x -= this.poleWidth;
				break;
		}
		this.drawHandRail(x, y, z);
	}

	drawHandRail(x, y, z) {
		let xPosition;
		let zPosition;
		let childPoleX;
		let childPoleZ;

		// Kickedge over de gehele lengte.
		if (this.childFriendly === false) {
			Canvas3D.CURRENT.addDrawObject(
				new HandRailKickEdge3D(x, this.onFloorPosition, z, {
					length: this.length,
					position: this.flippedKickEdge ? Stair.toOppositeUpComing(this.position) : this.position,
					handRailMaterials: this.handRailMaterials,
				}),
				Canvas3D.TYPE_KICKEGDE,
			);
		}

		for (let i = this.totalPoles; i >= 0; i--) {
			// params.cornerPole = false;
			xPosition = this.getXPosition(x, this.distance, i);
			zPosition = this.getZPosition(z, this.distance, i);

			if (i === 0 || i === this.totalPoles) {
				// Aangeven dat het eerste en laatste paaltje een corner is.
				// Daar komt dan een toplate op en deze zijn beide iets hoger.
				this.globalParams.cornerPole = true;
				// Dit is nodig om te vertellen of het de eerste is.
				// De houders voor dit paaltje plaatsen we op basis van deze waarde
				if (i === 0) {
					this.globalParams.pole = 1;
				}
				// Dit is nodig om te vertellen of het de laatste is.
				// De houders voor dit paaltje plaatsen we op basis van deze waarde
				if (i === this.totalPoles) {
					this.globalParams.pole = 1;
				}
			} else {
				// Anders weer terugzetten.
				this.globalParams.cornerPole = false;
			}

			// Paaltjes toevoegen
			Canvas3D.CURRENT.addDrawObject(new HandRailPole3D(xPosition, y, zPosition, this.globalParams), Canvas3D.TYPE_POLE);

			// Voorkomen dat na het laatste paaltje ook nog plates en kindvriendelijke paaltjes worden getekend.
			if (i < this.totalPoles) {
				// Childfriendly paaltjes tekenen.
				if (this.childFriendly === true) {
					Canvas3D.CURRENT.addDrawObject(new HandRailChildPoleHolder3D(xPosition, y + this.childPoleHolderHeightFromGround, zPosition, this.childPoleHolderParams), Canvas3D.TYPE_POLE);
					Canvas3D.CURRENT.addDrawObject(
						new HandRailChildPoleHolder3D(xPosition, y + this.childPoleHeight + this.childPoleHolderHeight + this.childPoleHolderHeightFromGround, zPosition, this.childPoleHolderParams),
						Canvas3D.TYPE_POLE,
					);

					if (this.position === HandRails.BOTTOM || this.position === HandRails.TOP) {
						for (let childI = 0; childI < this.amountofChildPoles; childI++) {
							childPoleX = xPosition + this.childPoleWidth * (this.amountofChildPoles > 1 ? 1.5 : 1) + this.poleWidth + (this.childPoleDistance / this.amountofChildPoles) * childI;
							childPoleZ = zPosition + (this.position === HandRails.BOTTOM ? this.poleWidth - this.childPoleWidth : 0);
							Canvas3D.CURRENT.addDrawObject(new HandRailChildPole3D(childPoleX, y + this.childPoleDistanceFromGround, childPoleZ, this.globalParams), Canvas3D.TYPE_POLE);
						}
					} else if (this.position === HandRails.LEFT || this.position === HandRails.RIGHT) {
						for (let childI = 0; childI < this.amountofChildPoles; childI++) {
							childPoleZ = zPosition + this.childPoleWidth * (this.amountofChildPoles > 1 ? 1.5 : 2) + this.poleWidth + (this.childPoleDistance / this.amountofChildPoles) * childI;
							childPoleX = xPosition + (this.position === HandRails.RIGHT ? this.poleWidth - this.childPoleWidth : 0);
							Canvas3D.CURRENT.addDrawObject(new HandRailChildPole3D(childPoleX, y + this.childPoleDistanceFromGround, childPoleZ, this.globalParams), Canvas3D.TYPE_POLE);
						}
					}
				} else {
					// Leuning op de helft van de paalhoogte
					Canvas3D.CURRENT.addDrawObject(
						new HandRailPlateMiddle3D(xPosition, this.onFloorPosition - (this.sideMounted === true ? this.mainBeamHeight / 2 : 0), zPosition, {
							length: this.distance,
							position: this.position,
							handRailMaterials: this.handRailMaterials,
							cornerPoleHeight: this.cornerPoleLength,
						}),
						Canvas3D.TYPE_KNEERULE,
					);
				}
			}
		}

		// Lat toevoegen, logica zit in drawobject zelf.
		Canvas3D.CURRENT.addDrawObject(
			new HandRailPlateTop3D(x, this.onFloorPosition, z, {
				length: this.length,
				middlePoleHeight: this.middlePoleLength - (this.sideMounted === true ? this.mainBeamHeight : 0),
				position: this.position,
				handRailMaterials: this.handRailMaterials,
			}),
			Canvas3D.TYPE_HANDRULE,
		);

		// Wanneer Led getekend moet worden.
		if (this.addLeds === true) {
			Canvas3D.CURRENT.addDrawObject(
				new LedBar3D(x, y, z, {
					length: this.length,
					position: this.position,
					handRailMaterials: this.handRailMaterials,
					middlePoleHeight: this.middlePoleLength,
					ledColor: Configuration.CURRENT.handrailLedColor,
				}),
				Canvas3D.TYPE_LED,
			);
		}

		// Voor nu even uit omdat we anders issues krijgen met door de vloer heenkijken.
		// if (this.position === HandRails.BOTTOM || this.position === HandRails.TOP) {
		// 	// Box voor viewer toevoegen
		// 	Canvas3D.CURRENT.addDrawObject(
		// 		new Box3D(x, z, {
		// 			drawY: y,
		// 			width: this.length,
		// 			depth: 70,
		// 			height: this.cornerPoleLength,
		// 			ralColor: 10002,
		// 			invisible: true,
		// 			hoverTags: ['handrail'],
		// 		}),
		// 		Canvas3D.TYPE_HANDRAIL_BOX,
		// 	);
		// } else if (this.position === HandRails.LEFT || this.position === HandRails.RIGHT) {
		// 	// Box voor viewer toevoegen
		// 	Canvas3D.CURRENT.addDrawObject(
		// 		new Box3D(x, z, {
		// 			drawY: y,
		// 			width: 70,
		// 			depth: this.length,
		// 			height: this.cornerPoleLength,
		// 			ralColor: 10002,
		// 			invisible: false,
		// 			hoverTags: ['handrail'],
		// 		}),
		// 		Canvas3D.TYPE_HANDRAIL_BOX,
		// 	);
		// }
	}

	calculatePaaltes(length) {
		return Math.ceil(length / 1.5) + (length * 1000 > 1500 ? 1 : 0);
	}
	getXPosition(x, distance, i) {
		if (this.position === HandRails.BOTTOM || this.position === HandRails.TOP) {
			x += i * distance;
		}
		return x;
	}
	getZPosition(z, distance, i) {
		if (this.position === HandRails.LEFT || this.position === HandRails.RIGHT) {
			z += i * distance;
		}
		return z;
	}
}
