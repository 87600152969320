import { Calculate } from './calculate';
import { Data } from './data';
import { Store } from './store';
export class TorsionalSupportArticle extends Calculate {
	objectName = 'TorsionalSupportArticle';

	url = 'CalculateTorsionalSupportArticle';
	calculate(data, thenCallBack, catchCallBack) {
		if (typeof window === 'undefined') {
			return; // voorkom infinity loop op de server
		}

		// als er al een lijst is opgehaald dan opzoeken. Deze hoort al aanwezig te zijn en wordt opgehaald op het moment dat er gekozen wordt
		if (typeof this._list.torsionalSupportArticle !== 'undefined' && this._list.torsionalSupportArticle !== null) {
			if (typeof thenCallBack === 'function') {
				thenCallBack(this._list.torsionalSupportArticle);
			}
			return;
		} else if (this.listRequest === null) {
			//als er nog geen lopend verzoek is start een nieuw verzoek

			let cacheId = 'torsionalSupportArticle';

			if (typeof data.companyId === 'undefined' || data.companyId === null || data.companyId === -1) {
				data.companyId = Store.CURRENT.companies.selectedCompany.id;
			}

			this.post(
				data,
				cacheId,
				Data.baseUrl + this.url,
				true,
				() => {
					this.thenCallBack(thenCallBack);
				},
				() => {
					this.thenCallBack(thenCallBack);
				},
				'calculate',
			);
			return;
		}
		//geen artikel maar lopend verzoek placeholder teruggeven
		if (typeof catcthenCallBackCallBack === 'function') {
			thenCallBack({ id: 0, description: '', article: { oid: 0 } });
		}
	}
	thenCallBack(parentThenCallBack) {
		if (typeof this._list.torsionalSupportArticle !== 'undefined' && this._list.torsionalSupportArticle !== null) {
			if (typeof parentThenCallBack === 'function') {
				parentThenCallBack(this._list.torsionalSupportArticle);
			}
			return;
		}
		//geen artikel dan placeholder teruggeven
		if (typeof parentThenCallBack === 'function') {
			parentThenCallBack({ id: 0, description: '', article: { oid: 0 } });
		}
	}

	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}
		if (typeof data.companyId === 'undefined' || data.companyId === null || data.companyId === -1) {
			data.companyId = Store.CURRENT.companies.selectedCompany.id;
		}

		super.list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack);
	}
}
