import { ObjectGroup } from '../../draw/objectGroup';
import { Configuration } from '../configuration';
import { Profiles } from '../profiles';
import { Columns } from '../columns';
import { RemoveRaster } from '../removeRaster';
import { HoleTrimming } from './holeTrimming';
import { Trimmings } from './trimmings';

export class HoleTrimmings extends Trimmings {
	objectName = 'HoleTrimmings';
	setTrimming(x, y, width, depth, holeObjectId, addColumns = true) {
		let etage = Configuration.CURRENT.etages.activeEtage();
		let columnSize = Columns.COLUMN_SIZE / Configuration.CURRENT.canvas.scaleFactor;
		let columns = Configuration.CURRENT.columns;
		columns.removeByName(holeObjectId);

		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			// zoek raster bovenste en onderste raster

			if (
				(x < 0 && x + width < 0) ||
				(x > Configuration.CURRENT.raster.getSizeX() && x + width > Configuration.CURRENT.raster.getSizeX()) ||
				(y < 0 && y + depth < 0) ||
				(y > Configuration.CURRENT.raster.getSizeY() && y + depth > Configuration.CURRENT.raster.getSizeY())
			) {
				// kolom buiten de vloer dan niets doen
				return;
			}
			let topRaster = Configuration.CURRENT.raster.getRasterByCoordinate(x, y);
			let topRightRaster = Configuration.CURRENT.raster.getRasterByCoordinate(x + width, y);

			let bottomRaster = Configuration.CURRENT.raster.getRasterByCoordinate(x, y + depth);
			let bottomRightRaster = Configuration.CURRENT.raster.getRasterByCoordinate(x + width, y + depth);

			let endRaster = bottomRaster.y;
			if (bottomRaster.y === -1) {
				endRaster = Configuration.CURRENT.raster.spansY.length;
			}

			if (etage.isActiveRaster(new RemoveRaster(topRaster.x, topRaster.y)) || etage.isActiveRaster(new RemoveRaster(bottomRightRaster.x, bottomRightRaster.y))) {
				for (let raster = topRaster.y; raster <= endRaster; raster++) {
					let startY = Configuration.CURRENT.raster.getSizeY(raster - 1);
					let endY = Configuration.CURRENT.raster.getSizeY(raster);

					if (etage.isActiveRaster(new RemoveRaster(topRaster.x, topRaster.y)) === false && etage.isActiveRaster(new RemoveRaster(topRightRaster.x, topRightRaster.y)) === false) {
						startY = y;
					}

					if (etage.isActiveRaster(new RemoveRaster(bottomRaster.x, bottomRaster.y)) === false && etage.isActiveRaster(new RemoveRaster(bottomRightRaster.x, bottomRightRaster.y)) === false) {
						endY = y + depth;
					}

					// eventueel toevoegen van trimming
					if (x > columnSize && x < Configuration.CURRENT.raster.getSizeX() - columnSize) {
						// links alleen een volledige trimming als hij niet op de rand staat
						if (raster > -1 && raster < Configuration.CURRENT.raster.spansY.length) {
							// en niet buiten de vloer
							//* * Checken of het linkerpunt in een actief raster ligt, als dit niet zo is, gebruiken we een kortere hoofdbalk rond de gebouwkolom. */
							if ((etage.isActiveRaster(new RemoveRaster(topRaster.x, topRaster.y)) && etage.isActiveRaster(new RemoveRaster(bottomRaster.x, bottomRaster.y))) || topRaster.y !== bottomRaster.y) {
								this.trimmings.push(
									new HoleTrimming({
										startX: x,
										startY: startY,
										endX: x,
										endY: endY,
										position: Trimmings.LEFT,
									}),
								); // trimming links
							} else {
								this.trimmings.push(
									new HoleTrimming({
										startX: x,
										startY: y,
										endX: x,
										endY: y + depth,
										position: Trimmings.LEFT,
									}),
								); // trimming links
							}
						}
					} else {
						// als buiten raster valt dan alleen een profiel langs gebouwkolom

						this.trimmings.push(new HoleTrimming({ startX: x, startY: y, endX: x, endY: y + depth, position: Trimmings.LEFT })); // trimming rechts
						if (addColumns) {
							columns.push(-1, raster, holeObjectId, Columns.HOLE_COLUMN, { x: x, y: y - columnSize / 2 });
							columns.push(-1, raster, holeObjectId, Columns.HOLE_COLUMN, { x: x, y: y + depth + columnSize / 2 });
						}
					}

					if (x + width > columnSize && x + width < Configuration.CURRENT.raster.getSizeX() - columnSize) {
						// rechts alleen een volledige trimming als hij niet op de rand staat
						if (raster > -1 && raster < Configuration.CURRENT.raster.spansY.length) {
							// en niet buiten de vloer
							//* * Checken of het rechterpunt in een actief raster ligt, als dit niet zo is, gebruiken we een kortere hoofdbalk rond de gebouwkolom. */
							if (
								(etage.isActiveRaster(new RemoveRaster(topRightRaster.x, topRightRaster.y)) && etage.isActiveRaster(new RemoveRaster(bottomRightRaster.x, bottomRightRaster.y))) ||
								topRightRaster.y !== bottomRightRaster.y
							) {
								this.trimmings.push(
									new HoleTrimming({
										startX: x + width,
										startY: startY,
										endX: x + width,
										endY: endY,
										position: Trimmings.RIGHT,
									}),
								); // trimming rechts
							} else {
								this.trimmings.push(
									new HoleTrimming({
										startX: x + width,
										startY: y,
										endX: x + width,
										endY: y + depth,
										position: Trimmings.RIGHT,
									}),
								); // trimming rechts
							}
						}
					} else {
						// als buiten raster valt dan alleen een profiel langs gebouwkolom + 2 kolommen
						this.trimmings.push(new HoleTrimming({ startX: x + width, startY: y, endX: x + width, endY: y + depth, position: Trimmings.RIGHT })); // trimming links
						if (addColumns) {
							columns.push(-1, raster, holeObjectId, Columns.HOLE_COLUMN, { x: x + width, y: y - columnSize / 2 });
							columns.push(-1, raster, holeObjectId, Columns.HOLE_COLUMN, { x: x + width, y: y + depth + columnSize / 2 });
						}
					}

					// eventueel toevoegen van kolommen bij doorsnijden hoofdbalk
					if (addColumns) {
						if (y <= Configuration.CURRENT.raster.getSizeY(raster - 1) + columnSize && y + depth >= Configuration.CURRENT.raster.getSizeY(raster - 1) && raster > -1) {
							// (x, y, name, type, position)
							columns.push(-1, raster, holeObjectId, Columns.HOLE_COLUMN, { x: x - columnSize / 2, y: Configuration.CURRENT.raster.getSizeY(raster - 1) });
							columns.push(-1, raster, holeObjectId, Columns.HOLE_COLUMN, { x: x + width + columnSize / 2, y: Configuration.CURRENT.raster.getSizeY(raster - 1) });
						}
					}
				}

				if (this.trimmings.length >= 2) {
					// als beide trimmings aanwezig zijn dan boven en onder ook toevoegen

					if (
						!(
							(y >= Configuration.CURRENT.raster.getSizeY(topRaster.y - 1) - columnSize && y <= Configuration.CURRENT.raster.getSizeY(topRaster.y - 1) + columnSize) ||
							(y >= Configuration.CURRENT.raster.getSizeY(topRaster.y) - columnSize && y <= Configuration.CURRENT.raster.getSizeY(topRaster.y) + columnSize)
						) &&
						y >= 0 &&
						y <= Configuration.CURRENT.raster.getSizeY()
					) {
						// niet buiten de vloer
						// bovenkant niet op een hoofdbalk dan boven trimming omdat kolom op midden staat kolom eraf/erbij als tussenwaarde. Voorbij de helft detecteert hij extra raster
						// dan kijken naar profiel onderaan raster
						this.trimmings.push(new HoleTrimming({ startX: x, startY: y, endX: x + width, endY: y, position: Trimmings.TOP })); // trimming boven
					}

					if (
						!(
							(y + depth >= Configuration.CURRENT.raster.getSizeY(bottomRaster.y - 1) - columnSize && y + depth <= Configuration.CURRENT.raster.getSizeY(bottomRaster.y - 1) + columnSize) ||
							(y + depth >= Configuration.CURRENT.raster.getSizeY(bottomRaster.y) - columnSize && y + depth <= Configuration.CURRENT.raster.getSizeY(bottomRaster.y) + columnSize)
						) &&
						y + depth >= 0 &&
						y + depth <= Configuration.CURRENT.raster.getSizeY()
					) {
						// niet buiten de vloer
						// onderkant niet op een hoofdbalk dan boven trimming omdat kolom op midden staat kolom eraf/erbij als tussenwaarde. Voorbij de helft detecteert hij extra raster
						// dan kijken naar profiel onderaan raster
						this.trimmings.push(new HoleTrimming({ startX: x, startY: y + depth, endX: x + width, endY: y + depth, position: Trimmings.BOTTOM })); // trimming onder
					}
				}
			}
		} else {
			if (
				(x < 0 && x + width < 0) ||
				(x > Configuration.CURRENT.raster.getSizeX() && x + width > Configuration.CURRENT.raster.getSizeX()) ||
				(y < 0 && y + depth < 0) ||
				(y > Configuration.CURRENT.raster.getSizeY() && y + depth > Configuration.CURRENT.raster.getSizeY())
			) {
				// kolom buiten de vloer dan niets doen
				return;
			}
			// zoek raster bovenste en onderste raster
			let leftRaster = Configuration.CURRENT.raster.getRasterByCoordinate(x, y);
			let leftBotomRaster = Configuration.CURRENT.raster.getRasterByCoordinate(x, y + depth);
			let rightRaster = Configuration.CURRENT.raster.getRasterByCoordinate(x + width, y);
			let rightBottomRaster = Configuration.CURRENT.raster.getRasterByCoordinate(x + width, y + depth);
			let bottomRaster = Configuration.CURRENT.raster.getRasterByCoordinate(x + width, y + depth);

			let endRaster = rightRaster.x;
			if (rightRaster.x === -1) {
				endRaster = Configuration.CURRENT.raster.spansX.length;
			}
			//* * Checken of X begin punt kolom raster actief is en of het eindpunt kolom raster ook actief is.
			if (etage.isActiveRaster(new RemoveRaster(leftRaster.x, leftRaster.y)) || etage.isActiveRaster(new RemoveRaster(bottomRaster.x, bottomRaster.y))) {
				for (let raster = leftRaster.x; raster <= endRaster; raster++) {
					//* Checken of het startX en endX in een ander raster liggen, als dat het geval is, dan lengte van voirge raster gebruiken + breedte van eigen buildingColumn, checken zowel verticaal als horizontaal.
					let startX = Configuration.CURRENT.raster.getSizeX(raster - 1);
					let endX = Configuration.CURRENT.raster.getSizeX(raster);

					if (etage.isActiveRaster(new RemoveRaster(leftRaster.x, rightRaster.y)) === false && etage.isActiveRaster(new RemoveRaster(leftBotomRaster.x, leftBotomRaster.y)) === false) {
						startX = x;
					}

					if (etage.isActiveRaster(new RemoveRaster(rightRaster.x, rightRaster.y)) === false && etage.isActiveRaster(new RemoveRaster(rightBottomRaster.x, rightBottomRaster.y)) === false) {
						endX = x + width;
					}

					if (y > columnSize && y < Configuration.CURRENT.raster.getSizeY() - columnSize) {
						// boven alleen een volledige trimming als hij niet op de rand staat
						if (raster > -1 && raster < Configuration.CURRENT.raster.spansX.length) {
							// en niet buiten de vloer

							if ((etage.isActiveRaster(new RemoveRaster(leftRaster.x, leftRaster.y)) && etage.isActiveRaster(new RemoveRaster(rightRaster.x, rightRaster.y))) || leftRaster.x !== rightRaster.x) {
								this.trimmings.push(new HoleTrimming({ startX: startX, startY: y, endX: endX, endY: y, position: Trimmings.TOP })); // trimming links
							} else {
								this.trimmings.push(new HoleTrimming({ startX: x, startY: y, endX: x + width, endY: y, position: Trimmings.TOP })); // trimming links
							}
						}
					} else {
						this.trimmings.push(new HoleTrimming({ startX: x, startY: y, endX: x + width, endY: y, position: Trimmings.TOP })); // trimming links
						// anders alleen romdom buildingkolom + 2 kolommen
						if (addColumns) {
							columns.push(-1, raster, holeObjectId, Columns.HOLE_COLUMN, { x: x - columnSize / 2, y: y });
							columns.push(-1, raster, holeObjectId, Columns.HOLE_COLUMN, { x: x + width + columnSize / 2, y: y });
						}
					}
					if (y + depth > columnSize && y + depth < Configuration.CURRENT.raster.getSizeY() - columnSize) {
						// onder alleen een trimming als hij niet op de rand staat
						if (raster > -1 && raster < Configuration.CURRENT.raster.spansX.length) {
							// en niet buiten de vloer
							// ** Als beginpunt gebouwkolom linksonder en beginpunt gebouwkolom rechtsonder raster beide actief zijn gebruik dan de uitgerekende lengte. anders lengte en beginpunten gebouwkolom aanhouden.
							if (
								(etage.isActiveRaster(new RemoveRaster(leftBotomRaster.x, leftBotomRaster.y)) && etage.isActiveRaster(new RemoveRaster(rightBottomRaster.x, rightBottomRaster.y))) ||
								leftBotomRaster.x !== rightBottomRaster.x
							) {
								this.trimmings.push(
									new HoleTrimming({
										startX: startX,
										startY: y + depth,
										endX: endX,
										endY: y + depth,
										position: Trimmings.BOTTOM,
									}),
								);
							} else {
								this.trimmings.push(
									new HoleTrimming({
										startX: x,
										startY: y + depth,
										endX: x + width,
										endY: y + depth,
										position: Trimmings.BOTTOM,
									}),
								);
							}
						}
					} else {
						// anders alleen romdom buildingkolom + 2 kolommen
						this.trimmings.push(new HoleTrimming({ startX: x, startY: y + depth, endX: x + width, endY: y + depth, position: Trimmings.BOTTOM })); // trimming rechts
						if (addColumns) {
							columns.push(-1, raster, holeObjectId, Columns.HOLE_COLUMN, { x: x - columnSize / 2, y: y + depth });
							columns.push(-1, raster, holeObjectId, Columns.HOLE_COLUMN, { x: x + width + columnSize / 2, y: y + depth });
						}
					}
					if (addColumns) {
						// eventueel toevoegen van kolommen bij doorsnijden hoofdbalk
						if (x <= Configuration.CURRENT.raster.getSizeX(raster - 1) + columnSize && x + width >= Configuration.CURRENT.raster.getSizeX(raster - 1) && raster > -1) {
							columns.push(raster, -1, holeObjectId, Columns.HOLE_COLUMN, { x: Configuration.CURRENT.raster.getSizeX(raster - 1), y: y - columnSize / 2 });
							columns.push(-1, raster, holeObjectId, Columns.HOLE_COLUMN, { x: Configuration.CURRENT.raster.getSizeX(raster - 1), y: y + depth + columnSize / 2 });
						}
					}
				}
				if (this.trimmings.length >= 2) {
					// als beide trimmings aanwezig zijn dan links en rechts ook toevoegen
					if (
						!(
							(x >= Configuration.CURRENT.raster.getSizeX(leftRaster.x - 1) - columnSize && x <= Configuration.CURRENT.raster.getSizeX(leftRaster.x - 1) + columnSize) ||
							(x >= Configuration.CURRENT.raster.getSizeX(leftRaster.x) - columnSize && x <= Configuration.CURRENT.raster.getSizeX(leftRaster.x) + columnSize)
						) &&
						x >= 0 &&
						x <= Configuration.CURRENT.raster.getSizeX()
					) {
						// niet buiten de vloer
						// bovenkant niet op een hoofdbalk dan boven trimming omdat kolom op midden staat kolom eraf/erbij als tussenwaarde. Voorbij de helft detecteert hij extra raster
						// dan kijken naar profiel onderaan raster
						this.trimmings.push(new HoleTrimming({ startX: x, startY: y, endX: x, endY: y + depth, position: Trimmings.LEFT })); // trimming links
					}

					if (
						!(
							(x + width >= Configuration.CURRENT.raster.getSizeX(rightRaster.x - 1) - columnSize && x + width <= Configuration.CURRENT.raster.getSizeX(rightRaster.x - 1) + columnSize) ||
							(x + width >= Configuration.CURRENT.raster.getSizeX(rightRaster.x) - columnSize && x + width <= Configuration.CURRENT.raster.getSizeX(rightRaster.x) + columnSize)
						) &&
						x + width > 0 &&
						x + width <= Configuration.CURRENT.raster.getSizeX()
					) {
						// niet buiten de vloer

						// onderkant niet op een hoofdbalk dan boven trimming omdat kolom op midden staat kolom eraf/erbij als tussenwaarde. Voorbij de helft detecteert hij extra raster
						// dan kijken naar profiel onderaan raster
						this.trimmings.push(new HoleTrimming({ startX: x + width, startY: y, endX: x + width, endY: y + depth, position: Trimmings.RIGHT })); // trimming rechts
					}
				}
			}
		}
	}
}
