import { Configuration } from '../configurator/configuration';
import { Canvas } from './canvas';
import { SizeHandle } from './sizeHandle';

export class SizeHandleTotalOutside extends SizeHandle {
	objectName = 'SizeHandleTotalOutside';
	referenceHandle = '';
	constructor(id, order) {
		super(id, order, false);
	}
	addDrawObjects(params) {
		const referenceHandle = Canvas.CURRENT.sizeHandles.get(SizeHandle.TYPE_RASTER);

		// Als referentie pakken we de sizehandle van raster, dit type is totaloutside dus overhang erbij aal beide kanten.
		if (typeof referenceHandle !== 'undefined' && referenceHandle !== null) {
			this.horizontal.push(referenceHandle.horizontal.getTotalLength() + Configuration.CURRENT.overhang.size * 2);
			this.vertical.push(referenceHandle.vertical.getTotalLength() + Configuration.CURRENT.overhang.size * 2);
		}
		this.startPosition = -Configuration.CURRENT.overhang.size;
		super.addDrawObjects(params);
	}
}
