import { Canvas } from '../../draw/canvas';
import { Circle } from '../../draw/circle';
import { DrawValue } from '../../draw/drawValue';
import { ObjectGroup } from '../../draw/objectGroup';
import { Rectangle } from '../../draw/rectangle';
import { SizeHandle } from '../../draw/sizeHandle';
import { Mathematic } from '../../helpers/mathematic';
import { Statics } from '../../helpers/statics';
import { Configuration } from '../configuration';
import { RemoveRaster } from '../removeRaster';
import { Stair } from '../stair';
import { Stairs } from '../stairs';
import { CageLadder } from './cageLadder';

export class CageLadderInFloor extends CageLadder {
	objectName = 'CageLadderInFloor';
	place = Stair.PLACE_INFLOOR;

	constructor(newCageLadderConfiguration, drawObject, cageLadder) {
		super(newCageLadderConfiguration, cageLadder);

		// Drawobject is hier geklikte possible position.
		if (drawObject !== null && typeof drawObject !== 'undefined') {
			const startClickedRasterX = Configuration.CURRENT.raster.getSizeX(drawObject.objectParams.x - 1);
			const startClickedRasterY = Configuration.CURRENT.raster.getSizeY(drawObject.objectParams.y - 1);
			const endClickedRasterX = Configuration.CURRENT.raster.getSizeX(drawObject.objectParams.x);
			const endClickedRasterY = Configuration.CURRENT.raster.getSizeY(drawObject.objectParams.y);

			this.x = startClickedRasterX + (endClickedRasterX - startClickedRasterX) / 2 - this.getWidth() / 2;
			this.y = startClickedRasterY + (endClickedRasterY - startClickedRasterY) / 2 - this.getDepth() / 2;
		}
	}

	// !! RASTERCHANGED FUNCTIONALITY.
	onRasterChanged() {
		this.setBoundaries();
		this.updateRasters();
		this.addUsedSurface();
		this.active = this.rastersActive();
		super.onRasterChanged();
	}

	updateRasters() {
		this.rasters = [];

		// bepaal bijbehorend raster linksboven
		const rasterLeftTop = Configuration.CURRENT.raster.getRasterByCoordinate(this.x, this.y);
		this.insertUniqueRaster(rasterLeftTop);

		// bepaal bijbehorend raster rechtsboven
		const rasterRightTop = Configuration.CURRENT.raster.getRasterByCoordinate(this.x + this.getWidth(), this.y);
		this.insertUniqueRaster(rasterRightTop);

		// bepaal bijbehorend raster rechtsboven
		const rasterLeftBottom = Configuration.CURRENT.raster.getRasterByCoordinate(this.z, this.y + this.getDepth());
		this.insertUniqueRaster(rasterLeftBottom);
		// bepaal bijbehorend raster rechtsonder
		const rasterRightBottom = Configuration.CURRENT.raster.getRasterByCoordinate(this.x + this.getWidth(), this.y + this.getDepth());
		this.insertUniqueRaster(rasterRightBottom);
	}
	rastersActive() {
		// Standaard actief false
		let active = false;
		this.rasters.forEach((raster) => {
			// Als er 1 raster is waar de trap invalt die actief is dan actief op true zetten, omdat de trap gedeeltelijk buiten de vloer kan vallen.
			if (Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(raster.x, raster.y)) === true) {
				active = true;
			}
		});
		return active;
	}

	// !! POSSIBLE POSITION LOGIC.
	addPossiblePositions() {
		Configuration.CURRENT.raster.spansX.getSpans().forEach((spanX, indexX) => {
			Configuration.CURRENT.raster.spansY.getSpans().forEach((spanY, indexY) => {
				if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(indexX, indexY)) === true) {
					let raster = new Rectangle(
						new DrawValue(Configuration.CURRENT.raster.getSizeX(indexX - 1)),
						new DrawValue(Configuration.CURRENT.raster.getSizeY(indexY - 1)),
						new DrawValue(spanX.value),
						new DrawValue(spanY.value),
						Statics.COLOR_POSITION_POSSIBLE,
						null,
						null,
						true,
						Configuration.CURRENT.cageLadders,
						{ x: indexX, y: indexY, place: this.place },
					);
					raster.opacity = 0.2;
					Canvas.CURRENT.addDrawObject(raster);
				}
			});
		});
	}

	// !! MOUSE OVER POSSIBLE POSITION AND LEAVE POSSIBLE POSITION.
	onMouseMovePossiblePosition(evt, drawObject) {
		drawObject.opacity = 0.5;
		return { stopPropagation: true };
	}
	onMouseLeavePossiblePosition(evt, drawObject) {
		drawObject.opacity = 0.2;
		return { stopPropagation: true };
	}

	// !! MOUSE EVENTS.
	onMouseUp(evt, drawObject) {
		this.setBoundaries();
		this.addDrawObjects(null);

		this.addUsedSurface();
		this.onChange();
		return { stopPropagation: true };
	}

	getPositionsMouseDrag() {
		let minX = 0;
		let maxX = Configuration.CURRENT.raster.spansX.getSize();
		let minY = 0;
		let maxY = Configuration.CURRENT.raster.spansY.getSize();

		let topLeftRaster = Configuration.CURRENT.raster.getRasterByCoordinate(this.boundaries[0].topLeft.x, this.boundaries[0].topLeft.y);
		let topRightRaster = Configuration.CURRENT.raster.getRasterByCoordinate(this.boundaries[0].topRight.x, this.boundaries[0].topRight.y);
		let bottomLeftRaster = Configuration.CURRENT.raster.getRasterByCoordinate(this.boundaries[0].bottomLeft.x, this.boundaries[0].bottomLeft.y);
		let bottomRightRaster = Configuration.CURRENT.raster.getRasterByCoordinate(this.boundaries[0].bottomRight.x, this.boundaries[0].bottomRight.y);

		switch (this.position) {
			case Mathematic.TOP:
				// minY bepalen.
				// Bij upComing top dan altijd tot aan vorige rasterpunt, bepalend dan lefttop en righttop.
				if (
					Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(topLeftRaster.x, topLeftRaster.y - 1)) === false ||
					Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(topRightRaster.x, topRightRaster.y - 1)) === false
				) {
					minY = Configuration.CURRENT.raster.spansY.getSize(topLeftRaster.y - 1);
				}

				// maxY bepalen.
				// Bij top mag hij vanaf boven in een raster naar beneden worden gesleept.
				//

				if (
					Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(topLeftRaster.x, topLeftRaster.y + 1)) === false ||
					Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(topRightRaster.x, topRightRaster.y + 1)) === false
				) {
					// Versleping naar onder, kijken of raster eronder actief is, als dat niet zo is dan stoppen aan eind actief raster.
					maxY = Configuration.CURRENT.raster.spansY.getSize(topLeftRaster.y);
				}
				break;
			case Mathematic.BOTTOM:
				// minY bepalen
				// Stair naar onder mag altijd naar boven in een leeg raster vallen.
				// Daarom vanaf onderste punten bepalen wat de min is.
				if (
					Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(bottomLeftRaster.x, bottomLeftRaster.y - 1)) === false ||
					Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(bottomRightRaster.x, bottomRightRaster.y - 1)) === false
				) {
					minY = Configuration.CURRENT.raster.spansY.getSize(bottomLeftRaster.y - 1);
				}

				// maxY bepalen.
				// Dan mag de stair altijd tot aan onderste punt stair, en niet verder in een leeg raster gesleept worden.
				if (
					Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(bottomLeftRaster.x, bottomLeftRaster.y + 1)) === false ||
					Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(bottomRightRaster.x, bottomRightRaster.y + 1)) === false
				) {
					// Versleping naar onder, kijken of raster eronder actief is, als dat niet zo is dan stoppen aan eind actief raster.
					maxY = Configuration.CURRENT.raster.spansY.getSize(bottomLeftRaster.y);
				}
				break;
			case Mathematic.LEFT:
				// Bij versleping naar links, minX bepalen.
				// Dan mag de trap nooit verder dan de eigen rand.
				if (
					Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(topLeftRaster.x - 1, topLeftRaster.y)) === false ||
					Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(bottomLeftRaster.x - 1, bottomLeftRaster.y)) === false
				) {
					minX = Configuration.CURRENT.raster.spansX.getSize(topLeftRaster.x - 1);
				}

				// Bij versleping naar rechts maxX bepalen.
				// Bij stair links dan mag hij tot aan volgende raster aan linkerpunt te zien. Zo kan hij gedeeltelijk in een leeg raster vallen.
				if (
					Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(topLeftRaster.x + 1, topLeftRaster.y)) === false ||
					Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(bottomLeftRaster.x + 1, bottomLeftRaster.y)) === false
				) {
					maxX = Configuration.CURRENT.raster.spansX.getSize(topLeftRaster.x);
				}
				break;
			case Mathematic.RIGHT:
				// Bij versleping naar links, minX bepalen
				// Kan dan gedeeltelijk aan de linkerkant van een raster uitsteken.
				// Daarom hier checken op right - 1.
				if (
					Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(topRightRaster.x - 1, topRightRaster.y)) === false ||
					Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(bottomRightRaster.x - 1, bottomRightRaster.y)) === false
				) {
					minX = Configuration.CURRENT.raster.spansX.getSize(bottomRightRaster.x - 1);
				}

				// Bij versleping naar rechts maxX bepalen.
				// Kan niet uitsteken aan de rechterkant dus maxX vanaf linkerpunt bepalen.
				if (
					Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(topLeftRaster.x + 1, topLeftRaster.y)) === false ||
					Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(bottomLeftRaster.x + 1, bottomLeftRaster.y)) === false
				) {
					maxX = Configuration.CURRENT.raster.spansX.getSize(topLeftRaster.x);
				}
				break;
		}
		// MaxX en MinX bepalen is voor top en bottom hetzelfde.
		if (this.position === Mathematic.BOTTOM || this.position === Mathematic.TOP) {
			// Als beide linker rasters niet actief zijn dan gewoon tot aan vorige raster (niet afhankelijk van het gecheckte raster).
			// Of bij stair naar boven, en raster links naast punt linksboven is niet actief, dan ook niet verder naar links.
			// Of bij stair naar onder, en raster links naast punt linksonder is niet actief, dan ook niet verder naar links.
			if (
				(Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(topLeftRaster.x - 1, topLeftRaster.y)) === false &&
					Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(bottomLeftRaster.x - 1, bottomLeftRaster.y)) === false) ||
				(Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(topLeftRaster.x - 1, topRightRaster.y)) === false && this.upComing === Stair.UPCOMING_TOP) ||
				(Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(bottomLeftRaster.x - 1, bottomLeftRaster.y)) === false && this.upComing === Stair.UPCOMING_BOTTOM)
			) {
				minX = Configuration.CURRENT.raster.spansX.getSize(topLeftRaster.x - 1);
			}

			// Als beide recter rasters niet actief zijn dan gewoon tot aan volgende raster (niet afhankelijk van het gecheckte raster).
			// Of bij stair naar boven, en raster rechts naast punt rechtsboven is niet actief, dan ook niet verder naar rechts.
			// Of bij stair naar benende, en raster rechtsonder naast punt rechtsonder is niet acitef, dan ook niet verder naar rechts.
			if (
				(Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(topRightRaster.x + 1, topRightRaster.y)) === false &&
					Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(bottomRightRaster.x + 1, bottomRightRaster.y)) === false) ||
				(Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(topRightRaster.x + 1, topRightRaster.y)) === false && this.upComing === Stair.UPCOMING_TOP) ||
				(Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(bottomRightRaster.x + 1, bottomRightRaster.y)) === false && this.upComing === Stair.UPCOMING_BOTTOM)
			) {
				maxX = Configuration.CURRENT.raster.spansX.getSize(topRightRaster.x);
			}
		}
		// MinY en MaxY bepalen is voor left en right hetzelde.
		else if (this.position === Mathematic.LEFT || this.position === Mathematic.RIGHT) {
			// Als beide onder rasters niet actief zijn dan gewoon tot aan volgende raster (niet afhankelijk van het gecheckte raster).
			// Of bij stair naar links, en raster onder linkspunt is niet actief, dan ook niet verder naar onder.
			// Of bij stair naar rechts, en raster onder rechterpunt is niet actief, dan ook niet verder naar onder.
			if (
				(Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(bottomLeftRaster.x, bottomLeftRaster.y + 1)) === false &&
					Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(bottomRightRaster.x, bottomRightRaster.y + 1)) === false) ||
				(Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(bottomLeftRaster.x, bottomLeftRaster.y + 1)) === false && this.upComing === Stair.UPCOMING_LEFT) ||
				(Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(bottomRightRaster.x, bottomRightRaster.y + 1)) === false && this.upComing === Stair.UPCOMING_RIGHT)
			) {
				maxY = Configuration.CURRENT.raster.spansY.getSize(bottomLeftRaster.y);
			}
			// Als beide linker rasters niet actief zijn dan gewoon tot aan vorige raster (niet afhankelijk van het gecheckte raster).
			// Of wanneer bij stair naar links, en raster boven linkerpunt is niet actief, dan ook niet verder naar boven.
			// Of wanneer bij stair naar rechts, en raster boven rechterpunt is niet actief, dan ook niet verder naar boven.
			if (
				(Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(topLeftRaster.x, topLeftRaster.y - 1)) === false &&
					Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(topRightRaster.x, topRightRaster.y - 1)) === false) ||
				(Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(topLeftRaster.x, topLeftRaster.y - 1)) === false && this.upComing == Stair.UPCOMING_LEFT) ||
				(Configuration.CURRENT.etages.etages[this.endEtageIndex].isActiveRaster(new RemoveRaster(topRightRaster.x, topRightRaster.y - 1)) === false && this.upComing == Stair.UPCOMING_RIGHT)
			) {
				minY = Configuration.CURRENT.raster.spansY.getSize(topLeftRaster.y - 1);
			}
		}

		return { minX: minX, maxX: maxX, minY: minY, maxY: maxY };
	}
	onMouseDrag(evt, drawObject) {
		// Group ID opgeslagen, alleen selecteren op rectangle maar wel hele groep verslepen.
		let groupObject = Canvas.CURRENT.drawObjects.findByDrawId(this.drawGroupId);

		let moveX = evt.delta.x / Canvas.CURRENT.scaleFactor; // verplaatsing links-rechts (links - rechts +)
		let moveY = evt.delta.y / Canvas.CURRENT.scaleFactor; // verplaatsing onder-boven (boven - onder +)

		// Trap in de vloer kan gedeeltelijk buiten vloer vallen, maar dan wel minimaal deze marge aanhouden zodat er nog op de vloer gestapt kan worden.
		const spaceStepOnFloor = 275;
		// Bij tegenovergestlede maximale X, dit zorgt ervoor dat de berekening van de stairwell en rasters goed gaat.
		const minSpaceEdge = 75;
		// Zodat trap niet op de rand van raster ligt om errors te voorkomen.
		const minSpaceRaster = 75;

		let minMaxPositions = this.getPositionsMouseDrag();

		let minX = minMaxPositions.minX;
		let minY = minMaxPositions.minY;
		let maxY = minMaxPositions.maxY;
		let maxX = minMaxPositions.maxX;

		let minMaxCoordinatesXmove = groupObject.minMaxCoordinates();
		let minMaxCoordinatesYmove = groupObject.minMaxCoordinates();

		switch (this.position) {
			case Mathematic.LEFT:
				// Trap verschuiving naar rechts niet groter dan maximale vloer.
				if (minMaxCoordinatesXmove.max.x + moveX > maxX - spaceStepOnFloor) {
					moveX = maxX - minMaxCoordinatesXmove.max.x - spaceStepOnFloor;
				}

				// Trap verschuiving naar links niet kleiner dan de stepMarge.
				if (minMaxCoordinatesXmove.min.x + moveX < minX + minSpaceRaster) {
					moveX = minX - minMaxCoordinatesXmove.min.x + minSpaceRaster;
				}
				break;
			case Mathematic.RIGHT:
				// Trap verschuiving naar rechts niet groter dan maximale min de stepMarge.
				if (minMaxCoordinatesXmove.max.x + moveX > maxX - minSpaceRaster) {
					moveX = maxX - minSpaceRaster - minMaxCoordinatesXmove.max.x;
				}

				// Cageladder naar links vershuiven,
				// In deze situatie hebben we opstap mogelijkheid nodig.
				// Daarom rekenen met 500mm vanaf linkerkant raster.
				if (minMaxCoordinatesXmove.min.x + moveX < minX + spaceStepOnFloor) {
					moveX = minX - minMaxCoordinatesXmove.min.x + spaceStepOnFloor;
				}
				break;
			case Mathematic.TOP:
				// Naar boven verschuiven,
				// Hoeven dan in principe geen rekening te houden met dat er ruimte is omdat we aan de onderkant op de vloer komen (vanaf cageladder gezien)
				// Maar voor rastercheck kleine marge gebruiken.
				if (minMaxCoordinatesYmove.min.y + moveY < minY + minSpaceEdge) {
					moveY = minY - minMaxCoordinatesYmove.min.y + minSpaceEdge;
				}

				// Naar onderen bij cage naar boven gericht willen we aan de onderkant minimale ruimte hebben om op de vloer te stappen.
				if (minMaxCoordinatesYmove.max.y + moveY > maxY - spaceStepOnFloor) {
					moveY = maxY - minMaxCoordinatesYmove.max.y - spaceStepOnFloor;
				}
				break;
			case Mathematic.BOTTOM:
				// Naar boven bij cage naar boven gericht willen we aan de onderkant minimale ruimte hebben om op de vloer te stappen.
				if (minMaxCoordinatesYmove.min.y + moveY < minY + spaceStepOnFloor) {
					moveY = minY - minMaxCoordinatesYmove.min.y + spaceStepOnFloor;
				}

				// Trap verschuiving naar onder niet groter dan maximale min de stepMarge.
				if (minMaxCoordinatesYmove.max.y + moveY > maxY - minSpaceEdge) {
					moveY = maxY - minMaxCoordinatesYmove.max.y - minSpaceEdge;
				}
				break;
		}

		// Wanneer trap boven of onder uitkomt dan minimale X = 0 en maximale X = maximale vloer.
		if (this.position === Mathematic.TOP || this.position === Mathematic.BOTTOM) {
			// Versleping naar links tot aan minX.
			if (minMaxCoordinatesXmove.min.x + moveX < minX + minSpaceRaster) {
				moveX = minX - minMaxCoordinatesXmove.min.x + minSpaceRaster;
			}
			// Versleping naar rechts tot aan maxX.
			if (minMaxCoordinatesXmove.max.x + moveX > maxX - minSpaceRaster) {
				moveX = maxX - minMaxCoordinatesXmove.max.x - minSpaceRaster;
			}
		}
		// Wanneer trap links of rechts uitkomt dan minimale Y = 0 en maximale Y = maximale vloer.
		if (this.position === Mathematic.LEFT || this.position === Mathematic.RIGHT) {
			// Onderkant groter dan vloer grootte dan resetten naar maximale vloer.
			if (minMaxCoordinatesYmove.max.y + moveY > maxY - minSpaceRaster) {
				moveY = maxY - minMaxCoordinatesYmove.max.y - minSpaceRaster;
			}

			// Bovenkant kleiner dan 0 punt dan resetten naar 0.
			if (minMaxCoordinatesYmove.min.y + moveY < minY + minSpaceRaster) {
				moveY = minY - minMaxCoordinatesYmove.min.y + minSpaceRaster;
			}
		}

		this.x += Math.round(moveX); // neem x van trapgat over naar de trap
		this.y += Math.round(moveY); // neem y van trapgat over naar de trap

		// Set boundaries hieraanroepen omdat als we blijven slepen regels bepaling gaat fout.
		this.setBoundaries();

		Configuration.CURRENT.dimensioning.updateDrawObjects(this.x, this.y, this.getWidth(), this.getDepth(), SizeHandle.TYPE_OBJECT);

		groupObject.drawObjects.forEach((drawObject) => {
			drawObject.x.value += Math.round(moveX); // naar linkes en rechts verplaatsen
			drawObject.y.value += Math.round(moveY); // naar boven/onder verplaatsen
		});

		return { stopPropagation: true };
	}

	// !! DRAW.
	addDrawObjects(drawGroup) {
		let useColor = Statics.COLOR_GREY;
		if (this.selected === true) {
			useColor = Statics.COLOR_SELECTED;
			Configuration.CURRENT.dimensioning.setSizeHandleObject(this, SizeHandle.TYPE_OBJECT, false, this.x, this.y, this.getWidth(), this.getDepth());
		}
		if (this.hasErrors === true) {
			useColor = Statics.COLOR_ERROR;
		}

		if (typeof drawGroup === 'undefined' || drawGroup === null) {
			drawGroup = new ObjectGroup(useColor, null, null, false, this, {});
			this.drawGroupId = drawGroup.id;
		}

		let cage;
		const cageLadderHole = new Rectangle(
			new DrawValue(this.x),
			new DrawValue(this.y),
			new DrawValue(this.getWidth()),
			new DrawValue(this.getDepth()),
			useColor,
			Statics.COLOR_WHITE,
			null,
			true,
			this,
			{
				type: 'cageLadderHole',
				object: this,
			},
			false,
			true,
		);

		// Create cage itself.
		switch (this.position) {
			case Mathematic.TOP:
				cage = new Circle(
					new DrawValue(this.x),
					new DrawValue(this.y + this.getDepth()),
					new DrawValue(this.getWidth()),
					new DrawValue(this.getDepth()),
					0,
					180,
					null,
					useColor,
					false,
					this,
					{ type: 'cageLadder', object: this },
					this.position === Mathematic.BOTTOM ? true : false,
					this.position,
				);
				break;
			case Mathematic.BOTTOM:
				cage = new Circle(
					new DrawValue(this.x),
					new DrawValue(this.y),
					new DrawValue(this.getWidth()),
					new DrawValue(this.getDepth()),
					0,
					180,
					null,
					useColor,
					false,
					this,
					{ type: 'cageLadder', object: this },
					this.position === Mathematic.BOTTOM ? true : false,
					this.position,
				);
				break;
			case Mathematic.RIGHT:
				cage = new Circle(
					new DrawValue(this.x),
					new DrawValue(this.y),
					new DrawValue(this.getDepth()),
					new DrawValue(this.getWidth()),
					90,
					270,
					null,
					useColor,
					false,
					this,
					{ type: 'cageLadder', object: this },
					this.position === Mathematic.LEFT ? true : false,
					this.position,
				);
				break;
			case Mathematic.LEFT:
				cage = new Circle(
					new DrawValue(this.x + this.getWidth()),
					new DrawValue(this.y),
					new DrawValue(this.getDepth()),
					new DrawValue(this.getWidth()),
					90,
					270,
					null,
					useColor,
					false,
					this,
					{ type: 'cageLadder', object: this },
					this.position === Mathematic.LEFT ? true : false,
					this.position,
				);
				break;
		}
		if (cage instanceof Circle) {
			cage.colorFromParent = false;
			drawGroup.push(cage);
		}

		if (cageLadderHole instanceof Rectangle) {
			cageLadderHole.colorFromParent = false;
			drawGroup.push(cageLadderHole, true);
		}
		Canvas.CURRENT.addDrawObject(drawGroup);
	}

	// !! SURFACE LOGIC.
	addUsedSurface() {
		this.removeUsedSurface();
		Configuration.CURRENT.etages.etages[this.endEtageIndex].usedSurface.push({
			width: this.getWidth(),
			depth: this.getDepth(),
			id: this.id,
		});
	}
	removeUsedSurface() {
		Configuration.CURRENT.etages.etages[this.endEtageIndex].usedSurface.forEach((surf, index) => {
			if (surf.id === this.id) {
				Configuration.CURRENT.etages.etages[this.endEtageIndex].usedSurface.splice(index, 1);
			}
		});
	}
	getHolePosition() {
		return { startX: this.x, startY: this.y, endX: this.x + this.getWidth(), endY: this.y + this.getDepth() };
	}
}
