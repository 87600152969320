import * as pc from 'playcanvas';
import { Model3D } from './Model3D';

export class DefaultFloor3D extends Model3D {
	objectName = 'DefaultFloor3D';
	fallBackName = 'defaultfloor';

	draw(app) {
		this.x += this.width / 2;
		this.y += this.height / 2;
		this.z += this.depth / 2;

		// Standaard iets breder dan de vloer, voor netheid.
		this.width += 5;
		this.depth += 5;

		// Starten met een pc entity
		let defaultFloor = new pc.Entity('defaultfloor');

		// Afmetingen van de defaultvloer
		defaultFloor.setLocalScale(this.width, this.height, this.depth);

		// Normaal gesproken tekenen we die op 0, maar omdat we op 0 beginnen met tekenen tekenen we de defaultfloor als beginpunt onder de 0.
		// hierdoor als we iets op positie y 0 plaatsen staat het echt op de vloer / defaultfloor.
		defaultFloor.setPosition(this.x, this.y - this.height - 0.005, this.z);

		defaultFloor.addComponent('light', {
			type: 'directional',
			range: 35,
			intensity: 0.85,
			innerConeAngle: 95,
			falloffMode: pc.LIGHTFALLOFF_LINEAR,
			affectDynamic: true,
			castsShadows: false,
		});

		defaultFloor.addComponent('model', { type: 'box' });

		let texture = app.assets.find('ground.jpg', 'texture');

		if (texture !== null && typeof texture !== 'undefined') {
			let material = new pc.StandardMaterial();
			material.diffuseMap = app.assets.find('ground.jpg', 'texture').resource;

			material.diffuseMapOffset.set(0, 0.25);
			let transform = new pc.Vec4(90, 90, 90, 90);
			defaultFloor.model.meshInstances[0].setParameter('texture_diffuseMapTransform', transform.data);
			material.update();
			if (material !== null && typeof material !== 'undefined') {
				defaultFloor.model.material = material;
				// defaultFloor.model.meshInstances[0].material = material;
			}
		}

		// toevoegen aan het canvas
		app.root.addChild(defaultFloor);
	}
}
