import { Configuration } from '../../../configurator/configuration';
import { HandRails } from '../../../configurator/handRails';
import { Canvas3D } from '../../Canvas3D';
import { Model3D } from '../../Model3D';

// DIT IS DE RAILING OP DE PAALTJES
export class HandRailPlateMiddle3D extends Model3D {
	objectName = 'HandRailPlateMiddle3D';
	hoverTags = ['handrail'];
	constructor(x, y, z, params) {
		super(x, y, z, params);
		this.width = params.length;
		this.oid = params.handRailMaterials.handRail;
	}
	draw(app) {
		let poleWidth = Canvas3D.CURRENT.getModelDataByOid(this.handRailMaterials.post, 'width');

		let plateDepth = Canvas3D.CURRENT.getModelDataByOid(this.handRailMaterials.handRail, 'depth');
		let plateHeight = Canvas3D.CURRENT.getModelDataByOid(this.handRailMaterials.handRail, 'height');

		if (Configuration.CURRENT.colors.handRail.finish === 1 || Configuration.CURRENT.colors.handRail.finish === 2) {
			this.ralColor = 7036;
		} else {
			this.ralColor = Configuration.CURRENT.colors.handRail.ralColor;
		}

		this.ralColor = 7036;

		this.rotationX = -90;
		this.y += this.cornerPoleHeight / 2;

		switch (this.position) {
			case HandRails.TOP:
				this.x += poleWidth;
				this.z += plateHeight;
				break;
			case HandRails.BOTTOM:
				this.rotationX = 90;
				this.x += poleWidth;
				this.y += plateDepth;
				this.z += poleWidth - plateHeight;
				break;
			case HandRails.LEFT:
				this.rotationY = -90;
				this.rotationX = 90;
				this.y += plateDepth;
				this.x += plateHeight;
				this.z += poleWidth;
				break;
			case HandRails.RIGHT:
				this.rotationY = -90;
				this.x += plateHeight;
				this.z += poleWidth;
				break;
		}

		this.width -= poleWidth;
		super.draw(app);
	}
}
