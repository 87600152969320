import { Data } from './data';
import { Store } from './store';
export class ColumnProtectors extends Data {
	objectName = 'ColumnProtectors';

	url = 'ColumnProtectors';
	getColumnProtector(position) {
		// als er al een lijst is opgehaald dan opzoeken. Deze hoort al aanwezig te zijn en wordt opgehaald op het moment dat er gekozen wordt
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			let columnProtector = this._list.list.find((cp) => cp.type === position);
			if (typeof columnProtector !== 'undefined' && columnProtector !== null) {
				return columnProtector;
			}
			return { id: 0, type: '', isCorner: true };
		} else if (this.listRequest === null) {
			this.list();
		}
		return { id: 0, type: '', isCorner: true };
	}
	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}
		if (typeof data.companyId === 'undefined' || data.companyId === null || data.companyId === -1) {
			data.companyId = Store.CURRENT.companies.selectedCompany.id;
		}

		super.list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack);
	}
}
