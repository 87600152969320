import { Box3D } from '../box3D';

export class Hole3D extends Box3D {
	objectName = 'Hole3D';
	constructor(x, y, params) {
		super(x, y, params);
	}

	draw(app) {
		super.draw(app);
	}
}
