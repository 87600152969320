import { Rectangle } from '../draw/rectangle';
import { DrawValue } from '../draw/drawValue';
import { ObjectGroup } from '../draw/objectGroup';

import { SizeHandleTotalOutside } from '../draw/SizeHandleTotalOutside';
import { RemoveRaster } from './removeRaster';
import { Raster } from './raster';
import { Columns } from './columns';
import { Configuration } from './configuration';
import { SizeHandle } from '../draw/sizeHandle';
import { Canvas } from '../draw/canvas';

export class Overhang {
	objectName = 'Overhang';
	static LEFT = 1;
	static TOP = 2;
	static RIGHT = 3;
	static BOTTOM = 4;
	_visible = true;
	get visible() {
		return this._visible;
	}
	set visible(value) {
		this._visible = value;
		this.onChange();
	}
	max = 500;
	_size = 0;
	get size() {
		return this._size;
	}
	set size(value) {
		this._size = parseInt(value);

		this.onChange();
	}
	color = '#dcdcdc';
	mousePriority = 30;
	mouseAreaOffset = { x: 0, y: 10 };
	_onChange = null;
	constructor(onChange) {
		this._onChange = onChange;
	}
	setReferences(params) {
		this._onChange = params.onChange;
	}
	removeReferences() {
		this._onChange = null;
	}
	onChange() {
		if (typeof this._onChange === 'function') {
			this._onChange();
		}
	}

	onChangeMainBeamLength(raster, delta, evt, drawObject) {
		this.onChangeChildBeamLength(raster, delta, evt, drawObject); // voor overhang mainbeamlength zelfde als childbeamlength
	}
	onChangeChildBeamLength(raster, delta, evt, drawObject) {
		const rasterX = raster.x;
		const rasterY = raster.y;
		const overhangX = drawObject.objectParams.raster.x;
		const overhangY = drawObject.objectParams.raster.y;
		const activeEtage = Configuration.CURRENT.etages.activeEtage();

		if (rasterY > -1) {
			if (overhangY === rasterY) {
				drawObject.drawObjects.forEach((overhang, index) => {
					if (
						!(
							(overhangX === 0 && overhang.objectParams.place === 'links') ||
							(overhangX === activeEtage.raster.spansX.length - 1 && overhang.objectParams.place === 'rechts') ||
							(overhangY === 0 && overhang.objectParams.place === 'boven') ||
							(overhangY === activeEtage.raster.spansY.length - 1 && overhang.objectParams.place === 'onder')
						)
					) {
						if (overhang.objectParams.place === 'links' || overhang.objectParams.place === 'rechts') {
							overhang.y.value += delta.y;
							overhang.height.value -= delta.y;
						} else if (overhang.objectParams.place === 'boven') {
							overhang.y.value += delta.y;
						}
					}
				});
			}
			if (overhangY === rasterY - 1) {
				drawObject.drawObjects.forEach((overhang, index) => {
					if (
						!(
							(overhangX === 0 && overhang.objectParams.place === 'links') ||
							(overhangX === activeEtage.raster.spansX.length - 1 && overhang.objectParams.place === 'rechts') ||
							(overhangY === 0 && overhang.objectParams.place === 'boven') ||
							(overhangY === activeEtage.raster.spansY.length - 1 && overhang.objectParams.place === 'onder')
						)
					) {
						if (overhang.objectParams.place === 'links' || overhang.objectParams.place === 'rechts') {
							overhang.height.value += delta.y;
						} else if (overhang.objectParams.place === 'onder') {
							overhang.y.value += delta.y;
						}
					}
				});
			}
		}
		if (rasterX > -1) {
			if (overhangX === rasterX) {
				drawObject.drawObjects.forEach((overhang, index) => {
					if (
						!(
							(overhangX === 0 && overhang.objectParams.place === 'links') ||
							(overhangX === activeEtage.raster.spansX.length - 1 && overhang.objectParams.place === 'rechts') ||
							(overhangY === 0 && overhang.objectParams.place === 'boven') ||
							(overhangY === activeEtage.raster.spansY.length - 1 && overhang.objectParams.place === 'onder')
						)
					) {
						if (overhang.objectParams.place === 'boven' || overhang.objectParams.place === 'onder') {
							overhang.x.value += delta.x;
							overhang.width.value -= delta.x;
						} else if (overhang.objectParams.place === 'links') {
							overhang.x.value += delta.x;
						}
					}
				});
			}
			if (overhangX === rasterX - 1) {
				drawObject.drawObjects.forEach((overhang, index) => {
					if (
						!(
							(overhangX === 0 && overhang.objectParams.place === 'links') ||
							(overhangX === activeEtage.raster.spansX.length - 1 && overhang.objectParams.place === 'rechts') ||
							(overhangY === 0 && overhang.objectParams.place === 'boven') ||
							(overhangY === activeEtage.raster.spansY.length - 1 && overhang.objectParams.place === 'onder')
						)
					) {
						if (overhang.objectParams.place === 'boven' || overhang.objectParams.place === 'onder') {
							overhang.width.value += delta.x;
						} else if (overhang.objectParams.place === 'rechts') {
							overhang.x.value += delta.x;
						}
					}
				});
			}
		}
	}

	addDrawObjects(canvas, params) {
		if (this.visible === false) {
			return;
		}

		if (canvas.sizeHandles.get(SizeHandle.TYPE_TOTAL) === null || typeof canvas.sizeHandles.get(SizeHandle.TYPE_TOTAL) === 'undefined') {
			let sizeHandleTotalOutside = new SizeHandleTotalOutside(SizeHandle.TYPE_TOTAL, 0);
			canvas.sizeHandles.push(sizeHandleTotalOutside);
		}

		params.raster.spansX.getSpans().forEach((spanX, indexX) => {
			params.raster.spansY.getSpans().forEach((spanY, indexY) => {
				// controleer of etage actief is.
				if (params.actieveEtage.isActiveRaster(new RemoveRaster(indexX, indexY)) === true) {
					const overhangGroup = new ObjectGroup(this.color, null, null, null, this, { raster: { x: indexX, y: indexY } });
					// lijn boven
					const topPosition = this.getPosition(indexX, indexY, Overhang.TOP);
					const topPositionDimensions = this.getLength(indexX, indexY, Overhang.TOP);

					if (topPosition !== null) {
						const top = new Rectangle(topPosition.startX, topPosition.startY, topPositionDimensions.width, topPositionDimensions.height);

						if (typeof top !== 'undefined') {
							top.mouseDetect = true; // nog toevoegen dat bij overhang mouseArea +/- breedte/hoogte is ofwel +/- this.size (een unscaled)
							top.object = this;
							top.objectParams = { x: indexX, y: indexY, place: 'boven' };
							overhangGroup.push(top);
						}
					}

					// lijn onder
					const bottomPosition = this.getPosition(indexX, indexY, Overhang.BOTTOM);
					const bottomPositionDimensions = this.getLength(indexX, indexY, Overhang.BOTTOM);

					if (bottomPosition !== null) {
						const bottom = new Rectangle(bottomPosition.startX, bottomPosition.startY, bottomPositionDimensions.width, bottomPositionDimensions.height);

						if (typeof bottom !== 'undefined') {
							bottom.mouseDetect = true; // nog toevoegen dat bij overhang mouseArea +/- breedte/hoogte is ofwel +/- this.size (een unscaled)
							bottom.object = this;
							bottom.objectParams = { x: indexX, y: indexY, place: 'onder' };
							overhangGroup.push(bottom);
						}
					}

					// lijn links
					const leftPosition = this.getPosition(indexX, indexY, Overhang.LEFT);
					const leftPositionDimensions = this.getLength(indexX, indexY, Overhang.LEFT);

					if (leftPosition !== null) {
						const left = new Rectangle(leftPosition.startX, leftPosition.startY, leftPositionDimensions.height, leftPositionDimensions.width);

						if (typeof left !== 'undefined') {
							left.mouseDetect = true; // nog toevoegen dat bij overhang mouseArea +/- breedte/hoogte is ofwel +/- this.size (een unscaled)
							left.object = this;
							left.objectParams = { x: indexX, y: indexY, place: 'links' };
							overhangGroup.push(left);
						}
					}
					// lijn rechts
					const rightPosition = this.getPosition(indexX, indexY, Overhang.RIGHT);
					const rightPositionDimensions = this.getLength(indexX, indexY, Overhang.RIGHT);

					if (rightPosition !== null) {
						const right = new Rectangle(rightPosition.startX, rightPosition.startY, rightPositionDimensions.height, rightPositionDimensions.width);

						if (typeof right !== 'undefined') {
							right.mouseDetect = true; // nog toevoegen dat bij overhang mouseArea +/- breedte/hoogte is ofwel +/- this.size (een unscaled)
							right.object = this;
							right.objectParams = { x: indexX, y: indexY, place: 'rechts' };
							overhangGroup.push(right);
						}
					}

					if (overhangGroup.containsItems() === true) {
						Canvas.CURRENT.addDrawObject(overhangGroup);
					}
				}
			});
		});
	}

	getLength(x, y, position, etage = null) {
		if (etage === null) {
			etage = Configuration.CURRENT.etages.activeEtage();
		}
		let result = null;
		switch (position) {
			case Overhang.TOP:
				result = this.getLengthOverhangHorizontal({ x: x, y: y }, Raster.BOVEN, Raster.LINKSBOVEN, Raster.LINKS, Raster.RECHTSBOVEN, Raster.RECHTS, etage);
				break;
			case Overhang.BOTTOM:
				result = this.getLengthOverhangHorizontal({ x: x, y: y }, Raster.ONDER, Raster.LINKSONDER, Raster.LINKS, Raster.RECHTSONDER, Raster.RECHTS, etage);
				break;
			case Overhang.LEFT:
				result = this.getLengthOverhangVertical({ x: x, y: y }, Raster.LINKS, Raster.BOVEN, Raster.LINKSBOVEN, Raster.ONDER, Raster.LINKSONDER, etage);
				break;
			case Overhang.RIGHT:
				result = this.getLengthOverhangVertical({ x: x, y: y }, Raster.RECHTS, Raster.BOVEN, Raster.RECHTSBOVEN, Raster.ONDER, Raster.RECHTSONDER, etage);
				break;
		}
		return result;
	}

	getLengthOverhangHorizontal(startRaster, boven, linksBoven, links, rechtsBoven, rechts, etage) {
		let result = null;
		// variabele namen beredeneert vanuit lijn boven.  Moeten nog aangepast worden
		const bovenAanwezig = etage.isActiveRaster(this.sumCoordinate(startRaster, boven));
		const linksBovenAanwezig = etage.isActiveRaster(this.sumCoordinate(startRaster, linksBoven));
		const linksAanwezig = etage.isActiveRaster(this.sumCoordinate(startRaster, links));
		const rechtsAanwezig = etage.isActiveRaster(this.sumCoordinate(startRaster, rechts));
		const rechtsBovenAanwezig = etage.isActiveRaster(this.sumCoordinate(startRaster, rechtsBoven));

		let gebruikLengte = { value: 0, offset: 0 };
		let span = etage.raster.spansX.get(startRaster.x);
		if (span !== null && typeof span !== 'undefined') {
			gebruikLengte.value = span.value;
		}

		if (bovenAanwezig === false) {
			if (linksAanwezig === false) {
				if (linksBovenAanwezig === false) {
					gebruikLengte = this.sumWithOffset(gebruikLengte, { value: this.size, offset: Columns.COLUMN_SIZE / 2 });
				}
			}
			if (rechtsAanwezig === false) {
				if (rechtsBovenAanwezig === false) {
					gebruikLengte = this.sumWithOffset(gebruikLengte, { value: this.size, offset: 0 });
				}
			}
			if (linksBovenAanwezig === true) {
				gebruikLengte = this.sumWithOffset(gebruikLengte, { value: -this.size, offset: -Columns.COLUMN_SIZE / 2 });
			}

			if (rechtsBovenAanwezig === true) {
				gebruikLengte = this.sumWithOffset(gebruikLengte, { value: -this.size, offset: -Columns.COLUMN_SIZE - Columns.COLUMN_SIZE / 2 });
			}
		}

		result = {
			width: new DrawValue(gebruikLengte.value, gebruikLengte.offset + Columns.COLUMN_SIZE / 2),
			height: new DrawValue(0, 1),
		};
		return result;
	}
	getLengthOverhangVertical(startRaster, links, boven, linksBoven, onder, linksOnder, etage) {
		let result = null;
		// variabele namen beredeneert vanuit lijn links.  Moeten nog aangepast worden
		const linksAanwezig = etage.isActiveRaster(this.sumCoordinate(startRaster, links));
		const linksBovenAanwezig = etage.isActiveRaster(this.sumCoordinate(startRaster, linksBoven));
		const bovenAanwezig = etage.isActiveRaster(this.sumCoordinate(startRaster, boven));
		const onderAanwezig = etage.isActiveRaster(this.sumCoordinate(startRaster, onder));
		const linksOnderAanwezig = etage.isActiveRaster(this.sumCoordinate(startRaster, linksOnder));

		let gebruikLengte = { value: 0, offset: 0 };
		let span = etage.raster.spansY.get(startRaster.y);
		if (span !== null && typeof span !== 'undefined') {
			gebruikLengte.value = span.value;
		}

		// Als links geen raster dan tekenen
		if (linksAanwezig === false) {
			if (bovenAanwezig === false) {
				if (linksBovenAanwezig === false) {
					// startpunt verplaatst dan ook lengte erbij
					gebruikLengte = this.sumWithOffset(gebruikLengte, { value: this.size, offset: Columns.COLUMN_SIZE / 2 });
				}
			}
			if (onderAanwezig === false) {
				if (linksOnderAanwezig === false) {
					gebruikLengte = this.sumWithOffset(gebruikLengte, { value: this.size, offset: Columns.COLUMN_SIZE / 2 });
				}
			}
			if (linksBovenAanwezig === true) {
				gebruikLengte = this.sumWithOffset(gebruikLengte, { value: -this.size, offset: -Columns.COLUMN_SIZE / 2 });
			}

			if (linksOnderAanwezig === true) {
				gebruikLengte = this.sumWithOffset(gebruikLengte, { value: -this.size, offset: -Columns.COLUMN_SIZE / 2 });
			}
			result = {
				width: new DrawValue(gebruikLengte.value, gebruikLengte.offset),
				height: new DrawValue(0, 1),
			};
		}
		return result;
	}

	getPosition(x, y, position, etage = null) {
		if (etage === null) {
			etage = Configuration.CURRENT.etages.activeEtage();
		}
		let result = null;
		switch (position) {
			case Overhang.TOP:
				result = this.getPositionOverhangHorizontal(
					{ x: x, y: y },
					{ x: { value: etage.raster.getSizeX(x - 1), offset: 0 }, y: { value: etage.raster.getSizeY(y - 1), offset: -Columns.COLUMN_SIZE / 2 } },
					Raster.BOVEN,
					Raster.LINKSBOVEN,
					Raster.LINKS,
					Raster.RECHTSBOVEN,
					Raster.RECHTS,
					{ x: { value: 0, offset: 0 }, y: { value: -this.size, offset: 0 } },
					etage,
				);
				break;
			case Overhang.BOTTOM:
				result = this.getPositionOverhangHorizontal(
					{ x: x, y: y },
					{ x: { value: etage.raster.getSizeX(x - 1), offset: 0 }, y: { value: etage.raster.getSizeY(y), offset: Columns.COLUMN_SIZE / 2 } },
					Raster.ONDER,
					Raster.LINKSONDER,
					Raster.LINKS,
					Raster.RECHTSONDER,
					Raster.RECHTS,
					{ x: { value: 0, offset: 0 }, y: { value: this.size, offset: 0 } },
					etage,
				);
				break;
			case Overhang.LEFT:
				result = this.getPositionOverhangVertical(
					{ x: x, y: y },
					{ x: { value: etage.raster.getSizeX(x - 1), offset: -Columns.COLUMN_SIZE / 2 }, y: { value: etage.raster.getSizeY(y - 1), offset: 0 } },
					Raster.LINKS,
					Raster.BOVEN,
					Raster.LINKSBOVEN,
					Raster.ONDER,
					Raster.LINKSONDER,
					{ x: { value: -this.size, offset: 0 }, y: { value: 0, offset: 0 } },
					etage,
				);
				break;
			case Overhang.RIGHT:
				result = this.getPositionOverhangVertical(
					{ x: x, y: y },
					{ x: { value: etage.raster.getSizeX(x), offset: Columns.COLUMN_SIZE }, y: { value: etage.raster.getSizeY(y - 1), offset: 0 } },
					Raster.RECHTS,
					Raster.BOVEN,
					Raster.RECHTSBOVEN,
					Raster.ONDER,
					Raster.RECHTSONDER,
					{ x: { value: this.size, offset: 0 }, y: { value: 0, offset: 0 } },
					etage,
				);
				break;
		}
		return result;
	}

	getPositionOverhangHorizontal(startRaster, startPosition, boven, linksBoven, links, rechtsBoven, rechts, overhang, etage) {
		let result = null;
		// variabele namen beredeneert vanuit lijn boven.  Moeten nog aangepast worden
		const bovenAanwezig = etage.isActiveRaster(this.sumCoordinate(startRaster, boven));
		const linksBovenAanwezig = etage.isActiveRaster(this.sumCoordinate(startRaster, linksBoven));
		const linksAanwezig = etage.isActiveRaster(this.sumCoordinate(startRaster, links));

		// als boven geen raster dan tekenen
		if (bovenAanwezig === false) {
			let startPoint = this.sumCoordinateWithOffset(startPosition, overhang);
			if (linksAanwezig === false) {
				if (linksBovenAanwezig === false) {
					startPoint = this.sumCoordinateWithOffset(startPoint, { x: { value: -this.size, offset: -Columns.COLUMN_SIZE }, y: { value: 0, offset: 0 } });
				}
			}
			if (linksBovenAanwezig === true) {
				startPoint = this.sumCoordinateWithOffset(startPoint, { x: { value: this.size, offset: Columns.COLUMN_SIZE / 2 }, y: { value: 0, offset: 0 } });
			}
			const startX = new DrawValue(startPoint.x.value, startPoint.x.offset - Columns.COLUMN_SIZE / 2);
			const startY = new DrawValue(startPoint.y.value, startPoint.y.offset);

			let dimensions = this.getLengthOverhangHorizontal(startRaster, boven, linksBoven, links, rechtsBoven, rechts, etage);

			result = {
				startX: startX,
				startY: startY,
				endX: startX.sum(dimensions.width),
				endY: startY.sum(dimensions.height),
			};
		}
		return result;
	}
	getPositionOverhangVertical(startRaster, startPosition, links, boven, linksBoven, onder, linksOnder, overhang, etage) {
		let result = null;
		// variabele namen beredeneert vanuit lijn links.  Moeten nog aangepast worden
		const linksAanwezig = etage.isActiveRaster(this.sumCoordinate(startRaster, links));
		const linksBovenAanwezig = etage.isActiveRaster(this.sumCoordinate(startRaster, linksBoven));
		const bovenAanwezig = etage.isActiveRaster(this.sumCoordinate(startRaster, boven));
		// als links geen raster dan tekenen
		if (linksAanwezig === false) {
			let startPoint = this.sumCoordinateWithOffset(startPosition, overhang);
			if (bovenAanwezig === false) {
				if (linksBovenAanwezig === false) {
					startPoint = this.sumCoordinateWithOffset(startPoint, { x: { value: 0, offset: 0 }, y: { value: -this.size, offset: -Columns.COLUMN_SIZE / 2 } });
				}
			}
			if (linksBovenAanwezig === true) {
				startPoint = this.sumCoordinateWithOffset(startPoint, { x: { value: 0, offset: 0 }, y: { value: this.size, offset: Columns.COLUMN_SIZE / 2 } });
			}

			const startX = new DrawValue(startPoint.x.value, startPoint.x.offset - Columns.COLUMN_SIZE);
			const startY = new DrawValue(startPoint.y.value, startPoint.y.offset);

			let dimensions = this.getLengthOverhangVertical(startRaster, links, boven, linksBoven, onder, linksOnder, etage);

			result = {
				startX: startX,
				startY: startY,
				endX: startX.sum(dimensions.height),
				endY: startY.sum(dimensions.width),
			};
		}
		return result;
	}

	sumCoordinate(coordinate1, coordinate2) {
		return { x: coordinate1.x + coordinate2.x, y: coordinate1.y + coordinate2.y };
	}
	sumCoordinateWithOffset(coordinate1, coordinate2) {
		return { x: this.sumWithOffset(coordinate1.x, coordinate2.x), y: this.sumWithOffset(coordinate1.y, coordinate2.y) };
	}
	sumWithOffset(value1, value2) {
		return {
			value: value1.value + value2.value,
			offset: value1.offset + value2.offset,
		};
	}
}
